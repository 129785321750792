import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ListService } from "app/services/list/list.service";

@Component({
  selector: "app-assitancesocial",
  templateUrl: "./assitancesocial.component.html",
  styleUrls: ["./assitancesocial.component.css"],
})
export class AssitancesocialComponent implements OnInit {
  id: string;
  assistSociale: [] = [];
  constructor(private route: ActivatedRoute, private list: ListService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getAssistanceSociale()
  }

  getAssistanceSociale(){
    this.list.getAssistanceSocialeByMatricule(this.id).subscribe(resp=>{
      this.assistSociale = resp.body
      
    })

  }
}

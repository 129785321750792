import { Router } from '@angular/router';
import { DragdropService } from './../../services/dropdrag/dragdrop.service';
import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account/account.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-liste-enquete',
  templateUrl: './liste-enquete.component.html',
  styleUrls: ['./liste-enquete.component.css']
})
export class ListeEnqueteComponent implements OnInit {
  liste: any[];
  recherche: any;

  totalItems: any;
  totalPages: any;
  currentPage: number = 1;
  textRecherche: "";
  enqueteToDelete: any;
  dataEnquete: any;

  constructor(
    private accountService: AccountService,
    private service: DragdropService,
    private router: Router,
    private toast: ToastrService) { }

  ngOnInit(): void {
    this.getAlllisteEnquete();

  }
  suppr() {
    this.service.deleteUser(this.enqueteToDelete).subscribe(
        (response) => {
            this.toast.success('Suppression réussie')
            window.location.reload();
          
        },(error) => {
          this.toast.error("Une erreur c'est produit");
        }
    );
}
  openModal(matricule) {
    this.enqueteToDelete = matricule;
    $('#exampleModalLongConfsupp').modal('show');
}
  getAlllisteEnquete() {
    this.service.listeEnquete().subscribe(res => {
      if (res.status === 200) {
        const ids = res.body.map(o => o.idEnquete)
        const filtered = res.body.filter(({idEnquete}, index) => !ids.includes(idEnquete, index + 1))
        this.paginate(filtered)
      }
    })
  }
  
  async paginate(items) {
    const startIndex = (this.currentPage - 1) * 10;
    const endIndex = Math.min(this.currentPage * 10, items.length);
    let countPage = 0
    this.liste = items.slice(startIndex, endIndex);
    countPage = Math.ceil(items.length / 10);
    this.totalPages = countPage;      
    this.currentPage = this.currentPage;
    this.totalItems =  countPage * countPage;    
  }

  filttre(text){
    if (text.target.value != "") {
      this.textRecherche = text.target.value;
      this.getAlllisteEnquete();
    } else {
      this.textRecherche = "";
      this.getAlllisteEnquete();
    }
  }

  changeValue(page: number) {
    if (page) {
      this.currentPage = page;
      this.getAlllisteEnquete();
    }
  }

  detail(id: any){
    this.router.navigate(['detail-enquete/'+id])
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }


}

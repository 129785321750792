import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../services/account/account.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import * as htmlToImage from 'html-to-image';
import { image } from 'html2canvas/dist/types/css/types/image';
import { CardService } from '../../services/card/card.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { any } from 'codelyzer/util/function';
@Component({
  selector: 'app-card-tech',
  templateUrl: './card-tech.component.html',
  styleUrls: ['./card-tech.component.css']
})
export class CardTechComponent implements OnInit {

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;


  page = 1;
  pageSize = 10;
  collectionSize: any;
  returnUrl: string
  showModal: boolean
  showModal2: boolean
  details: any = {}
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  etat_Civil: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  listProducteur: any
  listMenFamille: any
  detail: any
  data: any
  closeModal: string;
  loading: boolean;
  CardService: any;
  image: any;
  sary: any;
  dataAppui: any;
  dataFammile: any;
  dataSecActivite: any;
  constructor(private accountService: AccountService,
     private route: ActivatedRoute,
      private router: Router,
      private cardServ: CardService,
      private toast : ToastrService
      ) { }

  ngOnInit(): void {
    this.getAllListProduction()
    this.redirectionIncrire()
    this.getAllListeMenFamille()

  }

  getAllListProduction() {
    this.accountService.getListProducteur().subscribe(data => {
      if (data.status === 200) {
        this.listProducteur = data.body
        this.collectionSize = this.listProducteur.length
        this.refreshListProducteur()
      }
    })
  }
  getAllListeMenFamille() {
    this.accountService.getListProducteur().subscribe(data => {
      if (data.status === 200) {
        this.listMenFamille = []
        this.listMenFamille = data.body
        this.collectionSize = this.listMenFamille.length
        
      }
    })
  }
  generatePDF() {
    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      const img = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      img.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      img.save('newPDF.pdf');
    });

  }
  generatePDFRecto() {
    const data = document.getElementById('aina');
    html2canvas(data).then(canvas => {
      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      const img = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      img.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      img.save('newPDF.pdf');
    });

  }
  generatePDFVerso() {
    const data = document.getElementById('bota');
    html2canvas(data).then(canvas => {
      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      const img = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      img.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      img.save('newPDF.pdf');
    });

  }
  downloadImage() {
    const data: any = document.getElementById('contentToConvert');
    
   const kk = html2canvas(data).then(canvas => {
      const imgWidth = 1004;
      const imgHeight = 650;
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'card.png';
      this.downloadLink.nativeElement.click();
      
      this.sary = this.dataURLtoFile(canvas.toDataURL(), 'image')
      const formData = new FormData()
    formData.append("matricule", this.details.matricule);
    formData.append("carteProd", this.sary);
    
    this.cardServ.image(formData).subscribe(res =>{
      if (res.status == 200) {
        this.toast.success('Enregistrement de l\'image avec success')
      }else{
        this.toast.error('Non enregistrer')
      }
    })
    });
    
    
  }

  dataURLtoFile(dataurl, filename) {
 
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]), 
        n = bstr.length, 
        u8arr = new Uint8Array(n);
        
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    
    return new File([u8arr], filename, {type:mime});
}

  downloadImageRecto() {
    const data = document.getElementById('aina');
    html2canvas(data).then(canvas => {
      const imgWidth = 1004;
      const imgHeight = 650;
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'card.png';
      this.downloadLink.nativeElement.click();
    });
  }
  downloadImageVerso() {
    const data = document.getElementById('bota');
    html2canvas(data).then(canvas => {
      const imgWidth = 1004;
      const imgHeight = 650;
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'card.png';
      this.downloadLink.nativeElement.click();
    });
  }

  matricule() {
    this.CardService.matricule(this.matricule)
        .pipe(first())
        .subscribe(
            data => {

                this.router.navigate([this.returnUrl]);

                this.loading = false;
                // this.notification.showNotification('top','center','success');
            },
            error => {
                // this.showDanger(error);
                // this.notification.showNotification('top','center','danger');
                this.loading = false;
                this.showNotification('top', 'right', 'danger', 'Mots de passe ou utilisateur incorrect');
            });
  }
  showNotification(arg0: string, arg1: string, arg2: string, arg3: string) {
    throw new Error('Method not implemented.');
  }

  ouvrirCarte(list) {
    this.details = list
    this.image = list.photos
    this.showModal = true;

  }

  em(e){
  }


  // Bootstrap Modal Close event
  hide() {
    this.showModal = false;
  }
  redirectionIncrire() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || 'register';
  }

  refreshListProducteur() {
    this.data = this.listProducteur
      .map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
  }


  search(value: string): void {

    if (value === '') {
      this.refreshListProducteur()
      this.collectionSize = this.listProducteur.length
    } else {
      let ret = []
      ret = this.listProducteur.filter((val) =>
      val.nom.toLowerCase().includes(value)
      || val.prenom.toLowerCase().includes(value)
      || val.contact.telephone.toLowerCase().includes(value)
      || val.matricule.toLowerCase().includes(value)
      || val.cin.toLowerCase().includes(value)
      || val.fokontany.nomFokontany.toLowerCase().includes(value)
      || val.lieuNaiss.toLowerCase().includes(value)
      || val.province.nomProvince.toLowerCase().includes(value));

      this.data = ret.map((country, i) => ({ id: i + 1, ...country }))
      .slice((this.page - 1) * this.pageSize, (this.page - 1) * this.pageSize + this.pageSize);
      this.collectionSize = this.data.length
    }
  }

  getInfoFamille(id){
    this.cardServ.getFammille(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataFammile = res.body;
      }
    })
  }
  getListSecActivite(id){
    this.cardServ.getSectActivite(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataSecActivite = res.body;
      }
    })
  }
  getInfoAppui(id){
    this.cardServ.getAppui(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataAppui = res.body;
      }
    })
  }
  ouvrirDetails(list) {
    this.details = list
    this.getInfoFamille(this.details.matricule);
    this.getInfoAppui(this.details.matricule)
    this.getListSecActivite(this.details.secteurActivite.idSecteur)
    this.showModal2 = true;
  }

  infoProd(index){
    this.router.navigate(['info-prod/'+index])
  }
  redirect() {
    this.router.navigate(['acceuil']);
  }
  modif(index){
    this.router.navigate(['updateprod/'+index])
  }

}
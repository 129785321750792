import { Component, OnInit } from '@angular/core';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-file-info',
  templateUrl: './file-info.component.html',
  styleUrls: ['./file-info.component.css']
})
export class FileInfoComponent implements OnInit {
  nombre: any[]=[];
  nombreTotal: any;

  constructor(
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.getNombreProd()
  }
  getNombreProd(){
    this.list.GetNombreProd().subscribe(resp=>{
      this.nombre=resp.body.indivByRegions
      this.nombreTotal=resp.body.nbreIndiv
    })
  }

  

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';
//import * as XLSX from 'xlsx';
@Component({
  selector: 'app-labelisation',
  templateUrl: './labelisation.component.html',
  styleUrls: ['./labelisation.component.css']
})
export class LabelisationComponent implements OnInit {
  id: string
  labelisation: any[]=[];
  fileName= 'SheetJS.xlsx';
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getLabelisation()
    
  }
  getLabelisation() {
    this.list.getLabelisation(this.id).subscribe(resp => {
      this.labelisation = resp.body
    })
  }
// exportActive:boolean = false;
  //exportexcel(): void{
 // // this.exportActive = true;
 //  const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.labelisation);
 //  const wb: XLSX.WorkBook = XLSX.utils.book_new();
 //  XLSX.utils.book_append_sheet(wb, ws, 'Labelisaton1');
 //  
 //  /* save to file */
 //  XLSX.writeFile(wb, 'Labelisaton1.xlsx');
 //}

}

import { CIVILITE } from './../dynamic-input/static/static-data';
import { InputTextBox } from './../dynamic-input/base-input/input-textbox';
import { InputService } from './../dynamic-input/providers/input.service';
import { InputBase } from './../dynamic-input/base-input/input-base';
import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ListService } from 'app/services/list/list.service';
import { FileValidator } from 'ngx-material-file-input';
import { DatePipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';

@Component({
  selector: 'app-ajout-prod',
  templateUrl: './ajout-prod.component.html',
  styleUrls: ['./ajout-prod.component.css']
})
export class AjoutProdComponent implements OnInit {
  dynamicForm: FormGroup;
  submitted = false;
  /* value: string; */
  readonly maxSize = 104857600;
  selectedFile: File;

  isLinear = false; // true si prod
  dinameFormGroup: FormGroup;
  thierdFormGroup: FormGroup;
  statusFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  adresseFormGroup: FormGroup;
  familleFormGroup: FormGroup;
  affiliationFormGroup: FormGroup;
  sectavtagriFormGroup: FormGroup;
  actFormGroup: FormGroup;
  benfFormGroup: FormGroup;
  itemStatus: any;
  showProd: boolean = false;
  itemEtatCivil: any;
  itemNivEtude: any;
  showMbrFammille: boolean = false;
  showBenAp: boolean = false;
  secteur: string;

  foko: any[] = [];
  commune: any;
  district: any;
  region: any;
  province: any;

  agr: any[] = [];
  elv: any[] = [];
  pis: any[] = [];

  dataAppui: any;

  public fieldAr: Array<any> = [];
  public newAt: any = {};
  public fieldA: Array<any> = [];
  public newA: any = {};
  public fieldAB: Array<any> = [];
  public newB: any = {};

  public newDetails: any = {};


  itemtypeprodele: any[];
  itemtypeprodagri: any;
  itemtypeprodpis: any;
  itemAppui: any[];

  showNumLog: boolean = false;
  showAffiliation: boolean = false;
  showImf: boolean = false;
  itemRefTechProd: any[];
  itemRefCondElevage: any;
  showAppui: boolean;
  itemdetailsAppui: any;
  detailAppuie: string;

  jsonStatic: InputBase<any>[] = [
    {
      value: "",
      key: "string",
      label: "NOM",
      required: false,
      order: 1,
      controlType: "string",
      type: "text",
      readonly: false
    },
    {
      key: 'valeur',
      label: 'Selction test',
      required: false,
      controlType: "string",
      type: "dropdown",
      readonly: true,
      options: [
        { key: '1', value: 'test 1' },
        { key: '2', value: 'andrana 2' },
        { key: '3', value: 'hafa 3' },
        { key: '4', value: 'io 4' }
      ],
      order: 2
    }
  ]
  @Input() inputs: InputBase<any>[] = [];
  constructor(
    private listService: ListService,
    private _formBuilder: FormBuilder,
    private toastr: ToastrService,
    private route: Router,
    private ac: AccountService,
    private inServ: InputService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.dynamicForm = this.formBuilder.group({
      numberOfTickets: [''],
      tickets: new FormArray([])
    });
    /*     this.statusFormGroup = this._formBuilder.group({ status: [''],});
        this.getStatus(); */
    this.inputs = CIVILITE
    this.getEtatCivil();
    this.getNivEtude();
    this.ppElevage();
    this.ppAgriculture();
    this.ppPisciculture();
    this.getAppui(),
      this.getTypeAppui(),
      this.getRefTechProd(),
      this.getRefCondElevage(),
      this.dinameFormGroup = this._formBuilder.group({

      });
    this.firstFormGroup = this._formBuilder.group({
      nom: [''],
      prenom: [''],
      lieuNaiss: [''],
      dateNaiss: [''],
      cin: [''],
      dateCIN: [''],
      lieuCIN: [''],
      sexe: [''],
      Métier: [''],

      producteur_etatCivil: [''],
      producteur_niveauEtude: [''],

    });
    this.secondFormGroup = this._formBuilder.group({
      fokontany: [''],
      commune: [''],
      district: [''],
      region: [''],
      province: [''],
      village: [''],
    });
    this.thierdFormGroup = this._formBuilder.group({
      photos: ['', FileValidator.maxContentSize(this.maxSize)]
    });
    this.adresseFormGroup = this._formBuilder.group({
      lot: [''],
      logement: [''],
      Numéro_de_logement: [''],
      Tel: [''],
      Mail: [''],
      Pseudo: ['']
    });
    this.familleFormGroup = this._formBuilder.group({
      Taille_de_ménage: [''],
      conjoint_nom: [''],
      conjoint_prenom: [''],
      age: [''],
      Enfants: [''],
    });
    this.affiliationFormGroup = this._formBuilder.group({
      Membre_OP: [''],
      Nom_OP_d_affiliation: [''],
      Date_d_affiliation: [''],
      Poste_au_sein_de_l_OP: [''],
      Membre_IMF: [''],
      IMF_d_affiliation: [''],
    })
    this.sectavtagriFormGroup = this._formBuilder.group({
      producteur_typeSecteurActivite: [''],
      producteur_agricultures_idProduit: [''],
      producteur_agricultures_lieuExploitation: [''],
      producteur_agricultures_superficie: [''],
      producteur_agricultures_Techniques: [''],
      producteur_agricultures_productionKg: [''],
      producteur_agricultures_utilisation: [''],
      producteur_agricultures_prixUnitaire: [''],
      producteur_agricultures_chiffresAffaires: [''],
      producteur_agricultures_frequenceAnnuelle: [''],
      producteur_agricultures_periodeProduction: [''],
      producteur_agricultures_venteAnnuelle: [''],

      secteur_Activite: [''],
      Type_Prod: [''],
      Produits_Possible: [''],
      Filiere: [''],

      producteur_elevages_idProduit: [''],
      producteur_elevages_lieuExploitation: [''],
      producteur_elevages_conduite: [''],
      producteur_elevages_utilisation: [''],
      producteur_elevages_productionKg: [''],
      producteur_elevages_prixUnitaire: [''],
      producteur_elevages_chiffresAffaires: [''],
      producteur_elevages_frequenceAnnuelle: [''],
      producteur_elevages_periodeProduction: [''],
      producteur_elevages_venteAnnuelle: [''],
      producteur_elevages_nbreCheptel: [''],
      producteur_elevages_periodeVente: [''],
      producteur_elevages_quatiteVendue: [''],

      producteur_piscicultures_idProduit: [''],
      producteur_piscicultures_lieuExploitation: [''],
      producteur_piscicultures_conduite: [''],
      producteur_piscicultures_utilisation: [''],
      producteur_piscicultures_productionKg: [''],
      producteur_piscicultures_prixUnitaire: [''],
      producteur_piscicultures_chiffresAffaires: [''],
      producteur_piscicultures_frequenceAnnuelle: [''],
      producteur_piscicultures_periodeProduction: [''],
      producteur_piscicultures_venteAnnuelle: [''],
      producteur_piscicultures_nbreTangs: [''],
      producteur_piscicultures_especeEleve: [''],

    })
    this.actFormGroup = this._formBuilder.group({
      secteur_Activite: [''],
      Type_Prod: [''],
      Produits_Possible: [''],
      Filiere: [''],
    })
    this.benfFormGroup = this._formBuilder.group({
      producteur_beneficiaireAppui: [''],
      Organisme_d_appui: [''],
      producteur_appui_typeAppui: [''],
      producteur_appui_periodeAppui: [''],
      producteur_appui_montantSub: [''],
      producteur_appui_montantPret: [''],
      producteur_appui_designationAppui: [''],
    })
  }
  onChange(event) {
    this.secondFormGroup.get('fokontany').setValue(event[0].data.id);
    this.listService.detailFokotany(event[0].data.id).subscribe(res => {
      if (res.status === 200) {
        const data = res.body;
        this.secondFormGroup.get('commune').setValue(data.idCommune);
        this.commune = data.nomCommune;
        this.secondFormGroup.get('district').setValue(data.district.idDistrict);
        this.district = data.district.nomDistrict;
        this.secondFormGroup.get('region').setValue(data.district.region.idRegion);
        this.region = data.district.region.nomRegion;
        this.secondFormGroup.get('province').setValue(data.district.region.province.idProvince);
        this.province = data.district.region.province.nomProvince;
      }
    })
  }
  fkt(index) {
    this.foko = []
    if (index.length > 3) {
      this.listService.prendListeFokotany(index).subscribe(resp => {
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.foko.push({
              id: resp.body[i].idFokontany,
              text: resp.body[i].nomFokontany
            })
          }
        }
      })
    }
  }
  Logement(id) {
    if (id.value === 'O') {
      this.showNumLog = true;
    } else {
      this.showNumLog = false;
    }
  }
  Affilliation(id) {
    if (id.value === 'O') {
      this.showAffiliation = true;
    } else {
      this.showAffiliation = false;
    }
  }
  imf(id) {
    if (id.value === 'O') {
      this.showImf = true;
    } else {
      this.showImf = false;
    }
  }
  etCiv(id) {
    if (id.value === '2') {
      this.showMbrFammille = true;
    } else {
      this.showMbrFammille = false;
    }
  }
  sectact(id) {
    this.secteur = id.value
  }
  state(id) {
    if (id.value === '2') {
      this.showProd = true;
    } else {
      this.showProd = false;
    }
  }
  BenAp(id) {
    if (id.value === 'O') {
      this.showBenAp = true;
    } else {
      this.showBenAp = false;
    }
  }
  appui(id) {
    this.detailApui(id.value)
    this.detailAppuie = id.value
  }

  addFieldValu() {
    this.fieldAr.push(this.newAt);
    this.newAt = {};
  }
  deleteFieldValu(index) {
    this.fieldAr.splice(index, 1);
  }
  addFieldVal() {
    this.fieldA.push(this.newA);
    this.newA = {};
  }
  deleteFieldVal(index) {
    this.fieldA.splice(index, 1);
  }

  getEtatCivil() {
    this.listService.findAllEtatCivil().subscribe(res => { this.itemEtatCivil = res.body; })
  }
  getNivEtude() {
    this.listService.findAllNivEtude().subscribe(res => { this.itemNivEtude = res.body; })
  }
  getTypeAppui() {
    this.listService.findAllTypeAppui().subscribe(res => { this.itemAppui = res.body; })
  }
  getRefTechProd() {
    this.listService.findAllRefTechProd().subscribe(res => { this.itemRefTechProd = res.body; })
  }
  getRefCondElevage() {
    this.listService.findAllRefCondElevage().subscribe(res => { this.itemRefCondElevage = res.body; })
  }

  ppAgriculture() {
    this.itemtypeprodagri = [];
    this.listService.prendProduitPossibleAgri().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtypeprodagri.push({
            id: res.body[i].idProduit,
            text: res.body[i].libelleProduit,
            texte: res.body[i].filiere.designationFiliere
          })
        }
      }
    })
  }
  ppElevage() {
    this.itemtypeprodele = [];
    this.listService.prendProduitPossibleElev().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtypeprodele.push({
            id: res.body[i].idProduit,
            text: res.body[i].libelleProduit,
            texte: res.body[i].filiere.designationFiliere
          })
        }
      }
    })
  }
  ppPisciculture() {
    this.itemtypeprodpis = [];
    this.listService.prendProduitPossiblePis().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtypeprodpis.push({
            id: res.body[i].idProduit,
            text: res.body[i].libelleProduit,
            texte: res.body[i].filiere.designationFiliere
          })
        }
      }
    })
  }
  /*   getDetailAppui() {
      this.itemdetailsAppui = [];
      this.listService.detailAppui(idTypeAppui[0].data.id).subscribe(res => {
        if (res.status === 200) {
          for (let i = 0; i < res.body.length; i++) {
            this.itemtypeprodpis.push({
              id: res.body[i].idTypeProdPossible,
              text: res.body[i].libelleTypeProdPossible
            })
          }
        }
      })
    } */
  detailApui(idTypeAppui) {
    this.listService.detailAppui(idTypeAppui).subscribe((resp) => {
      if (resp.status == 200) {
        let data: any = [];
        if (resp.body.length > 0) {
          for (let i = 0; i < resp.body.length; i++) {
            data.push({
              id: resp.body[i].idDetailAppui,
              text: resp.body[i].designationTypeAppui
            })
          }
          this.dataAppui = data;
        }
      }
    })
  }

  get f() { return this.dynamicForm.controls; }
  get t() { return this.f.tickets as FormArray; }
  changeFrequence(e) {
    const numberOfTickets = e.target.value || 0;
    if (this.t.length < numberOfTickets) {
      for (let i = this.t.length; i < numberOfTickets; i++) {
        this.t.push(this.formBuilder.group({
          producteur_elevages_periodeProduction: [''],
          producteur_agricultures_periodeProduction: [''],
          producteur_piscicultures_periodeProduction: [''],
        }));
      }
    } else {
      for (let i = this.t.length; i >= numberOfTickets; i--) {
        this.t.removeAt(i);
      }
    }
  }

  getAppui() {
    this.itemAppui = [];
    this.listService.findAllTypeAppui().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemAppui.push({
            id: res.body[i].idTypeAppui,
            text: res.body[i].libelleType
          })
        }
      }
    })
  }

  onMiova(event) {
    this.newAt.libelleProduit = event[0].data.text
    this.newAt.idProduit = event[0].data.id
    this.newAt.filiere = event[0].data.texte
    this.actFormGroup.get('Produits_Possible').setValue(event[0].data.id);
    this.listService.prendProduitPossibleElev().subscribe(res => {
      if (res.status === 200) {
        const data = res.body;
        /* this.actFormGroup.get('Produits_Possible').setValue(data.produitPossibles.libelleProduit);
        this.newAt.libelleTypeProdPossible = data.produitPossibles.libelleProduit; */
        this.actFormGroup.get('Filiere').setValue(event[0].data.id);
        this.newAt.filiere = data.newAt.filiere;
      }
    })
  }
  onMiova1(event) {
    this.newAt.idProduit = event[0].data.id
    this.newAt.libelleProduit = event[0].data.text
    this.newAt.filiere = event[0].data.texte
    this.actFormGroup.get('Produits_Possible').setValue(event[0].data.id);
    this.listService.prendProduitPossibleAgri().subscribe(res => {
      if (res.status === 200) {
        const data = res.body;
        /* this.actFormGroup.get('Produits_Possible').setValue(data.produitPossibles.libelleProduit);
        this.newAt.libelleTypeProdPossible = data.produitPossibles.libelleProduit; */
        this.actFormGroup.get('Filiere').setValue(event[0].data.id);
        this.newAt.filiere = data.newAt.filiere;
      }
    })
  }
  onMiova2(event) {
    this.newAt.filiere = event[0].data.texte
    this.newAt.libelleProduit = event[0].data.text
    this.newAt.idProduit = event[0].data.id
    this.actFormGroup.get('Produits_Possible').setValue(event[0].data.id);
    this.listService.prendProduitPossiblePis().subscribe(res => {
      if (res.status === 200) {
        const data = res.body;
        /* this.actFormGroup.get('Produits_Possible').setValue(data.produitPossibles.libelleProduit);
        this.newAt.libelleTypeProdPossible = data.produitPossibles.libelleProduit; */
        this.actFormGroup.get('Filiere').setValue(event[0].data.id);
        this.newAt.filiere = data.newAt.filiere;
      }
    })
  }
  public onFileChanged(event) {
    // Select File
    this.selectedFile = event.target.files[0];
  }
  registerProd() {
    var datePipe = new DatePipe('en-US');
    var date = datePipe.transform(this.firstFormGroup.get('dateNaiss').value, 'yyyy-MM-dd');

    const formData = new FormData()
    formData.append('status', '2');

    formData.append('fokontany.idFokontany', this.secondFormGroup.get('fokontany').value);
    formData.append('commune.idCommune', this.secondFormGroup.get('commune').value);
    formData.append('district.idDistrict', this.secondFormGroup.get('district').value);
    formData.append('region.idRegion', this.secondFormGroup.get('region').value);
    formData.append('province.idProvince', this.secondFormGroup.get('province').value);
    formData.append('village', this.secondFormGroup.get('village').value);

    formData.append('lot', this.adresseFormGroup.get('lot').value);
    formData.append('logement', this.adresseFormGroup.get('logement').value);
    formData.append('numLogement', this.adresseFormGroup.get('Numéro_de_logement').value);
    formData.append('telephone', this.adresseFormGroup.get('Tel').value);
    formData.append('mail', this.adresseFormGroup.get('Mail').value);
    formData.append('pseudo', this.adresseFormGroup.get('Pseudo').value);

    formData.append('photos', this.selectedFile);

    formData.append('nom', this.firstFormGroup.get('nom').value);
    formData.append('prenom', this.firstFormGroup.get('prenom').value);
    formData.append('lieuNaiss', this.firstFormGroup.get('lieuNaiss').value);
    formData.append('dateNaiss', date);
    formData.append('cin', this.firstFormGroup.get('cin').value);
    formData.append('dateCIN', this.firstFormGroup.get('dateCIN').value);
    formData.append('lieuCIN', this.firstFormGroup.get('lieuCIN').value);
    formData.append('sexe', this.firstFormGroup.get('sexe').value);
    formData.append('metier', this.firstFormGroup.get('Métier').value);
    formData.append('producteur.etatCivil', this.firstFormGroup.get('producteur_etatCivil').value);
    formData.append('producteur.niveauEtude', this.firstFormGroup.get('producteur_niveauEtude').value);

    formData.append('producteur.affiliation.membreOp', this.affiliationFormGroup.get('Membre_OP').value);
    formData.append('producteur.affiliation.nomOpAffiliation', this.affiliationFormGroup.get('Nom_OP_d_affiliation').value);
    formData.append('producteur.affiliation.anneeAffiliation', this.affiliationFormGroup.get('Date_d_affiliation').value);
    formData.append('producteur.affiliation.posteOp', this.affiliationFormGroup.get('Poste_au_sein_de_l_OP').value);
    formData.append('producteur.affiliation.membreIMF', this.affiliationFormGroup.get('Membre_IMF').value);
    formData.append('producteur.affiliation.imfAffiliation', this.affiliationFormGroup.get('IMF_d_affiliation').value);

    formData.append('producteur.conjoint.tailleMenage', this.familleFormGroup.get('Taille_de_ménage').value);
    formData.append('producteur.conjoint.nomConjoint', this.familleFormGroup.get('conjoint_nom').value);
    formData.append('producteur.conjoint.prenomConjoint', this.familleFormGroup.get('conjoint_prenom').value);
    formData.append('producteur.conjoint.dateNaissConjoint', this.familleFormGroup.get('age').value);
    formData.append('producteur.conjoint.nbrEnfant', this.familleFormGroup.get('Enfants').value);

    for (let i = 0; i < this.fieldAr.length; i++) {
      /* if (this.fieldAr[i].secteur === "Agriculture") { */
      switch (this.fieldAr[i].secteur) {
        case "Agriculture":
          this.agr.push(this.fieldAr[i])
          break;
        case "Elevage":
          this.elv.push(this.fieldAr[i])
          break;
        default:
          this.pis.push(this.fieldAr[i])
          break;
      }

    }
    /*       for(let i = 0; i < secteur_activite.length; i++){
            for(let k = 0; k < secteur_activite.length; k++){
              data.push(producteur.elevage[i].periodes[k].libellePeriode)
            }
          } */
    for (let i = 0; i < this.agr.length; i++) {
      formData.append('producteur.agricultures[' + i + '].idProduit', this.agr[i].idProduit);
      formData.append('producteur.agricultures[' + i + '].lieuExploitation', this.agr[i].lieuExploitation);
      formData.append('producteur.agricultures[' + i + '].productionKg', this.agr[i].productionKg);
      formData.append('producteur.agricultures[' + i + '].prixUnitaire', this.agr[i].prixUnitaire);
      formData.append('producteur.agricultures[' + i + '].chiffresAffaires', this.agr[i].chiffresAffaires);
      formData.append('producteur.agricultures[' + i + '].frequenceAnnuelle', this.agr[i].frequenceAnnuelle);

      for (let k = 0; k < this.agr.length; k++) {
        var date1 = datePipe.transform(this.agr[i].periodeProductionDebut, 'yyyy-MM-dd');
        var date2 = datePipe.transform(this.agr[i].periodeProductionFin, 'yyyy-MM-dd');
        formData.append('producteur.agricultures[' + i + '].periodes[' + k + '].libellePeriode', JSON.stringify(date1 + date2));
      }
      formData.append('producteur.agricultures[' + i + '].venteAnnuelle', this.agr[i].venteAnnuelle);
      formData.append('producteur.agricultures[' + i + '].techniqueAgriUse', this.agr[i].producteur_agricultures_Techniques);
      formData.append('producteur.agricultures[' + i + '].utilisationProduction', this.agr[i].utilisation);
      formData.append('producteur.agricultures[' + i + '].superficie', this.agr[i].superficie);
    }
    for (let i = 0; i < this.elv.length; i++) {
      formData.append('producteur.elevages[' + i + '].idProduit', this.elv[i].idProduit);
      formData.append('producteur.elevages[' + i + '].lieuExploitation', this.elv[i].lieuExploitation);
      formData.append('producteur.elevages[' + i + '].productionKg', this.elv[i].productionKg);
      formData.append('producteur.elevages[' + i + '].prixUnitaire', this.elv[i].prixUnitaire);
      formData.append('producteur.elevages[' + i + '].chiffresAffaires', this.elv[i].chiffresAffaires);
      formData.append('producteur.elevages[' + i + '].frequenceAnnuelle', this.elv[i].frequenceAnnuelle);

      var date1 = datePipe.transform(this.elv[i].periodeProductionDebut, 'yyyy-MM-dd');
      var date2 = datePipe.transform(this.elv[i].periodeProductionFin, 'yyyy-MM-dd');
      formData.append('producteur.elevages[' + i + '].periodeProduction', date1.toString() + date2.toString());

      formData.append('producteur.elevages[' + i + '].venteAnnuelle', this.elv[i].venteAnnuelle);
      formData.append('producteur.elevages[' + i + '].conduitElevage', this.elv[i].conduite);
      formData.append('producteur.elevages[' + i + '].utilisationProduction', this.elv[i].utilisation);
      /* formData.append('producteur.elevages['+i+'].superficie', this.elv[i].superficie); */
      formData.append('producteur.elevages[' + i + '].nbreCheptel', this.elv[i].nbreCheptel);
      formData.append('producteur.elevages[' + i + '].quatiteVendue', this.elv[i].quatiteVendue);

      var date1 = datePipe.transform(this.elv[i].periodeVenteDebut, 'yyyy-MM-dd');
      var date2 = datePipe.transform(this.elv[i].periodeVenteFin, 'yyyy-MM-dd');
      formData.append('producteur.elevages[' + i + '].periodeVente', date1.toString() + date2.toString());
    }
    for (let i = 0; i < this.pis.length; i++) {
      formData.append('producteur.piscicultures[' + i + '].idProduit', this.pis[i].idProduit);
      formData.append('producteur.piscicultures[' + i + '].lieuExploitation', this.pis[i].lieuExploitation);
      formData.append('producteur.piscicultures[' + i + '].productionKg', this.pis[i].productionKg);
      formData.append('producteur.piscicultures[' + i + '].prixUnitaire', this.pis[i].prixUnitaire);
      formData.append('producteur.piscicultures[' + i + '].chiffresAffaires', this.pis[i].chiffresAffaires);
      formData.append('producteur.piscicultures[' + i + '].frequenceAnnuelle', this.pis[i].frequenceAnnuelle);

      var date1 = datePipe.transform(this.pis[i].periodeProductionDebut, 'yyyy-MM-dd');
      var date2 = datePipe.transform(this.pis[i].periodeProductionFin, 'yyyy-MM-dd');
      formData.append('producteur.piscicultures[' + i + '].periodeProduction', date1.toString() + date2.toString());

      formData.append('producteur.piscicultures[' + i + '].venteAnnuelle', this.pis[i].venteAnnuelle);
      formData.append('producteur.piscicultures[' + i + '].conduitElevage', this.pis[i].conduite);
      formData.append('producteur.piscicultures[' + i + '].utilisationProduction', this.pis[i].utilisation);
      /* formData.append('producteur.piscicultures['+i+'].superficie', this.pis[i].superficie); */
      formData.append('producteur.piscicultures[' + i + '].nbreTangs', this.pis[i].nbreTangs);
      formData.append('producteur.piscicultures[' + i + '].especeEleve', this.pis[i].especeEleve);
    }
    formData.append('producteur.beneficiaireAppui', this.benfFormGroup.get('producteur_beneficiaireAppui').value);
    for (let i = 0; i < this.fieldA.length; i++) {
      formData.append('producteur.appui[' + i + '].nomOrganisme', this.fieldA[i].Organisme_d_appui);

      var date1 = datePipe.transform(this.fieldA[i].periodeAppuiDebut, 'yyyy-MM-dd');
      var date2 = datePipe.transform(this.fieldA[i].periodeAppuiFin, 'yyyy-MM-dd');
      formData.append('producteur.appui[' + i + '].anneeRecuAppui', date1.toString() + date2.toString());

      formData.append('producteur.appui[' + i + '].designationAppui', this.fieldA[i].producteur_appui_designationAppui);
      formData.append('producteur.appui[' + i + '].montantSub', this.fieldA[i].producteur_appui_montantSub);
      formData.append('producteur.appui[' + i + '].montantPret', this.fieldA[i].producteur_appui_montantPret);
      formData.append('producteur.appui[' + i + '].typeAppui', this.fieldA[i].producteur_appui_typeAppui);
    }
    new Response(formData).text().then(console.log)
    this.ac.lasa(formData).subscribe(res => {
      if (res.status === 200) {
        /* this.toastr.error('Erreur lord le l\'inscription') */
        this.toastr.success('Inscription avec success')
        this.route.navigate(['app_profil_individus'])
      } else {
        /* this.toastr.success('Inscription avec success')
        this.route.navigate(['login']) */
        this.toastr.error('Erreur lord le l\'inscription')
      }
    })
  }

}

import { InputService } from './../providers/input.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { InputBase } from './../base-input/input-base';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DragdropService } from 'app/services/dropdrag/dragdrop.service';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dynamic-input',
  templateUrl: './dynamic-input.component.html',
  styleUrls: ['./dynamic-input.component.css']
})
export class DynamicInputComponent implements OnInit {
  @Input() input: InputBase<any>;
  @Input() form: FormGroup;
  @Input() formAdress: FormGroup;
  @Input() AgeFormGroup: FormGroup;
  @Input() public tranche: any[] ;
  @Output() tranchefemelle: any[]=[];
  forms!: FormGroup;
  payLoad = ''
  
  showDate: string
  plus: any[]=[];
  miavaka: any;
  sectionForm: any;
  effectif: any[]=[];
  UniteFormGroup: FormGroup;
  produit: any[];
  typeService: any[];
/*   tranche: any[]=[];
  tranchefemelle: any[]=[]; */


  constructor(private inputScv: InputService,
    private _formBuilder: FormBuilder,private ac:AccountService) { }

  ngOnInit(): void {
    this.AgeFormGroup = this._formBuilder.group({
      trancheAge:['']
    })
    this.UniteFormGroup = this._formBuilder.group({
      unite:['']
    })
    this.miavaka = this.input
    this.sectionForm = this.miavaka.idSection
    
  }

  clavier(){
  	if(this.input["type"] == "number"){
  		let champ = $("#"+this.input["key"]);
  		let valeur = champ.val();
  		let nombre = Number(valeur.toString().replace(",","."));
  		let nonNombre = isNaN(nombre);
  		if(nonNombre){
  			champ.val("");
  		}
  	}
    
  }
  get isValid() { 
    return this.form.controls[this.input.key].valid;
  }
  fkt(index) {
    this.produit = [];
    if (index.length > 2) {
      this.ac.prendListeSpeculation(index).subscribe((resp) => {
        
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.produit.push({
              id: resp.body[i].code,
              text: resp.body[i].produit 
            });
          }
        }
      });
    }
  }

  service(index) {
    this.typeService = [];
    if (index.length > 2) {
      this.ac.prendListeService(index).subscribe((resp) => {
        
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.typeService.push({
              id: resp.body[i].codeTypeServiceAppui,
              text: resp.body[i].niveau4 
            });
          }
        }
      });
    }
  }

  
  ajout(){
    const data = this.form.value
    this.plus.push(this.form.value)
    this.effectif.push(this.AgeFormGroup.value)
    this.form.controls['effectifParTrancheAgeMale'].reset()
    const trancheAgeElevage = []
    for (let i = 0; i < this.plus.length && this.effectif.length; i++) {
      trancheAgeElevage.push(
        {
          "effectifParTrancheAge": this.plus[i].effectifParTrancheAgeMale,
          "sexeElevage": 1,
          "tranche_age": this.effectif[i].trancheAge
        }
      )
    }
    this.tranche = trancheAgeElevage
    
  }

  ajouter(){
    this.plus.push(this.form.value)
    this.effectif.push(this.AgeFormGroup.value)
    this.AgeFormGroup.reset()
    this.form.controls['effectifParTrancheAgeFemelle'].reset()
    const trancheAgeElevagefemelle = []
    for (let i = 0; i < this.plus.length && this.effectif.length; i++) {
      trancheAgeElevagefemelle.push(
        {
          "effectifParTrancheAge": this.plus[i].effectifParTrancheAgeFemelle,
          "sexeElevage": 2,
          "tranche_age": this.effectif[i].trancheAge
        }
      )
    }
    this.tranchefemelle = trancheAgeElevagefemelle
  }


  onSubmit() {
    this.payLoad = JSON.stringify(this.forms.getRawValue());
  }
  datevrais(id) {
    this.showDate = id.value
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-secteuractivite',
  templateUrl: './secteuractivite.component.html',
  styleUrls: ['./secteuractivite.component.css']
})
export class SecteuractiviteComponent implements OnInit {
  panelOpenState = false;
  id: string;
  listeSecteur: any[]=[];
  listeRente: any[]=[];
  listePeche: any[]=[];
  listeAquaculture: any[]=[];
  listeEngrais: any[]=[];
  listePerimetre: any[]=[];
  listeOrganique: any[]=[];
  listeChaulage: any[]=[];
  listeAgricultureGeneral: any[]=[];
  listeApicultures: any[]=[];
  listeSylviGeneral: any[]=[];
  listeSylviprod: any[]=[];
  listeSylviCasPepiniere: any[]=[];
  listeElevagePratique: any[]=[];
  listePratiquElevage: any[]=[];

  constructor(
    private route: ActivatedRoute,
    private list: ListService,
    private router: Router) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getEngraisMinerauxAzote()
    this.getPerimetreIrrigue()
    this.getagricultureGeneral()
    this.getRente()
    this.getAmedementOrganique()
    this.getchaulage()
    this.getApicultures()
    this.getPeche()
    this.getAquaculture()
    this.getSylviGeneral()
    this.getSylviprod()
    this.getSylviCasPepiniere()
    this.getElevagePratique()
    this.getPratiqueElevage()
    
  }

  getEngraisMinerauxAzote(){
    this.list.getEngraisMinerauxAzote(this.id).subscribe(resp=>{
    this.listeEngrais = resp.body.engraisMinerauxAzote
    })
  }

  getPerimetreIrrigue(){
    this.list.getPerimetreIrrigue(this.id).subscribe(resp=>{
    this.listePerimetre = resp.body.perimetreIrrigue
    })
  }

  getagricultureGeneral(){
    this.list.getagricultureGeneral(this.id).subscribe(resp=>{
    this.listeAgricultureGeneral = resp.body.agricultureGeneral
    })
  }
  getRente(){
    this.list.getRente(this.id).subscribe(resp=>{
    this.listeRente = resp.body.culturerente
    })
  }
  getAmedementOrganique(){
    this.list.getAmedementOrganique(this.id).subscribe(resp=>{
    this.listeOrganique = resp.body.amedementOrganique
    })
  }
  getchaulage(){
    this.list.getchaulage(this.id).subscribe(resp=>{
    this.listeChaulage = resp.body.chaulage
    })
  }
  getApicultures(){
    this.list.getApicultures(this.id).subscribe(resp=>{
    this.listeApicultures = resp.body
    })
  }
  getElevagePratique(){
    this.list.getElevagePratique(this.id).subscribe(resp=>{
    this.listeElevagePratique = resp.body.pratique
    })
  }

  getPeche(){
    this.list.getPeche(this.id).subscribe(resp=>{
    this.listePeche = resp.body
    })
  }
  getSylviGeneral(){
    this.list.getSylviGeneral(this.id).subscribe(resp=>{
    this.listeSylviGeneral = resp.body
    })
  }
  getSylviprod(){
    this.list.getSylviprod(this.id).subscribe(resp=>{
    this.listeSylviprod = resp.body
    })
  }

  getSylviCasPepiniere(){
    this.list.getSylviCasPepiniere(this.id).subscribe(resp=>{
    this.listeSylviCasPepiniere = resp.body
    })
  }

  getAquaculture(){
    this.list.getAquaculture(this.id).subscribe(resp=>{
    this.listeAquaculture = resp.body
    })
  }

  getPratiqueElevage(){
    this.list.getpratiqueElevage(this.id).subscribe(resp=>{
      this.listePratiquElevage = resp.body
      })
  }
  getdetails(libelle_type_secteur: string,matricule:string){
    this.router.navigate(['detailsecteur/'+matricule+'/'+libelle_type_secteur])
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-production',
  templateUrl: './production.component.html',
  styleUrls: ['./production.component.css']
})
export class ProductionComponent implements OnInit {
  id: string
  listeProduction: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getProduction()
  }
  getProduction(){
    this.list.getProduction(this.id).subscribe(response=>{
      this.listeProduction = response.body
    })
  }
}

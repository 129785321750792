import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-nouveau-motde-passe',
  templateUrl: './nouveau-motde-passe.component.html',
  styleUrls: ['./nouveau-motde-passe.component.css']
})
export class NouveauMotdePasseComponent implements OnInit {
  form: FormGroup;

  constructor(
    private ac : AccountService,
    private toastr : ToastrService,
    private route : Router
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      Nouveaumdp: new FormControl('', [Validators.required]), /* j'ai changer email par matricule et j'ai effacer Validators.email */
      code: new FormControl('', [Validators.required]),
    });
  }
  onSubmit(){
    const data = {
      "newPassword":  this.form.get('Nouveaumdp').value,
      "token":  this.form.get('code').value
    }
    this.ac.nadresse(data).subscribe(res=>{
      if (res.status === 200) {
        this.toastr.success("Modification avec succées");
        this.route.navigate(["login"]);
      }
    })
  }

/*   public hasError = (controlName: string, errorName: string) => {
    return this.f[controlName].hasError(errorName);
  }
  get f() { return this.form.controls; } */

}

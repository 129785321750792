import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-nutrition',
  templateUrl: './nutrition.component.html',
  styleUrls: ['./nutrition.component.css']
})
export class NutritionComponent implements OnInit {
  id: string
  listenutrition: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getNutrition()
  }
  getNutrition(){
    this.list.getNutritionByMatricule(this.id).subscribe(resp=>{
      this.listenutrition = resp.body
    })
  }

}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-line',
  templateUrl: './line.component.html',
  styleUrls: ['./line.component.css']
})
export class LineComponent implements OnInit, OnChanges{

  public barChartOptions: ChartOptions = {
    responsive: true,};
   // public barChartLabels: Label[] = [];
   /* '2006', '2007', '2008', '2009', '2010', '2011', '2012'*/
    public barChartType: ChartType = 'line';
    public barChartLegend = true;
    public barChartPlugins = [];


  @Input() dataChart: any
  dataX2: any[];
  dataY2: any[];
  dataY3: any[];
  dataX3: any[];
  dataY4: any[];
  dataX4: any[];
  dataSerie: any[];
  barChartLabels: any[];
  barChartData: any[];
  dataCondition: boolean;
  bardata: string[] = [];
  dataxv: any;
  dataxy: any;
  idataxv: any;
  idataxy: any;
  idataY: any;
  constructor() { }
  option: any
  dataX: any[] = []
  dataY: any[] = []

  ngOnInit(): void {
  }

  ngOnChanges() {
    this.modifierDataChart();
  }

  modifierDataChart() {
   /*  this.dataX = [];
    this.dataY = [];
    this.dataChart.map(element => {
      this.dataY.push(element.tauxPourcentage !== undefined ? element.tauxPourcentage : element.nbreTotal)
      this.dataX.push(element.nomColonne)
    }); */

    this.dataX = [];
    this.dataY = [];
    this.dataX2 = [];
    this.dataY2 =  [];
    this.dataY3 = [];
    this.dataX3 = [];
    this.dataY4 = [];
    this.dataX4 = [];
    this.dataSerie = [];
    this.barChartLabels = [];
    this.barChartData = [];
    this.dataChart.map(element => {
                  if( element.listeLocalite == undefined && element.listeLocalite ==null){
                    this.dataY.push(element.tauxPourcentage !== undefined ? element.tauxPourcentage : element.nbreTotal)
                    this.dataX.push( element.valeurColonne);
                    this.dataCondition=true;
                  }else{
                    this.dataCondition=false;
                    this.bardata.push( element.listeLocalite);
                        this.bardata = Object.values(element.listeLocalite)
                        this.dataY.push(element.listeTauxNbreColonnes);
                    for (let i = 0; i <  element.listeTauxNbreColonnes.length; i++) {
                      this.dataxv = element.listeTauxNbreColonnes[i].valueColonne
                      this.dataxy = element.listeTauxNbreColonnes[i].tauxNbreListe
                    };

                  }    
                });
    this.initChart()
  }

  initChart() {

   // GESTION OPTION bar chart AGGREGE
    this.dataChart.map(item => {
        if(item.listeLocalite == undefined && item.listeLocalite ==null){
          this.dataY2.push(item.tauxPourcentage !== undefined ? item.tauxPourcentage : item.nbreTotal)
          this.dataX2.push(item.valeurColonne);
        }else{
   this.barChartLabels = Object.values(item.listeLocalite)
        for (let i = 0; i <  item.listeTauxNbreColonnes.length; i++) {
          this.idataxv = item.listeTauxNbreColonnes[i].valueColonne
          this.idataxy = item.listeTauxNbreColonnes[i].tauxNbreListe
    this.barChartData[i] = { data:item.listeTauxNbreColonnes[i].tauxNbreListe , label:  item.listeTauxNbreColonnes[i].valueColonne } ; 
        }
        }
    });
    this.option = {
      color: ['#6d9401'],
      xAxis: {
        type: 'category',
        data: this.dataX
      },
      yAxis: {
        type: 'value'
      },
      series: [
        {
          data: this.dataY,
          type: 'line'
        }
      ]
    };
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-memfamille',
  templateUrl: './memfamille.component.html',
  styleUrls: ['./memfamille.component.css']
})
export class MemfamilleComponent implements OnInit {
  id: string
  memFamille: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getMemFamille()
  }
  getMemFamille() {
    this.list.getMemFamille(this.id).subscribe(resp => {
      this.memFamille = resp.body
    })
  }

}

import { TestService } from './../../services/realisation/test.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-realisation',
  templateUrl: './liste-realisation.component.html',
  styleUrls: ['./liste-realisation.component.css']
})
export class ListeRealisationComponent implements OnInit {
  idquest: any
  liste: any = {}
  declanche: any
  constructor(private validationService: TestService) { }

  ngOnInit(): void {
    this.declanche = false
  }
  getliste() {
    this.validationService.getRealisationTestByIdQuest(this.idquest).subscribe(data => {
      this.liste = data.body
      this.declanche = true
    })
  }
}

import { TestService } from './../../services/realisation/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-details-section',
  templateUrl: './details-section.component.html',
  styleUrls: ['./details-section.component.css']
})
export class DetailsSectionComponent implements OnInit {
  id:any;
  flag:any;
  liste:any;
  constructor(
    private validationService: TestService,
    private route: ActivatedRoute,
    private router:Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.flag = this.route.snapshot.paramMap.get('flag')
    if (this.flag === true) {
      this.validationService.listeSousSectionThemeNonVide(this.id).subscribe(res =>{
        if (res.status == 200) {
          this.liste = res.body
        }
      })
    } else {
      this.validationService.listeSousSectionThemeVide(this.id).subscribe(res =>{
        if (res.status == 200) {
          this.liste = res.body
        }
      })
    }
  }
  revenir(){
    this.router.navigate(['validation']);

  }

}

import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-adrescontact',
  templateUrl: './adrescontact.component.html',
  styleUrls: ['./adrescontact.component.css']
})
export class AdrescontactComponent implements OnInit {
  @Input() idAdrese: any
  id: string
  listeAdrss: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getAdrsCtnt()
  }
  getAdrsCtnt() {
    this.list.getAdrsCtnct(this.id).subscribe(resp => {
      this.listeAdrss = resp.body
    })
  }

}

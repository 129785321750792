import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountService } from 'app/services/account/account.service';
import { ListService } from 'app/services/list/list.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modif-localite',
  templateUrl: './modif-localite.component.html',
  styleUrls: ['./modif-localite.component.css']
})
export class ModifLocaliteComponent implements OnInit {
  matricule: any;
  thierdFormGroup: FormGroup;
  datailsProd: any;
  foko: any[] = [];
  commune: any;
  district: any;
  region: any;
  province: any;
  fokontany: any;

  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private list: ListService,
    private ac: AccountService,
    private router: Router,
    private _location: Location,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {    
    this.matricule = this.route.snapshot.paramMap.get('matr');
    this.thierdFormGroup = this._formBuilder.group({
     commune:[''],
     province:[''],
     district:[''],
     fokontany:[''],
     region:[''],
    });
    this.getProdByMatricule();
  }
  fkt(index) {
    if (index.length > 3) {
      this.ac.prendListeFokotany(index).subscribe((resp) => {
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.foko.push({
              id: resp.body[i].codeFokontany,
              text: resp.body[i].fokontany + "(" + resp.body[i].commune + ")",
              fokontany: resp.body[i].fokontany,
              codeCommune: resp.body[i].codeCommune,
              commune: resp.body[i].commune,
              codeDistrict: resp.body[i].codeDistrict,
              district: resp.body[i].district,
              codeRegion: resp.body[i].codeRegion,
              region: resp.body[i].region,
              codeProvince: resp.body[i].codeProvince,
              province: resp.body[i].province,
              codeLocalite: resp.body[i].codeLocalite,
              milieu: resp.body[i].libelleMilieu,
            });
          }
        }
      });
    }
  }
  onChange(event) {
    this.thierdFormGroup.get("fokontany").setValue(event[0].data.fokontany);
    this.fokontany = event[0].data.fokontany
    this.thierdFormGroup.get("commune").setValue(event[0].data.codeCommune);
    this.commune = event[0].data.commune;
    this.thierdFormGroup.get("district").setValue(event[0].data.codeDistrict);
    this.district = event[0].data.district;
    this.thierdFormGroup.get("region").setValue(event[0].data.codeRegion);
    this.region = event[0].data.region;
    this.thierdFormGroup.get("province").setValue(event[0].data.codeProvince);
    this.province = event[0].data.province;
  }
  getProdByMatricule(){
    this.list.getProdByMatricule(this.matricule).subscribe((response) => {
      this.datailsProd = response.body;
    })
  }
  retour(){
    this._location.back();
  }
  sauvegarder(){
    const data = 
    {
      "commune": this.thierdFormGroup.get('commune').value,
      "district": this.thierdFormGroup.get('district').value,
      "fokontany": this.fokontany,
      "matricule": this.matricule,
      "province": this.thierdFormGroup.get('province').value,
      "region": this.thierdFormGroup.get('region').value
    }
    this.ac.ModifLocalite(data).subscribe(resp=>{
      
      if(resp.status == 200 ){
        this.toastr.success('Modification réussie')
        window.location.reload();
      }
      else{
        this.toastr.error("Une erreur c'est produit");
      }
    })
  }
}

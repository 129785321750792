import { Router } from '@angular/router';
import { TestService } from './../../services/realisation/test.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-section',
  templateUrl: './liste-section.component.html',
  styleUrls: ['./liste-section.component.css']
})
export class ListeSectionComponent implements OnInit {
  listeSection:any

  constructor(private validationService: TestService,
    private router:Router) { }

  ngOnInit(): void {
    this.validationService.getAllSection().subscribe(data => {
      this.listeSection = data.body
    })
  }
  getdetails(section){
    this.validationService.ckeckThemeBySection(section).subscribe(data => {
      this.router.navigate(['détailsSection/'+section+'/'+data.body])
    })
    

  }

}

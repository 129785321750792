import { Component, OnInit, ElementRef} from '@angular/core';
import { ROUTES } from '../sidebar/sidebar.component';
import {Location} from '@angular/common';
import { Router } from '@angular/router';
import {AccountService} from "../../services/account/account.service";
import { User } from 'app/models/user';
import { Status } from 'app/models/status';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    mobile_menu_visible: any = 0;
    private toggleButton: any;
    private sidebarVisible: boolean;
    currentRoute: string;
    activeUrl: any;
    user: User;

    constructor(location: Location, private element: ElementRef, private router: Router, private accountService: AccountService) {
      this.location = location;
      this.sidebarVisible = false;
      this.accountService.user.subscribe(x => this.user = x);
    }

    get isAdmin() {
      return this.user && this.user.status === Status.admin;
    }
    get isEnqueteur() {
      return this.user && this.user.status === Status.enquêteur;
    }
    get isDirecteur() {
      return this.user && this.user.status === Status.directeur;
    }
    get isAdmine() {
      return this.user && this.user.status === Status.admine;
    }
    get isSuperviseur() {
      return this.user && this.user.status === Status.superviseur;
    }
    get isFDA() {
      return this.user && this.user.status === Status.fda;
    }
    get isMinistre() {
      return this.user && this.user.status === Status.ministre;
    }
    get isCoordonnateur() {
      return this.user && this.user.status === Status.coordonnateur;
    }
    get isSG() {
      return this.user && this.user.status === Status.sg;
    }
    get isGestionnaireDeCarte() {
      return this.user && this.user.status === Status.GestionnaireDeCarte;
    }
    getUrl(url){ 
      if (url == '/acceuil') {        
        this.activeUrl = 1;
      }else if (url == '/listeprod') {
       this.activeUrl = 2; 
      }else if (url == "/report") {
       this.activeUrl = 3;
      }else if (url == "/dragdrop") {
       this.activeUrl = 4;
      }else if (url == "/tableadmin") {
       this.activeUrl = 5 ;
      }
    }
    ngOnInit(){
      
      this.listTitles = ROUTES.filter(listTitle => listTitle);
      const navbar: HTMLElement = this.element.nativeElement;
      this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
      this.router.events.subscribe((event) => {
     
         var $layer: any = document.getElementsByClassName('close-layer')[0];
         if ($layer) {
           $layer.remove();
           this.mobile_menu_visible = 0;
         }
     });

     this.getUrl(this.router.url);
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);

        body.classList.add('nav-open');

        this.sidebarVisible = true;
    };


    getTitle(){
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }

      for(var item = 0; item < this.listTitles.length; item++){
          if(this.listTitles[item].path === titlee){
              return this.listTitles[item].title;
          }
      }
      return 'Dashboard';
    }
    logout() {
        this.accountService.logout();
        this.router.navigate(['/login']);
    }
}

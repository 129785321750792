import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { DragdropService } from '../../services/dropdrag/dragdrop.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-admin',
  templateUrl: './create-admin.component.html',
  styleUrls: ['./create-admin.component.css']
})
export class CreateAdminComponent implements OnInit {
  data = {
    adrsActuelle: "",
    cin: null,
    codeQr: "",
    dateDelivranceCin: "",
    dateNaiss: "",
    email: "",
    hameauVillage: "",
    lieuDelivranceCin: "",
    lieuNaiss: "",
    localiteIndiv: "",
    logement: "",
    metier: "",
    mobileBanking: "",
    nom: "",
    nomPrenomMere: "",
    nomPrenomPere: "",
    numLogement: "",
    password: "",
    photos: "",
    prenom: "",
    pseudo: "",
    sexe: "",
    status: 5,
    statusMatrimonial: "",
    telephone: "",
    userAjouter: ""
  }
  user: any;
  constructor(private svc: DragdropService,
    private route: Router,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    const mat = JSON.parse(localStorage.getItem('user')).matricule;
    this.data.userAjouter = mat;
  }
  save(){
    this.svc.saveIndividu(this.data).subscribe(res =>{
      if (res.status == 200) {
        this.toastr.success('Ajout admin avec success')
        this.route.navigate(['acceuil'])
      }
    })
  }

  cancel(){
    this.route.navigate(['acceuil'])
  }
}

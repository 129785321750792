import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ListService } from '../services/list/list.service';
import { AccountService } from '../services/account/account.service';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-modif-info-indiv',
  templateUrl: './modif-info-indiv.component.html',
  styleUrls: ['./modif-info-indiv.component.css']
})
export class ModifInfoIndivComponent implements OnInit {
  matricule: any;
  thierdFormGroup: FormGroup;
  datailsProd: any;

  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private list: ListService,
    private ac: AccountService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.matricule = this.route.snapshot.paramMap.get('matr');
    this.thierdFormGroup = this._formBuilder.group({
     nom:[''],
     prenom:[''],
     dateNaiss:[''],
     lieuNaiss:[''],
     nomPrenomMere:[''],
     nomPrenomPere:[''],
     sexe:[''],
     statusMatrimonial:[''],
     cin:[''],
     datecin:[''],
     lieucin:[''],
    });
    this.getProdByMatricule();

  }
  getProdByMatricule(){
    this.list.getProdByMatricule(this.matricule).subscribe((response) => {
      this.datailsProd = response.body;
      
    })
  }

  retour(){
    this._location.back();
  }
  sauvegarder(){
    const data = 
    {
      "cin": this.thierdFormGroup.get('cin').value,
      "dateDelivranceCin": this.thierdFormGroup.get('datecin').value,
      "dateNaiss": this.thierdFormGroup.get('dateNaiss').value,
      "lieuDelivranceCin": this.thierdFormGroup.get('lieucin').value,
      "lieuNaiss": this.thierdFormGroup.get('lieuNaiss').value,
      "nom": this.thierdFormGroup.get('nom').value,
      "prenom": this.thierdFormGroup.get('prenom').value,
      "nomPrenomMere": this.thierdFormGroup.get('nomPrenomMere').value,
      "nomPrenomPere": this.thierdFormGroup.get('nomPrenomPere').value,
      "sexe": this.thierdFormGroup.get('sexe').value,
      "statusMatrimonial":this.thierdFormGroup.get('statusMatrimonial').value
    }
    this.ac.ModifIndiv(this.matricule, data).subscribe(resp=>{
      if(resp.status == 200 ){
        this.toastr.success('Modification réussie')
        this._location.back();
      }
      else{
        this.toastr.error("Une erreur c'est produit");
      }
    })
  }
}

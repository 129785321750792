import { User } from "./../../models/user";
import { environment } from "./../../../environments/environment";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { Status } from "../../models/status";

@Injectable({
  providedIn: "root",
})
export class AccountService {
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private subject = new Subject<any>();

  constructor(private router: Router, private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("user"))
    );
    this.user = this.userSubject.asObservable();
  }

  public get userValue(): User {
    return this.userSubject.value;
  }

  sendClickEvent() {
    this.subject.next();
  }
  getClickEvent(): Observable<any> {
    return this.subject.asObservable();
  }
  login(matricule, password) {
    return this.http
      .post<User>(`${environment.apiUrlIndiv}api/individu/connecter`, {
        matricule,
        password,
      })
      .pipe(
        map((user) => {
          localStorage.setItem("user", JSON.stringify(user));
          this.userSubject.next(user);
          return user;
        })
      );
  }

  logout() {
    localStorage.removeItem("user");
    this.userSubject.next(null);
    this.router.navigate(["/login"]);
    localStorage.clear();
  }

  sendLogout() {
    var data = {
      username: this.userValue.username,
      email: this.userValue.email,
    };
    this.http
      .post(`${environment.apiUrl2}/api/auth/logout`, data)
      .subscribe((data) => {
      });
  }

  register(inscrire) {
    return this.http.post(
      `${environment.apiUrl2}api/auth/inscription`,
      inscrire,
      { observe: "response" }
    );
    
  }
  ModifMdp(data) {
    return this.http.post(
      `${environment.apiUrlIndiv}api/individu/updatePasswordIndiv`, data,
      { observe: "response" }
    );
    
  }
  ModifLocalite(data) {
    return this.http.post(
      `${environment.apiUrlIndiv}api/individu/UpdateLocaliteIndiv`, data,
      { observe: "response" }
    );
    
  }
  getListProducteur() {
    return this.http.get(
      `${environment.apiUrl2}api/auth/prendListeProducteur`,
      { observe: "response" }
    );
  }

  ModifIndiv(matricule, data) {
    return this.http.put(
      `${environment.apiUrlIndiv}api/individu/updateIndividu/`+ matricule, data,
      { observe: "response" }
    );
  }
  getListMenFamille() {
    return this.http.get(`${environment.apiUrl2}api/auth/prendListeFamille`, {
      observe: "response",
    });
  }
  sendImage(data) {
    return this.http.post(`${environment.apiUrl2}api/user/uploadFile`, data);
  }

  inscription(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/addIndividuMinae`,
      data,
      { headers: headers, observe: "response" }
    );
  }

  inscriptionParExcel(data) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
      /* .set("Access-Control-Allow-Origin", "*"); */
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/addImportIndividuMinae`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  saveAdrssContact(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiUrlIndiv2}api/contactAdrs/addAdrsContact`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  mailladresse( data) {
    const headers= new HttpHeaders()
  .set('content-type', 'application/json')
    return this.http.post<any>(`${environment.apiUrlIndiv}api/individu/forgot_password`, data, { headers: headers, observe: 'response' })
  }
  nadresse( data) {
    const headers= new HttpHeaders()
  .set('content-type', 'application/json')
    return this.http.post<any>(`${environment.apiUrlIndiv}api/individu/change_password`, data, { headers: headers, observe: 'response' })
  }
  saveMembreFamille(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiMembrefamille}api/famille/saveMembreFamille`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  saveServiceFinancier(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiServiceFinancier}api/serviceFinacier/saveServicesFinancier`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  saveEconomie(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiEconomie}api/economie/saveEconomie`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  saveEtatCarte(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/updateCarteProducteur`, data,
      { headers: headers, observe: "response" }
    );
  }
  saveAppuis(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiAppuis}api/appui/saveAppuis`,
      data,
      { headers: headers, observe: "response" }
    );
  }

  /* saveOrganisation(data) {
    const headers = new HttpHeaders()
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(`${environment.apiAppuis}api/appui/saveAppuis`, data,  { headers: headers, observe: 'response' });
  } */

  saveContratAgrie(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiContratAgrie}api/contratAgricole/addContratAgri`,
      data,
      { headers: headers, observe: "response" }
    );
  }

  saveNutrition(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiNutrition}/api/nutrition/saveNutrition`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  saveOrganisationPaysane(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiOrganisationPaysane}api/organisation/saveOrganisationPaysane`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  lasa(data) {
    const headers = new HttpHeaders()
      /* .set('content-type', 'application/json') */
      .set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(
      `${environment.apiUrl2}api/account/signIn`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  findAllStatus(): Observable<Status[]> {
    return this.http.get<Status[]>(
      `${environment.apiUrlIndiv}api/individu/listeStatus`
    );
  }
  matrimonial(): Observable<Status[]> {
    return this.http.get<Status[]>(
      `${environment.apiUrlIndiv}api/individu/prendSituationMatrimonial`
    );
  }
  prendListeFokotany(nom) {
    const data = {
      nomFokontany: nom,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv2}apiMinae/localite/searchFokontany`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  prendListeSpeculation(produit) {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("content-type", "application/json"); //
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendSpeculationByProduit/` +
        produit,
      { headers: headers, observe: "response" }
    );
  }
  updateIndiviu(matricule, data) {
    const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    return this.http.post<any>(`${environment.apiUrlIndiv}api/individu/updatePhotosPieceIndividu/` + matricule, data,
    { headers: headers, observe: "response" })
  }
/*   update(id, data): Observable<any> {
    return this.http.put(`${baseUrl}/${id}`, data);
  } */
  prendListeService(niveau4) {
    const headers = new HttpHeaders()
      .set("content-type", "application/json")
      .set("content-type", "application/json"); //
    return this.http.get<any>(
      `${environment.apiAppuis}api/appui/prendTypeServiceAppuiByNiveau4/` +
        niveau4,
      { headers: headers, observe: "response" }
    );
  }

  getmatricule(code: any, status: any) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get(
      `${environment.apiUrlIndiv}api/individu/createMatricule/` +
        code +
        "/" +
        status,
      { headers: headers, responseType: "text" }
    );
  }
  getmatriculeFamille(code: any) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get(
      `${environment.apiMembrefamille}api/famille/createMatriculeFamille/` +
        code,
      { headers: headers, responseType: "text" }
    );
  }
  codecontact() {
    return this.http.get(
      `${environment.apiUrlIndiv2}api/contactAdrs/seqAdrsContact`,
      { observe: "response" }
    );
  }
  getAllTechNonValider(page: any) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/findAllTechnicienNonValider/` + page,
      { headers: headers, observe: "response" }
    );
  }
  getAllTechValider(page: any) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/findAllTechnicienValider/` + page,
      { headers: headers, observe: "response" }
    );
  }
  validerTechnicien(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/validerCompteEnqueteur`, data,
      { headers: headers }
    );
  }
  deleteUser(matricule: number): Observable<any> {
    const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    return this.http.delete(`${environment.apiUrlIndiv}api/individu/deleteUtilisateur/` + matricule, { headers: headers });
  }
}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ListService } from '../services/list/list.service';
import { ToastrService } from 'ngx-toastr';
import { AccountService } from '../services/account/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestion-carte',
  templateUrl: './gestion-carte.component.html',
  styleUrls: ['./gestion-carte.component.css']
})
export class GestionCarteComponent implements OnInit {
  currentPage: number = 1;
  pagination: any;
  listeCarte: any;
  totalPages: any;
  textRecherche: any;
  recherche: any;
  recherche1: any;
  recherche2: any;
  recherche3: any;
  recherche4: any;
  recherche5: any;
  recherche6: any;
  totalItems: any;
  show: false;
  textRechercheDistrict: any;
  textRecherchePrenom: any;
  textRechercheRegion: any;
  textRechercheProvince: any;
  form: FormGroup;
  Icarte: any[] = [];
  etacarte: FormGroup;
  textRechercheCarte: any = 9;
  textRechercheCommune: any;
  select_all = false;
  isChecked: boolean
  rechercheBy: FormGroup;
  constructor(
    private list: ListService,
    private _formBuilder: FormBuilder,
    private ac: AccountService,
    private toastr: ToastrService,
    private route: Router
  ) {
    this.form = _formBuilder.group({
      check: new FormControl(false, [])
    });
  }

  ngOnInit(): void {
    this.etacarte = this._formBuilder.group({
      etacart: ['']
    });
    this.rechercheBy = this._formBuilder.group({
      nom: [''],
      prenom: [''],
      Province: [''],
      region: [''],
      district: [''],
      commune: [''],
      etatDucarte: [''],
    });
    this.getAllProd()

  }
  checkCheckBoxvalue(event, isChecked: boolean) {
    if (isChecked) {
      this.Icarte.push(event)
    }
    else {
      let index = this.Icarte.indexOf(event);
      this.Icarte.splice(index, 1);
    }
  }
  toggleCheckboxAll(event, isChecked: boolean) {
    if (isChecked) {

    }
  }
  Valider() {
    const data = {
      "etatCarte": this.etacarte.get('etacart').value,
      "idCartes": this.Icarte
    }
    this.ac.saveEtatCarte(data).subscribe(resp => {
      if (resp.status === 200) {
        /* window.location.reload(); */
      }
    })
  }

  refresh(): void {
    window.location.reload();
  }

  filttre(text) {
    if (text.target.value != "" && text.target.value.length > 2) {
      this.textRecherche = text.target.value;
      this.getAllProd();
    } else {
      this.textRecherche = "";
      this.getAllProd();
    }
  }
  filttrePrenom(text1) {
    if (text1.target.value != "" && text1.target.value.length > 2) {
      this.textRecherchePrenom = text1.target.value;
      this.getAllProd();
    } else {
      this.textRecherchePrenom = "";
      this.getAllProd();
    }
  }
  filttreDistrict(text2) {
    if (text2.target.value != "" && text2.target.value.length > 2) {
      this.textRechercheDistrict = text2.target.value;
      this.getAllProd();
    } else {
      this.textRechercheDistrict = "";
      this.getAllProd();
    }
  }
  filttreProvince(text3) {
    if (text3.target.value != "" && text3.target.value.length > 2) {
      this.textRechercheProvince = text3.target.value;
      this.getAllProd();
    } else {
      this.textRechercheProvince = "";
      this.getAllProd();
    }
  }
  filttreRegion(text4) {
    if (text4.target.value != "" && text4.target.value.length > 2) {
      this.textRechercheRegion = text4.target.value;
      this.getAllProd();
    } else {
      this.textRechercheRegion = "";
      this.getAllProd();
    }
  }
  filttreCarte(text5) {
    if (text5.target.value != "") {
      this.textRechercheCarte = text5.target.value;
      this.getAllProd();
    } else {
      this.textRechercheCarte = "";
      this.getAllProd();
    }
  }
  filttreCommune(text6) {
    if (text6.target.value != "" && text6.target.value.length > 2) {
      this.textRechercheCommune = text6.target.value;
      this.getAllProd();
    } else {
      this.textRechercheCommune = "";
      this.getAllProd();
    }
  }
  getAllProd() {
    const dataalefa = {
      page: this.currentPage,
      nom: this.textRecherche,
      idStatus: this.textRechercheCarte,
      commune: this.textRechercheCommune,
      district: this.textRechercheDistrict,
      /* fokontany: this.textRecherche, */
      prenom: this.textRecherchePrenom,
      province: this.textRechercheProvince,
      region: this.textRechercheRegion,
    };
    this.list.getCarte(dataalefa).subscribe((response) => {
      this.pagination = response.body;
      this.listeCarte = response.body.content;
      this.totalPages = this.pagination.totalPages;
      this.currentPage = parseInt(this.pagination.currentPage) + 1;
      this.totalItems = this.pagination.totalPages * this.pagination.totalPages;
    });

  }
  changeValue(page: 1) {
    this.show = false;
    if (page) {
      this.currentPage = page;
      this.getAllProd()
    }
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu-indiv',
  templateUrl: './menu-indiv.component.html',
  styleUrls: ['./menu-indiv.component.css']
})
export class MenuIndivComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

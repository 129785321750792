import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-modif-mot-de-pass',
  templateUrl: './modif-mot-de-pass.component.html',
  styleUrls: ['./modif-mot-de-pass.component.css']
})
export class ModifMotDePassComponent implements OnInit {
  mdpFormGroup: FormGroup;
  user: User;
  matricule: string;

  constructor(
    private _formBuilder: FormBuilder,
    private router: Router, 
    private accountService: AccountService,
    private toastr: ToastrService,
  ) { 
    /* this.location = location;
      this.sidebarVisible = false; */
      this.accountService.user.subscribe(x => this.user = x);
  }

  ngOnInit(): void {
    this.mdpFormGroup = this._formBuilder.group({
      nouveauMdp:['']
     });
    this.matricule = this.user.matricule
  }
  sauvegarder(){
    const data = 
    {
      "matricule": this.matricule,
      "password": this.mdpFormGroup.get('nouveauMdp').value
    }
    this.accountService.ModifMdp(data).subscribe(response =>{
      if (response.status === 200) {
        this.toastr.success("Modification avec success");
        this.router.navigate(["acceuil"]);
      }else{
        this.toastr.error("Une erreur c'est produit. Veuillez réessayer")
      }
    })
  }

}

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup} from '@angular/forms';
import { ReportingService } from 'app/services/reporting/reporting.service';

@Component({
  selector: 'app-affichage',
  templateUrl: './affichage.component.html',
  styleUrls: ['./affichage.component.css']
})
export class AffichageComponent implements OnInit, OnChanges {
  @Input() typeAffichage: any;
  @Input() objChart: any;
  @Input() titre: any;
  @Input() change: any;
  @Input() selectLocalite: any;
  @Input() dataChart: any;					   

  dataChart1: any[] = []
  dataChart2: any[] = []
  dataChart3: any[] = []
  dataChart4: any[] = []
  dataChart5: any[] = []
  dataChart6: any[] = []
  dataChart7: any[] = []
  dataChart8: any[] = []
  dataChart9: any[] = []
  dataChart10: any[] = []
  typeChartFormGroup: FormGroup
  formChoix: FormGroup
  valColumnModale: any[] = []
  nbChoix: any

  titre1: any
  titre2: any
  titre3: any
  titre4: any
  titre5: any
  titre6: any
  titre7: any
  titre8: any
  titre9: any
  titre10: any

  choixChartDiv1: any
  choixChartDiv2: any
  choixChartDiv3: any
  choixChartDiv4: any
  choixChartDiv5: any
  choixChartDiv6: any
  choixChartDiv7: any
  choixChartDiv8: any
  choixChartDiv9: any
  choixChartDiv10: any
  typeChart = [
    {
      "id": 1,
      "name": 'Bar'
    },
    {
      "id": 2,
      "name": 'Pie'
    },
    {
      "id": 3,
      "name": 'Liste'
    },
    {
      "id": 4,
      "name": 'courbe'
    }
  ]
  showDate: string;


  constructor(private _formBuilder: FormBuilder, private service: ReportingService) { }
														
			

  ngOnChanges(changes: SimpleChanges): void {
    this.initTypeChart()
    this.modifiierAfficage(this.typeAffichage);
    if (this.change == true) {
      this.afficherChart() 
    }  
    
  }

  ngOnInit(): void {
    this.typeChartFormGroup = this._formBuilder.group({
      type1: [''],
      type2: [''],
      type3: [''],
      type4: [''],
      type5: [''],
      type6: [''],
      type7: [''],
      type8: [''],
      type9: [''],
      type10: ['']
    })

    this.formChoix = this._formBuilder.group({
      choix: ['']
    })
    this.initTypeChart()
    this.modifiierAfficage(this.typeAffichage)
  }  

  modifiierAfficage(value) {
    this.typeAffichage = value
    this.nbChoix = new Array(value)
    this.modifiierAfficageModale(value)
  }

  modifiierAfficageModale(value) {
    this.typeAffichage = value
    let data = new Object()
    data['tails'] = value
    switch (value) {
      case 1:
      case 2:
        data['value'] = 'col-md-12'
        break;
      case 3:
        data['value'] = 'col-md-6'
        break;
      case 4:
        data['value'] = 'col-md-6'
        break;
      default:
        break;
    }
    this.boucleColonne(data)
  }

  boucleColonne(data) {
    this.valColumnModale = []
    for (let index = 0; index < data.tails; index++) {
      this.valColumnModale.push(data.value)
    }
  }
  datevrais(id) {
    this.showDate = id.value
    /* if (this.showDate==="1") {
      this.valColumnModale === 
    } */
  }

  afficherChart(){    
    // this.titreRepporting = this.objChart.libelleIndicateur;
    const typeAffModale = this.formChoix.get('choix').value
    this.service.calculeTaux(this.objChart).subscribe(res2 => {
     if(res2.status == 200){											  
      switch (this.typeAffichage) {
        case 1:
          this.dataChart1 = []
          let dataChart2 = this.dataChart;
          this.service.calculeTaux(this.objChart).subscribe(res3 => {
            if(res3.status == 200){      
          this.dataChart1 = res2.body
          this.titre1 = this.titre
            }
          })
          
        case 2: {
          if (typeAffModale == 2) {
            this.dataChart2 = res2.body
            this.titre2 = this.titre

          } else if (typeAffModale == 2) {
            this.dataChart3 = res2.body
            this.titre3 = this.titre
          }
        }
        case 3: {
          if (typeAffModale == 1) {
            this.dataChart4 = res2.body
            this.titre4 = this.titre

          } else if (typeAffModale == 2) {
            this.dataChart5 = res2.body
            this.titre5 = this.titre
          }
          else if (typeAffModale == 3) {
            this.dataChart6 = res2.body
            this.titre6 = this.titre
          }
        }
        case 4: {
          if (typeAffModale == 1) {
            this.dataChart7 = res2.body
            this.titre7 = this.titre
          } else if (typeAffModale == 2) {
            this.dataChart8 = res2.body
            this.titre8 = this.titre
          }
          else if (typeAffModale == 3) {
            this.titre9 = this.titre
            this.dataChart9 = res2.body
          }
          else if (typeAffModale == 4) {
            this.titre10 = this.titre
            this.dataChart10 = res2.body
          }
        }
      }
     }
    });    
  }

  modifierTypeChart(typeCharts, value) {
    const type = typeCharts.value
    this.choixChartDiv1 = typeCharts.value
    switch (value) {
      case 1:
        this.choixChartDiv1 = type
        break
      case 2:
        this.choixChartDiv2 = type
        break
      case 3:
        this.choixChartDiv3 = type
        break
      case 4:
        this.choixChartDiv4 = type
        break
      case 5:
        this.choixChartDiv5 = type
        break
      case 6:
        this.choixChartDiv6 = type
        break
      case 7:
        this.choixChartDiv7 = type
        break
      case 8:
        this.choixChartDiv8 = type
        break
      case 9:
        this.choixChartDiv9 = type
        break
      case 10:
        this.choixChartDiv10 = type
        break
      default:
        break
    }
  }

  initTypeChart() {
    this.choixChartDiv1 = 1
    this.choixChartDiv2 = 3
    this.choixChartDiv3 = 2
    this.choixChartDiv4 = 1
    this.choixChartDiv5 = 2
    this.choixChartDiv6 = 3
    this.choixChartDiv7 = 1
    this.choixChartDiv8 = 2
    this.choixChartDiv9 = 1
    this.choixChartDiv10 = 3
  }
}

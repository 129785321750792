import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account/account.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-initial',
  templateUrl: './initial.component.html',
  styleUrls: ['./initial.component.css']
})
export class InitialComponent implements OnInit {
  infoFormGroup: FormGroup;
  datejour: Date;

  constructor(
    private accountService: AccountService,
    private router: Router,
    private _formBuilder: FormBuilder  ) { }

  ngOnInit(): void {
    let currentDate = new Date();
    this.datejour = currentDate
    this.infoFormGroup = this._formBuilder.group({
    nom: [''],
    prenom: [''],
    service: [''],
    datejour: ['']
  })
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
  Enregistrez(){

  }

}

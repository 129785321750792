import { Injectable } from "@angular/core";
import { environment } from "./../../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Fokontany } from "../../models/fokontany";
import { Router } from "@angular/router";
import { Status } from "../../models/status";
import { Secteur } from "../../models/secteur";
import { ModePaiement } from "../../models/mode-paiement";

@Injectable({
  providedIn: "root",
})
export class ListService {
  AllSecteurAgriculture(id: string) {
    throw new Error('Method not implemented.');
  }
  constructor(private router: Router, private http: HttpClient) {}

  getAllFokontany(): Observable<Fokontany[]> {
    return this.http.get<Fokontany[]>(
      `${environment.apiUrl2}api/auth/prendListeStatusFamille`
    );
  }
  getCommuneByFonkotany(fokontany) {
    return this.http.get(
      `${environment.apiUrlIndiv}api/individu/prendCommuneByFokontany/` +
        fokontany,
      { observe: "response" }
    );
  }
  getGeoByIdFonkotany(idFokontany) {
    return this.http.get(
      `${environment.apiUrl2}api/adresse/prendCommuneDistrictRegionProvinceByFokontany/` +
        idFokontany,
      { observe: "response" }
    );
  }
  getallModePaiement(): Observable<ModePaiement[]> {
    return this.http.get<ModePaiement[]>(
      `${environment.apiUrl2}api/user/allModePaiement`
    );
  }
  getallSecteur(): Observable<Secteur[]> {
    return this.http.get<Secteur[]>(
      `${environment.apiUrl2}api/user/allSecteur`
    );
  }
  findAllNivEtude() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendListeNiveauEtude`,
      { headers: headers, observe: "response" }
    );
  }
  findAllTypeAppui() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendListeTypeAppui`,
      { headers: headers, observe: "response" }
    );
  }
  findAllRefTechProd() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/account/listeTechProd`,
      { headers: headers, observe: "response" }
    );
  }
  findAllRefCondElevage() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/account/listeCondAgricole`,
      { headers: headers, observe: "response" }
    );
  }
  findAllEtatCivil() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendListeEtatCivil`,
      { headers: headers, observe: "response" }
    );
  }
  findAllStatus() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<Status>(
      `${environment.apiUrl2}api/individu/listeStatus`,
      { headers: headers, observe: "response" }
    );
  }
  findAllSituationMatrimonial() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<Status>(
      `${environment.apiUrl2}api/individu/prendSituationMatrimonial`,
      { headers: headers, observe: "response" }
    );
  }
  findAllFiliereAgri() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossibleAgri`,
      { headers: headers, observe: "response" }
    );
  }
  findAllFiliereElev() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossibleElev`,
      { headers: headers, observe: "response" }
    );
  }
  findAllFilierePis() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossiblePisc`,
      { headers: headers, observe: "response" }
    );
  }
  findAllTypprodele() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendAllTypeProduitElevage`,
      { headers: headers, observe: "response" }
    );
  }
  findAllTypprodagri() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendAllTypeProduitAgriculture`,
      { headers: headers, observe: "response" }
    );
  }
  prendProduitPossibleElev() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossibleElev`,
      { headers: headers, observe: "response" }
    );
  }
  prendProduitPossibleAgri() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossibleAgri`,
      { headers: headers, observe: "response" }
    );
  }
  prendProduitPossiblePis() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendProduitPossiblePisc`,
      { headers: headers, observe: "response" }
    );
  }
  GetNombreProd() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendCountIndivByRegion`,
      { headers: headers, observe: "response" }
    );
  }
  findAllTypprodpis() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendAllTypeProduitPisciculture`,
      { headers: headers, observe: "response" }
    );
  }
  findAllCheptel() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendListeCheptel`,
      { headers: headers, observe: "response" }
    );
  }

  detailFokotany(id) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/adresse/prendCommuneDistrictRegionProvinceByFokontany/` +
        id,
      { headers: headers, observe: "response" }
    );
  }
  detailTypeProdEle(id) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/auth/prendTypeProduitById/` + id,
      { headers: headers, observe: "response" }
    );
  }
  detailAppui(idTypeAppui) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrl2}api/account/detailAppuiListeParType/` +
        idTypeAppui,
      { headers: headers, observe: "response" }
    );
  }

  prendListeFokotany(nom) {
    const data = {
      nomFokontany: nom,
    };
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrl2}api/auth/searchByFokontany`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  prendListeCommune(nom) {
    /* const data = {
      commune: nom,
    }; */
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/rechercheCommune/`+ nom,
    );
  }
  prendListeDistrict(nom) {
    /* const data = {
      commune: nom,
    }; */
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/rechercheDistrict/`+ nom,
    );
  }
  prendFokontanyByComune(commune){
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/rechercheFokontanyByCommune/`+ commune,
    );
  }
  prendIndividuByCommune(commune){
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendIndividuByAllMatriculeAndCommune/`+ commune,
    );
  }
  getAllProd(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/prendListeIndivProd`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  AjoutFokontany(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/AjoutFokontanyByCommune`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  AjoutCommune(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/SaveLocalite`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  getCarte(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/searchInfoIndivCarteNews`,data,
      { headers: headers, observe: "response" }
    );
  }
  getAllSecteur(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/prendListSecteurActiviteByMatricule`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  detaiSecteur(matricule, secteur) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendDetailSecteurByMatriculeAndType/` +
        matricule +
        "/" +
        secteur,
      { headers: headers, observe: "response" }
    );
  }
  AllSecteur(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/listeSecteurByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getPerimetreIrrigue(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getEngraisMinerauxAzote(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getagricultureGeneral(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getRente(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getAmedementOrganique(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getchaulage(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAgricultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
/* elevage*/  
getApicultures(matricule) {
  const headers = new HttpHeaders().set("content-type", "application/json");
  return this.http.get<any>(
    `${environment.apiActivite}api/secteur/prendApicultureByMatricule/` +
      matricule,
    { headers: headers, observe: "response" }
  );
}
getElevagePratique(matricule) {
  const headers = new HttpHeaders().set("content-type", "application/json");
  return this.http.get<any>(
    `${environment.apiActivite}api/secteur/prendElevageByMatricule/` +
      matricule,
    { headers: headers, observe: "response" }
  );
}
getSylviGeneral(matricule) {
  const headers = new HttpHeaders().set("content-type", "application/json");
  return this.http.get<any>(
    `${environment.apiActivite}api/secteur/prendSylviGeneralByMatricule/` +
      matricule,
    { headers: headers, observe: "response" }
  );
}
getSylviprod(matricule) {
  const headers = new HttpHeaders().set("content-type", "application/json");
  return this.http.get<any>(
    `${environment.apiActivite}api/secteur/prendSylviSylvicoleByMatricule/` +
      matricule,
    { headers: headers, observe: "response" }
  );
}
getSylviCasPepiniere(matricule) {
  const headers = new HttpHeaders().set("content-type", "application/json");
  return this.http.get<any>(
    `${environment.apiActivite}api/secteur/prendSylviCasPepiniereByMatricule/` +
      matricule,
    { headers: headers, observe: "response" }
  );
}



getPeche(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendPecheByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getAquaculture(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendAquacultureByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getpratiqueElevage(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiActivite}api/secteur/prendElevageByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }


  getLabelisation(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendLabelisationProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }


  getEconomie(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendEconomieIndivByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getTransformation(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendTransformationIndivProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getLogistique(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendLogistiqueByIndivProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getMemFamille(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiMembrefamille}api/famille/prendMembreFamilleIndiv/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getProduction(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendProductionIndivProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getMateriels(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendMaterielEquipementIndivProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getOrgpaysane(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendOrganisationPaysaneIndiv/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getContrainte(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendContraintesByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  

  getNutrition(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/prendNutritionIndivProd`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  getAdrsCtnct(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendAdresseIndivByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getProdByMatricule(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendIndivByMatriculeModif/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getServiceFinance(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiServiceFinancier}api/serviceFinacier/serviceFinancieByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getAppuis(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiAppuis}api/appui/prendListeAppuiByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getrefAppuis(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiAppuis}api/appui/prendListeAppuiByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getrefAppuis01(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiAppuis}api/appui/prendListeAppuiByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getFournisseur(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendFournisseurProd/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }
  getfemme(data) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.post<any>(
      `${environment.apiUrlIndiv}api/individu/prendPlaceFemmeIndivProd`,
      data,
      { headers: headers, observe: "response" }
    );
  }
  
  getTerre(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendTerreIndivByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getCivilite(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendCiviliteByIndiv/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getContratByMatricule(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiContratAgrie}api/contratAgricole/prendListeContratAgricoleByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getEconomieByMatricule(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiEconomie}api/economie/prendEconomieByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getNutritionByMatricule(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiNutrition}api/nutrition/prendNutritionByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getAssistanceSocialeByMatricule(matricule) {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiUrlIndiv}api/individu/prendAssistanceSocialeByMatricule/` +
        matricule,
      { headers: headers, observe: "response" }
    );
  }

  getOrganisationByMatricule() {
    const headers = new HttpHeaders().set("content-type", "application/json");
    return this.http.get<any>(
      `${environment.apiOrganisationPaysane}api/organisation/prendListeOrganisationPaysane`,
      { headers: headers, observe: "response" }
    );
  }
}

import { Component, OnInit } from "@angular/core";
import { ListService } from "../services/list/list.service";
import { AccountService } from "../services/account/account.service";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from '@angular/platform-browser';
import * as XLSX from 'xlsx';
import { User } from "app/models/user";
import { Status } from 'app/models/status';

@Component({
  selector: "app-listeprod",
  templateUrl: "./listeprod.component.html",
  styleUrls: ["./listeprod.component.css"],
})
export class ListeprodComponent implements OnInit {
  size: number = 10;
  status: number = 2;
  page: number;
  listeProd: any[] = [];
  pagination: any;
  show: false;
  totalItems: any;
  totalPages: any;
  currentPage: number = 1;
  textRecherche:any;
  recherche: any;
  imagePath: any;
  user: User;
  constructor(
    private list: ListService,
    private accountService: AccountService,
    private router: Router,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer
  ) {
    this.accountService.user.subscribe(x => this.user = x);
  }
  get isAdmin() {
    return this.user && this.user.status === Status.admin;
  }
  get isEnqueteur() {
    return this.user && this.user.status === Status.enquêteur;
  }
  get isDirecteur() {
    return this.user && this.user.status === Status.directeur;
  }
  get isAdmine() {
    return this.user && this.user.status === Status.admine;
  }
  get isSuperviseur() {
    return this.user && this.user.status === Status.superviseur;
  }
  get isFDA() {
    return this.user && this.user.status === Status.fda;
  }
  get isMinistre() {
    return this.user && this.user.status === Status.ministre;
  }
  get isCoordonnateur() {
    return this.user && this.user.status === Status.coordonnateur;
  }
  get isSG() {
    return this.user && this.user.status === Status.sg;
  }
  get isGestionnaireDeCarte() {
    return this.user && this.user.status === Status.GestionnaireDeCarte;
  }

  ngOnInit(): void {
    this.getAllProd();
  }

  openDialog() {
    const dialogRef = this.dialog.open(DetailprodComponent);

    dialogRef.afterClosed().subscribe((result) => {
    });
  }
  getAllProd() {
    const dataalefa = {
      page: this.currentPage,
      status: 2,
      search: this.textRecherche
    };
    this.list.getAllProd(dataalefa).subscribe((response) => {
      
      this.pagination = response.body;
      this.listeProd = response.body.content;
      this.totalPages = this.pagination.totalPages;      
      this.currentPage = parseInt(this.pagination.currentPage) + 1;
      this.totalItems = this.pagination.totalPages * this.pagination.totalPages;     
            
      for (let i = 0; i < this.listeProd.length; i++) {
        this.imagePath = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' 
                 + this.listeProd[i].photos);
      }

    });
   
  }
  
  getdetails(matricule: string) {
    this.router.navigate(["detailprod/" + matricule]);
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(["/login"]);
  }
  changeValue(page: number) {
    this.show = false;
    if (page) {
      this.currentPage = page;
      this.getAllProd()
    }
  }
  filttre(text){
    if (text.target.value != "" && text.target.value.length > 2) {
      this.textRecherche = text.target.value;
      this.getAllProd();
    } else {
      this.textRecherche = "";
      this.getAllProd();
    }
  }
  /* exportexcel(): void{
     const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.listeProd);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'ListeProdution');
	 
     XLSX.writeFile(wb, 'ListeProdution1.xlsx');
   } */
  templateUrl: "../detailprod/detailprod.component.html"
}

@Component({
  selector: "app-detailprod",
  templateUrl: "../detailprod/detailprod.component.html",
})		 
export class DetailprodComponent {}

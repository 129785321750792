import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-femme',
  templateUrl: './femme.component.html',
  styleUrls: ['./femme.component.css']
})
export class FemmeComponent implements OnInit {
  panelOpenState = false;
  id: string
  femme: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getFemme()
  } 
  getFemme(){
    const dataalefa={
      "matricule": this.id
    }
    this.list.getfemme(dataalefa).subscribe(resp=>{
      this.femme = resp.body
    })
  }

}

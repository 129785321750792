import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AccountService } from '../services/account/account.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(
    private router: Router,
    private accountService: AccountService
  ){
    
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {
      const user = this.accountService.userValue;
      if (user) {
        if (route.data.roles && route.data.roles.indexOf(user.status[0]) === -1) {
          // role not authorised so redirect to home page
          this.router.navigate(['login']);
          return false;
      }
      // authorised so return true
      return true;
      }
      this.router.navigate(['login'], { queryParams: { returnUrl: state.url }});
    return true;
  }
  
}

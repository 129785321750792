export class Detailrealisation {
  idRealisation: number;
  idQuest: number;
  dateRealisationTest: string;
  idSection: string;
  libelleSection: string;
  idSousSection: number;
  libelleSousSection: string;
  idTheme: number;
  libelleTheme: string;
  idSousTheme: number;
  libelleSousTheme: string;
  idQuestionnaire: string;
  libelleQuestionnaire: string;
  idReponse: number;
  libelleReponse: string;
  libelleType: string;
  controlType: string;
  ordre: number;
  exigences: string;
  developpement: number;
  testIntegrationDonnee: number;
  testValidation: number;
  scenarioTest: string;
  observation: string;
  supportWeb: string;
  supportMobile: string;
  dateDebutTest: string;
  tauxRealisation: number;
  tauxValidation: number;
  dateValidationRealisation: {
    idDateRealisation: number;
    nom: string;
    prenom: string;
    service: string;
    observation: string;
    dateValidation: string;
    dateRealisationTest: string;
    dateG: string;
  };
  codeRealisation: string;
}

import { Component, OnInit } from '@angular/core';
import { ListService } from '../services/list/list.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestionlocalite',
  templateUrl: './gestionlocalite.component.html',
  styleUrls: ['./gestionlocalite.component.css']
})
export class GestionlocaliteComponent implements OnInit {
  foko: any[] = [];
  commune: any;
  district: any;
  region: any;
  province: any;
  secondFormGroup: FormGroup;
  listCommune: any;
  status: number;
  listefokotany: any[]=null;
  codeCommune: any;
  commune2: any;
  listefokotany2: any[]=null;
  codeditrict: any;
  fokotany: any[]=[];
  distr: any[] = [];
  districts: any;
  codeDistrict: any;

  constructor(
    private listService: ListService,
    private _formBuilder: FormBuilder,
    private tostr: ToastrService, 
    private router: Router
  ) { }

  ngOnInit(): void {
    this.secondFormGroup = this._formBuilder.group({
      fokontany: ['', Validators.required],
      fokontany2: ['', Validators.required],
      fokontany3: ['', Validators.required],
      commune: ['', Validators.required],
      commune2: ['', Validators.required],
      district: ['', Validators.required],
      region: ['', Validators.required],
      province: ['', Validators.required],
    });
  }
  recherche(){
    this.listefokotany = null
    this.listefokotany2 = null
    this.listService.getCommuneByFonkotany(this.secondFormGroup.get("fokontany").value).subscribe(resp=>{
      this.status = resp.status
      if (resp.status==200) {
        this.listCommune = resp.body
      }
    })
  }
  ajout(){
    
    const data = {
      "codeCommune": this.codeCommune,
      "fokontany": this.secondFormGroup.get("fokontany2").value
    }
    this.listService.AjoutFokontany(data).subscribe(resp=>{
      if (resp.status == 200) {
        this.tostr.success("ajout avec succès")
        window.location.reload()
      }
      else{
        this.tostr.error("Commune inexitant")
      }
    })
  }
  AjoutFokontany(){
    this.fokotany.push(this.secondFormGroup.get("fokontany3").value)
    this.secondFormGroup.controls["fokontany3"].reset()
  }
  ajouter(){
    var dataFokontany = []
    if (this.fokotany != null) {
      dataFokontany = [this.secondFormGroup.get("fokontany3").value]
    }
    for (let i = 0; i < this.fokotany.length; i++) {
      dataFokontany = this.fokotany
    }
    
    const data = {
      "codeDistrict": this.codeDistrict,
      "commune": this.secondFormGroup.get("commune2").value,
      "fokontany": dataFokontany
    }
    this.listService.AjoutCommune(data).subscribe(resp=>{
      if (resp.status == 200) {
        this.tostr.success("ajout avec succès")
        window.location.reload()
      }
      else{
        this.tostr.error("erreur")
      }
    })
  }
  choisirItem(code){
    this.listService.prendFokontanyByComune(code).subscribe(reponse=>{
      this.listefokotany2 = reponse
      for (let i = 0; i < reponse.length; i++) {
        this.codeCommune = reponse[i].codeCommune
        this.codeditrict = reponse[i].codeDistrict
        
      }
    })
  }
  onChange(event) {
    this.codeCommune = event[0].data.id
    this.commune2 = event[0].data.text
    this.listService.prendFokontanyByComune(event[0].data.text).subscribe(reponse=>{
      this.listefokotany = reponse
    })
  }
  onChanges(event) {
    this.codeDistrict = event[0].data.id
    this.districts = event[0].data.text
    
  }
  fkt(index) {
    this.foko = []
    if (index.length > 3) {
      this.listService.prendListeCommune(index).subscribe(resp => {
        for (let i = 0; i < resp.length; i++) {
          this.foko.push({
            id: resp[i].codeCommune,
            text: resp[i].commune + " (District:"+ resp[i].district + ")" + " (Région:"+ resp[i].region + ")"
          })
        }
      })
    }
  }
  fktn(index) {
    this.distr = []
    if (index.length > 3) {
      this.listService.prendListeDistrict(index).subscribe(resp => {
        for (let i = 0; i < resp.length; i++) {
          this.distr.push({
            id: resp[i].codeDistrict,
            text: resp[i].district 
          })
        }
      })
    }
  }

}
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.css']
})
export class ViewComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }
  typeChartFormGroup: FormGroup
  typeAffichage: any
  valColumn: any[] = []
  choixChart: any
  typeChart = [
    {
      "id": 1,
      "name": 'Barre / courbe'
    },
    {
      "id": 2,
      "name": 'Pie'
    },
    {
      "id": 3,
      "name": 'Liste'
    }
  ];

  ngOnInit(): void {
    this.typeAffichage = 1
    this.typeChartFormGroup = this.formBuilder.group({
      type: ['']
    });
    this.modifiierAfficage(1)
  }

  modifiierAfficage(value) {
    this.typeAffichage = value
    let data = new Object()
    data['tails'] = value
    switch (value) {
      case 1:
      case 2:
        data['value'] = 'col-md-12'
        break;
      case 4:
        data['value'] = 'col-md-6'
        break;
      default:
        break;
    }
    this.boucleColonne(data)
  }

  boucleColonne(data) {
    this.valColumn = []
    for (let index = 0; index < data.tails; index++) {
      this.valColumn.push(data.value)
    }
  }

  modifierTypeChart(typeCharts, value) {
    this.choixChart = typeCharts.value

  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ReportingService {

  constructor(
    private http: HttpClient
  ) { }

  getIndicateurByLibelle(libelle) {
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl3}/api/indicateur/getIndicateurByLibelle/${libelle}`, { headers: headers, observe: 'response' })
  }

  getIndicateurById(id){
    const headers = new HttpHeaders()
      .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl3}api/indicateur/getAllIndicateurById/${id}`, { headers: headers, observe: 'response' })
  }


  calculeTaux(data) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.post<any>(`${environment.apiUrl3}api/indicateur/calculeNbreOuTaux`,data,  { headers: headers, observe: 'response' });



  }
 getAllSection(){
  const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  return this.http.get<any>(`${environment.apiUrl3}api/indicateur/listeAllSection`,  { headers: headers, observe: 'response' });
 }

 getSectionById(idSection){
  const headers = new HttpHeaders()
  .set('content-type', 'application/json')
 return this.http.get<any>(`${environment.apiUrl3}api/indicateur/listeIndicateurBySection/${idSection}`, { headers: headers, observe: 'response' })
 }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-suivicart',
  templateUrl: './suivicart.component.html',
  styleUrls: ['./suivicart.component.css']
})
export class SuivicartComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

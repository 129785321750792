import { Component, OnInit } from '@angular/core';
import { CardService } from '../services/card/card.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-parcelle',
  templateUrl: './parcelle.component.html',
  styleUrls: ['./parcelle.component.css']
})
export class ParcelleComponent implements OnInit {
  dataIndiv: any;
  sexe: any;
  imgIndividu: any;
  id: any;
  dataSect: any;

  constructor(
    private cardServ: CardService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getListIndividu(this.id)
    this.getListsectAct(this.id)
  }
  getListIndividu(id){
    this.cardServ.getAllIndividu(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataIndiv = res.body;
      }
    })
  }
  retour() {
    this._location.back();
  }
  getListsectAct(id){
    this.cardServ.getSectActivite(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataSect = res.body;
      }
    })
  }
  activ() {
    this.router.navigate(['sectact/' + this.id]);
  }
  home() {
    this.router.navigate(['acceuil']);
  }
  menfamille() {
    this.router.navigate(['menfamille/' + this.id]);
  }
  hitstappui() {
    this.router.navigate(['histappui/' + this.id]);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-pie',
  templateUrl: './pie.component.html',
  styleUrls: ['./pie.component.css']
})
export class PieComponent implements OnInit {
  @Input() dataChart: any
  dataChartPie: any[] = []
  constructor() { }

  ngOnInit(): void {  
  }

  ngOnChanges(){
    this.modifierDataChart();    
  }

  modifierDataChart(){
    this.dataChartPie = [];
    this.dataChart.map(element => {
      let obj = {

        name: element.valeurColonne ,
        //+ element.nomLocalite,
        value: element.tauxPourcentage !== undefined ? element.tauxPourcentage: element.nbreTotal
      }
      this.dataChartPie.push(obj);
    });
    this.chartPie();
  }

  options: any
  chartPie() {
    this.options = {
      tooltip: {
        trigger: 'item'
      },
      legend: {
        top: '5%',
        left: 'center'
      },
      series: [
        {
          name: '',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          top: '30%',
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2
          },
          label: {
            show: false,
            position: 'center'
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold'
            }
          },
          labelLine: {
            show: false
          },
          data: this.dataChartPie
          
        }
      ]
     
          
    };
  }

}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-info-indiv',
  templateUrl: './info-indiv.component.html',
  styleUrls: ['./info-indiv.component.css']
})
export class InfoIndivComponent implements OnInit {
  panelOpenState = false;
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  dateInsert: any

  forAdmin: boolean = false;
  matricule: any;
  metier: any;
  numLogement: any;
  constructor() { }

  ngOnInit(): void {
    this.getLocalStorage()
  }
  getLocalStorage() {
    const values = JSON.parse(localStorage.getItem('user'));
    this.prenom = values.individu.prenom
    this.nom = values.individu.nom
    this.contact = values.individu.contact
    this.mail = values.individu.mail
    this.lieuNaiss = values.individu.lieuNaiss
    this.dateNaiss = values.individu.dateNaiss
    this.cin = values.individu.cin
    this.sexe = values.individu.sexe.libelleSexe
    this.libelleStatus = values.status.libelleStatus
    this.national = values.individu.national
    this.matricule = values.individu.matricule
    this.metier = values.individu.metier
    this.numLogement = values.individu.numLogement
    this.dateInsert = values.individu.dateInsert
    
    /* this.nomFokontany = values.individu.fokontany.nomFokontany */
/*     this.nomCommune = values.individu.commune.nomCommune
    this.nomDistrict = values.individu.district.nomDistrict
    this.nomRegion = values.individu.region.nomRegion
    this.nomProvince = values.individu.province.nomProvince */
    this.status = values.individu.status
    this.imgIndividu = values.individu.imgIndividu
    /* this.status = values.status[0] */
    this.imgIndividu = values.individu.photos
    if (this.status === "Admin") {
      this.forAdmin = true;
    }else{
      this.forAdmin = false;
    }
  }

}

import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})


export class TableComponent implements OnInit {
  @Input() dataTableau: any;
  nomLocaliteTable: any[]= [];
  secondValues: any[] = [];
  valeurColonneTable: any[] = [];
  regex = /\"/g;
  dataX: any[];
  dataY: any[];
  dataX2: any[];
  dataY2: any[];
  dataY3: any[];
  dataX3: any[];
  dataY4: any[];
  dataX4: any[] = [];
  dataSerie: any[];
  barChartLabels: any[];
  barChartData: any[];
  dataCondition: boolean;
  bardata: string[] = [];
  dataxv: any[]=[];
  dataxy: any[]=[];
  dataxz: any[]=[];
  dataelement: any;
  constructor() { }

  headElements = ['ID', 'Nom', 'Prenom', 'Age', 'Sexe'];

  ngOnInit(): void {
   
  }

  ngOnChanges(){
    this.modifierDataChart();   
  }

  modifierDataChart() {

    this.dataX = [];
    this.dataY = [];
    this.dataX2 = [];
    this.dataY2 =  [];
    this.dataY3 = [];
    this.dataX3 = [];
    this.dataY4 = [];
    this.dataX4 = [];
    this.dataSerie = [];
    this.barChartLabels = [];
    this.barChartData = [];
    this.dataTableau.map(element => {
                  if( element.listeLocalite == undefined && element.listeLocalite ==null){
                    this.dataY.push(element.tauxPourcentage !== undefined ? element.tauxPourcentage : element.nbreTotal)
                    this.dataX.push( element.valeurColonne);
                    this.dataCondition=true;
                  }else{
                    this.dataCondition=false;
                    this.bardata.push( element.listeLocalite);
                        this.bardata = Object.values(element.listeLocalite)
                        this.dataY.push(element.listeTauxNbreColonnes);
                    for (let i = 0; i <  element.listeTauxNbreColonnes.length; i++) {
                      this.dataxv.push(element.listeTauxNbreColonnes[i].valueColonne)
                      this.dataxy.push(element.listeTauxNbreColonnes[i].tauxNbreListe)
                    };
                    
                  }    
                });
  }

  exportTableExcel(): void {
      const downloadLink = document.createElement('a');
      const dataType = "application/vnd.ms-excel";
      const table = document.getElementById('tableToExcel');
      const tableHtml = table.outerHTML.replace(/ /g, '%20');
      document.body.appendChild(downloadLink);
      downloadLink.href= 'data:'+ dataType +' '+ tableHtml;
      downloadLink.download = 'httptrace.xls';
      downloadLink.click();
  }

}

import { Component, OnInit } from '@angular/core';
import { AccountService } from "../services/account/account.service";
import { Router } from '@angular/router';
import { User } from 'app/models/user';
import { Status } from 'app/models/status';

@Component({
  selector: 'app-acceuils',
  templateUrl: './acceuils.component.html',
  styleUrls: ['./acceuils.component.css']
})
export class AcceuilsComponent implements OnInit {
  panelOpenState = false;
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  user: User;
  forAdmin: boolean = false;
  matricule: any;
  constructor(private accountService: AccountService, private router: Router) {
    this.accountService.user.subscribe(x => this.user = x);
   }

  get isAdmin() {
    return this.user && this.user.status === Status.admin;
  }
  get isEnqueteur() {
    return this.user && this.user.status === Status.enquêteur;
  }
  get isDirecteur() {
    return this.user && this.user.status === Status.directeur;
  }
  get isAdmine() {
    return this.user && this.user.status === Status.admine;
  }
  get isSuperviseur() {
    return this.user && this.user.status === Status.superviseur;
  }
  get isFDA() {
    return this.user && this.user.status === Status.fda;
  }
  get isMinistre() {
    return this.user && this.user.status === Status.ministre;
  }
  get isCoordonnateur() {
    return this.user && this.user.status === Status.coordonnateur;
  }
  get isSG() {
    return this.user && this.user.status === Status.sg;
  }
  get isGestionnaireDeCarte() {
    return this.user && this.user.status === Status.GestionnaireDeCarte;
  }

  ngOnInit(): void {
    this.getLocalStorage()
  }
  getLocalStorage() {
    const values = JSON.parse(localStorage.getItem('user'));
    this.prenom = values.individu.prenom
    this.nom = values.individu.nom
    this.contact = values.individu.contact
    this.mail = values.individu.mail
    this.lieuNaiss = values.individu.lieuNaiss
    this.dateNaiss = values.individu.dateNaiss
    this.cin = values.individu.cin
    this.sexe = values.individu.sexe
    this.libelleStatus = values.status.libelleStatus
    this.national = values.individu.national
    this.matricule = values.individu.matricule
    /* this.nomFokontany = values.individu.fokontany.nomFokontany */
    /*     this.nomCommune = values.individu.commune.nomCommune
        this.nomDistrict = values.individu.district.nomDistrict
        this.nomRegion = values.individu.region.nomRegion
        this.nomProvince = values.individu.province.nomProvince */
    this.status = values.individu.status
    this.imgIndividu = values.individu.imgIndividu
    /* this.status = values.status[0] */
    this.imgIndividu = values.individu.photos
    if (this.status === "Admin") {
      this.forAdmin = true;
    } else {
      this.forAdmin = false;
    }
  }
  redirect() {
    this.router.navigate(['app_profil_individus']);
  }
  redirec() {
    this.router.navigate(['liste-enquete']);
  }
  redire() {
    this.router.navigate(['stepper']);
  }
  redir() {
    this.router.navigate(['report']);
  }
  testvalidation() {
    this.router.navigate(['orgPays']);
  }
  /*   mandeha() {
    this.router.navigate(['dragdrop']);
    } */
  azo() {
    this.router.navigate(['tableadmin']);
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }

  listeEnquete() {
    this.router.navigate(['listeprod']);
  }

  createIndiv() {
    this.router.navigate(['create_individu'])
  }
}


import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CardService } from '../services/card/card.service';

@Component({
  selector: 'app-tableprod',
  templateUrl: './tableprod.component.html',
  styleUrls: ['./tableprod.component.css']
})
export class TableprodComponent implements OnInit {
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  dataIndiv: any = {};
  mat: any;
  details: any = {};
  constructor(
    private router: Router,
    private cardServ: CardService,
    private route: ActivatedRoute) { }

  

  ngOnInit(): void {
    this.mat = this.route.snapshot.paramMap.get('matricule')
    this.getListIndividu(this.mat)
  }
  parcelle() {
    this.router.navigate(['parcelle/' + this.mat]);
  }
  histappui() {
    this.router.navigate(['histappui/' + this.mat]);
  }
  menfamille() {
    this.router.navigate(['menfamille/' + this.mat]);
  }
  sect() {
    this.router.navigate(['sectact/' + this.mat]); 
  }
  redirect() {
    this.router.navigate(['app_profil_individus']);
  }
  mandeha() {
  this.router.navigate(['inscription']);
  }
// misitona matricule amna compenent
  getListIndividu(id){
    this.cardServ.getAllIndividu(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataIndiv = res.body;
      }
    })
  }
  
}

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../../models/user';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CardService {

  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
  private subject = new Subject<any>();

  constructor(
    private router: Router,
    private http: HttpClient) {
      this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
      this.user = this.userSubject.asObservable();
    }

    public get userValue(): User {
      return this.userSubject.value;
    }
    matricule( matricule ) {
      return this.http.post<User>(`${environment.apiUrl2}api/carteProducteur`, { matricule, })
    }
    sendVerso(_carteRecto) {
      return this.http.post(`${environment.apiUrl2}api/carteProducteur`, { _carteRecto, });
    }
    sendRecto(_carteVerso ) {
      return this.http.post(`${environment.apiUrl2}api/carteProducteur`, { _carteVerso, });
    }

    // image( matricule , token) {
    //   const headers= new HttpHeaders()
    // .set('content-type', 'application/json')
    // .set('Authorization:', 'Bearer ' + token)
    //   return this.http.post<User>(`${environment.apiUrl2}api/carte/carteProducteur`, matricule, { headers: headers, observe: 'response' })
    // }

    image( matricule) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.post<User>(`${environment.apiUrl2}api/carte/carteProducteur`, matricule, { headers: headers, observe: 'response' })
    }

    getFammille( matricule) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/auth/prendListeFamille/`+ matricule, { headers: headers, observe: 'response' })
    }
    getSectActivite( id) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/auth/prendListeSecteurByMatriculeProd/`+ id, { headers: headers, observe: 'response' })
    }
    getAppui( matricule) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/auth/prendListeAppuiByMat/`+ matricule, { headers: headers, observe: 'response' })
    }
    getAllIndividu(matricule) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/auth/prendIndivParMatricule/`+ matricule, { headers: headers, observe: 'response' })
    }
    getAllTechNonValider() {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/individu/listeTechnicienNonValider`, { headers: headers, observe: 'response' })
    }
    getAllTechValider() {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/individu/listeTechnicienValider`, { headers: headers, observe: 'response' })
    }

    validerTechnicien(admin, tech) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/individu/validationCompteTechnicien/`+ admin + "/" + tech, { headers: headers, observe: 'response' })
    }
    getIscription( matricule) {
      const headers= new HttpHeaders()
    .set('content-type', 'application/json')
      return this.http.get<any>(`${environment.apiUrl2}api/account/prendInscription/`+ matricule, { headers: headers, observe: 'response' })
    }
}

import { Component, OnInit } from '@angular/core';
import { CardService } from '../services/card/card.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { AccountService } from '../services/account/account.service';
declare var $: any;

@Component({
  selector: 'app-technonvalider',
  templateUrl: './technonvalider.component.html',
  styleUrls: ['./technonvalider.component.css']
})
export class TechnonvaliderComponent implements OnInit {
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  dataNonValider: any = [];
  dataValider: any;
  admin: any = {};
  token: any;
  activeTech: any = {};
  currentPage: number = 1;
  pagination: any;
  totalPages: any;
  totalItems: number;
  show: false;
  userToValider: any;

  constructor(
    private router: Router,
    private accountService: AccountService,
    private cardServ: CardService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    
    this.admin = JSON.parse(localStorage.getItem('user'));
    this.getListTechNonValider()
  }
  getListTechNonValider() {
    this.accountService.getAllTechNonValider(this.currentPage).subscribe(res => {
      if (res.status == 200) {
        this.dataNonValider = res.body.content;
        this.pagination = res.body;
        this.totalPages = this.pagination.totalPages;      
        this.currentPage = parseInt(this.pagination.currentPage) + 1;
        this.totalItems = this.pagination.totalPages * this.pagination.totalPages; 
      }
    })
  }
  changeValue(page: number) {
    this.show = false;
    if (page) {
      this.currentPage = page;
      this.getListTechNonValider()
    }
  }

  getActive(item){
    this.activeTech = item;
  }
  valider(){
    const data={
      "matricule": this.userToValider.matricule
    }
    this.accountService.validerTechnicien(data).
    subscribe(
      (resp) =>{
        this.toast.success('Validation technicien avec success') 
        this.router.navigate(['tableadmin'])
    },(error)=>{
      this.toast.error('Une erreur s\'est produite')
    }
    )
  }
  openModal(matricule) {
    this.userToValider = this.dataNonValider.find(x => x.matricule === matricule);
    $('#exampleModalLongConf').modal('show');
  }
  home() {
    this.router.navigate(['acceuil']);
  }
  TechNV() {
    this.router.navigate(['technonvalider']);
  }
  TechV() {
    this.router.navigate(['tableadmin']);
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
}

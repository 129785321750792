import { InputBase } from './../base-input/input-base';
export const CIVILITE: InputBase<any>[] = [
    {
        value: "",
        key: "prenom",
        label: "Prenom",
        required: false,
        order: 1,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "nom",
        label: "Nom de la famille",
        required: false,
        order: 2,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        key: 'sexe',
        label: 'Sexe',
        required: false,
        controlType: "string",
        type: "dropdown",
        readonly: true,
        options: [
          {key: '1',  value: 'Masculin'},
          {key: '2',  value: 'Feminin'}
        ],
        order: 3
      },
       {
        value: "",
        key: "nationalite",
        label: "Nationalité",
        required: false,
        order: 4,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "string",
        label: "NOM",
        required: false,
        order: 5,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "data_naissance",
        label: "Date de naissance",
        required: false,
        order: 6,
        controlType: "string",
        type: "date",
        readonly: false
       },
       {
        value: "",
        key: "lieu_naissance",
        label: "Lieu de naissance",
        required: false,
        order: 7,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "cin",
        label: "CIN",
        required: false,
        order: 8,
        controlType: "string",
        type: "number",
        readonly: false
       },
       {
        value: "",
        key: "date_cin",
        label: "Date de delivrance CIN",
        required: false,
        order: 9,
        controlType: "string",
        type: "date",
        readonly: false
       },
       {
        value: "",
        key: "lieu_cin",
        label: "Lieu de delivrance CIN",
        required: false,
        order: 10,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "nom_mere",
        label: "Nom de la mere",
        required: false,
        order: 11,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        value: "",
        key: "nom_pere",
        label: "Nom du pere",
        required: false,
        order: 12,
        controlType: "string",
        type: "text",
        readonly: false
       },
       {
        key: 'niveau_instruction',
        label: 'Quel est le Niveau d’instruction du CE ?  ',
        required: false,
        controlType: "string",
        type: "dropdown",
        readonly: true,
        options: [
          {key: '0',  value: 'N’a pas été à l’école'},
          {key: '1',  value: 'Préscolaire/primaire'},
          {key: '2',  value: 'Collège général'},
          {key: '3',  value: 'Collège professionnel'},
          {key: '4',  value: 'Lycée général'},
          {key: '5',  value: 'Lycée professionnel'},
          {key: '6',  value: 'Supérieur'},
        ],
        order: 13
      },
      {
        key: 'statut_matrimonial',
        label: 'Statut matrimonial ',
        required: false,
        controlType: "string",
        type: "dropdown",
        readonly: true,
        options: [
          {key: '1',  value: 'Célibataire'},
          {key: '2',  value: 'Marié'},
          {key: '3',  value: 'Séparé/divorcé'},
          {key: '4',  value: 'Veuf (ve)'}
        ],
        order: 14
      },
]
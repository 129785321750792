import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { AlertService } from '../../services/alert.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from '../../services/account/account.service';
import { first } from 'rxjs/operators';
import { HttpEventType } from '@angular/common/http';
import { Fokontany } from '../../models/fokontany';
import { ListService } from '../../services/list/list.service';
import { geometry } from '@progress/kendo-drawing';
import { image } from 'html2canvas/dist/types/css/types/image';
import { ModePaiement } from '../../models/mode-paiement';
import { Secteur } from '../../models/secteur';
import { Status } from '../../models/status';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
declare var $: any;

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
    myControl = new FormControl();


    alert = false
    filePath: string;
    listsFokontany?: Fokontany[];
    listsModePaiment?: ModePaiement[];
    listsSecteur?: Secteur[];
    listsStatus?: any;
    hide = true;
    geo: any;
    matricule: any;
    optionCommune: {
        id: any,
        libelle: any
    }

    dataUser = {
        password: '',
        nom: '',
        prenom: '',
        contact: '',
        mail: '',
        lieuNaiss: '',
        dateNaiss: '',
        cin: '',
        sexe: '',
        status: '',
        national: '',
        secteurActivite: '',
        modePaiement: '',
        fokontany: '',
        commune: '',
        district: '',
        region: '',
        province: '',
        image: null
    };
    dataRet = [];
    fileInputImage: string;
    showModal2: boolean;
    selected: boolean;
    resAddutilisateur: any;
    isOfx = false;

    constructor(private formBuilder: FormBuilder,
        private router: Router,
        private accountService: AccountService,
        private alertService: AlertService,
        private listService: ListService) {
        if (this.accountService.userValue) {
            this.router.navigate(['/']);
        }


    }
    ngOnInit(): void {
        this.listFokontany()
        this.listModePaiement()
        this.listSecteur()
        this.listStatus()
        this.optionCommune = null
        this.selected = false

    }

    ajoutUtilisateur() {

        this.accountService.register(this.dataUser).subscribe(res => {
            this.resAddutilisateur = res;
            this.router.navigate(['/Authentification']);

        })

    }

    listFokontany(): void {
        this.listService.getAllFokontany()
            .subscribe(
                data => {
                    this.listsFokontany = data;
                },
                error => {
                });
    }
    listModePaiement(): void {
        this.listService.getallModePaiement()
            .subscribe(
                data => {
                    this.listsModePaiment = data;
                },
                error => {
                });
    }
    listSecteur(): void {
        this.listService.getallSecteur()
            .subscribe(
                data => {
                    this.listsSecteur = data;
                },
                error => {
                });
    }
    listStatus(): void {
        this.listService.findAllStatus()
            .subscribe(
                data => {
                    this.listsStatus = data;
                },
                error => {
                });
    }
    onSelect(idFok) {
        this.listService.getGeoByIdFonkotany(idFok)
            .subscribe(
                data => {
                    this.geo = data.body;
                    this.dataUser.commune = this.geo.idCommune
                    this.dataUser.region = this.geo.district.region.idRegion
                    this.dataUser.district = this.geo.district.idDistrict
                    this.dataUser.province = this.geo.district.region.province.idProvince
                    this.selected = true;

                },
                error => {
                }
            );


    }
    fileChange(event) {
        const file = event.target.files[0];
        if (file) {
            this.fileInputImage = file.name;
            this.dataUser.image = file;
        }
        this.isOfx = false;
        const extension = this.fileInputImage.substring(this.fileInputImage.length - 4, this.fileInputImage.length);
        if (extension.toLocaleLowerCase() === '.txt') {
            this.isOfx = false;
        } else if (extension.toLocaleLowerCase() === '.ofx') {
            this.isOfx = true;
        }
        const reader = new FileReader();
        reader.onload = () => {
            this.filePath = reader.result as string;
        }
        reader.readAsDataURL(file)
    }

    inscrire() {
        const formData = new FormData();
        formData.append('password', this.dataUser.password);
        formData.append('nom', this.dataUser.nom);
        formData.append('prenom', this.dataUser.prenom);
        formData.append('contact', this.dataUser.contact);
        formData.append('mail', this.dataUser.mail);
        formData.append('lieuNaiss', this.dataUser.lieuNaiss);
        formData.append('dateNaiss', this.dataUser.dateNaiss);
        formData.append('cin', this.dataUser.cin);
        formData.append('sexe', this.dataUser.sexe);
        formData.append('national', this.dataUser.status);
        formData.append('fokontany', this.dataUser.fokontany);
        formData.append('commune', this.dataUser.commune);
        formData.append('district', this.dataUser.district);
        formData.append('region', this.dataUser.region);
        formData.append('province', this.dataUser.province);
        formData.append('image', this.dataUser.image);
        formData.append('status', this.dataUser.status);
        formData.append('secteurActivite', this.dataUser.secteurActivite);
        formData.append('modePaiement', this.dataUser.modePaiement);
        this.accountService.register(formData).subscribe(res => {
            if (res.status === 200) {
                this.dataRet.push(res.body);
                this.matricule = this.dataRet[0].individu.matricule;
                this.showModal2 = true;
                this.alert = true
                // document.getElementById("#staticBackdrop").classList.add('OuvrirModale');
            }
        });
        // this.router.navigate(['/login'])

    }
    closeAlert() {
        this.showModal2 = false;
    }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ListService } from '../../services/list/list.service';
import { AccountService } from '../../services/account/account.service';

@Component({
  selector: 'app-detailsecteur',
  templateUrl: './detailsecteur.component.html',
  styleUrls: ['./detailsecteur.component.css']
})
export class DetailsecteurComponent implements OnInit {
  id: string
  matr: string;
  detaisecteur: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private accountService: AccountService,
    private router: Router,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.matr = this.route.snapshot.paramMap.get('matr')
    this.getDetailSecteur()
  }
  getDetailSecteur(){
    this.list.detaiSecteur(this.matr,this.id).subscribe(resp=>{
      this.detaisecteur = resp.body
    })
  }  
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }

}

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'app/services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-mot-de-passe-oublier',
  templateUrl: './mot-de-passe-oublier.component.html',
  styleUrls: ['./mot-de-passe-oublier.component.css']
})
export class MotDePasseOublierComponent implements OnInit {
  form: FormGroup;

  constructor(
    private ac : AccountService,
    private toastr: ToastrService,
    private route: Router,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      AdresseMail: new FormControl('', [Validators.required]), /* j'ai changer email par matricule et j'ai effacer Validators.email */
    });
  }
  onSubmit(){
    const data = {
      "email": this.form.get('AdresseMail').value
    }
    this.ac.mailladresse(data).subscribe(res=>{
      if (res.status === 200) {
        this.toastr.success("Un code a été envoyée dans votre mail");
        this.route.navigate(["nouveauMotdePasse"]);
      }
    })
  }

  public hasError = (controlName: string, errorName: string) => {
    return this.f[controlName].hasError(errorName);
  }
  get f() { return this.form.controls; }

}

import { LoginComponent } from './account/login/login.component';
import { AuthGuard } from './helper/auth.guard';
import { NgModule, Component } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { RegisterComponent } from './account/register/register.component';
import { ProfilIndividusComponent } from './profil-individus/profil-individus.component';
import { CardTechComponent } from './componentsutul/card-tech/card-tech.component';
import { CardComponent } from './componentsutul/card/card.component';
import { InscriptionComponent } from './account/inscription/inscription.component';
import { AjoutProdComponent } from './ajout-prod/ajout-prod.component';
import { AcceuilsComponent } from './acceuils/acceuils.component';
import { TableprodComponent } from './tableprod/tableprod.component';
import { InfoProducteurComponent } from './info-producteur/info-producteur.component';
import { SectactComponent } from './sectact/sectact.component';
import { MenfamilleComponent } from './menfamille/menfamille.component';
import { HistappuiComponent } from './histappui/histappui.component';
import { ParcelleComponent } from './parcelle/parcelle.component';
import { TableadminComponent } from './tableadmin/tableadmin.component';
import { AuthGuardGuard } from './guard/auth-guard.guard';
import { Role } from './models/role';
import { TechnonvaliderComponent } from './technonvalider/technonvalider.component';
import { DragdropComponent } from './dragdrop/dragdrop.component';
import { GraphComponent } from './reporting/graph/graph.component';
import { StepperComponent } from './stepper/stepper.component';
import { RealisationtestComponent } from './realisationtest/realisationtest.component';
import { ViewComponent } from './reporting/view/view.component';
import { ListeequeteComponent } from './listeequete/listeequete.component';
import { ListeEnqueteComponent } from './enquete/liste-enquete/liste-enquete.component';
import { DetailEnqueteComponent } from './enquete/detail-enquete/detail-enquete.component';
import { ValidationComponent } from './validation/validation/validation.component';
import { ListeRealisationComponent } from './validation/liste-realisation/liste-realisation.component';
import { DetailsSectionComponent } from './validation/details-section/details-section.component';
import { ListeSectionComponent } from './validation/liste-section/liste-section.component';

import { DetailslisterealisationComponent } from './validation/detailslisterealisation/detailslisterealisation.component';
import { UpdaterealisationComponent } from './validation/updaterealisation/updaterealisation.component';
import { InitialComponent } from './realisationtest/initial/initial.component';


import { CreateAdminComponent } from './individu/create-admin/create-admin.component';
import { MenuIndivComponent } from './individu/menu-indiv/menu-indiv.component';
import { ListeprodComponent, DetailprodComponent } from './listeprod/listeprod.component';
import { DetailproducteurComponent } from './detailproducteur/detailproducteur.component';
import { DetailsecteurComponent } from './secteuractivite/detailsecteur/detailsecteur.component';
import { ExportExcelComponent } from './export-excel/export-excel.component';
import { RechercheComponent } from './recherche/recherche.component';
import { ImportExcelComponent } from './import-excel/import-excel.component';
import { OrganisatinPaysaneComponent } from './organisatin-paysane/organisatin-paysane.component';
import { AjoutPaysaneComponent } from './ajout-paysane/ajout-paysane.component';
import { DialogOverviewExampleDialogComponent } from './detailprod/dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import { GestionCarteComponent } from './gestion-carte/gestion-carte.component';
import { FileInfoComponent } from './file-info/file-info.component';
import { StatistiqueComponent } from './statistique/statistique.component';
import { GestionlocaliteComponent } from './gestionlocalite/gestionlocalite.component';
import { ModifInfoIndivComponent } from './modif-info-indiv/modif-info-indiv.component';
import { ModifMotDePassComponent } from './modif-mot-de-pass/modif-mot-de-pass.component';
import { Status } from './models/status';
import { MotDePasseOublierComponent } from './mot-de-passe-oublier/mot-de-passe-oublier.component';
import { NouveauMotdePasseComponent } from './nouveau-motde-passe/nouveau-motde-passe.component';
import { ModifLocaliteComponent } from './modif-localite/modif-localite.component';


const routes: Routes = [
  { path: 'détailsSection/:id/:flag', component: DetailsSectionComponent },
  { path: 'listeSection', component: ListeSectionComponent },
  { path: 'realisation', component: RealisationtestComponent, canActivate: [AuthGuardGuard] },
  { path: 'validation', component: ValidationComponent },
  { path: 'stepper', component: StepperComponent, canActivate: [AuthGuardGuard] },
  { path: 'dragdrop', component: DragdropComponent, canActivate: [AuthGuardGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: InscriptionComponent },
  { path: 'ajout_prod', component: AjoutProdComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'acceuil', component: AcceuilsComponent, canActivate: [AuthGuardGuard] },

  { path: '', component: LoginComponent },
  { path: 'inscription', component: InscriptionComponent },
  { path: 'passeOublier', component: MotDePasseOublierComponent },
  { path: 'nouveauMotdePasse', component: NouveauMotdePasseComponent },
  { path: 'modifMotDePass', component: ModifMotDePassComponent, canActivate: [AuthGuard]},

  { path: 'card-tech-profile', component: CardTechComponent, canActivate: [AuthGuard], data: { roles: [Role.enquêteur] } },
  { path: 'card', component: CardComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'app_profil_individus', component: ProfilIndividusComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'info-prod/:matricule', component: TableprodComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'sectact/:id', component: SectactComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'menfamille/:id', component: MenfamilleComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'histappui/:id', component: HistappuiComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'parcelle/:id', component: ParcelleComponent, canActivate: [AuthGuardGuard], data: { roles: [Role.enquêteur] } },
  { path: 'tableadmin', component: TableadminComponent, canActivate: [AuthGuardGuard]},
  { path: 'technonvalider', component: TechnonvaliderComponent, canActivate: [AuthGuardGuard] },
  { path: 'report', component: GraphComponent, canActivate: [AuthGuardGuard] },
  { path: 'liste-enquete', component: ListeEnqueteComponent, canActivate: [AuthGuardGuard] },
  { path: 'detail-enquete/:id', component: DetailEnqueteComponent, canActivate: [AuthGuardGuard] },

  { path: 'detaillisterealisation/:nom', component: DetailslisterealisationComponent, canActivate: [AuthGuardGuard] },
  { path: 'updaterealisation/:id', component: UpdaterealisationComponent, canActivate: [AuthGuardGuard] },
  { path: 'initial', component: InitialComponent, canActivate: [AuthGuardGuard] },

  { path: 'create_individu', component: MenuIndivComponent, canActivate: [AuthGuardGuard] },
  { path: 'listeprod', component: ListeprodComponent, canActivate: [AuthGuardGuard] },
  { path: 'excel', component: RechercheComponent, canActivate: [AuthGuardGuard] },
  { path: 'detailprod/:id', component: DetailproducteurComponent, canActivate: [AuthGuardGuard] },
  { path: 'detailsecteur/:id/:matr', component: DetailsecteurComponent },
  { path: 'export_excel',component:ExportExcelComponent, canActivate: [AuthGuardGuard]},
  { path: 'import_excel',component:ImportExcelComponent, canActivate: [AuthGuardGuard]},
  { path: 'orgPays' , component:OrganisatinPaysaneComponent, canActivate: [AuthGuardGuard]},
  { path: 'AjouTPaysane', component: AjoutPaysaneComponent, canActivate: [AuthGuardGuard]},
  { path: 'ModifProd/:matr', component: DialogOverviewExampleDialogComponent, canActivate: [AuthGuardGuard]},
  { path: 'gestioCart', component: GestionCarteComponent, canActivate: [AuthGuardGuard]},
  { path: 'fileInfo', component: FileInfoComponent, canActivate: [AuthGuardGuard]},
  { path: 'statistique', component: StatistiqueComponent, canActivate: [AuthGuardGuard]},
  { path: 'gestLocalite', component: GestionlocaliteComponent, canActivate: [AuthGuardGuard]},
  { path: 'modifInfo/:matr', component: ModifInfoIndivComponent, canActivate: [AuthGuardGuard]},
  { path: 'modiflocalite/:matr', component: ModifLocaliteComponent, canActivate: [AuthGuardGuard]}


];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes, {
      useHash: true
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ListService } from "../services/list/list.service";

@Component({
  selector: "app-logistique",
  templateUrl: "./logistique.component.html",
  styleUrls: ["./logistique.component.css"],
})
export class LogistiqueComponent implements OnInit {
  id: string;
  listeLogistique: any[] = [];
  constructor(private route: ActivatedRoute, private list: ListService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getLogistique();
  }
  getLogistique() {
    this.list.getLogistique(this.id).subscribe((resp) => {
      this.listeLogistique = resp.body;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AccountService } from '../../services/account/account.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.css']
})
export class ValidationComponent implements OnInit {

  constructor(
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const user = localStorage.getItem('user')
    const valUser = JSON.parse(user)
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
}

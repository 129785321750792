export const environment = {
  production: false,
  /* apiUrl: 'https://api-maep-dev.hr-itconsulting.net/',
  apiUrlIndiv2: 'https://adresse-contact-minae-dev.hr-itconsulting.net/',
  apiUrl2: 'https://back-realisation-dev.hr-itconsulting.net/',
  apiReferentiel: 'https://back-referentiel-dev.hr-itconsulting.net/',
  apiUrl3: 'https://indicateur-minae-dev.hr-itconsulting.net/',
  apiUrlIndiv: 'https://individu-maep-dev.hr-itconsulting.net/',
  apiValidation: 'https://back-realisation-dev.hr-itconsulting.net/', 
  apiMembrefamille: 'https://membre-famille-dev.hr-itconsulting.net/',
  apiServiceFinancier: 'https://service-financier-dev.hr-itconsulting.net/',
  apiOrganisationPaysane:  'https://back-orgpaysane-minae-dev.hr-itconsulting.net/',
  apiAppuis:'https://back-minae-appui-dev.hr-itconsulting.net/',
  apiEconomie:'https://back-economie-dev.hr-itconsulting.net/',
  apiContratAgrie:'https://contrat-agricole-dev.hr-itconsulting.net/',
  apiNutrition:'https://back-nutrition-dev.hr-itconsulting.net/',
  apiActivite:'https://secteur-activite-minae-dev.hr-itconsulting.net/'  */

  apiUrl: "https://api-maep-dev.hr-itconsulting.net/",
  apiUrlIndiv2: "https://adresse-contact-pf.minae.gov.mg/",
  apiUrl2: "https://back-realisation-pf.minae.gov.mg/",
  apiReferentiel: "https://back-referentiel-pf.minae.gov.mg/",
  apiUrl3: "https://indicateur-pf.minae.gov.mg/",
  apiUrlIndiv: "https://apiserver.minae.gov.mg/",
  apiValidation: "https://back-realisation-dev.hr-itconsulting.net/",
  apiMembrefamille: "https://membre-famille-pf.minae.gov.mg/",
  apiServiceFinancier: "https://service-financier-pf.minae.gov.mg/",
  apiOrganisationPaysane: "https://back-orgpaysane-pf.minae.gov.mg/",
  apiAppuis: "https://back-minae-appui-pf.minae.gov.mg/",
  apiEconomie: "https://back-economie-pf.minae.gov.mg/",
  apiContratAgrie: "https://contrat-agricole-pf.minae.gov.mg/",
  apiNutrition: "https://back-nutrition-pf.minae.gov.mg/",
  apiActivite: "https://secteur-activite-pf.minae.gov.mg/",

  /* apiUrl2:                'http://192.168.134.113:10500/',
  apiValidation:          'http://192.168.134.113:10500/',
  apiUrlIndiv:            'http://192.168.134.113:1014/',
  apiUrlIndiv2:           'http://192.168.134.113:1017/',
  apiReferentiel:         'http://192.168.134.113:1011/',
  apiMembrefamille:       'http://192.168.134.113:1021/',
  apiServiceFinancier:    'http://192.168.134.113:1016/',
  apiOrganisationPaysane: 'http://192.168.134.113:1018/',
  apiEconomie:            'http://192.168.134.113:1041/',
  apiAppuis:              'http://192.168.134.113:2031/',
  apiContratAgrie:        'http://192.168.134.113:1019/',
  apiNutrition:           'http://192.168.134.113:1020/',
  apiUrl3:                'http://192.168.134.113:1043/',
  apiActivite:            'http://192.168.134.113:1024/' */
};

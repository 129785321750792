import { Component, OnInit } from '@angular/core';
import { DragdropService } from '../services/dropdrag/dragdrop.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-recherche',
  templateUrl: './recherche.component.html',
  styleUrls: ['./recherche.component.css']
})
export class RechercheComponent implements OnInit {
  itemSection: any[]=[];
  Section: any;
  sectionName: any;
  itemSouthemes: any;
  fleche: boolean;
  sectionFormGroup: FormGroup;
  itemthemes: any;
  itemtheme: any[];
  ThemeNonVide: any;
  ListeSousSectionThemeNonVide: any[];
  dataEconomie: any[]=[];
  dataAgriG: any[]=[];
  themess: any;
  dataCulteRente: any []=[];
  dataApicult: any []=[];;

  constructor(
    private _formBuilder: FormBuilder,
    private dd: DragdropService,
  ) { }

  ngOnInit(): void {
    this.sectionFormGroup = this._formBuilder.group({
      section:[''], 
      sous_section: [''],
      theme: [''],
      sous_theme: ['']
    })
    this.getAllSection()
  }

    exportexcel(): void{
     const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.dataEconomie);
     const wb: XLSX.WorkBook = XLSX.utils.book_new();
     XLSX.utils.book_append_sheet(wb, ws, 'Economie');
    
      /* save to file  */
      XLSX.writeFile(wb, 'Liste_economie.xlsx');
   }
   exportexcel_rente():void{
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.dataCulteRente);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Rente');
   
     /* save to file  */
     XLSX.writeFile(wb, 'Liste_culture_rente.xlsx');
   }

   exportexcel_Apicult():void{
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.dataApicult);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Apiculture');
   
     /* save to file  */
     XLSX.writeFile(wb, 'Liste_Apiculture.xlsx');
   }




  ListeSousSectionThmeNonVides(Section){
    this.dd.ListesousSectionThemeNonvide(Section).subscribe(resp =>  {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.ThemeNonVide.push({
            id: resp.body[i].idSousSection,
            text: resp.body[i].libelleSousSection
          })
        }
      }
    })
  }
  prendListethemeSouthemeVide(idSousSection){
    this.itemtheme = [];
    this.dd.prendListethemeSouthemeVide(idSousSection).subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtheme.push({
            id: res.body[i].idTheme,
            text: res.body[i].LibelleTheme
          })
        }
      }
    })
  }
  getAllSection() {
    this.dd.findAllSection().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemSection.push({
            id: res.body[i].IdSection,
            text: res.body[i].libelleSection
          })
        }
      }
    })
  }

  SousSection(id) {
    this.prendListethemeSouthemeVide(id.value)
    this.checkSouTeme(id.value)
    this.itemSouthemes = []
  }

  theme(id) {
    this.themess = id.value
    this.getDataCultRente()
    this.getDataApiculture()
  }
  listProd(){
    
  }
  section(id) {
    this.getDataSouSectionVide()
    this.ListeSousSectionThmeNonVide(id.value)
    this.checkTeme(id.value)
    this.Section = id.value
    for (let i = 0; i < this.itemSection.length; i++) {
      if (this.itemSection[i].id === id.value) {
       this.sectionName = this.itemSection[i].text;
      }
    }
    this.itemSouthemes = []
    this.fleche = true
}

checkTeme(Section) {
  this.dd.Checktheme(Section).subscribe(resp => {
    this.itemthemes = resp.body
   })
 }
 checkSouTeme(sousSection) {
  this.dd.CheckSousThemes(sousSection).subscribe(resp => {
    this.itemSouthemes = resp.body
   })
 }
 getDataSouSectionVide(){
   this.dd.ListeProdEconomie().subscribe(resp =>{
     this.dataEconomie = resp.body
   })
 }

 getDataCultRente(){
  this.dd.ListeCultRente().subscribe(resp =>{
    this.dataCulteRente = resp.body
  })
}

getDataApiculture(){
  this.dd.ListeApicult().subscribe(resp =>{
    this.dataApicult = resp.body
  })
}



 ListeSousSectionThmeNonVide(Section){
  this.ListeSousSectionThemeNonVide = []
  this.dd.ListesousSectionThemeNonvide(Section).subscribe(resp =>  {
    if (resp.status === 200) {
      for (let i = 0; i < resp.body.length; i++) {
        this.ListeSousSectionThemeNonVide.push({
          id: resp.body[i].idSousSection,
          text: resp.body[i].libelleSousSection
        })
      }
    }
  })
}
}

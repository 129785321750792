import { Component, OnInit } from '@angular/core';
import { TestService } from '../../services/realisation/test.service';

@Component({
  selector: 'app-listobsevation',
  templateUrl: './listobsevation.component.html',
  styleUrls: ['./listobsevation.component.css']
})
export class ListobsevationComponent implements OnInit {
  listes: any[];
  itemSection: any[]=[];
  itemCheckthemes: any;
  itemCheckSouthemes: any;
  itemSousSection:  any[]=[];
  itemSousSection2:  any[]=[];
  itemtheme2: any[]=[];
  itemtheme: any[]=[];
  itemsoutheme: any;
  observ: any[]=[];

  constructor(
    private test: TestService
  ) { }

  ngOnInit(): void {
    this.getAllSection();
  }
  getAllSection() {
    this.test.findAllSection().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemSection.push({
            id: res.body[i].IdSection,
            text: res.body[i].libelleSection
          })
        }
      }
    })
  }
  checkTheme(Section) {
    this.test.Checktheme(Section).subscribe(resp => {
      this.itemCheckthemes = resp.body
     })
   }
  checkSouTheme(sousSection) {
    this.test.CheckSousThemes(sousSection).subscribe(resp => {
      this.itemCheckSouthemes = resp.body
    })
  }
  section(id) {
    this.checkTheme(id.value)
    this.getSousSectionCheckthemeFalse(id.value)
    this.getSousSectionchecktemeTrue(id.value)
    this.getObservation(id.value)
    this.itemCheckSouthemes = []
  }
  SousSection(id) {
    this.checkSouTheme(id.value)
    this.getthemeCheckSouThemeFalse(id.value)
    this.getthemeChecksouThemeTrue(id.value)
    this.itemCheckSouthemes =[]
  }
  theme(id) {
    this.getsouthemebyTheme(id.value)
  }
  getObservation(section){
    this.test.getObservation(section).subscribe(response=>{
      if (response.status === 200) {
        for (let i = 0; i < response.body.length; i++) {
          this.observ.push(
            {
            dateRealisationTest: response.body[i].dateRealisationTest,
            nom: response.body[i].nom,
            observationGlobale: response.body[i].observationGlobale,
            prenom: response.body[i].prenom,
            service:response.body[i].service
          }
          )
        }
      }
    })
  }
  getsouthemebyTheme(theme){
    this.itemsoutheme = []
    this.test.getObservatio3(theme).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemsoutheme.push({
            date: resp.body[i].dateRealisationTest,
            observation: resp.body[i].observation,
            section: resp.body[i].libelleSection,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            sousSection: resp.body[i].libelleSousSection,
            theme: resp.body[i].libelleTheme,
            sousTheme:resp.body[i].libelleSousTheme,
            supportMobile: resp.body[i].supportMobile,
            supportWeb: resp.body[i].supportWeb
          })
        }
      }
    })
  }
  getSousSectionCheckthemeFalse(Section) {
    this.itemSousSection = []
    this.test.getOservation(Section).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemSousSection.push({
            date: resp.body[i].date,
            observation: resp.body[i].observation,
            section: resp.body[i].section,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            sousSection: resp.body[i].sousSection,
            theme: resp.body[i].theme,
            sousTheme:resp.body[i].sousTheme,
            supportMobile: resp.body[i].supportMobile,
            supportWeb: resp.body[i].supportWeb
          })
        }
      }
    })
  }
  getSousSectionchecktemeTrue(Section){
    this.itemSousSection2 = []
    this.test.sousSection2(Section).subscribe(resp =>  {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemSousSection2.push({
            id: resp.body[i].idSousSection,
            text: resp.body[i].libelleSousSection
          })
        }
      }
    })
  } 

  getthemeCheckSouThemeFalse(idSousSection) {
    this.itemtheme2 = []
    this.test.getObservatio2(idSousSection).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemtheme2.push({
            date: resp.body[i].dateRealisationTest,
            observation: resp.body[i].observation,
            section: resp.body[i].libelleSection,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            sousSection: resp.body[i].libelleSousSection,
            theme: resp.body[i].libelleTheme,
            sousTheme:resp.body[i].sousTheme,
            supportMobile: resp.body[i].supportMobile,
            supportWeb: resp.body[i].supportWeb
          })
        }
      }
    })
  }
  getthemeChecksouThemeTrue(idSousSection) {
    this.itemtheme = [];
    this.test.theme(idSousSection).subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtheme.push({
            id: res.body[i].idTheme,
            text: res.body[i].LibelleTheme,
          })
        }
      }
    })
  }



}

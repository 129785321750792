import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from 'app/services/list/list.service';

@Component({
  selector: 'app-contratagri',
  templateUrl: './contratagri.component.html',
  styleUrls: ['./contratagri.component.css']
})
export class ContratagriComponent implements OnInit {
  id: string
  contrats: [] = []
  constructor(private route: ActivatedRoute,
    private list: ListService) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getContratAggricole()
  }

  getContratAggricole(){
    this.list.getContratByMatricule(this.id).subscribe(resp=>{
      this.contrats = resp.body
      
    })

  }

}

import { InputBase } from "./../../dynamic-input/base-input/input-base";
import { DragdropService } from "./../../services/dropdrag/dragdrop.service";
import { Observable } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { Component, Input, OnInit } from "@angular/core";
import { map } from "rxjs/operators";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { InputService } from "../../dynamic-input/providers/input.service";
import { AccountService } from "../../services/account/account.service";
import { ToastrService } from "ngx-toastr";
import { FileValidator } from "ngx-material-file-input";

@Component({
  selector: "app-detail-enquete",
  templateUrl: "./detail-enquete.component.html",
  styleUrls: ["./detail-enquete.component.css"],
})
export class DetailEnqueteComponent implements OnInit {
  tranche: any[] = [];
  /* unite: any */
  isLinear = true;
  inputs: any;
  ref: any;
  foko: any[] = [];
  commune: any;
  district: any;
  region: any;
  province: any;
  codeLocalite: any;
  milieu: any;
  Adressntct: any[] = [];
  Pratique: any[] = [];
  SylviCasPepiniere: any[] = [];
  SylviGeneral: any[] = [];
  SylviProdSylvicole: any[] = [];

  @Input() questions: InputBase<string>[] | null = [];
  dataStep: any[] = [];
  step: { section: string; data: any }[];
  myFormGroup: FormGroup;
  unite: FormGroup;
  AgeFormGroup: FormGroup;
  formTemplate: any;
  secondFormGroup: FormGroup;
  matr: any;
  codecontact: Object;
  matrFamille: any;
  matrEnqueteur: any;
  databoucle: any[] = [];
  boucleAdress: any[] = [];
  base64textString: string;
  thierdFormGroup: FormGroup;
  readonly maxSize = 104857600;
  sectionFormGroup: FormGroup;
  HataoBoucle: any[] = [];
  dataAAA: any[] = [];

  dataV: any = 1;
  databoucleFamille: any[] = [];
  boucleProductions: any[] = [];
  boucleEconomies: any[] = [];
  bouclesfs: any[] = [];
  boucleContrats: any[] = [];
  boucleContraint: any[] = [];
  boucleFournisseurs: any[] = [];
  boucleTransformation: any[] = [];
  boucleLogistiques: any[] = [];
  boucleNutritions: any[] = [];
  boucleASs: any[] = [];
  bouclePosMod: any[] = [];
  boucleOccupations: any[] = [];
  boucleParcell: any[] = [];
  bouclecategories: any[] = [];
  boucleTpesol: any[] = [];
  boucleTerreCultives: any[] = [];
  bouclePerrene: any[] = [];
  boucleDistrib: any[] = [];
  boucleSR: any[] = [];
  boucleBiomasses: any[] = [];
  bouclessylviCasPepiniere: any[] = [];
  bouclessylviGeneral: any[] = [];
  bouclessylviProdSylvicole: any[] = [];
  MatMan: any[] = [];
  Engins: any[] = [];
  Vaches: any[] = [];
  Abrie: any[] = [];
  stockages: any[] = [];
  motoriser: any[] = [];
  Amandement: any[] = [];
  Perimetre: any[] = [];
  bouclerente: any[] = [];
  AgricultureGeneral: any[] = [];
  boucleOrganique: any[] = [];
  boucleChaulage: any[] = [];
  boucleapiculture: any[] = [];
  Sylviprod: any[] = [];
  peche: any[] = [];
  aquaculture: any[] = [];
  codeNationalite: any;
  idNiveauEtude: any;
  sexe: any;
  status: any;
  codeStatusMatrimonial: any;

  rente: any[] = [];
  Perimetres: any[] = [];
  ammendement: any[] = [];
  motorisers: any[] = [];
  stockage: any[] = []; /* tranche: any; */
  abrie: any[] = [];
  vache: any[] = [];
  Enginss: any[] = [];
  matmanu: any[] = [];
  Biomasse: any[] = [];
  SR: any[] = [];
  distribution: any[] = [];
  Apiculture: any[] = [];
  Chaulage: any[] = [];
  organique: any[] = [];
  perrene: any[] = [];
  terreCultives: any[] = [];
  typeSol: any[] = [];
  categorie: any[] = [];
  parcelle: any[] = [];
  occupatio: any[] = [];
  possMode: any[] = [];
  AssistanceSociale: any[] = [];
  nutrition: any[] = [];
  logistique: any[] = [];
  transformation: any[] = [];
  contrainte: any[] = [];
  fournisseur: any[] = [];
  contrat: any[] = [];
  contratse: any[] = [];
  ServiceFinancier: any[] = [];
  economie: any[] = [];
  production: any[] = [];
  Famille: any[] = [];
  fournisseurss: any[] = [];
  terres: any;
  materielEquipement: any;
  Appuis: any[] = [];
  refAppuis: any[] = [];
  RefAppui01: any[] = [];
  bouclegeneral: any[] = [];
  bouclepeche: any[] = [];
  boucleaquaculture: any[] = [];
  boucleselevagepratique: any[] = [];
  bouclesAppui: any[] = [];
  bouclesRefAppui: any[] = [];
  bouclesRefAppui01: any[] = [];
  Labelisation: any[] = [];
  boucleLabelisation: any[] = [];
  UniteAppuiFormGroup: FormGroup;
  plus: any[] = [];
  effectif: any[] = [];
  tranchefemelle: any[] = [];
  trancheAgeElevage: any;
  elevage: any[] = [];
  pluss: any[] = [];
  tranchess: any;
  agriculture: any;
  ProductionUniteFormGroup: FormGroup;
  maDate = new Date();
  vaccin: any[] = [];
  refElevageVaccins: any;
  UniteAngreMineraux: FormGroup;
  UniteAngre: any[] = [];
  UniteContratAgri: FormGroup;
  reFournisseurFormss:any[] = [];
  bouclesreFournisseurForms:any[] = [];
  reFournisseurForms: any[] = [];
  placeDesFemme: any[]=[];
  placeDesFemmess: any[]=[];
  uniteAgriGeneral: FormGroup;
  UniteAmmendemetss: FormGroup;
  uniteCulturerente: FormGroup;
  UniteAquaculture: FormGroup;
  UniteEconomie: FormGroup;
  UniteFourbisseur: FormGroup;
  Unitelogistique: FormGroup;
  UniteAbrisAnimauxx: FormGroup;
  uniteProduitTransssss: FormGroup;
  /* uniteProductionss: FormGroup;
  UniteProduction: FormGroup; */


  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private service: DragdropService,
    private inputSvc: InputService,
    private accountService: AccountService,
    private router: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem("user"));
    this.matrEnqueteur = data.matricule;
    this.accountService.codecontact().subscribe((resp) => {
      if (resp.status === 200) {
        this.codecontact = resp.body;
      }
    });

    this.uniteAgriGeneral = this._formBuilder.group({
      uniteEngraisAzote: [''],
      uniteEngraisUtilise: ['']

    })

    this.UniteEconomie = this._formBuilder.group({
      uniteAutoConsomation: [''],
      uniteExporte: [''],
      uniteVenteEconomie: ['']

    })

    this.UniteFourbisseur = this._formBuilder.group({
      uniteParTypeFourni: [''],
      uniteAvantDernierRecolte: [''],
      uniteExploitationStockFourni: ['']

    })

    this.uniteCulturerente = this._formBuilder.group({
      uniteAgePiedProductif: [''],
      uniteVendueNature: [''],
      uniteVenduePrepare: ['']

    })

    this.UniteAppuiFormGroup = this._formBuilder.group({
      UniteQuantite: [""],
    });

    this.Unitelogistique = this._formBuilder.group({
      unitePertePostRecolte: [""],
    });

    this.UniteAquaculture = this._formBuilder.group({
      uniteProd: [""],
    });

    this.UniteAmmendemetss = this._formBuilder.group({
      uniteAmendement: [""],
    });

    this.UniteAbrisAnimauxx = this._formBuilder.group({
      uniteAbrisAnimaux: [""],
    });
    this.uniteProduitTransssss = this._formBuilder.group({
      uniteProduitTrans: [""],
    });









    /* this.uniteProductionss = this._formBuilder.group({
      uniteProduction: [""],
    }); */









    


    this.UniteAngreMineraux = this._formBuilder.group({
      UniteQuantiteAngre: [""],
    });
    this.UniteContratAgri = this._formBuilder.group({
      UniteCAConcerne: [""],
      UniteCAVendue: [""],
    });
    /* this.ProductionUniteFormGroup = this._formBuilder.group({
      UniteQuantiteprop:['']
    }) */
    this.secondFormGroup = this._formBuilder.group({
      fokontany: [""],
      commune: [""],
      district: [""],
      region: [""],
      province: [""],
      village: [""],
      codeLocalite: [""],
      milieu: [""],
    });
    this.thierdFormGroup = this._formBuilder.group({
      photos: ["", FileValidator.maxContentSize(this.maxSize)],
    });
    this.AgeFormGroup = this._formBuilder.group({
      trancheAgeMale: [""],
      trancheAgeFemelle: [""],
    });

    const id = this.route.snapshot.paramMap.get("id");
    this.ref = id;
    this.service.detailEnquete(id).subscribe((res) => {
      if (res.status == 200) {
        this.inputs = res.body;
      }

      var grouped = res.body.reduce(function (r, a) {
        if (a.libelleTheme === null && a.idSousTheme === null) {
          r[a.section] = r[a.section] || [];
          r[a.section].push(a);

          return r;
        } else if (a.idSousTheme === null) {
          r[a.libelleSousSection] = r[a.libelleSousSection] || [];
          r[a.libelleSousSection].push(a);
          return r;
        } else {
          r[a.libelleTheme] = r[a.libelleTheme] || [];
          r[a.libelleTheme].push(a);
          return r;
        }
      }, {});

      var groups = Object.keys(grouped).map(function (key) {
        let data = grouped[key];
        return {
          section: key,
          idSection: data[0].idSection,
          idSouSection: data[0].idSousSection,
          idTheme: data[0].idTheme,
          idQuestionnaire: data[0].idQuestionnaire,
          data: grouped[key],
        };
      });

      groups.sort(function (a, b) {
        const nameA = a.idSection.toUpperCase(); // ignore upper and lowercase
        const nameB = b.idSection.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      this.HataoBoucle.push(groups);

      this.step = groups;

      const key = [];
      for (let i = 0; i < res.body.length; i++) {
        key.push(res.body[i].key);
      }

      let group = {};
      key.forEach((input_template) => {
        group[input_template] = new FormControl("", Validators.required);
      });
      this.myFormGroup = new FormGroup(group);
    });
  }

  ajout() {
    this.plus.push(this.myFormGroup.value);
    this.effectif.push(this.AgeFormGroup.value);
    this.AgeFormGroup.controls["trancheAgeMale"].reset();
    this.myFormGroup.controls["effectifParTrancheAgeMale"].reset();
    this.trancheAgeElevage = "";
    for (let i = 0; i < this.plus.length; i++) {
      this.trancheAgeElevage = {
        /* "effectifParTrancheAgeFemelle": this.pluss[i].effectifParTrancheAgeFemelle, */
        effectifParTrancheAgeMale: this.plus[i].effectifParTrancheAgeMale,
        sexeElevage: 1,
        tranche_age: this.effectif[i].trancheAgeMale,
      };
    }
    this.tranche.push(this.trancheAgeElevage);

    


    this.refElevageVaccins = "";
    this.myFormGroup.controls["dateVaccination"].reset();
    this.myFormGroup.controls["typeVaccins"].reset();
    for (let i = 0; i < this.plus.length; i++) {
      this.refElevageVaccins = {
        dateVaccination: this.plus[i].dateVaccination,
        typeVaccins: this.plus[i].typeVaccins
      };
    }
    this.vaccin.push(this.refElevageVaccins);

    
  }

  ajouter() {
    this.pluss.push(this.myFormGroup.value);
    this.effectif.push(this.AgeFormGroup.value);
    this.AgeFormGroup.controls["trancheAgeFemelle"].reset();
    this.myFormGroup.controls["effectifParTrancheAgeFemelle"].reset();
    this.trancheAgeElevage = "";
    for (let i = 0; i < this.pluss.length; i++) {
      this.trancheAgeElevage = {
        effectifParTrancheAgeFemelle:
          this.pluss[i].effectifParTrancheAgeFemelle,
        /* "effectifParTrancheAgeMale": this.plus[i].effectifParTrancheAgeMale, */
        sexeElevage: 2,
        tranche_age: this.effectif[i].trancheAgeFemelle,
      };
    }
    this.tranche.push(this.trancheAgeElevage);

    
  }


  

  fkt(index) {
    if (index.length > 3) {
      this.accountService.prendListeFokotany(index).subscribe((resp) => {
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.foko.push({
              id: resp.body[i].codeFokontany,
              text: resp.body[i].fokontany + "(" + resp.body[i].commune + ")",
              codeCommune: resp.body[i].codeCommune,
              commune: resp.body[i].commune,
              codeDistrict: resp.body[i].codeDistrict,
              district: resp.body[i].district,
              codeRegion: resp.body[i].codeRegion,
              region: resp.body[i].region,
              codeProvince: resp.body[i].codeProvince,
              province: resp.body[i].province,
              codeLocalite: resp.body[i].codeLocalite,
              milieu: resp.body[i].libelleMilieu,
            });
          }
        }
      });
    }
  }
  onChange(event) {
    this.secondFormGroup.get("fokontany").setValue(event[0].data.id);
    this.secondFormGroup.get("commune").setValue(event[0].data.codeCommune);
    this.commune = event[0].data.commune;
    this.secondFormGroup.get("district").setValue(event[0].data.codeDistrict);
    this.district = event[0].data.district;
    this.secondFormGroup.get("region").setValue(event[0].data.codeRegion);
    this.region = event[0].data.region;
    this.secondFormGroup.get("province").setValue(event[0].data.codeProvince);
    this.province = event[0].data.province;
    this.secondFormGroup
      .get("codeLocalite")
      .setValue(event[0].data.codeLocalite);

    this.secondFormGroup.get("milieu").setValue(event[0].data.milieu);
    this.milieu = event[0].data.milieu;

    this.codeLocalite = event[0].data.codeLocalite;
    //ato no mambotra ny matricule
    this.accountService
      .getmatricule(this.codeLocalite, 2)
      .subscribe((resp: any) => {
        if (resp) {
          this.matr = resp;
        }
      });
    this.accountService
      .getmatriculeFamille(this.codeLocalite)
      .subscribe((resp: any) => {
        if (resp) {
          this.matrFamille = resp;
        }
      });
  }

  public onFileChanged(event) {
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  boucleAdres(i) {
    this.databoucle.push(this.myFormGroup.value);
    this.myFormGroup.controls["adrsActuelle"].reset();
    this.myFormGroup.controls["pseudo"].reset();
    this.myFormGroup.controls["telephone"].reset();
    this.myFormGroup.controls["mobileBanking"].reset();
    this.myFormGroup.controls["email"].reset();
    this.myFormGroup.controls["hameauVillage"].reset();
  }
  boucleFamille(i) {
    this.databoucleFamille.push(this.myFormGroup.value);
    this.myFormGroup.controls["nomFamille"].reset();
    this.myFormGroup.controls["prenomFamille"].reset();
    this.myFormGroup.controls["sexeFamille"].reset();
    this.myFormGroup.controls["nationaliteFamille"].reset();
    this.myFormGroup.controls["lieuNaissFamille"].reset();
    this.myFormGroup.controls["dateNaissFamille"].reset();
    this.myFormGroup.controls["cinFamille"].reset();
    this.myFormGroup.controls["dateCinFamille"].reset();
    this.myFormGroup.controls["lieuCinFamille"].reset();
    this.myFormGroup.controls["parentLien"].reset();
    this.myFormGroup.controls["niveauInstructionFamille"].reset();
  }
  boucleProduction() {
    this.boucleProductions.push(this.myFormGroup.value);
   // this.boucleProductions.push(this.UniteProduction.value)
    this.myFormGroup.controls["nomCultureProd"].reset();
    this.myFormGroup.controls["productionKgProd"].reset();
    this.myFormGroup.controls["objProductionProd"].reset();
    this.myFormGroup.controls["dispoProductionProd"].reset();
    this.myFormGroup.controls["pourcentageAuto"].reset();
    //this.UniteProduction.controls["uniteProductionss"].reset();
  }
  boucleEconomie() {
    this.boucleEconomies.push(this.myFormGroup.value);
    this.boucleEconomies.push(this.UniteEconomie.value)
    this.myFormGroup.controls["speculatios"].reset();
    this.myFormGroup.controls["destinationProd"].reset();
    this.myFormGroup.controls["qteAutoConsommation"].reset();
    this.myFormGroup.controls["coutRevient"].reset();
    this.myFormGroup.controls["venteEconomie"].reset();
    this.myFormGroup.controls["prixProd"].reset();
    this.myFormGroup.controls["fixationPrix"].reset();
    this.myFormGroup.controls["infoSource"].reset();
    this.myFormGroup.controls["margeBenefice"].reset();
    this.myFormGroup.controls["commercialisation"].reset();
    this.myFormGroup.controls["qteExporte"].reset();
    this.UniteEconomie.controls["uniteAutoConsomation"].reset();
    this.UniteEconomie.controls["uniteExporte"].reset();
    this.UniteEconomie.controls["uniteVenteEconomie"].reset();
  }
  boucleSf() {
    this.bouclesfs.push(this.myFormGroup.value);
    this.myFormGroup.controls["disposerviceFinance"].reset();
    this.myFormGroup.controls["epargneCredit"].reset();
    this.myFormGroup.controls["nomImfBanque"].reset();
    this.myFormGroup.controls["demandeCredit"].reset();
    this.myFormGroup.controls["raisonPrincipale"].reset();
    this.myFormGroup.controls["creditObtenu"].reset();
    this.myFormGroup.controls["effectifCreditObtenu"].reset();
    this.myFormGroup.controls["numCredit"].reset();
    this.myFormGroup.controls["creditutiliser"].reset();
    this.myFormGroup.controls["creditsource"].reset();
    this.myFormGroup.controls["secteurActiviteCredit"].reset();
    this.myFormGroup.controls["genrePersRecouvrir"].reset();
    this.myFormGroup.controls["demandeServiceFinance"].reset();
    this.myFormGroup.controls["creditutilisation"].reset();
    this.myFormGroup.controls["assuranceAgricole"].reset();
    this.myFormGroup.controls["assureur"].reset();
    this.myFormGroup.controls["interesseProduit"].reset();
    this.myFormGroup.controls["prixProduit"].reset();
    this.myFormGroup.controls["raison"].reset();
  }
  boucleContrat() {
    this.boucleContrats.push(this.myFormGroup.value);
    this.boucleContrats.push(this.UniteContratAgri.value)
    this.myFormGroup.controls["contract"].reset();
    this.myFormGroup.controls["typeContrat"].reset();
    this.myFormGroup.controls["contratSpecifie"].reset();
    this.myFormGroup.controls["qteProduire"].reset();
    this.myFormGroup.controls["prixProduitAgri"].reset();
    this.myFormGroup.controls["periodiciteProduction"].reset();
    this.myFormGroup.controls["superficieCultive"].reset();
    this.myFormGroup.controls["serviceFournie"].reset();
    this.myFormGroup.controls["serviceProposer"].reset();
    this.myFormGroup.controls["produitConcerne"].reset();
    this.myFormGroup.controls["qteConcerne"].reset();
    this.myFormGroup.controls["qteVendue"].reset();
    this.myFormGroup.controls["respectContrat"].reset();
    this.myFormGroup.controls["finContrat"].reset();
    this.myFormGroup.controls["debutContrat"].reset();
    this.UniteContratAgri.controls["UniteCAConcerne"].reset();
    this.UniteContratAgri.controls["UniteCAVendue"].reset();
  }
  boucleContrainte() {
    this.boucleContraint.push(this.myFormGroup.value);
    this.myFormGroup.controls["agricoleContrainte"].reset();
    this.myFormGroup.controls["elevageContrainte"].reset();
    this.myFormGroup.controls["aquacultureContrainte"].reset();
    this.myFormGroup.controls["forestiereContrainte"].reset();
  }

  boucleFournisseur() {
    this.boucleFournisseurs.push(this.myFormGroup.value);
    this.boucleFournisseurs.push(this.UniteFourbisseur.value);
    this.myFormGroup.controls["produiseMemeIntrant"].reset();
    this.myFormGroup.controls["qteAvantDernierRecolte"].reset();
    this.myFormGroup.controls["qteExploitationStockFourni"].reset();
    this.myFormGroup.controls["typeSpeculationFouni"].reset();


    this.myFormGroup.controls["alimentationAnimal"].reset();
    this.myFormGroup.controls["appliqueFormationFouni"].reset();
    this.myFormGroup.controls["fournisseurIntrant"].reset();
    this.myFormGroup.controls["modeUtilisationFourni"].reset();
    this.myFormGroup.controls["niveauEfficaciteFouni"].reset();
    this.myFormGroup.controls["nomEngrais"].reset();
    this.myFormGroup.controls["nomProduitPhyto"].reset();
    this.myFormGroup.controls["prixAchatFourni"].reset();
    this.myFormGroup.controls["produitVeto"].reset();
    this.myFormGroup.controls["qteParTypeFourni"].reset();
    this.myFormGroup.controls["typeIntrantsFourni"].reset();
    this.myFormGroup.controls["typeSemence"].reset();
    this.UniteFourbisseur.controls["uniteAvantDernierRecolte"].reset();
    this.UniteFourbisseur.controls["uniteExploitationStockFourni"].reset();
    this.UniteFourbisseur.controls["uniteParTypeFourni"].reset();
  }

  bouclesreFournisseurFormss() {
    this.bouclesreFournisseurForms.push(this.myFormGroup.value);
    this.bouclesreFournisseurForms.push(this.UniteFourbisseur.value);
    this.myFormGroup.controls["alimentationAnimal"].reset();
    this.myFormGroup.controls["appliqueFormationFouni"].reset();
    this.myFormGroup.controls["fournisseurIntrant"].reset();
    this.myFormGroup.controls["modeUtilisationFourni"].reset();
    this.myFormGroup.controls["niveauEfficaciteFouni"].reset();
    this.myFormGroup.controls["nomEngrais"].reset();
    this.myFormGroup.controls["nomProduitPhyto"].reset();
    this.myFormGroup.controls["prixAchatFourni"].reset();
    this.myFormGroup.controls["produitVeto"].reset();
    this.myFormGroup.controls["qteParTypeFourni"].reset();
    this.myFormGroup.controls["typeIntrantsFourni"].reset();
    this.UniteFourbisseur.controls["uniteParTypeFourni"].reset();
  }
  boucleTransfo() {
    this.boucleTransformation.push(this.myFormGroup.value);
    this.boucleTransformation.push(this.uniteProduitTransssss.value);
    this.myFormGroup.controls["produitsTransformation"].reset();
    this.myFormGroup.controls["qteProduitTransformation"].reset();
    this.myFormGroup.controls["uniteTransforme"].reset();
    this.myFormGroup.controls["formelle"].reset();
    this.myFormGroup.controls["uniteTransformation"].reset();
    this.myFormGroup.controls["formationTransformation"].reset();
    this.myFormGroup.controls["sousProduit"].reset();
    this.myFormGroup.controls["sousProduitsAll"].reset();
    this.myFormGroup.controls["ecoulement"].reset();
    this.uniteProduitTransssss.controls["uniteProduitTrans"].reset();
    
  }
  boucleLogistique() {
    this.boucleLogistiques.push(this.myFormGroup.value);
    this.boucleLogistiques.push(this.Unitelogistique.value)
    this.myFormGroup.controls["modeTransport"].reset();
    this.myFormGroup.controls["moyensTransport"].reset();
    this.myFormGroup.controls["moyensTransportUtilise"].reset();
    this.myFormGroup.controls["coutTransport"].reset();
    this.myFormGroup.controls["pertePostRecolte"].reset();
    this.myFormGroup.controls["acheminement"].reset();
    this.Unitelogistique.controls["unitePertePostRecolte"].reset();
  }
  boucleNutrition() {
    this.boucleNutritions.push(this.myFormGroup.value);
    this.myFormGroup.controls["nutriAlimentation"].reset();
    this.myFormGroup.controls["alimentationBase"].reset();
    this.myFormGroup.controls["alimentAccess"].reset();
    this.myFormGroup.controls["menageProbleme"].reset();
    this.myFormGroup.controls["debutPeriodeSoudure"].reset();
    this.myFormGroup.controls["finPeriodeSoudure"].reset();
    this.myFormGroup.controls["repasParMois"].reset();
    this.myFormGroup.controls["repasParPeriode"].reset();
    this.myFormGroup.controls["beneficiaireAide"].reset();
    this.myFormGroup.controls["alimentaireAide"].reset();
  }
  boucleAssistanceSociale() {
    this.boucleASs.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeAssistanceSociale"].reset();
    this.myFormGroup.controls["nomAgence"].reset();
    this.myFormGroup.controls["futureAssistanceSosiale"].reset();
  }
  bouclePosMode() {
    this.bouclePosMod.push(this.myFormGroup.value);
    this.myFormGroup.controls["nbreParcelles"].reset();
    this.myFormGroup.controls["cultiveMeme"].reset();
    this.myFormGroup.controls["cultiveAutre"].reset();
    this.myFormGroup.controls["nbreParcellesNon"].reset();
    this.myFormGroup.controls["totalParcelles"].reset();
    this.myFormGroup.controls["nbreParcellesEtre"].reset();
    this.myFormGroup.controls["principaleRaison"].reset();
    this.myFormGroup.controls["terrainsNonExploitees"].reset();
  }
  boucleOccupation() {
    this.boucleOccupations.push(this.myFormGroup.value);
    this.myFormGroup.controls["qteProductionFraiche"].reset();
    this.myFormGroup.controls["residusBrulePourcentage"].reset();
    this.myFormGroup.controls["pourcentageResidusPaturage"].reset();
    this.myFormGroup.controls["residusConservePourcentage"].reset();
    this.myFormGroup.controls["pourcentageTotaleRecolte"].reset();
  }
  boucleParcelle() {
    this.boucleParcell.push(this.myFormGroup.value);
    this.myFormGroup.controls["numParcelle"].reset();
    this.myFormGroup.controls["durreeDeplacement"].reset();
    this.myFormGroup.controls["emplacement"].reset();
    this.myFormGroup.controls["modeFaireValoir"].reset();
    this.myFormGroup.controls["statutFoncierParcelle"].reset();
    this.myFormGroup.controls["superficieParcelle"].reset();
    this.myFormGroup.controls["personneResponsable"].reset();
    this.myFormGroup.controls["typeCultures"].reset();
    this.myFormGroup.controls["nomCultureParcelles"].reset();
    this.myFormGroup.controls["gpsParcelles"].reset();
  }
  bouclecategorie() {
    this.bouclecategories.push(this.myFormGroup.value);
    this.myFormGroup.controls["superficieRestant"].reset();
    this.myFormGroup.controls["superficieTransAutres"].reset();
    this.myFormGroup.controls["superficieForestiere"].reset();
    this.myFormGroup.controls["superficiePrairies"].reset();
    this.myFormGroup.controls["superficieZoneHumide"].reset();
    this.myFormGroup.controls["superficieZonehabitation"].reset();
    this.myFormGroup.controls["superficieAutreTerre"].reset();
    this.myFormGroup.controls["superficieTransfPrairies"].reset();
    this.myFormGroup.controls["nomCultureParcelles"].reset();
    this.myFormGroup.controls["superficieTransZoneHumide"].reset();
  }
  boucleTypeSol() {
    this.boucleTpesol.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeSol"].reset();
    this.myFormGroup.controls["superficieTypeSol"].reset();
  }
  boucleTerreCultive() {
    this.boucleTerreCultives.push(this.myFormGroup.value);
    this.myFormGroup.controls["superficieTerreCultiveAns"].reset();
    this.myFormGroup.controls["superficieTerreCultive"].reset();
    this.myFormGroup.controls["systemeGestion"].reset();
  }
  boucleperrene() {
    this.bouclePerrene.push(this.myFormGroup.value);
    this.myFormGroup.controls["gestionTerres"].reset();
    this.myFormGroup.controls["superficiePerennes"].reset();
  }
  boucleDistribution() {
    this.boucleDistrib.push(this.myFormGroup.value);
    this.myFormGroup.controls["pourcentagePlantationMature"].reset();
    this.myFormGroup.controls["pourcentagePlantationImmature"].reset();
    this.myFormGroup.controls["agePlantationImmature"].reset();
    this.myFormGroup.controls["gestionClasseMature"].reset();
    this.myFormGroup.controls["agePlantationMature"].reset();
    this.myFormGroup.controls["pourcentagePlantation"].reset();
  }
  boucleSRizicol() {
    this.boucleSR.push(this.myFormGroup.value);
    this.myFormGroup.controls["regimeHydriqueAvant"].reset();
    this.myFormGroup.controls["amendementOrganique"].reset();
    this.myFormGroup.controls["durreCulture"].reset();
    this.myFormGroup.controls["superficie"].reset();
    this.myFormGroup.controls["regimeHydriquePendant"].reset();
  }
  boucleBiomasse() {
    this.boucleBiomasses.push(this.myFormGroup.value);
    this.myFormGroup.controls["superficieFeuxFriche"].reset();
    this.myFormGroup.controls["superficieIncendie"].reset();
    this.myFormGroup.controls["pourcentageIncendie"].reset();
    this.myFormGroup.controls["autrePerturbationSuperficie"].reset();
  }
  boucleMaterielMan() {
    this.MatMan.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeMateriels"].reset();
    this.myFormGroup.controls["effectifTractionAnimal"].reset();
    this.myFormGroup.controls["anneeAcquisitionTractionAnimal"].reset();
    this.myFormGroup.controls["acquisitionTractionAnimal"].reset();
    this.myFormGroup.controls["fournisseurMatTractionAnimal"].reset();
    this.myFormGroup.controls["prixAchatTractionAnimal"].reset();
  }
  boucleEngins() {
    this.Engins.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeEnginsEnginsPeche"].reset();
    this.myFormGroup.controls["effectifEnginsPeche"].reset();
    this.myFormGroup.controls["anneeAcquisitionEnginsPeche"].reset();
    this.myFormGroup.controls["acquisitionEnginsPeche"].reset();
    this.myFormGroup.controls["fourniMaterielEnginsPeche"].reset();
    this.myFormGroup.controls["prixAchatEnginsPeche"].reset();
  }
  boucleVache() {
    this.Vaches.push(this.myFormGroup.value);
    this.myFormGroup.controls["nomMatVacheLait"].reset();
    this.myFormGroup.controls["effectifVacheLait"].reset();
    this.myFormGroup.controls["anneeAcquisitionVacheLait"].reset();
    this.myFormGroup.controls["acquisitionVacheLait"].reset();
    this.myFormGroup.controls["fournisseurMatVacheLait"].reset();
    this.myFormGroup.controls["prixAchatVacheLait"].reset();
  }
  boucleAbrie() {
    this.Abrie.push(this.myFormGroup.value);
    this.Abrie.push(this.UniteAbrisAnimauxx.value)
    this.myFormGroup.controls["nomAbrisAnimInstallFixe"].reset();
    this.myFormGroup.controls["possessionAbrisAnimaux"].reset();
    this.myFormGroup.controls["localisationAbrisAnimaux"].reset();
    this.myFormGroup.controls["capaciteAbrisAnimaux"].reset();
    this.myFormGroup.controls["uniteAbrisAnimaux"].reset();
    this.UniteAbrisAnimauxx.controls["uniteAbrisAnimaux"].reset();
  }
  boucleStockage() {
    this.stockages.push(this.myFormGroup.value);
    this.myFormGroup.controls["modeSechageStockageStockProd"].reset();
    this.myFormGroup.controls["possessionStockProd"].reset();
    this.myFormGroup.controls["localisationStockProd"].reset();
    this.myFormGroup.controls["capaciteStockProd"].reset();
    this.myFormGroup.controls["uniteStockProd"].reset();
    this.myFormGroup.controls["modeStockageStockProd"].reset();
  }
  boucleMotoriser() {
    this.motoriser.push(this.myFormGroup.value);
    this.myFormGroup.controls["materielMotoriseMotorise"].reset();
    this.myFormGroup.controls["nbreTotaleMotorise"].reset();
    this.myFormGroup.controls["anneeAcquisitionMotorise"].reset();
    this.myFormGroup.controls["proprieteExploitationMotorise"].reset();
    this.myFormGroup.controls["coproprieteMotorise"].reset();
    this.myFormGroup.controls["organisationPaysanneMotorise"].reset();
  }
  boucleAmandement() {
    this.Amandement.push(this.myFormGroup.value);
    this.Amandement.push(this.UniteAngreMineraux.value)
    this.myFormGroup.controls["typeEngrais"].reset();
    this.myFormGroup.controls["pourcentageUree"].reset();
    this.myFormGroup.controls["pourcentageAzote"].reset();
    this.myFormGroup.controls["qteUtilise"].reset();
    this.UniteAngreMineraux.controls["UniteQuantiteAngre"].reset()
  }
  boucleperimetre() {
    this.Perimetre.push(this.myFormGroup.value);
    this.myFormGroup.controls["prodPerimetreIrrigue"].reset();
    this.myFormGroup.controls["nomPerimetreIrrigue"].reset();
    this.myFormGroup.controls["districtAppartient"].reset();
    this.myFormGroup.controls["membreAue"].reset();
    this.myFormGroup.controls["nomAue"].reset();
    this.myFormGroup.controls["cotisationAue"].reset();
    this.myFormGroup.controls["montantCotisation"].reset();
    this.myFormGroup.controls["frequencePaiement"].reset();
    this.myFormGroup.controls["etatPerimetreIrrigue"].reset();
  }
  bouclerentes() {
    this.bouclerente.push(this.myFormGroup.value);
    this.bouclerente.push(this.uniteCulturerente.value);
    this.myFormGroup.controls["cultureRenteProduisez"].reset();
    this.myFormGroup.controls["codePoinconVanille"].reset();
    this.myFormGroup.controls["typeExploitation"].reset();
    this.myFormGroup.controls["nbreExploitation"].reset();
    this.myFormGroup.controls["lieuExploitation"].reset();
    this.myFormGroup.controls["surfaceApproximative"].reset();
    this.myFormGroup.controls["gpsRente"].reset();
    this.myFormGroup.controls["surfaceApproximativeTotal"].reset();
    this.myFormGroup.controls["nbrePiedsTotal"].reset();
    this.myFormGroup.controls["ecartementPiedCulture"].reset();
    this.myFormGroup.controls["agePiedsProductif"].reset();
    this.myFormGroup.controls["anneeProductif"].reset();
    this.myFormGroup.controls["totalProduction"].reset();
    this.myFormGroup.controls["debutPeriodeCultureRente"].reset();
    this.myFormGroup.controls["finPeriodeCultureRente"].reset();
    this.myFormGroup.controls["debutPeriodePlantation"].reset();
    this.myFormGroup.controls["finPeriodePlantation"].reset();
    this.myFormGroup.controls["debutPeriodeRecolte"].reset();
    this.myFormGroup.controls["finPeriodeRecolte"].reset();
    this.myFormGroup.controls["UtilisationProduit"].reset();
    this.myFormGroup.controls["detaillantOuNon"].reset();
    this.myFormGroup.controls["nomDetaillant"].reset();
    this.myFormGroup.controls["variete"].reset();
    this.myFormGroup.controls["qteVendueNature"].reset();
    this.myFormGroup.controls["qteVenduePrepare"].reset();
    this.myFormGroup.controls["prixVenteNature"].reset();
    this.myFormGroup.controls["prixVentePrepare"].reset();
    this.myFormGroup.controls["collecteurOuNon"].reset();
    this.myFormGroup.controls["nomCollecteurAcheteur"].reset();
    this.myFormGroup.controls["prixAchat"].reset();
    this.myFormGroup.controls["dateAchat"].reset();
    this.myFormGroup.controls["marcher"].reset();
    this.myFormGroup.controls["agreeOuNon"].reset();
    this.myFormGroup.controls["referenceContrat"].reset();
    this.myFormGroup.controls["refAgreement"].reset();
    this.myFormGroup.controls["exportateurFinale"].reset();
    this.myFormGroup.controls["prixVenteEuros"].reset();
    this.myFormGroup.controls["observation"].reset();
    this.uniteCulturerente.controls["uniteAgePiedProductif"].reset();
    this.uniteCulturerente.controls["uniteVendueNature"].reset();
    this.uniteCulturerente.controls["uniteVenduePrepare"].reset()
  }
  boucleGenerale() {
    this.bouclegeneral.push(this.myFormGroup.value);
    this.bouclegeneral.push(this.uniteAgriGeneral.value);
    this.myFormGroup.controls["nomCulture"].reset();
    this.myFormGroup.controls["sourceSemences"].reset();
    this.myFormGroup.controls["nom_variete"].reset();
    this.myFormGroup.controls["anneeAcquisition"].reset();
    this.myFormGroup.controls["typeSemences"].reset();
    this.myFormGroup.controls["periodeCulture"].reset();
    this.myFormGroup.controls["modeLbour"].reset();
    this.myFormGroup.controls["modePlantation"].reset();
    this.myFormGroup.controls["modeRepiquage"].reset();
    this.myFormGroup.controls["agePlanteRepique"].reset();
    this.myFormGroup.controls["debutPlantation"].reset();
    this.myFormGroup.controls["finPlantation"].reset();
    this.myFormGroup.controls["debutPeriodeRecolteAgri"].reset();
    this.myFormGroup.controls["finPeriodeRecolteAgri"].reset();
    this.myFormGroup.controls["dureeDernierRecolt"].reset();
    this.myFormGroup.controls["autre_culture"].reset();
    this.myFormGroup.controls["agePlante"].reset();
    this.myFormGroup.controls["nbrePieds"].reset();
    this.myFormGroup.controls["productionSemence"].reset();
    this.myFormGroup.controls["tempsTravail"].reset();
    this.myFormGroup.controls["sourceEauAgri"].reset();
    this.myFormGroup.controls["systemeIrrigation"].reset();
    this.myFormGroup.controls["systemeDrainage"].reset();
    this.myFormGroup.controls["modeFertilisation"].reset();
    this.myFormGroup.controls["qtEngraisAzote"].reset();
    this.myFormGroup.controls["qteEngraisUtilise"].reset();
    this.myFormGroup.controls["modeSarclageDesherbage"].reset();
    this.myFormGroup.controls["culture_succession"].reset();
    this.myFormGroup.controls["nomCultureDerobee"].reset();
    this.myFormGroup.controls["cultureAssociation"].reset();
    this.myFormGroup.controls["nomCultureAssociation"].reset();
    this.myFormGroup.controls["productionSaison"].reset();
    this.myFormGroup.controls["traitementsMaladies"].reset();
    this.myFormGroup.controls["traitementsContreInsectes"].reset();
    /* this.myFormGroup.controls["typeEngrais"].reset(); */
    this.myFormGroup.controls["traitementsContreRavageurs"].reset();
    this.uniteAgriGeneral.controls["uniteEngraisAzote"].reset();
    this.uniteAgriGeneral.controls["uniteEngraisUtilise"].reset()
  }
  boucleOrganiques() {
    this.boucleOrganique.push(this.myFormGroup.value);
    this.boucleOrganique.push(this.UniteAmmendemetss.value)
    this.myFormGroup.controls["typeAmendement"].reset();
    this.myFormGroup.controls["qteAmendement"].reset();
    this.UniteAmmendemetss.controls["uniteAmendement"].reset();

  }
  boucleChaulages() {
    this.boucleChaulage.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeChaux"].reset();
    this.myFormGroup.controls["totalChaux"].reset();
  }
  boucleApicultures() {
    this.boucleapiculture.push(this.myFormGroup.value);
    this.myFormGroup.controls["nbreRuches"].reset();
    this.myFormGroup.controls["techniqueUtilise"].reset();
    this.myFormGroup.controls["conduitElevage"].reset();
    this.myFormGroup.controls["nbreLitreProdAnnuel"].reset();
    this.myFormGroup.controls["pratiqueTraitement"].reset();
    this.myFormGroup.controls["typePlantes"].reset();
  }

  boucleselevagepratiques() {
    this.boucleselevagepratique.push(this.myFormGroup.value);
    this.myFormGroup.controls["elevagePratique"].reset();
    this.myFormGroup.controls["effectifParTrancheAgeMale"].reset();
    this.myFormGroup.controls["effMaleRaceLocale"].reset();
    this.myFormGroup.controls["effMaleRaceAmeliore"].reset();
    this.myFormGroup.controls["effBoeufCastres"].reset();
    this.myFormGroup.controls["effBoeufNonCastres"].reset();
    this.myFormGroup.controls["carnetBovins"].reset();
    this.myFormGroup.controls["fibBovins"].reset();
    this.myFormGroup.controls["puceElectroique"].reset();
    this.myFormGroup.controls["boucleElectronique"].reset();
    this.myFormGroup.controls["objectifPrincipaleMale"].reset();
    this.myFormGroup.controls["effectifParTrancheAgeFemelle"].reset();
    this.myFormGroup.controls["effFemelleRaceLocale"].reset();
    this.myFormGroup.controls["effFemelleRaceAmeliore"].reset();
    this.myFormGroup.controls["objectifPrincipaleFemelle"].reset();
    this.myFormGroup.controls["modeElevage"].reset();
    this.myFormGroup.controls["effInitialChep"].reset();
    this.myFormGroup.controls["nbreAnimauxAchete"].reset();
    this.myFormGroup.controls["nbreNaissTroupeau"].reset();
    this.myFormGroup.controls["nbreCheptelAcquis"].reset();
    this.myFormGroup.controls["gardeProvisoire"].reset();
    this.myFormGroup.controls["nbreAutoconsomation"].reset();
    this.myFormGroup.controls["nbreVendu"].reset();
    this.myFormGroup.controls["nbreDeces"].reset();
    this.myFormGroup.controls["causePrincipal"].reset();
    this.myFormGroup.controls["nbrePret"].reset();
    this.myFormGroup.controls["nbreCadeauDon"].reset();
    this.myFormGroup.controls["nbreAutreSortie"].reset();
    this.myFormGroup.controls["typesAliment"].reset();
    this.myFormGroup.controls["cheptelVaccine"].reset();
    this.myFormGroup.controls["typeVaccins"].reset();
    this.myFormGroup.controls["dateVaccination"].reset();
    this.myFormGroup.controls["maladieFrequement"].reset();
    this.myFormGroup.controls["traitementFrequement"].reset();
    this.myFormGroup.controls["encadrementSanitaire"].reset();
    this.myFormGroup.controls["saisonPluie"].reset();
    this.myFormGroup.controls["saisonSeche"].reset();
    this.myFormGroup.controls["gpsElevage"].reset();
    this.myFormGroup.controls["tempsTravailElevage"].reset();
  }
  bouclessylviGenerals() {
    this.bouclessylviGeneral.push(this.myFormGroup.value);
    this.myFormGroup.controls["especeSylv"].reset();
    this.myFormGroup.controls["nbrPiedsSylv"].reset();
    this.myFormGroup.controls["agePeuplementsSylv"].reset();
    this.myFormGroup.controls["nbreMoins5"].reset();
    this.myFormGroup.controls["nbre5a10"].reset();
    this.myFormGroup.controls["nbrePlus10"].reset();
    this.myFormGroup.controls["vocationPrincipaleSylv"].reset();
    this.myFormGroup.controls["productiviteSylv"].reset();
    this.myFormGroup.controls["produitObtenusSylv"].reset();
  }
  bouclessylviProdSylvicoles() {
    this.bouclessylviProdSylvicole.push(this.myFormGroup.value);
    this.myFormGroup.controls["typeProduitSylvicole"].reset();
    this.myFormGroup.controls["qteProdAnnuelSylvicole"].reset();
    this.myFormGroup.controls["qteProdAnnuelArboriculture"].reset();
  }
  bouclessylviCasPepinieres() {
    this.bouclessylviCasPepiniere.push(this.myFormGroup.value);
    this.myFormGroup.controls["especePepiniere"].reset();
    this.myFormGroup.controls["nbreProduitAnnuelPepiniere"].reset();
  }
  bouclePeches() {
    this.bouclepeche.push(this.myFormGroup.value);
    this.myFormGroup.controls["typePechePeche"].reset();
    this.myFormGroup.controls["zonePeche"].reset();
    this.myFormGroup.controls["typeEmbarcationPeche"].reset();
    this.myFormGroup.controls["statusUtilisePecheNon"].reset();
    this.myFormGroup.controls["statusUtilisePeche"].reset();
    this.myFormGroup.controls["embarcationPeche"].reset();
    this.myFormGroup.controls["typeEmbarcationPeche"].reset();
    this.myFormGroup.controls["cartePecheurPeche"].reset();
    this.myFormGroup.controls["transformationProdPeche"].reset();
    this.myFormGroup.controls["objectifPrincipalePeche"].reset();
    this.myFormGroup.controls["captureMoyenneSansEmb"].reset();
    this.myFormGroup.controls["dureeMoyenneSansEmb"].reset();
    this.myFormGroup.controls["nbreSortieSansEmb"].reset();
    this.myFormGroup.controls["nbreMoisSansEmb"].reset();
    this.myFormGroup.controls["captureMoyenneEmbNoMotorise"].reset();
    this.myFormGroup.controls["dureeMoyenneEmbNoMotorise"].reset();
    this.myFormGroup.controls["nbreSortieEmbNoMotorise"].reset();
    this.myFormGroup.controls["nbreMoisEmbNoMotorise"].reset();
    this.myFormGroup.controls["captureMoyenneEmbMotorise"].reset();
    this.myFormGroup.controls["dureeMoyenneEmbMotorise"].reset();
    this.myFormGroup.controls["nbreSortieEmbMotorise"].reset();
    this.myFormGroup.controls["nbreMoisEmbMotorise"].reset();
    this.myFormGroup.controls["nbreTotalPersEmbMotorise"].reset();
    this.myFormGroup.controls["nbreFemmesEmbMotorise"].reset();
  }
  boucleAquacultures() {
    this.boucleaquaculture.push(this.myFormGroup.value);
    this.boucleaquaculture.push(this.UniteAquaculture.value)
    this.myFormGroup.controls["typeActiviteAqua"].reset();
    this.myFormGroup.controls["especeAqua"].reset();
    this.myFormGroup.controls["typeSouchesAqua"].reset();
    this.myFormGroup.controls["modeProductionAqua"].reset();
    this.myFormGroup.controls["systemProductionAqua"].reset();
    this.myFormGroup.controls["nbreUniteAqua"].reset();
    this.myFormGroup.controls["surperficieAqua"].reset();
    this.myFormGroup.controls["gpsAqua"].reset();
    this.myFormGroup.controls["valorisationProdAqua"].reset();
    this.myFormGroup.controls["nbreTotalPersAqua"].reset();
    this.myFormGroup.controls["nbreFemmeAqua"].reset();
    this.myFormGroup.controls["espece"].reset();
    this.myFormGroup.controls["qteProdAqua"].reset();
    this.myFormGroup.controls["unite_mesureAqua"].reset();
    this.myFormGroup.controls["duree_moyenneAqua"].reset();
    this.myFormGroup.controls["objectifProductionAqua"].reset();
    this.UniteAquaculture.controls["uniteProd"].reset();
  }
  boucleAppui() {
    this.bouclesAppui.push(this.myFormGroup.value);
    this.bouclesAppui.push(this.UniteAppuiFormGroup.value);
    this.myFormGroup.controls["titreObtentionAppui"].reset();
    this.myFormGroup.controls["nomOrganisation"].reset();
    this.myFormGroup.controls["projet"].reset();
    this.myFormGroup.controls["ptfAppui"].reset();
    this.myFormGroup.controls["typeFinance"].reset();
    this.myFormGroup.controls["typeCredit"].reset();
    this.myFormGroup.controls["satisfaitAppui"].reset();
    this.myFormGroup.controls["operatioAppui"].reset();
    this.myFormGroup.controls["numAgrement"].reset();
    this.myFormGroup.controls["motifAppui"].reset();
    this.myFormGroup.controls["futureAcquisition"].reset();
    this.myFormGroup.controls["specificationAppui"].reset();
    this.myFormGroup.controls["datePrevuReceptionAppui"].reset();

    this.myFormGroup.controls["filiereAppuier"].reset();
    this.myFormGroup.controls["typeServiceAppui"].reset();
    this.myFormGroup.controls["nombreMaterielSup10"].reset();
    this.myFormGroup.controls["qteObtenuParType"].reset();
    this.myFormGroup.controls["montantObtenuSup10"].reset();
    this.myFormGroup.controls["montantApportBenefSup10"].reset();

    this.myFormGroup.controls["dateDebAppui"].reset();
    this.myFormGroup.controls["dateFinAppui"].reset();
    this.UniteAppuiFormGroup.controls["UniteQuantite"].reset();
  }

  boucleRefAppui() {
    this.bouclesRefAppui.push(this.myFormGroup.value);
    this.bouclesRefAppui.push(this.UniteAppuiFormGroup.value);
    this.myFormGroup.controls["filiereAppuier"].reset();
    this.myFormGroup.controls["dateDebAppui"].reset();
    this.myFormGroup.controls["dateFinAppui"].reset();

    this.myFormGroup.controls["genreAppuiBeneficie"].reset();
    this.myFormGroup.controls["typeServiceAppui"].reset();
    this.myFormGroup.controls["nombreMaterielSup10"].reset();
    this.myFormGroup.controls["qteObtenuParType"].reset();
    this.myFormGroup.controls["montantObtenuSup10"].reset();
    this.myFormGroup.controls["montantApportBenefSup10"].reset();
    this.UniteAppuiFormGroup.controls["UniteQuantite"].reset();
  }
  boucleRefAppui01() {
    this.bouclesRefAppui01.push(this.myFormGroup.value);
    this.bouclesRefAppui01.push(this.UniteAppuiFormGroup.value);
    this.myFormGroup.controls["genreAppuiBeneficie"].reset();
    this.myFormGroup.controls["typeServiceAppui"].reset();
    this.myFormGroup.controls["nombreMaterielSup10"].reset();
    this.myFormGroup.controls["qteObtenuParType"].reset();
    this.myFormGroup.controls["montantObtenuSup10"].reset();
    this.myFormGroup.controls["montantApportBenefSup10"].reset();
    this.UniteAppuiFormGroup.controls["UniteQuantite"].reset();
  }
  boucleLabelisations() {
    this.boucleLabelisation.push(this.myFormGroup.value);
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["systemeLabelisation"].reset();
  }
 /*  bouclePlaceFemme(){
    this.placeDesFemmess.push(this.myFormGroup.value);
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
    this.myFormGroup.controls["labelisation"].reset();
  } */

  // eto no manomboka ny save
  onSubmit() {
    // manomboka eto ny conditio retra2
    const data = this.myFormGroup.value;

    if (
      data.boucleElectronique != null ||
      data.carnetBovins != null ||
      data.causePrincipal != null ||
      data.cheptelVaccine != null ||
     // data.dateVaccination != null ||
      data.effBoeufCastres != null ||
      data.effBoeufNonCastres != null ||
      data.effFemelleRaceAmeliore != null ||
      data.effFemelleRaceLocale != null ||
      data.effInitialChep != null ||
      data.effMaleRaceAmeliore != null ||
      data.effMaleRaceLocale != null ||
      data.elevagePratique != null ||
      data.encadrementSanitaire != null ||
      data.fibBovins != null ||
      data.gardeProvisoire != null ||
      data.gpsElevage != null ||
      data.maladieFrequement != null ||
      data.matriculeProd != null ||
      data.modeElevage != null ||
      data.nbreAnimauxAchete != null ||
      data.nbreAutoconsomation != null ||
      data.nbreAutreSortie != null ||
      data.nbreCadeauDon != null ||
      data.nbreCheptelAcquis != null ||
      data.nbreDeces != null ||
      data.nbreNaissTroupeau != null ||
      data.nbrePret != null ||
      data.nbreVendu != null ||
      data.objectifPrincipaleFemelle != null ||
      data.objectifPrincipaleMale != null ||
      data.puceElectroique != null ||
      data.saisonPluie != null ||
      data.saisonSeche != null ||
      data.tempsTravailElevage != null ||
      data.traitementFrequement != null ||
     // data.typeVaccins != null ||
      data.typesAliment != null
    ) {
      this.Pratique = [
        {
          boucleElectronique: data.boucleElectronique
            ? data.boucleElectronique
            : null,
          carnetBovins: data.carnetBovins ? data.carnetBovins : null,
          causePrincipal: data.causePrincipal ? data.causePrincipal : null,
          cheptelVaccine: data.cheptelVaccine ? data.cheptelVaccine : null,
         // dateVaccination: data.dateVaccination ? data.dateVaccination : null,
          effBoeufCastres: data.effBoeufCastres ? data.effBoeufCastres : null,
          effBoeufNonCastres: data.effBoeufNonCastres
            ? data.effBoeufNonCastres
            : null,
          effFemelleRaceAmeliore: data.effFemelleRaceAmeliore
            ? data.effFemelleRaceAmeliore
            : null,
          effFemelleRaceLocale: data.effFemelleRaceLocale
            ? data.effFemelleRaceLocale
            : null,
          effInitialChep: data.effInitialChep ? data.effInitialChep : null,
          effMaleRaceAmeliore: data.effMaleRaceAmeliore
            ? data.effMaleRaceAmeliore
            : null,
          effMaleRaceLocale: data.effMaleRaceLocale
            ? data.effMaleRaceLocale
            : null,
          elevagePratique: data.elevagePratique ? data.elevagePratique : null,
          encadrementSanitaire: data.encadrementSanitaire
            ? data.encadrementSanitaire
            : null,
          fibBovins: data.fibBovins ? data.fibBovins : null,
          gardeProvisoire: data.gardeProvisoire ? data.gardeProvisoire : null,
          gpsElevage: data.gpsElevage ? data.gpsElevage : null,
          maladieFrequement: data.maladieFrequement
            ? data.maladieFrequement
            : null,
          modeElevage: data.modeElevage ? data.modeElevage : null,
          nbreAnimauxAchete: data.nbreAnimauxAchete
            ? data.nbreAnimauxAchete
            : null,
          nbreAutoconsomation: data.nbreAutoconsomation
            ? data.nbreAutoconsomation
            : null,
          nbreAutreSortie: data.nbreAutreSortie ? data.nbreAutreSortie : null,
          nbreCadeauDon: data.nbreCadeauDon ? data.nbreCadeauDon : null,
          nbreCheptelAcquis: data.nbreCheptelAcquis
            ? data.nbreCheptelAcquis
            : null,
          nbreDeces: data.nbreDeces ? data.nbreDeces : null,
          nbreNaissTroupeau: data.nbreNaissTroupeau
            ? data.nbreNaissTroupeau
            : null,
          nbrePret: data.nbrePret ? data.nbrePret : null,
          nbreVendu: data.nbreVendu ? data.nbreVendu : null,
          objectifPrincipaleFemelle: data.objectifPrincipaleFemelle
            ? data.objectifPrincipaleFemelle
            : null,
          objectifPrincipaleMale: data.objectifPrincipaleMale
            ? data.objectifPrincipaleMale
            : null,
          puceElectroique: data.puceElectroique ? data.puceElectroique : null,
          saisonPluie: data.saisonPluie ? data.saisonPluie : null,
          saisonSeche: data.saisonSeche ? data.saisonSeche : null,
          tempsTravailElevage: data.tempsTravailElevage
            ? data.tempsTravailElevage
            : null,
          traitementFrequement: data.traitementFrequement
            ? data.traitementFrequement
            : null,
          trancheAgeElevage: this.tranche,
          refElevageVaccins: this.vaccin,
         // typeVaccins: data.typeVaccins ? data.typeVaccins : null,
          typesAliment: data.typesAliment ? data.typesAliment : null,
        },
      ];
    }


    for (let i = 0; i < this.boucleselevagepratique.length; i++) {
      this.Pratique.push(
        this.boucleselevagepratique[i].boucleElectronique ||
          this.boucleselevagepratique[i].carnetBovins ||
          this.boucleselevagepratique[i].causePrincipal ||
          this.boucleselevagepratique[i].cheptelVaccine ||
          this.boucleselevagepratique[i].dateVaccination ||
          this.boucleselevagepratique[i].effBoeufCastres ||
          this.boucleselevagepratique[i].effBoeufNonCastres ||
          this.boucleselevagepratique[i].effFemelleRaceAmeliore ||
          this.boucleselevagepratique[i].effFemelleRaceLocale ||
          this.boucleselevagepratique[i].effInitialChep ||
          this.boucleselevagepratique[i].effMaleRaceAmeliore ||
          this.boucleselevagepratique[i].effMaleRaceLocale ||
          this.boucleselevagepratique[i].elevagePratique ||
          this.boucleselevagepratique[i].encadrementSanitaire ||
          this.boucleselevagepratique[i].fibBovins ||
          this.boucleselevagepratique[i].gardeProvisoire ||
          this.boucleselevagepratique[i].gpsElevage ||
          this.boucleselevagepratique[i].maladieFrequement ||
          this.boucleselevagepratique[i].matriculeProd ||
          this.boucleselevagepratique[i].modeElevage ||
          this.boucleselevagepratique[i].nbreAnimauxAchete ||
          this.boucleselevagepratique[i].nbreAutoconsomation ||
          this.boucleselevagepratique[i].nbreAutreSortie ||
          this.boucleselevagepratique[i].nbreCadeauDon ||
          this.boucleselevagepratique[i].nbreCheptelAcquis ||
          this.boucleselevagepratique[i].nbreDeces ||
          this.boucleselevagepratique[i].nbreNaissTroupeau ||
          this.boucleselevagepratique[i].nbrePret ||
          this.boucleselevagepratique[i].nbreVendu ||
          this.boucleselevagepratique[i].objectifPrincipaleFemelle ||
          this.boucleselevagepratique[i].objectifPrincipaleMale ||
          this.boucleselevagepratique[i].puceElectroique ||
          this.boucleselevagepratique[i].saisonPluie ||
          this.boucleselevagepratique[i].saisonSeche ||
          this.boucleselevagepratique[i].tempsTravailElevage ||
          this.boucleselevagepratique[i].traitementFrequement ||
          this.boucleselevagepratique[i].typeVaccins ||
          this.boucleselevagepratique[i].typesAliment
          ? {
              boucleElectronique: this.boucleselevagepratique[i]
                .boucleElectronique
                ? this.boucleselevagepratique[i].boucleElectronique
                : null,
              carnetBovins: this.boucleselevagepratique[i].carnetBovins
                ? this.boucleselevagepratique[i].carnetBovins
                : null,
              causePrincipal: this.boucleselevagepratique[i].causePrincipal
                ? this.boucleselevagepratique[i].causePrincipal
                : null,
              cheptelVaccine: this.boucleselevagepratique[i].cheptelVaccine
                ? this.boucleselevagepratique[i].cheptelVaccine
                : null,
              dateVaccination: this.boucleselevagepratique[i].dateVaccination
                ? this.boucleselevagepratique[i].dateVaccination
                : null,
              effBoeufCastres: this.boucleselevagepratique[i].effBoeufCastres
                ? this.boucleselevagepratique[i].effBoeufCastres
                : null,
              effBoeufNonCastres: this.boucleselevagepratique[i]
                .effBoeufNonCastres
                ? this.boucleselevagepratique[i].effBoeufNonCastres
                : null,
              effFemelleRaceAmeliore: this.boucleselevagepratique[i]
                .effFemelleRaceAmeliore
                ? this.boucleselevagepratique[i].effFemelleRaceAmeliore
                : null,
              effFemelleRaceLocale: this.boucleselevagepratique[i]
                .effFemelleRaceLocale
                ? this.boucleselevagepratique[i].effFemelleRaceLocale
                : null,
              effInitialChep: this.boucleselevagepratique[i].effInitialChep
                ? this.boucleselevagepratique[i].effInitialChep
                : null,
              effMaleRaceAmeliore: this.boucleselevagepratique[i]
                .effMaleRaceAmeliore
                ? this.boucleselevagepratique[i].effMaleRaceAmeliore
                : null,
              effMaleRaceLocale: this.boucleselevagepratique[i]
                .effMaleRaceLocale
                ? this.boucleselevagepratique[i].effMaleRaceLocale
                : null,
              elevagePratique: this.boucleselevagepratique[i].elevagePratique
                ? this.boucleselevagepratique[i].elevagePratique
                : null,
              encadrementSanitaire: this.boucleselevagepratique[i]
                .encadrementSanitaire
                ? this.boucleselevagepratique[i].encadrementSanitaire
                : null,
              fibBovins: this.boucleselevagepratique[i].fibBovins
                ? this.boucleselevagepratique[i].fibBovins
                : null,
              gardeProvisoire: this.boucleselevagepratique[i].gardeProvisoire
                ? this.boucleselevagepratique[i].gardeProvisoire
                : null,
              gpsElevage: this.boucleselevagepratique[i].gpsElevage
                ? this.boucleselevagepratique[i].gpsElevage
                : null,
              maladieFrequement: this.boucleselevagepratique[i]
                .maladieFrequement
                ? this.boucleselevagepratique[i].maladieFrequement
                : null,
              modeElevage: this.boucleselevagepratique[i].modeElevage
                ? this.boucleselevagepratique[i].modeElevage
                : null,
              nbreAnimauxAchete: this.boucleselevagepratique[i]
                .nbreAnimauxAchete
                ? this.boucleselevagepratique[i].nbreAnimauxAchete
                : null,
              nbreAutoconsomation: this.boucleselevagepratique[i]
                .nbreAutoconsomation
                ? this.boucleselevagepratique[i].nbreAutoconsomation
                : null,
              nbreAutreSortie: this.boucleselevagepratique[i].nbreAutreSortie
                ? this.boucleselevagepratique[i].nbreAutreSortie
                : null,
              nbreCadeauDon: this.boucleselevagepratique[i].nbreCadeauDon
                ? this.boucleselevagepratique[i].nbreCadeauDon
                : null,
              nbreCheptelAcquis: this.boucleselevagepratique[i]
                .nbreCheptelAcquis
                ? this.boucleselevagepratique[i].nbreCheptelAcquis
                : null,
              nbreDeces: this.boucleselevagepratique[i].nbreDeces
                ? this.boucleselevagepratique[i].nbreDeces
                : null,
              nbreNaissTroupeau: this.boucleselevagepratique[i]
                .nbreNaissTroupeau
                ? this.boucleselevagepratique[i].nbreNaissTroupeau
                : null,
              nbrePret: this.boucleselevagepratique[i].nbrePret
                ? this.boucleselevagepratique[i].nbrePret
                : null,
              nbreVendu: this.boucleselevagepratique[i].nbreVendu
                ? this.boucleselevagepratique[i].nbreVendu
                : null,
              objectifPrincipaleFemelle: this.boucleselevagepratique[i]
                .objectifPrincipaleFemelle
                ? this.boucleselevagepratique[i].objectifPrincipaleFemelle
                : null,
              objectifPrincipaleMale: this.boucleselevagepratique[i]
                .objectifPrincipaleMale
                ? this.boucleselevagepratique[i].objectifPrincipaleMale
                : null,
              puceElectroique: this.boucleselevagepratique[i].puceElectroique
                ? this.boucleselevagepratique[i].puceElectroique
                : null,
              saisonPluie: this.boucleselevagepratique[i].saisonPluie
                ? this.boucleselevagepratique[i].saisonPluie
                : null,
              saisonSeche: this.boucleselevagepratique[i].saisonSeche
                ? this.boucleselevagepratique[i].saisonSeche
                : null,
              tempsTravailElevage: this.boucleselevagepratique[i]
                .tempsTravailElevage
                ? this.boucleselevagepratique[i].tempsTravailElevage
                : null,
              traitementFrequement: this.boucleselevagepratique[i]
                .traitementFrequement
                ? this.boucleselevagepratique[i].traitementFrequement
                : null,
              typeVaccins: this.boucleselevagepratique[i].typeVaccins
                ? this.boucleselevagepratique[i].typeVaccins
                : null,
              typesAliment: this.boucleselevagepratique[i].typesAliment
                ? this.boucleselevagepratique[i].typesAliment
                : null,
            }
          : null
      );
    }
    if (
      data.conduitElevage != null ||
      data.nbreLitreProdAnnuel != null ||
      data.nbreRuches != null ||
      data.pratiqueTraitement != null ||
      data.techniqueUtilise != null ||
      data.typePlantes != null
    ) {
      this.Apiculture = [
        {
          conduitElevage: data.conduitElevage ? data.conduitElevage : null,
          nbreLitreProdAnnuel: data.nbreLitreProdAnnuel
            ? data.nbreLitreProdAnnuel
            : null,
          nbreRuches: data.nbreRuches ? data.nbreRuches : null,
          pratiqueTraitement: data.pratiqueTraitement
            ? data.pratiqueTraitement
            : null,
          techniqueUtilise: data.techniqueUtilise
            ? data.techniqueUtilise
            : null,
          typePlantes: data.typePlantes ? data.typePlantes : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleapiculture.length; i++) {
      this.Apiculture.push(
        this.boucleapiculture[i].conduitElevage ||
          this.boucleapiculture[i].nbreLitreProdAnnuel ||
          this.boucleapiculture[i].nbreRuches ||
          this.boucleapiculture[i].pratiqueTraitement ||
          this.boucleapiculture[i].techniqueUtilise ||
          this.boucleapiculture[i].typePlantes
          ? {
              conduitElevage: this.boucleapiculture[i].conduitElevage
                ? this.boucleapiculture[i].conduitElevage
                : null,
              nbreLitreProdAnnuel: this.boucleapiculture[i].nbreLitreProdAnnuel
                ? this.boucleapiculture[i].nbreLitreProdAnnuel
                : null,
              nbreRuches: this.boucleapiculture[i].nbreRuches
                ? this.boucleapiculture[i].nbreRuches
                : null,
              pratiqueTraitement: this.boucleapiculture[i].pratiqueTraitement
                ? this.boucleapiculture[i].pratiqueTraitement
                : null,
              techniqueUtilise: this.boucleapiculture[i].techniqueUtilise
                ? this.boucleapiculture[i].techniqueUtilise
                : null,
              typePlantes: this.boucleapiculture[i].typePlantes
                ? this.boucleapiculture[i].typePlantes
                : null,
            }
          : null
      );
    }

    this.aquaculture = [
      {
        duree_moyenneAqua: data.duree_moyenneAqua
          ? data.duree_moyenneAqua
          : null,
        espece: data.espece ? data.espece : null,
        especeAqua: data.especeAqua ? data.especeAqua : null,
        especeAqucultureAqua: data.especeAqucultureAqua
          ? data.especeAqucultureAqua
          : null,
        gpsAqua: data.gpsAqua ? data.gpsAqua : null,
        modeProductionAqua: data.modeProductionAqua
          ? data.modeProductionAqua
          : null,
        nbrActiviteAqua: data.nbrActiviteAqua ? data.nbrActiviteAqua : null,
        nbreFemmeAqua: data.nbreFemmeAqua ? data.nbreFemmeAqua : null,
        nbreTotalPersAqua: data.nbreTotalPersAqua
          ? data.nbreTotalPersAqua
          : null,
        nbreUniteAqua: data.nbreUniteAqua ? data.nbreUniteAqua : null,
        objectifProductionAqua: data.objectifProductionAqua
          ? data.objectifProductionAqua
          : null,
        qteProdAqua: data.qteProdAqua ? data.qteProdAqua : null,
        surperficieAqua: data.surperficieAqua ? data.surperficieAqua : null,
        systemProductionAqua: data.systemProductionAqua
          ? data.systemProductionAqua
          : null,
        typeActiviteAqua: data.typeActiviteAqua ? data.typeActiviteAqua : null,
        typeSouchesAqua: data.typeSouchesAqua ? data.typeSouchesAqua : null,
        "uniteProd": this.UniteAquaculture.get("uniteProd").value,
        unite_mesureAqua: data.unite_mesureAqua ? data.unite_mesureAqua : null,
        valorisationProdAqua: data.valorisationProdAqua
          ? data.valorisationProdAqua
          : null,
      },
    ];

    for (let i = 0; i < this.boucleaquaculture.length; i++) {
      this.aquaculture.push(
        this.boucleaquaculture[i].duree_moyenneAqua ||
          this.boucleaquaculture[i].espece ||
          this.boucleaquaculture[i].especeAqua ||
          this.boucleaquaculture[i].especeAqucultureAqua ||
          this.boucleaquaculture[i].gpsAqua ||
          this.boucleaquaculture[i].modeProductionAqua ||
          this.boucleaquaculture[i].nbrActiviteAqua ||
          this.boucleaquaculture[i].nbreFemmeAqua ||
          this.boucleaquaculture[i].nbreTotalPersAqua ||
          this.boucleaquaculture[i].nbreUniteAqua ||
          this.boucleaquaculture[i].objectifProductionAqua ||
          this.boucleaquaculture[i].qteProdAqua ||
          this.boucleaquaculture[i].surperficieAqua ||
          this.boucleaquaculture[i].systemProductionAqua ||
          this.boucleaquaculture[i].typeActiviteAqua ||
          this.boucleaquaculture[i].typeSouchesAqua ||
          this.boucleaquaculture[i].unite_mesureAqua ||
          this.boucleaquaculture[i].valorisationProdAqua
          ? {
              duree_moyenneAqua: this.boucleaquaculture[i].duree_moyenneAqua
                ? this.boucleaquaculture[i].duree_moyenneAqua
                : null,
              espece: this.boucleaquaculture[i].espece
                ? this.boucleaquaculture[i].espece
                : null,
              especeAqua: this.boucleaquaculture[i].especeAqua
                ? this.boucleaquaculture[i].especeAqua
                : null,
              especeAqucultureAqua: this.boucleaquaculture[i]
                .especeAqucultureAqua
                ? this.boucleaquaculture[i].especeAqucultureAqua
                : null,
              gpsAqua: this.boucleaquaculture[i].gpsAqua
                ? this.boucleaquaculture[i].gpsAqua
                : null,
              modeProductionAqua: this.boucleaquaculture[i].modeProductionAqua
                ? this.boucleaquaculture[i].modeProductionAqua
                : null,
              nbrActiviteAqua: this.boucleaquaculture[i].nbrActiviteAqua
                ? this.boucleaquaculture[i].nbrActiviteAqua
                : null,
              nbreFemmeAqua: this.boucleaquaculture[i].nbreFemmeAqua
                ? this.boucleaquaculture[i].nbreFemmeAqua
                : null,
              nbreTotalPersAqua: this.boucleaquaculture[i].nbreTotalPersAqua
                ? this.boucleaquaculture[i].nbreTotalPersAqua
                : null,
              nbreUniteAqua: this.boucleaquaculture[i].nbreUniteAqua
                ? this.boucleaquaculture[i].nbreUniteAqua
                : null,
              objectifProductionAqua: this.boucleaquaculture[i]
                .objectifProductionAqua
                ? this.boucleaquaculture[i].objectifProductionAqua
                : null,
              qteProdAqua: this.boucleaquaculture[i].qteProdAqua
                ? this.boucleaquaculture[i].qteProdAqua
                : null,
              surperficieAqua: this.boucleaquaculture[i].surperficieAqua
                ? this.boucleaquaculture[i].surperficieAqua
                : null,
              systemProductionAqua: this.boucleaquaculture[i]
                .systemProductionAqua
                ? this.boucleaquaculture[i].systemProductionAqua
                : null,
              typeActiviteAqua: this.boucleaquaculture[i].typeActiviteAqua
                ? this.boucleaquaculture[i].typeActiviteAqua
                : null,
              typeSouchesAqua: this.boucleaquaculture[i].typeSouchesAqua
                ? this.boucleaquaculture[i].typeSouchesAqua
                : null,
              "uniteProd": this.boucleaquaculture[i].uniteProd,
              unite_mesureAqua: this.boucleaquaculture[i].unite_mesureAqua
                ? this.boucleaquaculture[i].unite_mesureAqua
                : null,
              valorisationProdAqua: this.boucleaquaculture[i]
                .valorisationProdAqua
                ? this.boucleaquaculture[i].valorisationProdAqua
                : null,
            }
          : null
      );
    }
    if (
      data.captureMoyenneEmbMotorise ||
      data.captureMoyenneEmbNoMotorise ||
      data.captureMoyenneSansEmb ||
      data.dureeMoyenneEmbMotorise ||
      data.dureeMoyenneEmbNoMotorise ||
      data.dureeMoyenneSansEmb ||
      data.embarcationPeche ||
      data.nbreFemmesEmbMotorise ||
      data.nbreMoisEmbMotorise ||
      data.nbreMoisEmbNoMotorise ||
      data.nbreMoisSansEmb ||
      data.nbreSortieEmbMotorise ||
      data.nbreSortieEmbNoMotorise ||
      data.nbreSortieSansEmb ||
      data.nbreTotalPersEmbMotorise ||
      data.objectifPrincipalePeche ||
      data.pecheMaritimePeche ||
      data.statusUtilisePeche ||
      data.cartePecheurPeche ||
      data.transformationProdPeche ||
      data.typeEmbarcationPeche ||
      data.typePechePeche ||
      data.zonePeche
    ) {
      this.peche = [
        {
          captureMoyenneEmbMotorise: data.captureMoyenneEmbMotorise
            ? data.captureMoyenneEmbMotorise
            : null,
          captureMoyenneEmbNoMotorise: data.captureMoyenneEmbNoMotorise
            ? data.captureMoyenneEmbNoMotorise
            : null,
          captureMoyenneSansEmb: data.captureMoyenneSansEmb
            ? data.captureMoyenneSansEmb
            : null,
          cartePecheurPeche: data.cartePecheurPeche
            ? data.cartePecheurPeche
            : null,
          dureeMoyenneEmbMotorise: data.dureeMoyenneEmbMotorise
            ? data.dureeMoyenneEmbMotorise
            : null,
          dureeMoyenneEmbNoMotorise: data.dureeMoyenneEmbNoMotorise
            ? data.dureeMoyenneEmbNoMotorise
            : null,
          dureeMoyenneSansEmb: data.dureeMoyenneSansEmb
            ? data.dureeMoyenneSansEmb
            : null,
          embarcationPeche: data.embarcationPeche
            ? data.embarcationPeche
            : null,
          nbreFemmesEmbMotorise: data.nbreFemmesEmbMotorise
            ? data.nbreFemmesEmbMotorise
            : null,
          nbreMoisEmbMotorise: data.nbreMoisEmbMotorise
            ? data.nbreMoisEmbMotorise
            : null,
          nbreMoisEmbNoMotorise: data.nbreMoisEmbNoMotorise
            ? data.nbreMoisEmbNoMotorise
            : null,
          nbreMoisSansEmb: data.nbreMoisSansEmb ? data.nbreMoisSansEmb : null,
          nbreSortieEmbMotorise: data.nbreSortieEmbMotorise
            ? data.nbreSortieEmbMotorise
            : null,
          nbreSortieEmbNoMotorise: data.nbreSortieEmbNoMotorise
            ? data.nbreSortieEmbNoMotorise
            : null,
          nbreSortieSansEmb: data.nbreSortieSansEmb
            ? data.nbreSortieSansEmb
            : null,
          nbreTotalPersEmbMotorise: data.nbreTotalPersEmbMotorise
            ? data.nbreTotalPersEmbMotorise
            : null,
          objectifPrincipalePeche: data.objectifPrincipalePeche
            ? data.objectifPrincipalePeche
            : null,
          pecheMaritimePeche: data.pecheMaritimePeche
            ? data.pecheMaritimePeche
            : null,
          statusUtilisePeche: data.statusUtilisePeche
            ? data.statusUtilisePeche
            : null,
          transformationProdPeche: data.transformationProdPeche
            ? data.transformationProdPeche
            : null,
          typeEmbarcationPeche: data.typeEmbarcationPeche
            ? data.typeEmbarcationPeche
            : null,
          typePechePeche: data.typePechePeche ? data.typePechePeche : null,
          zonePeche: data.zonePeche ? data.zonePeche : null,
        },
      ];
    }
    for (let i = 0; i < this.bouclepeche.length; i++) {
      this.peche.push(
        this.bouclepeche[i].captureMoyenneEmbMotorise ||
          this.bouclepeche[i].captureMoyenneEmbNoMotorise ||
          this.bouclepeche[i].captureMoyenneSansEmb ||
          this.bouclepeche[i].cartePecheurPeche ||
          this.bouclepeche[i].dureeMoyenneEmbMotorise ||
          this.bouclepeche[i].dureeMoyenneEmbNoMotorise ||
          this.bouclepeche[i].dureeMoyenneSansEmb ||
          this.bouclepeche[i].embarcationPeche ||
          this.bouclepeche[i].nbreFemmesEmbMotorise ||
          this.bouclepeche[i].nbreMoisEmbMotorise ||
          this.bouclepeche[i].nbreMoisEmbNoMotorise ||
          this.bouclepeche[i].nbreMoisSansEmb ||
          this.bouclepeche[i].nbreSortieEmbMotorise ||
          this.bouclepeche[i].nbreSortieEmbNoMotorise ||
          this.bouclepeche[i].nbreSortieSansEmb ||
          this.bouclepeche[i].nbreTotalPersEmbMotorise ||
          this.bouclepeche[i].objectifPrincipalePeche ||
          this.bouclepeche[i].pecheMaritimePeche ||
          this.bouclepeche[i].statusUtilisePeche ||
          this.bouclepeche[i].transformationProdPeche ||
          this.bouclepeche[i].typeEmbarcationPeche ||
          this.bouclepeche[i].typePechePeche ||
          this.bouclepeche[i].zonePeche
          ? {
              captureMoyenneEmbMotorise: this.peche[i].captureMoyenneEmbMotorise
                ? this.peche[i].captureMoyenneEmbMotorise
                : null,
              captureMoyenneEmbNoMotorise: this.peche[i]
                .captureMoyenneEmbNoMotorise
                ? this.peche[i].captureMoyenneEmbNoMotorise
                : null,
              captureMoyenneSansEmb: this.peche[i].captureMoyenneSansEmb
                ? this.peche[i].captureMoyenneSansEmb
                : null,
              cartePecheurPeche: this.peche[i].cartePecheurPeche
                ? this.peche[i].cartePecheurPeche
                : null,
              dureeMoyenneEmbMotorise: this.peche[i].dureeMoyenneEmbMotorise
                ? this.peche[i].dureeMoyenneEmbMotorise
                : null,
              dureeMoyenneEmbNoMotorise: this.peche[i].dureeMoyenneEmbNoMotorise
                ? this.peche[i].dureeMoyenneEmbNoMotorise
                : null,
              dureeMoyenneSansEmb: this.peche[i].dureeMoyenneSansEmb
                ? this.peche[i].dureeMoyenneSansEmb
                : null,
              embarcationPeche: this.peche[i].embarcationPeche
                ? this.peche[i].embarcationPeche
                : null,
              nbreFemmesEmbMotorise: this.peche[i].nbreFemmesEmbMotorise
                ? this.peche[i].nbreFemmesEmbMotorise
                : null,
              nbreMoisEmbMotorise: this.peche[i].nbreMoisEmbMotorise
                ? this.peche[i].nbreMoisEmbMotorise
                : null,
              nbreMoisEmbNoMotorise: this.peche[i].nbreMoisEmbNoMotorise
                ? this.peche[i].nbreMoisEmbNoMotorise
                : null,
              nbreMoisSansEmb: this.peche[i].nbreMoisSansEmb
                ? this.peche[i].nbreMoisSansEmb
                : null,
              nbreSortieEmbMotorise: this.peche[i].nbreSortieEmbMotorise
                ? this.peche[i].nbreSortieEmbMotorise
                : null,
              nbreSortieEmbNoMotorise: this.peche[i].nbreSortieEmbNoMotorise
                ? this.peche[i].nbreSortieEmbNoMotorise
                : null,
              nbreSortieSansEmb: this.peche[i].nbreSortieSansEmb
                ? this.peche[i].nbreSortieSansEmb
                : null,
              nbreTotalPersEmbMotorise: this.peche[i].nbreTotalPersEmbMotorise
                ? this.peche[i].nbreTotalPersEmbMotorise
                : null,
              objectifPrincipalePeche: this.peche[i].objectifPrincipalePeche
                ? this.peche[i].objectifPrincipalePeche
                : null,
              pecheMaritimePeche: this.peche[i].pecheMaritimePeche
                ? this.peche[i].pecheMaritimePeche
                : null,
              statusUtilisePeche: this.peche[i].statusUtilisePeche
                ? this.peche[i].statusUtilisePeche
                : null,
              transformationProdPeche: this.peche[i].transformationProdPeche
                ? this.peche[i].transformationProdPeche
                : null,
              typeEmbarcationPeche: this.peche[i].typeEmbarcationPeche
                ? this.peche[i].typeEmbarcationPeche
                : null,
              typePechePeche: this.peche[i].typePechePeche
                ? this.peche[i].typePechePeche
                : null,
              zonePeche: this.peche[i].zonePeche
                ? this.peche[i].zonePeche
                : null,
            }
          : null
      );
    }
    const sylviCasPepiniere = [{}];
    const sylviProSylvicole = [{}];
    const sylviGeneral = [{}];
    if (data.totalChaux != null || data.typeChaux != null) {
      this.Chaulage = [
        {
          totalChaux: data.totalChaux ? data.totalChaux : null,
          typeChaux: data.typeChaux ? data.typeChaux : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleChaulage.length; i++) {
      this.Chaulage.push(
        this.boucleChaulage[i].totalChaux || this.boucleChaulage[i].typeChaux
          ? {
              totalChaux: this.boucleChaulage[i].totalChaux
                ? this.boucleChaulage[i].totalChaux
                : null,
              typeChaux: this.boucleChaulage[i].typeChaux
                ? this.boucleChaulage[i].typeChaux
                : null,
            }
          : null
      );
    }
    if (data.qteAmendement != null || data.typeAmendement != null) {
      this.organique = [
        {
          qteAmendement: data.qteAmendement ? data.qteAmendement : null,
          typeAmendement: data.typeAmendement ? data.typeAmendement : null,
          "uniteAmendement": this.UniteAmmendemetss.get("uniteAmendement").value
        },
      ];
    }
    for (let i = 0; i < this.boucleOrganique.length; i++) {
      this.organique.push(
        this.boucleOrganique[i].qteAmendement ||
          this.boucleOrganique[i].typeAmendement
          ? {
              qteAmendement: this.boucleOrganique[i].qteAmendement
                ? this.boucleOrganique[i].qteAmendement
                : null,
              typeAmendement: this.boucleOrganique[i].typeAmendement
                ? this.boucleOrganique[i].typeAmendement
                : null,
              "uniteAmendement": this.boucleOrganique[i].uniteAmendement
                
            }
          : null
      );
    }
    if (
      data.agePlante != null ||
      data.agePlanteRepique != null ||
      data.anneeAcquisition != null ||
      data.autre_culture != null ||
      data.culture_succession != null ||
      data.debutPeriodeRecolteAgri != null ||
      data.debutPlantation != null ||
      data.dureeDernierRecolte != null ||
      data.finPeriodeRecolteAgri != null ||
      data.finPlantation != null ||
      data.modeFertlisation != null ||
      data.modeLbour != null ||
      data.modePlantation != null ||
      data.modeRepiquage != null ||
      data.modeSarclageDesherbage != null ||
      data.modeSemis != null ||
      data.nbrePieds != null ||
      data.nomCulture != null ||
      data.nomCultureAssociation != null ||
      data.nomCultureDerobee != null ||
      data.nom_variete != null ||
      data.periodeCulture != null ||
      data.pratiqueCultureAssociation != null ||
      data.productionSaison != null ||
      data.productionSemence != null ||
      data.protectionVegetaux != null ||
      data.qtEngraisAzote != null ||
      data.qteEngraisUtilise != null ||
      data.sourceSemences != null ||
      data.sourceEauAgri != null ||
      data.systemeCulture != null ||
      data.systemeDrainage != null ||
      data.systemeIrrigation != null ||
      data.tempsTravail != null ||
      data.traitementsContreInsectes != null ||
      data.traitementsContreRavageurs != null ||
      data.traitementsMaladies != null ||
      data.traitementsPhytosanitaire != null ||
      data.typeSemences != null
    ) {
      this.AgricultureGeneral = [
        {
          agePlante: data.agePlante ? data.agePlante : null,
          agePlanteRepique: data.agePlanteRepique
            ? data.agePlanteRepique
            : null,
          anneeAcquisition: data.anneeAcquisition
            ? data.anneeAcquisition
            : null,
          autre_culture: data.autre_culture ? data.autre_culture : null,
          culture_succession: data.culture_succession
            ? data.culture_succession
            : null,
          debutPeriodeRecolteAgri: data.debutPeriodeRecolteAgri
            ? data.debutPeriodeRecolteAgri
            : null,
          debutPlantation: data.debutPlantation ? data.debutPlantation : null,
          dureeDernierRecolte: data.dureeDernierRecolte
            ? data.dureeDernierRecolte
            : null,
          finPeriodeRecolteAgri: data.finPeriodeRecolteAgri
            ? data.finPeriodeRecolteAgri
            : null,
          finPlantation: data.finPlantation ? data.finPlantation : null,
          modeFertlisation: data.modeFertlisation
            ? data.modeFertlisation
            : null,
          modeLbour: data.modeLbour ? data.modeLbour : null,
          modePlantation: data.modePlantation ? data.modePlantation : null,
          modeRepiquage: data.modeRepiquage ? data.modeRepiquage : null,
          modeSarclageDesherbage: data.modeSarclageDesherbage
            ? data.modeSarclageDesherbage
            : null,
          modeSemis: data.modeSemis ? data.modeSemis : null,
          nbrePieds: data.nbrePieds ? data.nbrePieds : null,
          nomCulture: data.nomCulture ? data.nomCulture : null,
          nomCultureAssociation: data.nomCultureAssociation
            ? data.nomCultureAssociation
            : null,
          nomCultureDerobee: data.nomCultureDerobee
            ? data.nomCultureDerobee
            : null,
          nom_variete: data.nom_variete ? data.nom_variete : null,
          periodeCulture: data.periodeCulture ? data.periodeCulture : null,
          pratiqueCultureAssociation: data.pratiqueCultureAssociation
            ? data.pratiqueCultureAssociation
            : null,
          productionSaison: data.productionSaison
            ? data.productionSaison
            : null,
          productionSemence: data.productionSemence
            ? data.productionSemence
            : null,
          protectionVegetaux: data.protectionVegetaux
            ? data.protectionVegetaux
            : null,
          qtEngraisAzote: data.qtEngraisAzote ? data.qtEngraisAzote : null,
          qteEngraisUtilise: data.qteEngraisUtilise
            ? data.qteEngraisUtilise
            : null,
          sourceSemences: data.sourceSemences ? data.sourceSemences : null,
          sourceEauAgri: data.sourceEauAgri ? data.sourceEauAgri : null,
          systemeCulture: data.systemeCulture ? data.systemeCulture : null,
          systemeDrainage: data.systemeDrainage ? data.systemeDrainage : null,
          systemeIrrigation: data.systemeIrrigation
            ? data.systemeIrrigation
            : null,
          tempsTravail: data.tempsTravail ? data.tempsTravail : null,
          traitementsContreInsectes: data.traitementsContreInsectes
            ? data.traitementsContreInsectes
            : null,
          traitementsContreRavageurs: data.traitementsContreRavageurs
            ? data.traitementsContreRavageurs
            : null,
          traitementsMaladies: data.traitementsMaladies
            ? data.traitementsMaladies
            : null,
          traitementsPhytosanitaire: data.traitementsPhytosanitaire
            ? data.traitementsPhytosanitaire
            : null,
          /* typeEngrais: data.typeEngrais ? data.typeEngrais : null, */
          typeSemences: data.typeSemences ? data.typeSemences : null,
          "uniteEngraisAzote": this.uniteAgriGeneral.get("uniteEngraisAzote").value,
          "uniteEngraisUtilise": this.uniteAgriGeneral.get("uniteEngraisUtilise").value
        },
      ];
    }
    for (let i = 0; i < this.bouclegeneral.length; i++) {
      this.AgricultureGeneral.push(
        this.bouclegeneral[i].agePlante ||
          this.bouclegeneral[i].agePlanteRepique ||
          this.bouclegeneral[i].anneeAcquisition ||
          this.bouclegeneral[i].autre_culture ||
          this.bouclegeneral[i].culture_succession ||
          this.bouclegeneral[i].debutPeriodeRecolteAgri ||
          this.bouclegeneral[i].debutPlantation ||
          this.bouclegeneral[i].dureeDernierRecolte ||
          this.bouclegeneral[i].finPeriodeRecolteAgri ||
          this.bouclegeneral[i].finPlantation ||
          this.bouclegeneral[i].modeFertlisation ||
          this.bouclegeneral[i].modeLbour ||
          this.bouclegeneral[i].modePlantation ||
          this.bouclegeneral[i].modeRepiquage ||
          this.bouclegeneral[i].modeSarclageDesherbage ||
          this.bouclegeneral[i].modeSemis ||
          this.bouclegeneral[i].nbrePieds ||
          this.bouclegeneral[i].nomCulture ||
          this.bouclegeneral[i].nomCultureAssociation ||
          this.bouclegeneral[i].nomCultureDerobee ||
          this.bouclegeneral[i].nom_variete ||
          this.bouclegeneral[i].periodeCulture ||
          this.bouclegeneral[i].pratiqueCultureAssociation ||
          this.bouclegeneral[i].productionSaison ||
          this.bouclegeneral[i].productionSemence ||
          this.bouclegeneral[i].protectionVegetaux ||
          this.bouclegeneral[i].qtEngraisAzote ||
          this.bouclegeneral[i].qteEngraisUtilise ||
          this.bouclegeneral[i].sourceSemences ||
          this.bouclegeneral[i].sourceEauAgri ||
          this.bouclegeneral[i].systemeCulture ||
          this.bouclegeneral[i].systemeDrainage ||
          this.bouclegeneral[i].systemeIrrigation ||
          this.bouclegeneral[i].tempsTravail ||
          this.bouclegeneral[i].traitementsContreInsectes ||
          this.bouclegeneral[i].traitementsContreRavageurs ||
          this.bouclegeneral[i].traitementsMaladies ||
          this.bouclegeneral[i].traitementsPhytosanitaire ||
          /* this.bouclegeneral[i].typeEngrais || */
          this.bouclegeneral[i].typeSemences
          ? {
              agePlante: this.bouclegeneral[i].agePlante
                ? this.bouclegeneral[i].agePlante
                : null,
              agePlanteRepique: this.bouclegeneral[i].agePlanteRepique
                ? this.bouclegeneral[i].agePlanteRepique
                : null,
              anneeAcquisition: this.bouclegeneral[i].anneeAcquisition
                ? this.bouclegeneral[i].anneeAcquisition
                : null,
              autre_culture: this.bouclegeneral[i].autre_culture
                ? this.bouclegeneral[i].autre_culture
                : null,
              culture_succession: this.bouclegeneral[i].culture_succession
                ? this.bouclegeneral[i].culture_succession
                : null,
              debutPeriodeRecolteAgri: this.bouclegeneral[i]
                .debutPeriodeRecolteAgri
                ? this.bouclegeneral[i].debutPeriodeRecolteAgri
                : null,
              debutPlantation: this.bouclegeneral[i].debutPlantation
                ? this.bouclegeneral[i].debutPlantation
                : null,
              dureeDernierRecolte: this.bouclegeneral[i].dureeDernierRecolte
                ? this.bouclegeneral[i].dureeDernierRecolte
                : null,
              finPeriodeRecolteAgri: this.bouclegeneral[i].finPeriodeRecolteAgri
                ? this.bouclegeneral[i].finPeriodeRecolteAgri
                : null,
              finPlantation: this.bouclegeneral[i].finPlantation
                ? this.bouclegeneral[i].finPlantation
                : null,
              modeFertlisation: this.bouclegeneral[i].modeFertlisation
                ? this.bouclegeneral[i].modeFertlisation
                : null,
              modeLbour: this.bouclegeneral[i].modeLbour
                ? this.bouclegeneral[i].modeLbour
                : null,
              modePlantation: this.bouclegeneral[i].modePlantation
                ? this.bouclegeneral[i].modePlantation
                : null,
              modeRepiquage: this.bouclegeneral[i].modeRepiquage
                ? this.bouclegeneral[i].modeRepiquage
                : null,
              modeSarclageDesherbage: this.bouclegeneral[i]
                .modeSarclageDesherbage
                ? this.bouclegeneral[i].modeSarclageDesherbage
                : null,
              modeSemis: this.bouclegeneral[i].modeSemis
                ? this.bouclegeneral[i].modeSemis
                : null,
              nbrePieds: this.bouclegeneral[i].nbrePieds
                ? this.bouclegeneral[i].nbrePieds
                : null,
              nomCulture: this.bouclegeneral[i].nomCulture
                ? this.bouclegeneral[i].nomCulture
                : null,
              nomCultureAssociation: this.bouclegeneral[i].nomCultureAssociation
                ? this.bouclegeneral[i].nomCultureAssociation
                : null,
              nomCultureDerobee: this.bouclegeneral[i].nomCultureDerobee
                ? this.bouclegeneral[i].nomCultureDerobee
                : null,
              nom_variete: this.bouclegeneral[i].nom_variete
                ? this.bouclegeneral[i].nom_variete
                : null,
              periodeCulture: this.bouclegeneral[i].periodeCulture
                ? this.bouclegeneral[i].periodeCulture
                : null,
              pratiqueCultureAssociation: this.bouclegeneral[i]
                .pratiqueCultureAssociation
                ? this.bouclegeneral[i].pratiqueCultureAssociation
                : null,
              productionSaison: this.bouclegeneral[i].productionSaison
                ? this.bouclegeneral[i].productionSaison
                : null,
              productionSemence: this.bouclegeneral[i].productionSemence
                ? this.bouclegeneral[i].productionSemence
                : null,
              protectionVegetaux: this.bouclegeneral[i].protectionVegetaux
                ? this.bouclegeneral[i].protectionVegetaux
                : null,
              qtEngraisAzote: this.bouclegeneral[i].qtEngraisAzote
                ? this.bouclegeneral[i].qtEngraisAzote
                : null,
              qteEngraisUtilise: this.bouclegeneral[i].qteEngraisUtilise
                ? this.bouclegeneral[i].qteEngraisUtilise
                : null,
              sourceSemences: this.bouclegeneral[i].sourceSemences
                ? this.bouclegeneral[i].sourceSemences
                : null,
              sourceEauAgri: this.bouclegeneral[i].sourceEauAgri
                ? this.bouclegeneral[i].sourceEauAgri
                : null,
              systemeCulture: this.bouclegeneral[i].systemeCulture
                ? this.bouclegeneral[i].systemeCulture
                : null,
              systemeDrainage: this.bouclegeneral[i].systemeDrainage
                ? this.bouclegeneral[i].systemeDrainage
                : null,
              systemeIrrigation: this.bouclegeneral[i].systemeIrrigation
                ? this.bouclegeneral[i].systemeIrrigation
                : null,
              tempsTravail: this.bouclegeneral[i].tempsTravail
                ? this.bouclegeneral[i].tempsTravail
                : null,
              traitementsContreInsectes: this.bouclegeneral[i]
                .traitementsContreInsectes
                ? this.bouclegeneral[i].traitementsContreInsectes
                : null,
              traitementsContreRavageurs: this.bouclegeneral[i]
                .traitementsContreRavageurs
                ? this.bouclegeneral[i].traitementsContreRavageurs
                : null,
              traitementsMaladies: this.bouclegeneral[i].traitementsMaladies
                ? this.bouclegeneral[i].traitementsMaladies
                : null,
              traitementsPhytosanitaire: this.bouclegeneral[i]
                .traitementsPhytosanitaire
                ? this.bouclegeneral[i].traitementsPhytosanitaire
                : null,
              /* typeEngrais: this.bouclegeneral[i].typeEngrais
                ? this.bouclegeneral[i].typeEngrais
                : null, */
              typeSemences: this.bouclegeneral[i].typeSemences
                ? this.bouclegeneral[i].typeSemences
                : null,
              "uniteEngraisAzote": this.bouclegeneral[i].uniteEngraisAzote,
              "uniteEngraisUtilise": this.bouclegeneral[i].uniteEngraisUtilise
              
            }
          : null
      );
    }
    if (
      data.agePiedsProductif != null ||
      data.agreeOuNon != null ||
      data.anneeProductif != null ||
      data.codePoinconVanille != null ||
      data.collecteurOuNon != null ||
      data.cultureRenteProduisez != null ||
      data.dateAchat != null ||
      data.debutPeriodeCultureRente != null ||
      data.debutPeriodePlantation != null ||
      data.debutPeriodeRecolte != null ||
      data.detaillantOuNon != null ||
      data.ecartementPiedCulture != null ||
      data.exportateurFinale != null ||
      data.finPeriodeCultureRente != null ||
      data.finPeriodePlantation != null ||
      data.finPeriodeRecolte != null ||
      data.gpsRente != null ||
      data.marcher != null ||
      data.lieuExploitation != null ||
      data.codePoinconVanille != null ||
      data.nbreExploitation != null ||
      data.nbrePiedsProductif != null ||
      data.nbrePiedsTotal != null ||
      data.nomCollecteurAcheteur != null ||
      data.nomDetaillant != null ||
      data.observation != null ||
      data.possedeExploitation != null ||
      data.pourcentageQteVendue != null ||
      data.prixAchat != null ||
      data.prixVenteNature != null ||
      data.prixVentePrepare != null ||
      data.prixVenteEuros != null ||
      data.cultureRenteProduisez != null ||
      data.qteVendueNature != null ||
      data.qteVenduePrepare != null ||
      data.refAgreement != null ||
      data.referenceContrat != null ||
      data.surfaceApproximative != null ||
      data.surfaceApproximativeTotal != null ||
      data.totalProduction != null ||
      data.variete != null
    ) {
      this.rente = [
        {
          agePiedsProductif: data.agePiedsProductif
            ? data.agePiedsProductif
            : null,
          agreeOuNon: data.agreeOuNon ? data.agreeOuNon : null,
          anneeProductif: data.anneeProductif ? data.anneeProductif : null,
          codePoinconVanille: data.codePoinconVanille
            ? data.codePoinconVanille
            : null,
          collecteurOuNon: data.collecteurOuNon ? data.collecteurOuNon : null,
          cultureRenteProduisez: data.cultureRenteProduisez
            ? data.cultureRenteProduisez
            : null,
          dateAchat: data.dateAchat ? data.dateAchat : null,
          debutPeriodeCultureRente: data.debutPeriodeCultureRente
            ? data.debutPeriodeCultureRente
            : null,
          debutPeriodePlantation: data.debutPeriodePlantation
            ? data.debutPeriodePlantation
            : null,
          debutPeriodeRecolte: data.debutPeriodeRecolte
            ? data.debutPeriodeRecolte
            : null,
          detaillantOuNon: data.detaillantOuNon ? data.detaillantOuNon : null,
          ecartementPiedCulture: data.ecartementPiedCulture
            ? data.ecartementPiedCulture
            : null,
          exportateurFinale: data.exportateurFinale
            ? data.exportateurFinale
            : null,
          finPeriodeCultureRente: data.finPeriodeCultureRente
            ? data.finPeriodeCultureRente
            : null,
          finPeriodePlantation: data.finPeriodePlantation
            ? data.finPeriodePlantation
            : null,
          finPeriodeRecolte: data.finPeriodeRecolte
            ? data.finPeriodeRecolte
            : null,
          gpsRente: data.gpsRente ? data.gpsRente : null,
          lieuEchange: data.marcher ? data.marcher : null,
          lieuExploitation: data.lieuExploitation
            ? data.lieuExploitation
            : null,
          marquePoincon: data.codePoinconVanille
            ? data.codePoinconVanille
            : null,
          nbreExploitation: data.nbreExploitation
            ? data.nbreExploitation
            : null,
          nbrePiedsProductif: data.nbrePiedsProductif
            ? data.nbrePiedsProductif
            : null,
          nbrePiedsTotal: data.nbrePiedsTotal ? data.nbrePiedsTotal : null,
          nomCollecteurAcheteur: data.nomCollecteurAcheteur
            ? data.nomCollecteurAcheteur
            : null,
          nomDetaillant: data.nomDetaillant ? data.nomDetaillant : null,
          observation: data.observation ? data.observation : null,
          possedeExploitation: data.possedeExploitation
            ? data.possedeExploitation
            : null,
          pourcentageQteVendue: data.pourcentageQteVendue
            ? data.pourcentageQteVendue
            : null,
          prixAchat: data.prixAchat ? data.prixAchat : null,
          prixVenteNature: data.prixVenteNature ? data.prixVenteNature : null,
          prixVentePrepare: data.prixVentePrepare
            ? data.prixVentePrepare
            : null,
          prixVenteEuros: data.prixVenteEuros ? data.prixVenteEuros : null,
          /* prodCultureRente: data.cultureRenteProduisez
                ? data.cultureRenteProduisez
                : null, */
          qteVendueNature: data.qteVendueNature ? data.qteVendueNature : null,
          qteVenduePrepare: data.qteVenduePrepare
            ? data.qteVenduePrepare
            : null,
          refAgreement: data.refAgreement ? data.refAgreement : null,
          referenceContrat: data.referenceContrat
            ? data.referenceContrat
            : null,
          surfaceApproximative: data.surfaceApproximative
            ? data.surfaceApproximative
            : null,
          surfaceApproximativeTotal: data.surfaceApproximativeTotal
            ? data.surfaceApproximativeTotal
            : null,
          totalProduction: data.totalProduction ? data.totalProduction : null,
        "uniteAgePiedProductif": this.uniteCulturerente.get("uniteAgePiedProductif").value,
        "uniteVendueNature": this.uniteCulturerente.get("uniteVendueNature").value,
        "uniteVenduePrepare": this.uniteCulturerente.get("uniteVenduePrepare").value,
          variete: data.variete ? data.variete : null,
        },
      ];
    }

    for (let i = 0; i < this.bouclerente.length; i++) {
      this.rente.push(
        this.bouclerente[i].agePiedsProductif ||
          this.bouclerente[i].agreeOuNon ||
          this.bouclerente[i].anneeProductif ||
          this.bouclerente[i].codePoinconVanille ||
          this.bouclerente[i].collecteurOuNon ||
          this.bouclerente[i].cultureRenteProduisez ||
          this.bouclerente[i].dateAchat ||
          this.bouclerente[i].debutPeriodeCultureRente ||
          this.bouclerente[i].debutPeriodePlantation ||
          this.bouclerente[i].debutPeriodeRecolte ||
          this.bouclerente[i].detaillantOuNon ||
          this.bouclerente[i].ecartementPiedCulture ||
          this.bouclerente[i].exportateurFinale ||
          this.bouclerente[i].finPeriodeCultureRente ||
          this.bouclerente[i].finPeriodePlantation ||
          this.bouclerente[i].finPeriodeRecolte ||
          this.bouclerente[i].gpsRente ||
          this.bouclerente[i].marcher ||
          this.bouclerente[i].lieuExploitation ||
          this.bouclerente[i].codePoinconVanille ||
          this.bouclerente[i].nbreExploitation ||
          this.bouclerente[i].nbrePiedsProductif ||
          this.bouclerente[i].nbrePiedsTotal ||
          this.bouclerente[i].nomCollecteurAcheteur ||
          this.bouclerente[i].nomDetaillant ||
          this.bouclerente[i].observation ||
          this.bouclerente[i].possedeExploitation ||
          this.bouclerente[i].pourcentageQteVendue ||
          this.bouclerente[i].prixAchat ||
          this.bouclerente[i].prixVenteNature ||
          this.bouclerente[i].prixVentePrepare ||
          this.bouclerente[i].prixVenteEuros ||
          this.bouclerente[i].cultureRenteProduisez ||
          this.bouclerente[i].qteVendueNature ||
          this.bouclerente[i].qteVenduePrepare ||
          this.bouclerente[i].refAgreement ||
          this.bouclerente[i].referenceContrat ||
          this.bouclerente[i].surfaceApproximative ||
          this.bouclerente[i].surfaceApproximativeTotal ||
          this.bouclerente[i].totalProduction ||
          this.bouclerente[i].variete
          ? {
              agePiedsProductif: this.bouclerente[i].agePiedsProductif
                ? this.bouclerente[i].agePiedsProductif
                : null,
              agreeOuNon: this.bouclerente[i].agreeOuNon
                ? this.bouclerente[i].agreeOuNon
                : null,
              anneeProductif: this.bouclerente[i].anneeProductif
                ? this.bouclerente[i].anneeProductif
                : null,
              codePoinconVanille: this.bouclerente[i].codePoinconVanille
                ? this.bouclerente[i].codePoinconVanille
                : null,
              collecteurOuNon: this.bouclerente[i].collecteurOuNon
                ? this.bouclerente[i].collecteurOuNon
                : null,
              cultureRenteProduisez: this.bouclerente[i].cultureRenteProduisez
                ? this.bouclerente[i].cultureRenteProduisez
                : null,
              dateAchat: this.bouclerente[i].dateAchat
                ? this.bouclerente[i].dateAchat
                : null,
              debutPeriodeCultureRente: this.bouclerente[i]
                .debutPeriodeCultureRente
                ? this.bouclerente[i].debutPeriodeCultureRente
                : null,
              debutPeriodePlantation: this.bouclerente[i].debutPeriodePlantation
                ? this.bouclerente[i].debutPeriodePlantation
                : null,
              debutPeriodeRecolte: this.bouclerente[i].debutPeriodeRecolte
                ? this.bouclerente[i].debutPeriodeRecolte
                : null,
              detaillantOuNon: this.bouclerente[i].detaillantOuNon
                ? this.bouclerente[i].detaillantOuNon
                : null,
              ecartementPiedCulture: this.bouclerente[i].ecartementPiedCulture
                ? this.bouclerente[i].ecartementPiedCulture
                : null,
              exportateurFinale: this.bouclerente[i].exportateurFinale
                ? this.bouclerente[i].exportateurFinale
                : null,
              finPeriodeCultureRente: this.bouclerente[i].finPeriodeCultureRente
                ? this.bouclerente[i].finPeriodeCultureRente
                : null,
              finPeriodePlantation: this.bouclerente[i].finPeriodePlantation
                ? this.bouclerente[i].finPeriodePlantation
                : null,
              finPeriodeRecolte: this.bouclerente[i].finPeriodeRecolte
                ? this.bouclerente[i].finPeriodeRecolte
                : null,
              gpsRente: this.bouclerente[i].gpsRente
                ? this.bouclerente[i].gpsRente
                : null,
              lieuEchange: this.bouclerente[i].marcher
                ? this.bouclerente[i].marcher
                : null,
              lieuExploitation: this.bouclerente[i].lieuExploitation
                ? this.bouclerente[i].lieuExploitation
                : null,
              marquePoincon: this.bouclerente[i].codePoinconVanille
                ? this.bouclerente[i].codePoinconVanille
                : null,
              nbreExploitation: this.bouclerente[i].nbreExploitation
                ? this.bouclerente[i].nbreExploitation
                : null,
              nbrePiedsProductif: this.bouclerente[i].nbrePiedsProductif
                ? this.bouclerente[i].nbrePiedsProductif
                : null,
              nbrePiedsTotal: this.bouclerente[i].nbrePiedsTotal
                ? this.bouclerente[i].nbrePiedsTotal
                : null,
              nomCollecteurAcheteur: this.bouclerente[i].nomCollecteurAcheteur
                ? this.bouclerente[i].nomCollecteurAcheteur
                : null,
              nomDetaillant: this.bouclerente[i].nomDetaillant
                ? this.bouclerente[i].nomDetaillant
                : null,
              observation: this.bouclerente[i].observation
                ? this.bouclerente[i].observation
                : null,
              possedeExploitation: this.bouclerente[i].possedeExploitation
                ? this.bouclerente[i].possedeExploitation
                : null,
              pourcentageQteVendue: this.bouclerente[i].pourcentageQteVendue
                ? this.bouclerente[i].pourcentageQteVendue
                : null,
              prixAchat: this.bouclerente[i].prixAchat
                ? this.bouclerente[i].prixAchat
                : null,
              prixVenteNature: this.bouclerente[i].prixVenteNature
                ? this.bouclerente[i].prixVenteNature
                : null,
              prixVentePrepare: this.bouclerente[i].prixVentePrepare
                ? this.bouclerente[i].prixVentePrepare
                : null,
              prixVenteEuros: this.bouclerente[i].prixVenteEuros
                ? this.bouclerente[i].prixVenteEuros
                : null,
              /* prodCultureRente: this.bouclerente[i].cultureRenteProduisez
                ? this.bouclerente[i].cultureRenteProduisez
                : null, */
              qteVendueNature: this.bouclerente[i].qteVendueNature
                ? this.bouclerente[i].qteVendueNature
                : null,
              qteVenduePrepare: this.bouclerente[i].qteVenduePrepare
                ? this.bouclerente[i].qteVenduePrepare
                : null,
              refAgreement: this.bouclerente[i].refAgreement
                ? this.bouclerente[i].refAgreement
                : null,
              referenceContrat: this.bouclerente[i].referenceContrat
                ? this.bouclerente[i].referenceContrat
                : null,
              surfaceApproximative: this.bouclerente[i].surfaceApproximative
                ? this.bouclerente[i].surfaceApproximative
                : null,
              surfaceApproximativeTotal: this.bouclerente[i]
                .surfaceApproximativeTotal
                ? this.bouclerente[i].surfaceApproximativeTotal
                : null,
              totalProduction: this.bouclerente[i].totalProduction
                ? this.bouclerente[i].totalProduction
                : null,
              "uniteAgePiedProductif": this.bouclerente[i].uniteAgePiedProductif,
              "uniteVendueNature": this.bouclerente[i].uniteVendueNature,
              "uniteVenduePrepare": this.bouclerente[i].uniteVenduePrepare,  
              variete: this.bouclerente[i].variete
                ? this.bouclerente[i].variete
                : null,
            }
          : null
      );
    }
    if (
      data.cotisationAue != null ||
      data.districtAppartient != null ||
      data.etatPerimetreIrrigue != null ||
      data.frequencePaiement != null ||
      data.membreAue != null ||
      data.montantCotisation != null ||
      data.nomAue != null ||
      data.nomPerimetreIrrigue != null ||
      data.prodPerimetreIrrigue != null
    ) {
      this.Perimetres = [
        {
          cotisationAue: data.cotisationAue ? data.cotisationAue : null,
          districtAppartient: data.districtAppartient
            ? data.districtAppartient
            : null,
          etatPerimetreIrrigue: data.etatPerimetreIrrigue
            ? data.etatPerimetreIrrigue
            : null,
          frequencePaiement: data.frequencePaiement
            ? data.frequencePaiement
            : null,
          membreAue: data.membreAue ? data.membreAue : null,
          montantCotisation: data.montantCotisation
            ? data.montantCotisation
            : null,
          nomAue: data.nomAue ? data.nomAue : null,
          nomPerimetreIrrigue: data.nomPerimetreIrrigue
            ? data.nomPerimetreIrrigue
            : null,
          prodPerimetreIrrigue: data.prodPerimetreIrrigue
            ? data.prodPerimetreIrrigue
            : null,
        },
      ];
    }
    for (let i = 0; i < this.Perimetre.length; i++) {
      this.Perimetres.push(
        this.Perimetre[i].cotisationAue ||
          this.Perimetre[i].districtAppartient ||
          this.Perimetre[i].etatPerimetreIrrigue ||
          this.Perimetre[i].frequencePaiement ||
          this.Perimetre[i].membreAue ||
          this.Perimetre[i].montantCotisation ||
          this.Perimetre[i].nomAue ||
          this.Perimetre[i].nomPerimetreIrrigue ||
          this.Perimetre[i].prodPerimetreIrrigue
          ? {
              cotisationAue: this.Perimetre[i].cotisationAue
                ? this.Perimetre[i].cotisationAue
                : null,
              districtAppartient: this.Perimetre[i].districtAppartient
                ? this.Perimetre[i].districtAppartient
                : null,
              etatPerimetreIrrigue: this.Perimetre[i].etatPerimetreIrrigue
                ? this.Perimetre[i].etatPerimetreIrrigue
                : null,
              frequencePaiement: this.Perimetre[i].frequencePaiement
                ? this.Perimetre[i].frequencePaiement
                : null,
              membreAue: this.Perimetre[i].membreAue
                ? this.Perimetre[i].membreAue
                : null,
              montantCotisation: this.Perimetre[i].montantCotisation
                ? this.Perimetre[i].montantCotisation
                : null,
              nomAue: this.Perimetre[i].nomAue
                ? this.Perimetre[i].nomAue
                : null,
              nomPerimetreIrrigue: this.Perimetre[i].nomPerimetreIrrigue
                ? this.Perimetre[i].nomPerimetreIrrigue
                : null,
              prodPerimetreIrrigue: this.Perimetre[i].prodPerimetreIrrigue
                ? this.Perimetre[i].prodPerimetreIrrigue
                : null,
            }
          : null
      );
    }
    if (
      data.pourcentageAzote != null ||
      data.pourcentageUree != null ||
      data.qteUtilise != null ||
      data.typeEngrais != null
    ) {
      this.ammendement = [
        {
          pourcentageAzote: data.pourcentageAzote? data.pourcentageAzote: null,
          pourcentageUree: data.pourcentageUree ? data.pourcentageUree : null,
          qteUtilise: data.qteUtilise ? data.qteUtilise : null,
          typeEngrais: data.typeEngrais ? data.typeEngrais : null,
          "uniteAmendement": this.UniteAngreMineraux.get('UniteQuantiteAngre').value
        },
      ];
    }

    for (let i = 0; i < this.Amandement.length; i++) {
      this.ammendement.push(
        this.Amandement[i].pourcentageAzote ||
          this.Amandement[i].pourcentageUree ||
          this.Amandement[i].qteUtilise ||
          this.Amandement[i].typeEngrais || this.UniteAngre[i].UniteQuantiteAngre
          ? {
              pourcentageAzote: this.Amandement[i].pourcentageAzote? this.Amandement[i].pourcentageAzote: null,
              pourcentageUree: this.Amandement[i].pourcentageUree? this.Amandement[i].pourcentageUree: null,
              qteUtilise: this.Amandement[i].qteUtilise? this.Amandement[i].qteUtilise: null,
              typeEngrais: this.Amandement[i].typeEngrais? this.Amandement[i].typeEngrais: null,
              "uniteAmendement":this.Amandement[i].UniteQuantiteAngre?this.Amandement[i].UniteQuantiteAngre:null 
            }
          : null
      );
    }

    if (
      data.anneeAcquisitionMotorise != null ||
      data.coproprieteMotorise != null ||
      data.materielMotoriseMotorise != null ||
      data.nbreTotaleMotorise != null ||
      data.organisationPaysanneMotorise != null ||
      data.proprieteExploitationMotorise != null
    ) {
      this.motorisers = [
        {
          anneeAcquisitionMotorise: data.anneeAcquisitionMotorise
            ? data.anneeAcquisitionMotorise
            : null,
          coproprieteMotorise: data.coproprieteMotorise
            ? data.coproprieteMotorise
            : null,
          materielMotoriseMotorise: data.materielMotoriseMotorise
            ? data.materielMotoriseMotorise
            : null,
          nbreTotaleMotorise: data.nbreTotaleMotorise
            ? data.nbreTotaleMotorise
            : null,
          organisationPaysanneMotorise: data.organisationPaysanneMotorise
            ? data.organisationPaysanneMotorise
            : null,
          proprieteExploitationMotorise: data.proprieteExploitationMotorise
            ? data.proprieteExploitationMotorise
            : null,
        },
      ];
    }

    for (let i = 0; i < this.motoriser.length; i++) {
      this.motorisers.push(
        this.motoriser[i].anneeAcquisitionMotorise ||
          this.motoriser[i].coproprieteMotorise ||
          this.motoriser[i].materielMotoriseMotorise ||
          this.motoriser[i].nbreTotaleMotorise ||
          this.motoriser[i].organisationPaysanneMotorise ||
          this.motoriser[i].proprieteExploitationMotorise
          ? {
              anneeAcquisitionMotorise: this.motoriser[i]
                .anneeAcquisitionMotorise
                ? this.motoriser[i].anneeAcquisitionMotorise
                : null,
              coproprieteMotorise: this.motoriser[i].coproprieteMotorise
                ? this.motoriser[i].coproprieteMotorise
                : null,
              materielMotoriseMotorise: this.motoriser[i]
                .materielMotoriseMotorise
                ? this.motoriser[i].materielMotoriseMotorise
                : null,
              nbreTotaleMotorise: this.motoriser[i].nbreTotaleMotorise
                ? this.motoriser[i].nbreTotaleMotorise
                : null,
              organisationPaysanneMotorise: this.motoriser[i]
                .organisationPaysanneMotorise
                ? this.motoriser[i].organisationPaysanneMotorise
                : null,
              proprieteExploitationMotorise: this.motoriser[i]
                .proprieteExploitationMotorise
                ? this.motoriser[i].proprieteExploitationMotorise
                : null,
            }
          : null
      );
    }
    if (
      data.capaciteStockProd ||
      data.localisationStockProd ||
      data.modeSechageStockageStockProd ||
      data.modeStockageStockProd ||
      data.possessionStockProd ||
      data.uniteStockProd
    ) {
      this.stockage = [
        {
          capaciteStockProd: data.capaciteStockProd
            ? data.capaciteStockProd
            : null,
          localisationStockProd: data.localisationStockProd
            ? data.localisationStockProd
            : null,
          modeSechageStockageStockProd: data.modeSechageStockageStockProd
            ? data.modeSechageStockageStockProd
            : null,
          modeStockageStockProd: data.modeStockageStockProd
            ? data.modeStockageStockProd
            : null,
          possessionStockProd: data.possessionStockProd
            ? data.possessionStockProd
            : null,
          uniteStockProd: data.uniteStockProd ? data.uniteStockProd : null,
        },
      ];
    }
    for (let i = 0; i < this.stockages.length; i++) {
      this.stockage.push(
        this.stockages[i].capaciteStockProd ||
          this.stockages[i].localisationStockProd ||
          this.stockages[i].modeSechageStockageStockProd ||
          this.stockages[i].modeStockageStockProd ||
          this.stockages[i].possessionStockProd ||
          this.stockages[i].uniteStockProd
          ? {
              capaciteStockProd: this.stockages[i].capaciteStockProd
                ? this.stockages[i].capaciteStockProd
                : null,
              localisationStockProd: this.stockages[i].localisationStockProd
                ? this.stockages[i].localisationStockProd
                : null,
              modeSechageStockageStockProd: this.stockages[i]
                .modeSechageStockageStockProd
                ? this.stockages[i].modeSechageStockageStockProd
                : null,
              modeStockageStockProd: this.stockages[i].modeStockageStockProd
                ? this.stockages[i].modeStockageStockProd
                : null,
              possessionStockProd: this.stockages[i].possessionStockProd
                ? this.stockages[i].possessionStockProd
                : null,
              uniteStockProd: this.stockages[i].uniteStockProd
                ? this.stockages[i].uniteStockProd
                : null,
            }
          : null
      );
    }

    if (
      data.capaciteAbrisAnimaux != null ||
      data.localisationAbrisAnimaux != null ||
      data.nomAbrisAnimInstallFixe != null ||
      data.possessionAbrisAnimaux != null ||
      data.uniteAbrisAnimaux != null
    ) {
      this.abrie = [
        {
          capaciteAbrisAnimaux: data.capaciteAbrisAnimaux
            ? data.capaciteAbrisAnimaux
            : null,
          localisationAbrisAnimaux: data.localisationAbrisAnimaux
            ? data.localisationAbrisAnimaux
            : null,
          nomAbrisAnimInstallFixe: data.nomAbrisAnimInstallFixe
            ? data.nomAbrisAnimInstallFixe
            : null,
          possessionAbrisAnimaux: data.possessionAbrisAnimaux
            ? data.possessionAbrisAnimaux
            : null,
          uniteAbrisAnimaux: this.UniteAbrisAnimauxx.get("uniteAbrisAnimaux").value,
        },
      ];
    }

    for (let i = 0; i < this.Abrie.length; i++) {
      this.abrie.push(
        this.Abrie[i].capaciteAbrisAnimaux ||
          this.Abrie[i].localisationAbrisAnimaux ||
          this.Abrie[i].nomAbrisAnimInstallFixe ||
          this.Abrie[i].possessionAbrisAnimaux ||
          this.Abrie[i].uniteAbrisAnimaux
          ? {
              capaciteAbrisAnimaux: this.Abrie[i].capaciteAbrisAnimaux
                ? this.Abrie[i].capaciteAbrisAnimaux
                : null,
              localisationAbrisAnimaux: this.Abrie[i].localisationAbrisAnimaux
                ? this.Abrie[i].localisationAbrisAnimaux
                : null,
              nomAbrisAnimInstallFixe: this.Abrie[i].nomAbrisAnimInstallFixe
                ? this.Abrie[i].nomAbrisAnimInstallFixe
                : null,
              possessionAbrisAnimaux: this.Abrie[i].possessionAbrisAnimaux
                ? this.Abrie[i].possessionAbrisAnimaux
                : null,
              uniteAbrisAnimaux: this.Abrie[i].uniteAbrisAnimaux
                ? this.Abrie[i].uniteAbrisAnimaux
                : null,
            }
          : null
      );
    }

    if (
      data.acquisitionVacheLait != null ||
      data.anneeAcquisitionVacheLait != null ||
      data.effectifVacheLait != null ||
      data.fournisseurMatVacheLait != null ||
      data.nomMatVacheLait != null ||
      data.prixAchatVacheLait != null
    ) {
      this.vache = [
        {
          acquisitionVacheLait: data.acquisitionVacheLait
            ? data.acquisitionVacheLait
            : null,
          anneeAcquisitionVacheLait: data.anneeAcquisitionVacheLait
            ? data.anneeAcquisitionVacheLait
            : null,
          effectifVacheLait: data.effectifVacheLait
            ? data.effectifVacheLait
            : null,
          fournisseurMatVacheLait: data.fournisseurMatVacheLait
            ? data.fournisseurMatVacheLait
            : null,
          nomMatVacheLait: data.nomMatVacheLait ? data.nomMatVacheLait : null,
          prixAchatVacheLait: data.prixAchatVacheLait
            ? data.prixAchatVacheLait
            : null,
        },
      ];
    }

    for (let i = 0; i < this.Vaches.length; i++) {
      this.vache.push(
        this.Vaches[i].acquisitionVacheLait ||
          this.Vaches[i].anneeAcquisitionVacheLait ||
          this.Vaches[i].effectifVacheLait ||
          this.Vaches[i].fournisseurMatVacheLait ||
          this.Vaches[i].nomMatVacheLait ||
          this.Vaches[i].prixAchatVacheLait
          ? {
              acquisitionVacheLait: this.Vaches[i].acquisitionVacheLait
                ? this.Vaches[i].acquisitionVacheLait
                : null,
              anneeAcquisitionVacheLait: this.Vaches[i]
                .anneeAcquisitionVacheLait
                ? this.Vaches[i].anneeAcquisitionVacheLait
                : null,
              effectifVacheLait: this.Vaches[i].effectifVacheLait
                ? this.Vaches[i].effectifVacheLait
                : null,
              fournisseurMatVacheLait: this.Vaches[i].fournisseurMatVacheLait
                ? this.Vaches[i].fournisseurMatVacheLait
                : null,
              nomMatVacheLait: this.Vaches[i].nomMatVacheLait
                ? this.Vaches[i].nomMatVacheLait
                : null,
              prixAchatVacheLait: this.Vaches[i].prixAchatVacheLait
                ? this.Vaches[i].prixAchatVacheLait
                : null,
            }
          : null
      );
    }

    if (
      data.acquisitionEnginsPeche != null ||
      data.anneeAcquisitionEnginsPeche != null ||
      data.effectifEnginsPeche != null ||
      data.fourniMaterielEnginsPeche != null ||
      data.prixAchatEnginsPeche != null ||
      data.typeEnginsEnginsPeche != null
    ) {
      this.Enginss = [
        {
          acquisitionEnginsPeche: data.acquisitionEnginsPeche
            ? data.acquisitionEnginsPeche
            : null,
          anneeAcquisitionEnginsPeche: data.anneeAcquisitionEnginsPeche
            ? data.anneeAcquisitionEnginsPeche
            : null,
          effectifEnginsPeche: data.effectifEnginsPeche
            ? data.effectifEnginsPeche
            : null,
          fourniMaterielEnginsPeche: data.fourniMaterielEnginsPeche
            ? data.fourniMaterielEnginsPeche
            : null,
          prixAchatEnginsPeche: data.prixAchatEnginsPeche
            ? data.prixAchatEnginsPeche
            : null,
          typeEnginsEnginsPeche: data.typeEnginsEnginsPeche
            ? data.typeEnginsEnginsPeche
            : null,
        },
      ];
    }

    for (let i = 0; i < this.Engins.length; i++) {
      this.Enginss.push(
        this.Engins[i].acquisitionEnginsPeche ||
          this.Engins[i].anneeAcquisitionEnginsPeche ||
          this.Engins[i].effectifEnginsPeche ||
          this.Engins[i].fourniMaterielEnginsPeche ||
          this.Engins[i].prixAchatEnginsPeche ||
          this.Engins[i].typeEnginsEnginsPeche
          ? {
              acquisitionEnginsPeche: this.Engins[i].acquisitionEnginsPeche
                ? this.Engins[i].acquisitionEnginsPeche
                : null,
              anneeAcquisitionEnginsPeche: this.Engins[i]
                .anneeAcquisitionEnginsPeche
                ? this.Engins[i].anneeAcquisitionEnginsPeche
                : null,
              effectifEnginsPeche: this.Engins[i].effectifEnginsPeche
                ? this.Engins[i].effectifEnginsPeche
                : null,
              fourniMaterielEnginsPeche: this.Engins[i]
                .fourniMaterielEnginsPeche
                ? this.Engins[i].fourniMaterielEnginsPeche
                : null,
              prixAchatEnginsPeche: this.Engins[i].prixAchatEnginsPeche
                ? this.Engins[i].prixAchatEnginsPeche
                : null,
              typeEnginsEnginsPeche: this.Engins[i].typeEnginsEnginsPeche
                ? this.Engins[i].typeEnginsEnginsPeche
                : null,
            }
          : null
      );
    }

    if (
      data.acquisitionTractionAnimal != null ||
      data.anneeAcquisitionTractionAnimal != null ||
      data.effectifTractionAnimal != null ||
      data.fournisseurMatTractionAnimal != null ||
      data.prixAchatTractionAnimal != null ||
      data.typeMateriels != null
    ) {
      this.matmanu = [
        {
          acquisitionTractionAnimal: data.acquisitionTractionAnimal
            ? data.acquisitionTractionAnimal
            : null,
          anneeAcquisitionTractionAnimal: data.anneeAcquisitionTractionAnimal
            ? data.anneeAcquisitionTractionAnimal
            : null,
          effectifTractionAnimal: data.effectifTractionAnimal
            ? data.effectifTractionAnimal
            : null,
          fournisseurMatTractionAnimal: data.fournisseurMatTractionAnimal
            ? data.fournisseurMatTractionAnimal
            : null,
          prixAchatTractionAnimal: data.prixAchatTractionAnimal
            ? data.prixAchatTractionAnimal
            : null,
          typeMateriels: data.typeMateriels ? data.typeMateriels : null,
        },
      ];
    }

    for (let i = 0; i < this.MatMan.length; i++) {
      this.matmanu.push(
        this.MatMan[i].acquisitionTractionAnimal ||
          this.MatMan[i].anneeAcquisitionTractionAnimal ||
          this.MatMan[i].effectifTractionAnimal ||
          this.MatMan[i].fournisseurMatTractionAnimal ||
          this.MatMan[i].prixAchatTractionAnimal ||
          this.MatMan[i].typeMateriels
          ? {
              acquisitionTractionAnimal: this.MatMan[i]
                .acquisitionTractionAnimal
                ? this.MatMan[i].acquisitionTractionAnimal
                : null,
              anneeAcquisitionTractionAnimal: this.MatMan[i]
                .anneeAcquisitionTractionAnimal
                ? this.MatMan[i].anneeAcquisitionTractionAnimal
                : null,
              effectifTractionAnimal: this.MatMan[i].effectifTractionAnimal
                ? this.MatMan[i].effectifTractionAnimal
                : null,
              fournisseurMatTractionAnimal: this.MatMan[i]
                .fournisseurMatTractionAnimal
                ? this.MatMan[i].fournisseurMatTractionAnimal
                : null,
              prixAchatTractionAnimal: this.MatMan[i].prixAchatTractionAnimal
                ? this.MatMan[i].prixAchatTractionAnimal
                : null,
              typeMateriels: this.MatMan[i].typeMateriels
                ? this.MatMan[i].typeMateriels
                : null,
            }
          : null
      );
    }

    if (
      data.autrePerturbationSuperficie != null ||
      data.pourcentageIncendie != null ||
      data.superficieFeuxFriche != null ||
      data.superficieIncendie != null
    ) {
      this.Biomasse = [
        {
          autrePerturbationSuperficie: data.autrePerturbationSuperficie
            ? data.autrePerturbationSuperficie
            : null,
          pourcentageIncendie: data.pourcentageIncendie
            ? data.pourcentageIncendie
            : null,
          superficieFeuxFriche: data.superficieFeuxFriche
            ? data.superficieFeuxFriche
            : null,
          superficieIncendie: data.superficieIncendie
            ? data.superficieIncendie
            : null,
        },
      ];
    }

    for (let i = 0; i < this.boucleBiomasses.length; i++) {
      this.Biomasse.push(
        this.boucleBiomasses[i].autrePerturbationSuperficie ||
          this.boucleBiomasses[i].pourcentageIncendie ||
          this.boucleBiomasses[i].superficieFeuxFriche ||
          this.boucleBiomasses[i].superficieIncendie
          ? {
              autrePerturbationSuperficie: this.boucleBiomasses[i]
                .autrePerturbationSuperficie
                ? this.boucleBiomasses[i].autrePerturbationSuperficie
                : null,
              pourcentageIncendie: this.boucleBiomasses[i].pourcentageIncendie
                ? this.boucleBiomasses[i].pourcentageIncendie
                : null,
              superficieFeuxFriche: this.boucleBiomasses[i].superficieFeuxFriche
                ? this.boucleBiomasses[i].superficieFeuxFriche
                : null,
              superficieIncendie: this.boucleBiomasses[i].superficieIncendie
                ? this.boucleBiomasses[i].superficieIncendie
                : null,
            }
          : null
      );
    }

    if (
      data.amendementOrganique != null ||
      data.durreCulture != null ||
      data.regimeHydriqueAvant != null ||
      data.regimeHydriquePendant != null ||
      data.superficie != null
    ) {
      this.SR = [
        {
          amendementOrganique: data.amendementOrganique
            ? data.amendementOrganique
            : null,
          durreCulture: data.durreCulture ? data.durreCulture : null,
          regimeHydriqueAvant: data.regimeHydriqueAvant
            ? data.regimeHydriqueAvant
            : null,
          regimeHydriquePendant: data.regimeHydriquePendant
            ? data.regimeHydriquePendant
            : null,
          superficie: data.superficie ? data.superficie : null,
        },
      ];
    }

    for (let i = 0; i < this.boucleSR.length; i++) {
      this.SR.push(
        this.boucleSR[i].amendementOrganique ||
          this.boucleSR[i].durreCulture ||
          this.boucleSR[i].regimeHydriqueAvant ||
          this.boucleSR[i].regimeHydriquePendant ||
          this.boucleSR[i].superficie
          ? {
              amendementOrganique: this.boucleSR[i].amendementOrganique
                ? this.boucleSR[i].amendementOrganique
                : null,
              durreCulture: this.boucleSR[i].durreCulture
                ? this.boucleSR[i].durreCulture
                : null,
              regimeHydriqueAvant: this.boucleSR[i].regimeHydriqueAvant
                ? this.boucleSR[i].regimeHydriqueAvant
                : null,
              regimeHydriquePendant: this.boucleSR[i].regimeHydriquePendant
                ? this.boucleSR[i].regimeHydriquePendant
                : null,
              superficie: this.boucleSR[i].superficie
                ? this.boucleSR[i].superficie
                : null,
            }
          : null
      );
    }

    if (
      data.agePlantationImmature != null ||
      data.agePlantationMature != null ||
      data.pourcentagePlantation != null ||
      data.gestionClasseMature != null ||
      data.pourcentagePlantationImmature != null ||
      data.pourcentagePlantationMature != null
    ) {
      this.distribution = [
        {
          agePlantationImmature: data.agePlantationImmature
            ? data.agePlantationImmature
            : null,
          agePlantationMature: data.agePlantationMature
            ? data.agePlantationMature
            : null,
          pourcentagePlantation: data.pourcentagePlantation
            ? data.pourcentagePlantation
            : null,
            gestionClasseMature: data.gestionClasseMature
            ? data.gestionClasseMature
            : null,
          pourcentagePlantationImmature: data.pourcentagePlantationImmature
            ? data.pourcentagePlantationImmature
            : null,
          pourcentagePlantationMature: data.pourcentagePlantationMature
            ? data.pourcentagePlantationMature
            : null,
        },
      ];
    }

    for (let i = 0; i < this.boucleDistrib.length; i++) {
      this.distribution.push(
        this.boucleDistrib[i].agePlantationImmature ||
          this.boucleDistrib[i].agePlantationMature ||
          this.boucleDistrib[i].gestionClasseMature ||
          this.boucleDistrib[i].pourcentagePlantation ||
          this.boucleDistrib[i].pourcentagePlantationImmature ||
          this.boucleDistrib[i].pourcentagePlantationMature
          ? {
              agePlantationImmature: this.boucleDistrib[i].agePlantationImmature
                ? this.boucleDistrib[i].agePlantationImmature
                : null,
              agePlantationMature: this.boucleDistrib[i].agePlantationMature
                ? this.boucleDistrib[i].agePlantationMature
                : null,
              pourcentagePlantation: this.boucleDistrib[i].pourcentagePlantation
                ? this.boucleDistrib[i].pourcentagePlantation
                : null,
                gestionClasseMature: this.boucleDistrib[i].gestionClasseMature
                ? this.boucleDistrib[i].gestionClasseMature
                : null,
              pourcentagePlantationImmature: this.boucleDistrib[i]
                .pourcentagePlantationImmature
                ? this.boucleDistrib[i].pourcentagePlantationImmature
                : null,
              pourcentagePlantationMature: this.boucleDistrib[i]
                .pourcentagePlantationMature
                ? this.boucleDistrib[i].pourcentagePlantationMature
                : null,
            }
          : null
      );
    }
    if (data.gestionTerres != null || data.superficiePerennes != null) {
      this.perrene = [
        {
          gestionTerres: data.gestionTerres ? data.gestionTerres : null,
          superficiePerennes: data.superficiePerennes
            ? data.superficiePerennes
            : null,
        },
      ];
    }
    for (let i = 0; i < this.bouclePerrene.length; i++) {
      this.perrene.push(
        this.bouclePerrene[i].gestionTerres || this.bouclePerrene[i]
          ? {
              gestionTerres: this.bouclePerrene[i].gestionTerres
                ? this.bouclePerrene[i].gestionTerres
                : null,
              superficiePerennes: this.bouclePerrene[i].superficiePerennes
                ? this.bouclePerrene[i].superficiePerennes
                : null,
            }
          : null
      );
    }

    if (
      data.superficieTerreCultive != null ||
      data.superficieTerreCultiveAns != null ||
      data.systemeGestion != null
    ) {
      this.terreCultives = [
        {
          superficieTerreCultive: data.superficieTerreCultive
            ? data.superficieTerreCultive
            : null,
          superficieTerreCultiveAns: data.superficieTerreCultiveAns
            ? data.superficieTerreCultiveAns
            : null,
          systemeGestion: data.systemeGestion ? data.systemeGestion : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleTerreCultives.length; i++) {
      this.terreCultives.push(
        this.boucleTerreCultives[i].superficieTerreCultive ||
          this.boucleTerreCultives[i].superficieTerreCultiveAns ||
          this.boucleTerreCultives[i].systemeGestion
          ? {
              superficieTerreCultive: this.boucleTerreCultives[i]
                .superficieTerreCultive
                ? this.boucleTerreCultives[i].superficieTerreCultive
                : null,
              superficieTerreCultiveAns: this.boucleTerreCultives[i]
                .superficieTerreCultiveAns
                ? this.boucleTerreCultives[i].superficieTerreCultiveAns
                : null,
              systemeGestion: this.boucleTerreCultives[i].systemeGestion
                ? this.boucleTerreCultives[i].systemeGestion
                : null,
            }
          : null
      );
    }
    if (data.superficieTypeSol != null || data.typeSol != null) {
      this.typeSol = [
        {
          superficieTypeSol: data.superficieTypeSol
            ? data.superficieTypeSol
            : null,
          typeSol: data.typeSol ? data.typeSol : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleTpesol.length; i++) {
      this.typeSol.push(
        this.boucleTpesol[i].superficieTypeSol || this.boucleTpesol[i].typSol
          ? {
              superficieTypeSol: this.boucleTpesol[i].superficieTypeSol
                ? this.boucleTpesol[i].superficieTypeSol
                : null,
              typeSol: this.boucleTpesol[i].typSol
                ? this.boucleTpesol[i].typeSol
                : null,
            }
          : null
      );
    }
    if (
      data.superficieAutreTerre != null ||
      data.superficieForestiere != null ||
      data.superficiePrairies != null ||
      data.superficieRestant != null ||
      data.superficieTransAutres != null ||
      data.superficieTransZoneHabitation != null ||
      data.superficieTransZoneHumide != null ||
      data.superficieTransfPrairies != null ||
      data.superficieZoneHumide != null ||
      data.superficieZonehabitation != null
    ) {
      this.categorie = [
        {
          superficieAutreTerre: data.superficieAutreTerre
            ? data.superficieAutreTerre
            : null,
          superficieForestiere: data.superficieForestiere
            ? data.superficieForestiere
            : null,
          superficiePrairies: data.superficiePrairies
            ? data.superficiePrairies
            : null,
          superficieRestant: data.superficieRestant
            ? data.superficieRestant
            : null,
          superficieTransAutres: data.superficieTransAutres
            ? data.superficieTransAutres
            : null,
          superficieTransZoneHabitation: data.superficieTransZoneHabitation
            ? data.superficieTransZoneHabitation
            : null,
          superficieTransZoneHumide: data.superficieTransZoneHumide
            ? data.superficieTransZoneHumide
            : null,
          superficieTransfPrairies: data.superficieTransfPrairies
            ? data.superficieTransfPrairies
            : null,
          superficieZoneHumide: data.superficieZoneHumide
            ? data.superficieZoneHumide
            : null,
          superficieZonehabitation: data.superficieZonehabitation
            ? data.superficieZonehabitation
            : null,
        },
      ];
    }
    for (let i = 0; i < this.bouclecategories.length; i++) {
      this.categorie.push(
        this.bouclecategories[i].superficieAutreTerre ||
          this.bouclecategories[i].superficieForestiere ||
          this.bouclecategories[i].superficiePrairies ||
          this.bouclecategories[i].superficieRestant ||
          this.bouclecategories[i].superficieTransAutres ||
          this.bouclecategories[i].superficieTransZoneHabitation ||
          this.bouclecategories[i].superficieTransZoneHumide ||
          this.bouclecategories[i].superficieTransfPrairies ||
          this.bouclecategories[i].superficieZoneHumide ||
          this.bouclecategories[i].superficieZonehabitation
          ? {
              superficieAutreTerre: this.bouclecategories[i]
                .superficieAutreTerre
                ? this.bouclecategories[i].superficieAutreTerre
                : null,
              superficieForestiere: this.bouclecategories[i]
                .superficieForestiere
                ? this.bouclecategories[i].superficieForestiere
                : null,
              superficiePrairies: this.bouclecategories[i].superficiePrairies
                ? this.bouclecategories[i].superficiePrairies
                : null,
              superficieRestant: this.bouclecategories[i].superficieRestant
                ? this.bouclecategories[i].superficieRestant
                : null,
              superficieTransAutres: this.bouclecategories[i]
                .superficieTransAutres
                ? this.bouclecategories[i].superficieTransAutres
                : null,
              superficieTransZoneHabitation: this.bouclecategories[i]
                .superficieTransZoneHabitation
                ? this.bouclecategories[i].superficieTransZoneHabitation
                : null,
              superficieTransZoneHumide: this.bouclecategories[i]
                .superficieTransZoneHumide
                ? this.bouclecategories[i].superficieTransZoneHumide
                : null,
              superficieTransfPrairies: this.bouclecategories[i]
                .superficieTransfPrairies
                ? this.bouclecategories[i].superficieTransfPrairies
                : null,
              superficieZoneHumide: this.bouclecategories[i]
                .superficieZoneHumide
                ? this.bouclecategories[i].superficieZoneHumide
                : null,
              superficieZonehabitation: this.bouclecategories[i]
                .superficieZonehabitation
                ? this.bouclecategories[i].superficieZonehabitation
                : null,
            }
          : null
      );
    }

    if (
      data.durreeDeplacement != null ||
      data.emplacement != null ||
      data.gpsParcelles != null ||
      data.modeFaireValoir != null ||
      data.nomCultureParcelles != null ||
      data.numParcelle != null ||
      data.personneResponsable != null ||
      data.statutFoncierParcelle != null ||
      data.superficieParcelle != null ||
      data.typeCultures != null
    ) {
      this.parcelle = [
        {
          durreeDeplacement: data.durreeDeplacement
            ? data.durreeDeplacement
            : null,
          emplacement: data.emplacement ? data.emplacement : null,
          gpsParcelles: data.gpsParcelles ? data.gpsParcelles : null,
          modeFaireValoir: data.modeFaireValoir ? data.modeFaireValoir : null,
          nomCultureParcelles: data.nomCultureParcelles
            ? data.nomCultureParcelles
            : null,
          numParcelle: data.numParcelle ? data.numParcelle : null,
          personneResponsable: data.personneResponsable
            ? data.personneResponsable
            : null,
          statutFoncierParcelle: data.statutFoncierParcelle
            ? data.statutFoncierParcelle
            : null,
          superficieParcelle: data.superficieParcelle
            ? data.superficieParcelle
            : null,
          typeCultures: data.typeCultures ? data.typeCultures : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleParcell.length; i++) {
      this.parcelle.push(
        this.boucleParcell[i].durreeDeplacement ||
          this.boucleParcell[i].emplacement ||
          this.boucleParcell[i].gpsParcelles ||
          this.boucleParcell[i].modeFaireValoir ||
          this.boucleParcell[i].nomCultureParcelles ||
          this.boucleParcell[i].numParcelle ||
          this.boucleParcell[i].personneResponsable ||
          this.boucleParcell[i].statutFoncierParcelle ||
          this.boucleParcell[i].superficieParcelle ||
          this.boucleParcell[i].typeCultures
          ? {
              durreeDeplacement: this.boucleParcell[i].durreeDeplacement
                ? this.boucleParcell[i].durreeDeplacement
                : null,
              emplacement: this.boucleParcell[i].emplacement
                ? this.boucleParcell[i].emplacement
                : null,
              gpsParcelles: this.boucleParcell[i].gpsParcelles
                ? this.boucleParcell[i].gpsParcelles
                : null,
              modeFaireValoir: this.boucleParcell[i].modeFaireValoir
                ? this.boucleParcell[i].modeFaireValoir
                : null,
              nomCultureParcelles: this.boucleParcell[i].nomCultureParcelles
                ? this.boucleParcell[i].nomCultureParcelles
                : null,
              numParcelle: this.boucleParcell[i].numParcelle
                ? this.boucleParcell[i].numParcelle
                : null,
              personneResponsable: this.boucleParcell[i].personneResponsable
                ? this.boucleParcell[i].personneResponsable
                : null,
              statutFoncierParcelle: this.boucleParcell[i].statutFoncierParcelle
                ? this.boucleParcell[i].statutFoncierParcelle
                : null,
              superficieParcelle: this.boucleParcell[i].superficieParcelle
                ? this.boucleParcell[i].superficieParcelle
                : null,
              typeCultures: this.boucleParcell[i].typeCultures
                ? this.boucleParcell[i].typeCultures
                : null,
            }
          : null
      );
    }
    if (
      data.pourcentageResidusPaturage != null ||
      data.pourcentageTotaleRecolte != null ||
      data.qteProductionFraiche != null ||
      data.residusBrulePourcentage != null ||
      data.residusConservePourcentage != null
    ) {
      this.occupatio = [
        {
          pourcentageResidusPaturage: data.pourcentageResidusPaturage
            ? data.pourcentageResidusPaturage
            : null,
          pourcentageTotaleRecolte: data.pourcentageTotaleRecolte
            ? data.pourcentageTotaleRecolte
            : null,
          qteProductionFraiche: data.qteProductionFraiche
            ? data.qteProductionFraiche
            : null,
          residusBrulePourcentage: data.residusBrulePourcentage
            ? data.residusBrulePourcentage
            : null,
          residusConservePourcentage: data.residusConservePourcentage
            ? data.residusConservePourcentage
            : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleOccupations.length; i++) {
      this.occupatio.push(
        this.boucleOccupations[i].pourcentageResidusPaturage ||
          this.boucleOccupations[i].pourcentageTotaleRecolte ||
          this.boucleOccupations[i].qteProductionFraiche ||
          this.boucleOccupations[i].residusBrulePourcentage ||
          this.boucleOccupations[i].residusConservePourcentage
          ? {
              pourcentageResidusPaturage: this.boucleOccupations[i]
                .pourcentageResidusPaturage
                ? this.boucleOccupations[i].pourcentageResidusPaturage
                : null,
              pourcentageTotaleRecolte: this.boucleOccupations[i]
                .pourcentageTotaleRecolte
                ? this.boucleOccupations[i].pourcentageTotaleRecolte
                : null,
              qteProductionFraiche: this.boucleOccupations[i]
                .qteProductionFraiche
                ? this.boucleOccupations[i].qteProductionFraiche
                : null,
              residusBrulePourcentage: this.boucleOccupations[i]
                .residusBrulePourcentage
                ? this.boucleOccupations[i].residusBrulePourcentage
                : null,
              residusConservePourcentage: this.boucleOccupations[i]
                .residusConservePourcentage
                ? this.boucleOccupations[i].residusConservePourcentage
                : null,
            }
          : null
      );
    }
    if (
      data.cultiveAutre ||
      data.cultiveMeme ||
      data.nbreParcelles ||
      data.nbreParcellesEtre ||
      data.nbreParcellesNon ||
      data.principaleRaison ||
      data.terrainsNonExploitees ||
      data.totalParcelles
    ) {
      this.possMode = [
        {
          cultiveAutre: data.cultiveAutre ? data.cultiveAutre : null,
          cultiveMeme: data.cultiveMeme ? data.cultiveMeme : null,
          nbreParcelles: data.nbreParcelles ? data.nbreParcelles : null,
          nbreParcellesEtre: data.nbreParcellesEtre
            ? data.nbreParcellesEtre
            : null,
          nbreParcellesNon: data.nbreParcellesNon
            ? data.nbreParcellesNon
            : null,
          principaleRaison: data.principaleRaison
            ? data.principaleRaison
            : null,
          terrainsNonExploitees: data.terrainsNonExploitees
            ? data.terrainsNonExploitees
            : null,
          totalParcelles: data.totalParcelles ? data.totalParcelles : null,
        },
      ];
    }
    for (let i = 0; i < this.bouclePosMod.length; i++) {
      this.possMode.push(
        this.bouclePosMod[i].cultiveAutre ||
          this.bouclePosMod[i].cultiveMeme ||
          this.bouclePosMod[i].nbreParcelles ||
          this.bouclePosMod[i].nbreParcellesEtre ||
          this.bouclePosMod[i].nbreParcellesNon ||
          this.bouclePosMod[i].principaleRaison ||
          this.bouclePosMod[i].terrainsNonExploitees ||
          this.bouclePosMod[i].totalParcelles
          ? {
              cultiveAutre: this.bouclePosMod[i].cultiveAutre
                ? this.bouclePosMod[i].cultiveAutre
                : null,
              cultiveMeme: this.bouclePosMod[i].cultiveMeme
                ? this.bouclePosMod[i].cultiveMeme
                : null,
              nbreParcelles: this.bouclePosMod[i].nbreParcelles
                ? this.bouclePosMod[i].nbreParcelles
                : null,
              nbreParcellesEtre: this.bouclePosMod[i].nbreParcellesEtre
                ? this.bouclePosMod[i].nbreParcellesEtre
                : null,
              nbreParcellesNon: this.bouclePosMod[i].nbreParcellesNon
                ? this.bouclePosMod[i].nbreParcellesNon
                : null,
              principaleRaison: this.bouclePosMod[i].principaleRaison
                ? this.bouclePosMod[i].principaleRaison
                : null,
              terrainsNonExploitees: this.bouclePosMod[i].terrainsNonExploitees
                ? this.bouclePosMod[i].terrainsNonExploitees
                : null,
              totalParcelles: this.bouclePosMod[i].totalParcelles
                ? this.bouclePosMod[i].totalParcelles
                : null,
            }
          : null
      );
    }
    if (
      data.futureAssistanceSosiale ||
      data.nomAgence ||
      data.typeAssistanceSociale
    ) {
      this.AssistanceSociale = [
        {
          futureAssistanceSosiale: data.futureAssistanceSosiale
            ? data.futureAssistanceSosiale
            : null,
          nomAgence: data.nomAgence ? data.nomAgence : null,
          typeAssistanceSociale: data.typeAssistanceSociale
            ? data.typeAssistanceSociale
            : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleASs.length; i++) {
      this.AssistanceSociale.push(
        this.boucleASs[i].futureAssistanceSosiale ||
          this.boucleASs[i].nomAgence ||
          this.boucleASs[i].typeAssistanceSociale
          ? {
              futureAssistanceSosiale: this.boucleASs[i].futureAssistanceSosiale
                ? this.boucleASs[i].futureAssistanceSosiale
                : null,
              nomAgence: this.boucleASs[i].nomAgence
                ? this.boucleASs[i].nomAgence
                : null,
              typeAssistanceSociale: this.boucleASs[i].typeAssistanceSociale
                ? this.boucleASs[i].typeAssistanceSociale
                : null,
            }
          : null
      );
    }
    if (
      data.debutPeriodeSoudure ||
      data.finPeriodeSoudure ||
      data.repasParMois ||
      data.repasParPeriode ||
      data.nutriAlimentation ||
      data.alimentationBase ||
      data.alimentAccess ||
      data.beneficiaireAide ||
      data.menageProbleme ||
      data.alimentaireAide
    ) {
      this.nutrition = [
        {
          debutPeriodeSoudure: data.debutPeriodeSoudure
            ? data.debutPeriodeSoudure
            : null,
          finPeriodeSoudure: data.finPeriodeSoudure
            ? data.finPeriodeSoudure
            : null,
          beneficiaireAide: data.beneficiaireAide
            ? data.beneficiaireAide
            : null,
          repasParMois: data.repasParMois ? data.repasParMois : null,
          repasParPeriode: data.repasParPeriode ? data.repasParPeriode : null,
          nutriAlimentation: data.nutriAlimentation
            ? data.nutriAlimentation
            : null,
          alimentationBase: data.alimentationBase
            ? data.alimentationBase
            : null,
          alimentAccess: data.alimentAccess ? data.alimentAccess : null,
          menageProbleme: data.menageProbleme ? data.menageProbleme : null,
          alimentaireAide: data.alimentaireAide ? data.alimentaireAide : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleNutritions.length; i++) {
      this.nutrition.push(
        this.boucleNutritions[i].debutPeriodeSoudure ||
          this.boucleNutritions[i].finPeriodeSoudure ||
          this.boucleNutritions[i].beneficiaireAide ||
          this.boucleNutritions[i].repasParMois ||
          this.boucleNutritions[i].repasParPeriode ||
          this.boucleNutritions[i].nutriAlimentation ||
          this.boucleNutritions[i].alimentationBase ||
          this.boucleNutritions[i].alimentAccess ||
          this.boucleNutritions[i].menageProbleme ||
          this.boucleNutritions[i].alimentaireAide
          ? {
              debutPeriodeSoudure: this.boucleNutritions[i].debutPeriodeSoudure
                ? this.boucleNutritions[i].debutPeriodeSoudure
                : null,
              finPeriodeSoudure: this.boucleNutritions[i].finPeriodeSoudure
                ? this.boucleNutritions[i].finPeriodeSoudure
                : null,
              beneficiaireAide: this.boucleNutritions[i].beneficiaireAide
                ? this.boucleNutritions[i].beneficiaireAide
                : null,
              repasParMois: this.boucleNutritions[i].repasParMois
                ? this.boucleNutritions[i].repasParMois
                : null,
              repasParPeriode: this.boucleNutritions[i].repasParPeriode
                ? this.boucleNutritions[i].repasParPeriode
                : null,
              nutriAlimentation: this.boucleNutritions[i].nutriAlimentation
                ? this.boucleNutritions[i].nutriAlimentation
                : null,
              alimentationBase: this.boucleNutritions[i].alimentationBase
                ? this.boucleNutritions[i].alimentationBase
                : null,
              alimentAccess: this.boucleNutritions[i].alimentAccess
                ? this.boucleNutritions[i].alimentAccess
                : null,
              menageProbleme: this.boucleNutritions[i].menageProbleme
                ? this.boucleNutritions[i].menageProbleme
                : null,
              alimentaireAide: this.boucleNutritions[i].alimentaireAide
                ? this.boucleNutritions[i].alimentaireAide
                : null,
            }
          : null
      );
    }
    if (
      data.acheminement ||
      data.coutTransport ||
      data.modeTransport ||
      data.moyensTransport ||
      data.moyensTransportUtilise ||
      data.pertePostRecolte ||
      data.unitePertePostRecolte
    ) {
      this.logistique = [
        {
          acheminement: data.acheminement ? data.acheminement : null,
          coutTransport: data.coutTransport ? data.coutTransport : null,
          modeTransport: data.modeTransport ? data.modeTransport : null,
          moyensTransport: data.moyensTransport ? data.moyensTransport : null,
          moyensTransportUtilise: data.moyensTransportUtilise
            ? data.moyensTransportUtilise
            : null,
          pertePostRecolte: data.pertePostRecolte
            ? data.pertePostRecolte
            : null,
          unitePertePostRecolte: this.Unitelogistique.get("unitePertePostRecolte").value,
        },
      ];
    }
    for (let i = 0; i < this.boucleLogistiques.length; i++) {
      this.logistique.push(
        this.boucleLogistiques[i].acheminement ||
          this.boucleLogistiques[i].coutTransport ||
          this.boucleLogistiques[i].modeTransport ||
          this.boucleLogistiques[i].moyensTransport ||
          this.boucleLogistiques[i].moyensTransportUtilise ||
          this.boucleLogistiques[i].pertePostRecolte ||
          this.boucleLogistiques[i].superficieCulturePerenne
          ? {
              acheminement: this.boucleLogistiques[i].acheminement
                ? this.boucleLogistiques[i].acheminement
                : null,
              coutTransport: this.boucleLogistiques[i].coutTransport
                ? this.boucleLogistiques[i].coutTransport
                : null,
              modeTransport: this.boucleLogistiques[i].modeTransport
                ? this.boucleLogistiques[i].modeTransport
                : null,
              moyensTransport: this.boucleLogistiques[i].moyensTransport
                ? this.boucleLogistiques[i].moyensTransport
                : null,
              moyensTransportUtilise: this.boucleLogistiques[i]
                .moyensTransportUtilise
                ? this.boucleLogistiques[i].moyensTransportUtilise
                : null,
              pertePostRecolte: this.boucleLogistiques[i].pertePostRecolte
                ? this.boucleLogistiques[i].pertePostRecolte
                : null,
              unitePertePostRecolte: this.boucleLogistiques[i].unitePertePostRecolte,
            }
          : null
      );
    }
    if (
      data.ecoulement ||
      data.formationTransformation ||
      data.formelle ||
      data.produitsTransformation ||
      data.qteProduitTransformation ||
      data.sousProduit ||
      data.sousProduitsAll ||
      data.uniteTransforme ||
      data.uniteTransformation
    ) {
      this.transformation = [
        {
          ecoulement: data.ecoulement ? data.ecoulement : null,
          formationTransformation: data.formationTransformation
            ? data.formationTransformation
            : null,
          formelle: data.formelle ? data.formelle : null,
          produitsTransformation: data.produitsTransformation
            ? data.produitsTransformation
            : null,
          qteProduitTransformation: data.qteProduitTransformation
            ? data.qteProduitTransformation
            : null,
          sousProduit: data.sousProduit ? data.sousProduit : null,
          sousProduitsAll: data.sousProduitsAll ? data.sousProduitsAll : null,
          uniteTransforme: data.uniteTransforme ? data.uniteTransforme : null,
          uniteTransformation: data.uniteTransformation
            ? data.uniteTransformation
            : null,
            uniteProduitTrans: this.uniteProduitTransssss.get("uniteProduitTrans").value,
        },
      ];
    }
    for (let i = 0; i < this.boucleTransformation.length; i++) {
      this.transformation.push(
        this.boucleTransformation[i].ecoulement ||
          this.boucleTransformation[i].formationTransformation ||
          this.boucleTransformation[i].formelle ||
          this.boucleTransformation[i].produitsTransformation ||
          this.boucleTransformation[i].qteProduitTransformation ||
          this.boucleTransformation[i].sousProduit ||
          this.boucleTransformation[i].sousProduitsAll ||
          this.boucleTransformation[i].uniteTransforme ||
          this.boucleTransformation[i].uniteTransformation
          ? {
              ecoulement: this.boucleTransformation[i].ecoulement
                ? this.boucleTransformation[i].ecoulement
                : null,
              formationTransformation: this.boucleTransformation[i]
                .formationTransformation
                ? this.boucleTransformation[i].formationTransformation
                : null,
              formelle: this.boucleTransformation[i].formelle
                ? this.boucleTransformation[i].formelle
                : null,
              produitsTransformation: this.boucleTransformation[i]
                .produitsTransformation
                ? this.boucleTransformation[i].produitsTransformation
                : null,
              qteProduitTransformation: this.boucleTransformation[i]
                .qteProduitTransformation
                ? this.boucleTransformation[i].qteProduitTransformation
                : null,
              sousProduit: this.boucleTransformation[i].sousProduit
                ? this.boucleTransformation[i].sousProduit
                : null,
              sousProduitsAll: this.boucleTransformation[i].sousProduitsAll
                ? this.boucleTransformation[i].sousProduitsAll
                : null,
              uniteTransforme: this.boucleTransformation[i].uniteTransforme
                ? this.boucleTransformation[i].uniteTransforme
                : null,
              uniteTransformation: this.boucleTransformation[i]
                .uniteTransformation
                ? this.boucleTransformation[i].uniteTransformation
                : null,
                uniteProduitTrans: this.boucleTransformation[i]
                .uniteProduitTrans
                ? this.boucleTransformation[i].uniteProduitTrans
                : null,
                
            }
          : null
      );
    }
    if (
      data.agricoleContrainte ||
      data.aquacultureContrainte ||
      data.elevageContrainte ||
      data.forestiereContrainte
    ) {
      this.contrainte = [
        {
          agricoleContrainte: data.agricoleContrainte
            ? data.agricoleContrainte
            : null,
          aquacultureContrainte: data.aquacultureContrainte
            ? data.aquacultureContrainte
            : null,
          elevageContrainte: data.elevageContrainte
            ? data.elevageContrainte
            : null,
          forestiereContrainte: data.forestiereContrainte
            ? data.forestiereContrainte
            : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleContraint.length; i++) {
      this.contrainte.push(
        this.boucleContraint[i].agricoleContrainte ||
          this.boucleContraint[i].aquacultureContrainte ||
          this.boucleContraint[i].elevageContrainte ||
          this.boucleContraint[i].forestiereContrainte
          ? {
              agricoleContrainte: this.boucleContraint[i].agricoleContrainte
                ? this.boucleContraint[i].agricoleContrainte
                : null,
              aquacultureContrainte: this.boucleContraint[i]
                .aquacultureContrainte
                ? this.boucleContraint[i].aquacultureContrainte
                : null,
              elevageContrainte: this.boucleContraint[i].elevageContrainte
                ? this.boucleContraint[i].elevageContrainte
                : null,
              forestiereContrainte: this.boucleContraint[i].forestiereContrainte
                ? this.boucleContraint[i].forestiereContrainte
                : null,
            }
          : null
      );
    }
    /* if (
      data.produiseMemeIntrant ||
      data.qteAvantDernierRecolte ||
      data.exploitationStockFourni ||
      data.typeSpeculationFouni 
    ) {
      this.Fournisseurss = [
        {

          produiseMemeIntrant: data.produiseMemeIntrant
            ? data.produiseMemeIntrant
            : null,
            qteAvantDernierRecolte: data.qteAvantDernierRecolte
            ? data.qteAvantDernierRecolte
            : null,
            exploitationStockFourni: data.exploitationStockFourni
            ? data.exploitationStockFourni
            : null,
            typeSpeculationFouni: data.typeSpeculationFouni
            ? data.typeSpeculationFouni
            : null,
            reFournisseurForms: this.reFournisseurFormss,

        },
      ];
    }
    for (let i = 0; i < this.boucleFournisseurs.length; i++) {
      this.Fournisseurss.push(
        this.boucleFournisseurs[i].produiseMemeIntrant ||
        this.boucleFournisseurs[i].qteAvantDernierRecolte ||
        this.boucleFournisseurs[i].exploitationStockFourni ||
        this.boucleFournisseurs[i].typeSpeculationFouni
          ? {
            produiseMemeIntrant: this.boucleFournisseurs[i]
            .produiseMemeIntrant
            ? this.boucleFournisseurs[i].produiseMemeIntrant
            : null,
            qteAvantDernierRecolte: this.boucleFournisseurs[i]
            .qteAvantDernierRecolte
            ? this.boucleFournisseurs[i].qteAvantDernierRecolte
            : null,
            exploitationStockFourni: this.boucleFournisseurs[i]
                .exploitationStockFourni
                ? this.boucleFournisseurs[i].exploitationStockFourni
                : null,
             typeSpeculationFouni: this.boucleFournisseurs[i]
                .typeSpeculationFouni
                ? this.boucleFournisseurs[i].typeSpeculationFouni
                : null,

              appliqueFormationFouni: this.boucleFournisseurs[i]
                .appliqueFormationFouni
                ? this.boucleFournisseurs[i].appliqueFormationFouni
                : null,
                reFournisseurForms: this.boucleFournisseurs[i].refAppuis,
            }
          : null
      );
    }
    if (
      data.alimentationAnimal != null ||
      data.appliqueFormationFouni != null ||
      data.fournisseurIntrant != null ||
	  data.modeUtilisationFourni != null ||
      data.niveauEfficaciteFouni != null ||
      data.nomEngrais != null||
	  data.nomProduitPhyto != null ||
      data.prixAchatFourni != null ||
      data.produitVeto != null||
	  data.qteParTypeFourni != null ||
      data.typeIntrantsFourni != null ||
      data.typeSemence != null
    ) {
      this.reFournisseurFormss = [
        {
          alimentationAnimal:			data.alimentationAnimal,
          appliqueFormationFouni:       data.appliqueFormationFouni,
          fournisseurIntrant:           data.fournisseurIntrant,
          modeUtilisationFourni:        data.modeUtilisationFourni,
          niveauEfficaciteFouni:        data.niveauEfficaciteFouni,
          nomEngrais:                   data.nomEngrais,
          nomProduitPhyto:              data.nomProduitPhyto,
          prixAchatFourni:              data.prixAchatFourni,
          produitVeto:                  data.produitVeto,
          qteParTypeFourni:             data.qteParTypeFourni,
          typeIntrantsFourni:           data.typeIntrantsFourni,
          typeSemence:                  data.typeSemence,
        },
      ];
    }

    for (let i = 0; i < this.bouclesreFournisseurForms.length; i++) {
      this.reFournisseurFormss.push({
		
		  alimentationAnimal:			this.bouclesreFournisseurForms[i].alimentationAnimal,
          appliqueFormationFouni:       this.bouclesreFournisseurForms[i].appliqueFormationFouni,
          fournisseurIntrant:           this.bouclesreFournisseurForms[i].fournisseurIntrant,
          modeUtilisationFourni:        this.bouclesreFournisseurForms[i].modeUtilisationFourni,
          niveauEfficaciteFouni:        this.bouclesreFournisseurForms[i].niveauEfficaciteFouni,
          nomEngrais:                   this.bouclesreFournisseurForms[i].nomEngrais,
          nomProduitPhyto:              this.bouclesreFournisseurForms[i].nomProduitPhyto,
          prixAchatFourni:              this.bouclesreFournisseurForms[i].prixAchatFourni,
          produitVeto:                  this.bouclesreFournisseurForms[i].produitVeto,
          qteParTypeFourni:             this.bouclesreFournisseurForms[i].qteParTypeFourni,
          typeIntrantsFourni:           this.bouclesreFournisseurForms[i].typeIntrantsFourni,
          typeSemence:                  this.bouclesreFournisseurForms[i].typeSemence,
      });
    }
	 */

    if (
      data.produiseMemeIntrant ||
      data.qteAvantDernierRecolte ||
      data.exploitationStockFourni ||
      data.typeSpeculationFouni ||



      data.appliqueFormationFouni ||
      
      data.fournisseurIntrant ||
      data.modeUtilisationFourni ||
      data.niveauEfficaciteFouni ||
      data.prixAchatFourni ||
      
      
      data.qteParTypeFourni ||
      data.typeIntrantsFourni ||
      data.typeSpeculationFouni ||
      data.utilisationIntrants
    ) {
      this.fournisseurss = [
        {
          appliqueFormationFouni: data.appliqueFormationFouni
            ? data.appliqueFormationFouni
            : null,
          exploitationStockFourni: data.exploitationStockFourni
            ? data.exploitationStockFourni
            : null,
          fournisseurIntrant: data.fournisseurIntrant
            ? data.fournisseurIntrant
            : null,
          modeUtilisationFourni: data.modeUtilisationFourni
            ? data.modeUtilisationFourni
            : null,
          niveauEfficaciteFouni: data.niveauEfficaciteFouni
            ? data.niveauEfficaciteFouni
            : null,
          prixAchatFourni: data.prixAchatFourni ? data.prixAchatFourni : null,
          qteAvantDernierRecolte: data.qteAvantDernierRecolte
            ? data.qteAvantDernierRecolte
            : null,
          produiseMemeIntrant: data.produiseMemeIntrant
            ? data.produiseMemeIntrant
            : null,
          qteParTypeFourni: data.qteParTypeFourni
            ? data.qteParTypeFourni
            : null,
          typeIntrantsFourni: data.typeIntrantsFourni
            ? data.typeIntrantsFourni
            : null,
          typeSpeculationFouni: data.typeSpeculationFouni
            ? data.typeSpeculationFouni
            : null,
          utilisationIntrants: data.utilisationIntrants
            ? data.utilisationIntrants
            : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleFournisseurs.length; i++) {
      this.fournisseur.push(
        this.boucleFournisseurs[i].appliqueFormationFouni ||
          this.boucleFournisseurs[i].exploitationStockFourni ||
          this.boucleFournisseurs[i].fournisseurIntrant ||
          this.boucleFournisseurs[i].modeUtilisationFourni ||
          this.boucleFournisseurs[i].niveauEfficaciteFouni ||
          this.boucleFournisseurs[i].prixAchatFourni ||
          this.boucleFournisseurs[i].qteAvantDernierRecolte ||
          this.boucleFournisseurs[i].produiseMemeIntrant ||
          this.boucleFournisseurs[i].qteParTypeFourni ||
          this.boucleFournisseurs[i].typeIntrantsFourni ||
          this.boucleFournisseurs[i].typeSpeculationFouni ||
          this.boucleFournisseurs[i].utilisationIntrants
          ? {
              appliqueFormationFouni: this.boucleFournisseurs[i]
                .appliqueFormationFouni
                ? this.boucleFournisseurs[i].appliqueFormationFouni
                : null,
              exploitationStockFourni: this.boucleFournisseurs[i]
                .exploitationStockFourni
                ? this.boucleFournisseurs[i].exploitationStockFourni
                : null,
              fournisseurIntrant: this.boucleFournisseurs[i].fournisseurIntrant
                ? this.boucleFournisseurs[i].fournisseurIntrant
                : null,
              modeUtilisationFourni: this.boucleFournisseurs[i]
                .modeUtilisationFourni
                ? this.boucleFournisseurs[i].modeUtilisationFourni
                : null,
              niveauEfficaciteFouni: this.boucleFournisseurs[i]
                .niveauEfficaciteFouni
                ? this.boucleFournisseurs[i].niveauEfficaciteFouni
                : null,
              prixAchatFourni: this.boucleFournisseurs[i].prixAchatFourni
                ? this.boucleFournisseurs[i].prixAchatFourni
                : null,
              qteAvantDernierRecolte: this.boucleFournisseurs[i]
                .qteAvantDernierRecolte
                ? this.boucleFournisseurs[i].qteAvantDernierRecolte
                : null,
              produiseMemeIntrant: this.boucleFournisseurs[i]
                .produiseMemeIntrant
                ? this.boucleFournisseurs[i].produiseMemeIntrant
                : null,
              qteParTypeFourni: this.boucleFournisseurs[i].qteParTypeFourni
                ? this.boucleFournisseurs[i].qteParTypeFourni
                : null,
              typeIntrantsFourni: this.boucleFournisseurs[i].typeIntrantsFourni
                ? this.boucleFournisseurs[i].typeIntrantsFourni
                : null,
              typeSpeculationFouni: this.boucleFournisseurs[i]
                .typeSpeculationFouni
                ? this.boucleFournisseurs[i].typeSpeculationFouni
                : null,
              utilisationIntrants: this.boucleFournisseurs[i]
                .utilisationIntrants
                ? this.boucleFournisseurs[i].utilisationIntrants
                : null,
            }
          : null
      );
    }
    if (
      data.contract ||
      data.contratSpecifie ||
      data.debutContrat ||
      data.finContrat ||
      data.periodiciteProduction ||
      data.prixProduitAgri ||
      data.produitConcerne ||
      data.qteConcerne ||
      data.qteProduire ||
      data.qteVendue ||
      data.respectContrat ||
      data.serviceFournie ||
      data.serviceProposer ||
      data.superficieCultive ||
      data.typeContrat
    ) {
      this.contratse = [
        {
          contract: data.contract ? data.contract : null,
          contratSpecifie: data.contratSpecifie ? data.contratSpecifie : null,
          debutContrat: data.debutContrat ? data.debutContrat : null,
          finContrat: data.finContrat ? data.finContrat : null,
          periodiciteProduction: data.periodiciteProduction
            ? data.periodiciteProduction
            : null,
          prixProduitAgri: data.prixProduitAgri ? data.prixProduitAgri : null,
          produitConcerne: data.produitConcerne ? data.produitConcerne : null,
          qteConcerne: data.qteConcerne ? data.qteConcerne : null,
          qteProduire: data.qteProduire ? data.qteProduire : null,
          qteVendue: data.qteVendue ? data.qteVendue : null,
          respectContrat: data.respectContrat ? data.respectContrat : null,
          serviceFournie: data.serviceFournie ? data.serviceFournie : null,
          serviceProposer: data.serviceProposer ? data.serviceProposer : null,
          superficieCultive: data.superficieCultive
            ? data.superficieCultive
            : null,
          typeContrat: data.typeContrat ? data.typeContrat : null,
          "uniteConcerne": this.UniteContratAgri.get('UniteCAConcerne').value?this.UniteContratAgri.get('UniteCAConcerne').value:null,
          "uniteVendue": this.UniteContratAgri.get('UniteCAVendue').value?this.UniteContratAgri.get('UniteCAVendue').value:null
        },
      ];
    }
    for (let i = 0; i < this.boucleContrats.length; i++) {
      this.contratse.push(
        this.boucleContrats[i].contract ||
          this.boucleContrats[i].contrat ||
          this.boucleContrats[i].contratSpecifie ||
          this.boucleContrats[i].debutContrat ||
          this.boucleContrats[i].finContrat ||
          this.boucleContrats[i].periodiciteProduction ||
          this.boucleContrats[i].prixProduitAgri ||
          this.boucleContrats[i].produitConcerne ||
          this.boucleContrats[i].qteConcerne ||
          this.boucleContrats[i].qteProduire ||
          this.boucleContrats[i].qteVendue ||
          this.boucleContrats[i].respectContrat ||
          this.boucleContrats[i].serviceFournie ||
          this.boucleContrats[i].serviceProposer ||
          this.boucleContrats[i].superficieCultive ||
          this.boucleContrats[i].typeContrat
          ? {
              contract: this.boucleContrats[i].contract
                ? this.boucleContrats[i].contract
                : null,
              contrat: this.boucleContrats[i].contrat
                ? this.boucleContrats[i].contrat
                : null,
              contratSpecifie: this.boucleContrats[i].contratSpecifie
                ? this.boucleContrats[i].contratSpecifie
                : null,
              debutContrat: this.boucleContrats[i].debutContrat
                ? this.boucleContrats[i].debutContrat
                : null,
              finContrat: this.boucleContrats[i].finContrat
                ? this.boucleContrats[i].finContrat
                : null,
              periodiciteProduction: this.boucleContrats[i]
                .periodiciteProduction
                ? this.boucleContrats[i].periodiciteProduction
                : null,
              prixProduitAgri: this.boucleContrats[i].prixProduitAgri
                ? this.boucleContrats[i].prixProduitAgri
                : null,
              produitConcerne: this.boucleContrats[i].produitConcerne
                ? this.boucleContrats[i].produitConcerne
                : null,
              qteConcerne: this.boucleContrats[i].qteConcerne
                ? this.boucleContrats[i].qteConcerne
                : null,
              qteProduire: this.boucleContrats[i].qteProduire
                ? this.boucleContrats[i].qteProduire
                : null,
              qteVendue: this.boucleContrats[i].qteVendue
                ? this.boucleContrats[i].qteVendue
                : null,
              respectContrat: this.boucleContrats[i].respectContrat
                ? this.boucleContrats[i].respectContrat
                : null,
              serviceFournie: this.boucleContrats[i].serviceFournie
                ? this.boucleContrats[i].serviceFournie
                : null,
              serviceProposer: this.boucleContrats[i].serviceProposer
                ? this.boucleContrats[i].serviceProposer
                : null,
              superficieCultive: this.boucleContrats[i].superficieCultive
                ? this.boucleContrats[i].superficieCultive
                : null,
              typeContrat: this.boucleContrats[i].typeContrat
                ? this.boucleContrats[i].typeContrat
                : null,
              "uniteConcerne": this.boucleContrats[i].UniteCAConcerne?this.boucleContrats[i].UniteCAConcerne:null,
              "uniteVendue": this.boucleContrats[i].UniteCAVendue?this.boucleContrats[i].UniteCAVendue:null  
            }
          : null
      );
    }

    if (
      data.assuranceAgricole ||
      data.assureur ||
      data.creditObtenu ||
      data.creditutiliser ||
      data.creditutilisation ||
      data.demandeCredit ||
      data.demandeServiceFinance ||
      data.effectifCreditObtenu ||
      data.epargneCredit ||
      data.genrePersRecouvrir ||
      data.interesseProduit ||
      data.nomImfBanque ||
      data.numCredit ||
      data.raisonPrincipale ||
      data.prixProduit ||
      data.raison ||
      data.secteurActiviteCredit ||
      data.disposerviceFinance ||
      data.creditsource
    ) {
      this.ServiceFinancier = [
        {
          assuranceAgricole: data.assuranceAgricole
            ? data.assuranceAgricole
            : null,
          assureur: data.assureur ? data.assureur : null,
          creditObtenu: data.creditObtenu ? data.creditObtenu : null,
          creditutiliser: data.creditutiliser ? data.creditutiliser : null,
          creditutilisation: data.creditutilisation
            ? data.creditutilisation
            : null,
          demandeCredit: data.demandeCredit ? data.demandeCredit : null,
          demandeServiceFinance: data.demandeServiceFinance
            ? data.demandeServiceFinance
            : null,
          effectifCreditObtenu: data.effectifCreditObtenu
            ? data.effectifCreditObtenu
            : null,
          epargneCredit: data.epargneCredit ? data.epargneCredit : null,
          genrePersRecouvrir: data.genrePersRecouvrir
            ? data.genrePersRecouvrir
            : null,
          interesseProduit: data.interesseProduit
            ? data.interesseProduit
            : null,
          nomImfBanque: data.nomImfBanque ? data.nomImfBanque : null,
          numCredit: data.numCredit ? data.numCredit : null,
          raisonPrincipale: data.raisonPrincipale
            ? data.raisonPrincipale
            : null,
          prixProduit: data.prixProduit ? data.prixProduit : null,
          raison: data.raison ? data.raison : null,
          secteurActiviteCredit: data.secteurActiviteCredit
            ? data.secteurActiviteCredit
            : null,
          disposerviceFinance: data.disposerviceFinance
            ? data.disposerviceFinance
            : null,
          creditsource: data.creditsource ? data.creditsource : null,
        },
      ];
    }

    for (let i = 0; i < this.bouclesfs.length; i++) {
      this.ServiceFinancier.push(
        this.bouclesfs[i].assuranceAgricole ||
          this.bouclesfs[i].assureur ||
          this.bouclesfs[i].creditObtenu ||
          this.bouclesfs[i].creditutiliser ||
          this.bouclesfs[i].creditutilisation ||
          this.bouclesfs[i].demandeCredit ||
          this.bouclesfs[i].demandeServiceFinance ||
          this.bouclesfs[i].effectifCreditObtenu ||
          this.bouclesfs[i].epargneCredit ||
          this.bouclesfs[i].genrePersRecouvrir ||
          this.bouclesfs[i].interesseProduit ||
          this.bouclesfs[i].nomImfBanque ||
          this.bouclesfs[i].numCredit ||
          this.bouclesfs[i].raisonPrincipale ||
          this.bouclesfs[i].prixProduit ||
          this.bouclesfs[i].raison ||
          this.bouclesfs[i].secteurActiviteCredit ||
          this.bouclesfs[i].disposerviceFinance ||
          this.bouclesfs[i].creditsource
          ? {
              assuranceAgricole: this.bouclesfs[i].assuranceAgricole
                ? this.bouclesfs[i].assuranceAgricole
                : null,
              assureur: this.bouclesfs[i].assureur
                ? this.bouclesfs[i].assureur
                : null,
              creditObtenu: this.bouclesfs[i].creditObtenu
                ? this.bouclesfs[i].creditObtenu
                : null,
              creditutiliser: this.bouclesfs[i].creditutiliser
                ? this.bouclesfs[i].creditutiliser
                : null,
              creditutilisation: this.bouclesfs[i].creditutilisation
                ? this.bouclesfs[i].creditutilisation
                : null,
              demandeCredit: this.bouclesfs[i].demandeCredit
                ? this.bouclesfs[i].demandeCredit
                : null,
              demandeServiceFinance: this.bouclesfs[i].demandeServiceFinance
                ? this.bouclesfs[i].demandeServiceFinance
                : null,
              effectifCreditObtenu: this.bouclesfs[i].effectifCreditObtenu
                ? this.bouclesfs[i].effectifCreditObtenu
                : null,
              epargneCredit: this.bouclesfs[i].epargneCredit
                ? this.bouclesfs[i].epargneCredit
                : null,
              genrePersRecouvrir: this.bouclesfs[i].genrePersRecouvrir
                ? this.bouclesfs[i].genrePersRecouvrir
                : null,
              interesseProduit: this.bouclesfs[i].interesseProduit
                ? this.bouclesfs[i].interesseProduit
                : null,
              nomImfBanque: this.bouclesfs[i].nomImfBanque
                ? this.bouclesfs[i].nomImfBanque
                : null,
              numCredit: this.bouclesfs[i].numCredit
                ? this.bouclesfs[i].numCredit
                : null,
              raisonPrincipale: this.bouclesfs[i].raisonPrincipale
                ? this.bouclesfs[i].raisonPrincipale
                : null,
              prixProduit: this.bouclesfs[i].prixProduit
                ? this.bouclesfs[i].prixProduit
                : null,
              raison: this.bouclesfs[i].raison
                ? this.bouclesfs[i].raison
                : null,
              secteurActiviteCredit: this.bouclesfs[i].secteurActiviteCredit
                ? this.bouclesfs[i].secteurActiviteCredit
                : null,
              disposerviceFinance: this.bouclesfs[i].disposerviceFinance
                ? this.bouclesfs[i].disposerviceFinance
                : null,
              creditsource: this.bouclesfs[i].creditsource
                ? this.bouclesfs[i].creditsource
                : null,
            }
          : null
      );
    }

    if (
      data.commercialisation ||
      data.coutRevient ||
      data.destinationProd ||
      data.fixationPrix ||
      data.margeBenefice ||
      data.prixProd ||
      data.qteAutoConsommation ||
      data.qteExporte ||
      data.infoSource ||
      data.speculatios ||
      data.venteEconomie
    ) {
      this.economie = [
        {
          commercialisation: data.commercialisation
            ? data.commercialisation
            : null,
          coutRevient: data.coutRevient ? data.coutRevient : null,
          destinationProd: data.destinationProd ? data.destinationProd : null,
          fixationPrix: data.fixationPrix ? data.fixationPrix : null,
          margeBenefice: data.margeBenefice ? data.margeBenefice : null,
          prixProd: data.prixProd ? data.prixProd : null,
          qteAutoConsommation: data.qteAutoConsommation
            ? data.qteAutoConsommation
            : null,
          qteExporte: data.qteExporte ? data.qteExporte : null,
          infoSource: data.infoSource ? data.infoSource : null,
          speculatios: data.speculatios ? data.speculatios : null,
          "uniteAutoConsomation": this.UniteEconomie.get("uniteAutoConsomation").value,
          "uniteExporte": this.UniteEconomie.get("uniteExporte").value,
          "uniteVenteEconomie": this.UniteEconomie.get("uniteVenteEconomie").value,
          venteEconomie: data.venteEconomie ? data.venteEconomie : null,
        },
      ];
    }

    for (let i = 0; i < this.boucleEconomies.length; i++) {
      this.economie.push(
        this.boucleEconomies[i].commercialisation ||
          this.boucleEconomies[i].coutRevient ||
          this.boucleEconomies[i].destinationProd ||
          this.boucleEconomies[i].fixationPrix ||
          this.boucleEconomies[i].margeBenefice ||
          this.boucleEconomies[i].prixProd ||
          this.boucleEconomies[i].qteAutoConsommation ||
          this.boucleEconomies[i].qteExporte ||
          this.boucleEconomies[i].infoSource ||
          this.boucleEconomies[i].speculatios ||
          this.boucleEconomies[i].venteEconomie
          ? {
              commercialisation: this.boucleEconomies[i].commercialisation
                ? this.boucleEconomies[i].commercialisation
                : null,
              coutRevient: this.boucleEconomies[i].coutRevient
                ? this.boucleEconomies[i].coutRevient
                : null,
              destinationProd: this.boucleEconomies[i].destinationProd
                ? this.boucleEconomies[i].destinationProd
                : null,
              fixationPrix: this.boucleEconomies[i].fixationPrix
                ? this.boucleEconomies[i].fixationPrix
                : null,
              margeBenefice: this.boucleEconomies[i].margeBenefice
                ? this.boucleEconomies[i].margeBenefice
                : null,
              prixProd: this.boucleEconomies[i].prixProd
                ? this.boucleEconomies[i].prixProd
                : null,
              qteAutoConsommation: this.boucleEconomies[i].qteAutoConsommation
                ? this.boucleEconomies[i].qteAutoConsommation
                : null,
              qteExporte: this.boucleEconomies[i].qteExporte
                ? this.boucleEconomies[i].qteExporte
                : null,
              infoSource: this.boucleEconomies[i].infoSource
                ? this.boucleEconomies[i].infoSource
                : null,
              speculatios: this.boucleEconomies[i].speculatios
                ? this.boucleEconomies[i].speculatios
                : null,
                "uniteAutoConsomation": this.boucleEconomies[i].uniteAutoConsomation,
                "uniteExporte": this.boucleEconomies[i].uniteExporte,
                "uniteVenteEconomie": this.boucleEconomies[i].uniteVenteEconomie,
              venteEconomie: this.boucleEconomies[i].venteEconomie
                ? this.boucleEconomies[i].venteEconomie
                : null,
            }
          : null
      );
    }
    if (
      data.dispoProductionProd!=null ||
      data.nomCultureProd !=null||
      data.objProductionProd !=null||
      data.productionKgProd !=null||
      data.pourcentageAuto!=null||
      data.uniteProduction!=null
    ) {
      this.production = [
        {
          dispoProductionProd: data.dispoProductionProd
            ? data.dispoProductionProd
            : null,
          nomCultureProd: data.nomCultureProd ? data.nomCultureProd : null,
          objProductionProd: data.objProductionProd
            ? data.objProductionProd
            : null,
          productionKgProd: data.productionKgProd
            ? data.productionKgProd
            : null,
          pourcentageAuto: data.pourcentageAuto ? data.pourcentageAuto : null,
         uniteProduction: data.uniteProduction ? data.uniteProduction : null,
        },
      ];
    }

    for (let i = 0; i < this.boucleProductions.length; i++) {
      this.production.push(
        this.boucleProductions[i].dispoProductionProd ||
          this.boucleProductions[i].nomCultureProd ||
          this.boucleProductions[i].objProductionProd ||
          this.boucleProductions[i].productionKgProd ||
          this.boucleProductions[i].pourcentageAuto
          ? {
              dispoProductionProd: this.boucleProductions[i].dispoProductionProd
                ? this.boucleProductions[i].dispoProductionProd
                : null,
              nomCultureProd: this.boucleProductions[i].nomCultureProd
                ? this.boucleProductions[i].nomCultureProd
                : null,
              objProductionProd: this.boucleProductions[i].objProductionProd
                ? this.boucleProductions[i].objProductionProd
                : null,
              productionKgProd: this.boucleProductions[i].productionKgProd
                ? this.boucleProductions[i].productionKgProd
                : null,
              pourcentageAuto: this.boucleProductions[i].pourcentageAuto
                ? this.boucleProductions[i].pourcentageAuto
                : null,
            }
          : null
      );
    }

    if (
      data.cinFamille ||
      data.dateCinFamille ||
      data.dateNaissFamille ||
      data.parentLien ||
      data.lieuCinFamille ||
      data.lieuNaissFamille ||
      data.nationaliteFamille ||
      data.niveauInstructionFamille ||
      data.nomFamille ||
      data.prenomFamille ||
      data.sexeFamille
    ) {
      this.Famille = [
        {
          cinFamille: data.cinFamille ? data.cinFamille : null,
          dateCinFamille: data.dateCinFamille ? data.dateCinFamille : null,
          dateNaissFamille: data.dateNaissFamille
            ? data.dateNaissFamille.length == 10
              ? data.dateNaissFamille
              : "vers " + data.dateNaissFamille
            : null,
          parentLien: data.parentLien ? data.parentLien : null,
          lieuCinFamille: data.lieuCinFamille ? data.lieuCinFamille : null,
          lieuNaissFamille: data.lieuNaissFamille
            ? data.lieuNaissFamille
            : null,
          nationaliteFamille: data.nationaliteFamille
            ? data.nationaliteFamille
            : null,
          niveauInstructionFamille: data.niveauInstructionFamille
            ? data.niveauInstructionFamille
            : null,
          nomFamille: data.nomFamille ? data.nomFamille : null,
          prenomFamille: data.prenomFamille ? data.prenomFamille : null,
          sexeFamille: data.sexeFamille ? data.sexeFamille : null,
        },
      ];
    }

    for (let i = 0; i < this.databoucleFamille.length; i++) {
      this.Famille.push(
        this.databoucleFamille[i].cinFamille ||
          this.databoucleFamille[i].dateCinFamille ||
          this.databoucleFamille[i].dateNaissFamille ||
          this.databoucleFamille[i].parentLien ||
          this.databoucleFamille[i].lieuCinFamille ||
          this.databoucleFamille[i].lieuNaissFamille ||
          this.databoucleFamille[i].nationaliteFamille ||
          this.databoucleFamille[i].niveauInstructionFamille ||
          this.databoucleFamille[i].nomFamille ||
          this.databoucleFamille[i].prenomFamille ||
          this.databoucleFamille[i].sexeFamille
          ? {
              cinFamille: this.databoucleFamille[i].cinFamille
                ? this.databoucleFamille[i].cinFamille
                : null,
              dateCinFamille: this.databoucleFamille[i].dateCinFamille
                ? this.databoucleFamille[i].dateCinFamille
                : null,
              dateNaissFamille: this.databoucleFamille[i].dateNaissFamille
                ? this.databoucleFamille[i].dateNaissFamille
                : null,
              parentLien: this.databoucleFamille[i].parentLien
                ? this.databoucleFamille[i].parentLien
                : null,
              lieuCinFamille: this.databoucleFamille[i].lieuCinFamille
                ? this.databoucleFamille[i].lieuCinFamille
                : null,
              lieuNaissFamille: this.databoucleFamille[i].lieuNaissFamille
                ? this.databoucleFamille[i].lieuNaissFamille
                : null,
              nationaliteFamille: this.databoucleFamille[i].nationaliteFamille
                ? this.databoucleFamille[i].nationaliteFamille
                : null,
              niveauInstructionFamille: this.databoucleFamille[i]
                .niveauInstructionFamille
                ? this.databoucleFamille[i].niveauInstructionFamille
                : null,
              nomFamille: this.databoucleFamille[i].nomFamille
                ? this.databoucleFamille[i].nomFamille
                : null,
              prenomFamille: this.databoucleFamille[i].prenomFamille
                ? this.databoucleFamille[i].prenomFamille
                : null,
              sexeFamille: this.databoucleFamille[i].sexeFamille
                ? this.databoucleFamille[i].sexeFamille
                : null,
            }
          : null
      );
    }

    if (
      data.especePepiniere != null ||
      data.matriculeProd != null ||
      data.nbreProduitAnnuelPepiniere != null
    ) {
      this.SylviCasPepiniere = [
        {
          especePepiniere: data.especePepiniere ? data.especePepiniere : null,
          matriculeProd: data.matriculeProd ? data.matriculeProd : null,
          nbreProduitAnnuelPepiniere: data.nbreProduitAnnuelPepiniere
            ? data.nbreProduitAnnuelPepiniere
            : null,
        },
      ];
    }
    for (let i = 0; i < this.bouclessylviCasPepiniere.length; i++) {
      this.SylviCasPepiniere.push(
        this.bouclessylviCasPepiniere[i].especePepiniere ||
          this.bouclessylviCasPepiniere[i].matriculeProd ||
          this.bouclessylviCasPepiniere[i].nbreProduitAnnuelPepiniere
          ? {
              especePepiniere: this.bouclessylviCasPepiniere[i].especePepiniere
                ? this.bouclessylviCasPepiniere[i].especePepiniere
                : null,
              matriculeProd: this.bouclessylviCasPepiniere[i].matriculeProd
                ? this.bouclessylviCasPepiniere[i].matriculeProd
                : null,
              nbreProduitAnnuelPepiniere: this.bouclessylviCasPepiniere[i]
                .nbreProduitAnnuelPepiniere
                ? this.bouclessylviCasPepiniere[i].nbreProduitAnnuelPepiniere
                : null,
            }
          : null
      );
    }

    if (
      data.nbre5a10 != null ||
      data.nbreMoins5 != null ||
      data.nbrePlus10 != null ||
      data.productiviteSylv != null ||
      data.produitObtenusSylv != null ||
      data.vocationPrincipaleSylv != null ||
      data.agePeuplementsSylv != null ||
      data.especeSylv != null ||
      data.nbrPiedsSylv != null
    ) {
      this.SylviGeneral = [
        {
          nbre5a10: data.nbre5a10 ? data.nbre5a10 : null,
          nbreMoins5: data.nbreMoins5 ? data.nbreMoins5 : null,
          nbrePlus10: data.nbrePlus10 ? data.nbrePlus10 : null,
          productiviteSylv: data.productiviteSylv
            ? data.productiviteSylv
            : null,
          produitObtenusSylv: data.produitObtenusSylv
            ? data.produitObtenusSylv
            : null,
          vocationPrincipaleSylv: data.vocationPrincipaleSylv
            ? data.vocationPrincipaleSylv
            : null,
          agePeuplementsSylv: data.agePeuplementsSylv
            ? data.agePeuplementsSylv
            : null,
          especeSylv: data.especeSylv ? data.especeSylv : null,
          nbrPiedsSylv: data.nbrPiedsSylv ? data.nbrPiedsSylv : null,
        },
      ];
    }

    for (let i = 0; i < this.bouclessylviGeneral.length; i++) {
      this.SylviGeneral.push(
        this.bouclessylviGeneral[i].nbre5a10 ||
          this.bouclessylviGeneral[i].nbreMoins5 ||
          this.bouclessylviGeneral[i].nbrePlus10 ||
          this.bouclessylviGeneral[i].productiviteSylv ||
          this.bouclessylviGeneral[i].produitObtenusSylv ||
          this.bouclessylviGeneral[i].VocationPrincipaleSylv ||
          this.bouclessylviGeneral[i].agePeuplementsSylv ||
          this.bouclessylviGeneral[i].especeSylv ||
          this.bouclessylviGeneral[i].nbrPiedsSylv
          ? {
              nbre5a10: this.bouclessylviGeneral[i].nbre5a10
                ? this.bouclessylviGeneral[i].nbre5a10
                : null,
              nbreMoins5: this.bouclessylviGeneral[i].nbreMoins5
                ? this.bouclessylviGeneral[i].nbreMoins5
                : null,
              nbrePlus10: this.bouclessylviGeneral[i].nbrePlus10
                ? this.bouclessylviGeneral[i].nbrePlus10
                : null,
              productiviteSylv: this.bouclessylviGeneral[i].productiviteSylv
                ? this.bouclessylviGeneral[i].productiviteSylv
                : null,
              produitObtenusSylv: this.bouclessylviGeneral[i].produitObtenusSylv
                ? this.bouclessylviGeneral[i].produitObtenusSylv
                : null,
              vocationPrincipaleSylv: this.bouclessylviGeneral[i]
                .vocationPrincipaleSylv
                ? this.bouclessylviGeneral[i].vocationPrincipaleSylv
                : null,
              agePeuplementsSylv: this.bouclessylviGeneral[i].agePeuplementsSylv
                ? this.bouclessylviGeneral[i].agePeuplementsSylv
                : null,
              especeSylv: this.bouclessylviGeneral[i].especeSylv
                ? this.bouclessylviGeneral[i].especeSylv
                : null,
              nbrPiedsSylv: this.bouclessylviGeneral[i].nbrPiedsSylv
                ? this.bouclessylviGeneral[i].nbrPiedsSylv
                : null,
            }
          : null
      );
    }
    if (
      data.typeProduitSylvicole != null ||
      data.qteProdAnnuelArboriculture != null ||
      data.qteProdAnnuelSylvicole != null ||
      data.uniteProdArboriculture != null ||
      data.uniteProdSylvicole != null
    ) {
      this.SylviProdSylvicole = [
        {
          typeProduitSylvicole: data.typeProduitSylvicole
            ? data.typeProduitSylvicole
            : null,
          matriculeProd: data.matriculeProd ? data.matriculeProd : null,
          qteProdAnnuelArboriculture: data.qteProdAnnuelArboriculture
            ? data.qteProdAnnuelArboriculture
            : null,
          qteProdAnnuelSylvicole: data.qteProdAnnuelSylvicole
            ? data.qteProdAnnuelSylvicole
            : null,
          uniteProdArboriculture: data.uniteProdArboriculture
            ? data.uniteProdArboriculture
            : null,
          uniteProdSylvicole: data.uniteProdSylvicole
            ? data.uniteProdSylvicole
            : null,
        },
      ];
    }

    for (let i = 0; i < this.bouclessylviProdSylvicole.length; i++) {
      this.SylviProdSylvicole.push(
        this.bouclessylviProdSylvicole[i].TypeProduitSylvicole ||
          this.bouclessylviProdSylvicole[i].matriculeProd ||
          this.bouclessylviProdSylvicole[i].qteProdAnnuelArboriculture ||
          this.bouclessylviProdSylvicole[i].qteProdAnnuelSylvicole ||
          this.bouclessylviProdSylvicole[i].uniteProdArboriculture ||
          this.bouclessylviProdSylvicole[i].uniteProdSylvicole
          ? {
              TypeProduitSylvicole: this.bouclessylviProdSylvicole[i]
                .TypeProduitSylvicole
                ? this.bouclessylviProdSylvicole[i].TypeProduitSylvicole
                : null,
              matriculeProd: this.bouclessylviProdSylvicole[i].matriculeProd
                ? this.bouclessylviProdSylvicole[i].matriculeProd
                : null,
              qteProdAnnuelArboriculture: this.bouclessylviProdSylvicole[i]
                .qteProdAnnuelArboriculture
                ? this.bouclessylviProdSylvicole[i].qteProdAnnuelArboriculture
                : null,
              qteProdAnnuelSylvicole: this.bouclessylviProdSylvicole[i]
                .qteProdAnnuelSylvicole
                ? this.bouclessylviProdSylvicole[i].qteProdAnnuelSylvicole
                : null,
              uniteProdArboriculture: this.bouclessylviProdSylvicole[i]
                .uniteProdArboriculture
                ? this.bouclessylviProdSylvicole[i].uniteProdArboriculture
                : null,
              uniteProdSylvicole: this.bouclessylviProdSylvicole[i]
                .uniteProdSylvicole
                ? this.bouclessylviProdSylvicole[i].uniteProdSylvicole
                : null,
            }
          : null
      );
    }

    if (
      data.adrsActuelle ||
      data.email ||
      data.hameauVillage ||
      data.mobileBanking ||
      data.pseudo ||
      data.telephone
    ) {
      this.Adressntct = [
        {
          adrsActuelle: data.adrsActuelle ? data.adrsActuelle : null,
          email: data.email ? data.email : null,
          hameauVillage: data.hameauVillage ? data.hameauVillage : null,
          mobileBanking: data.mobileBanking ? data.mobileBanking : null,
          pseudo: data.pseudo ? data.pseudo : null,
          telephone: data.telephone ? data.telephone : null,
        },
      ];
    }

    for (let i = 0; i < this.databoucle.length; i++) {
      this.Adressntct.push(
        this.databoucle[i].adrsActuelle ||
          this.databoucle[i].email ||
          this.databoucle[i].hameauVillage ||
          this.databoucle[i].mobileBanking ||
          this.databoucle[i].pseudo ||
          this.databoucle[i].telephone
          ? {
              adrsActuelle: this.databoucle[i].adrsActuelle
                ? this.databoucle[i].adrsActuelle
                : null,
              email: this.databoucle[i].email ? this.databoucle[i].email : null,
              hameauVillage: this.databoucle[i].hameauVillage
                ? this.databoucle[i].hameauVillage
                : null,
              mobileBanking: this.databoucle[i].mobileBanking
                ? this.databoucle[i].mobileBanking
                : null,
              pseudo: this.databoucle[i].pseudo
                ? this.databoucle[i].pseudo
                : null,
              telephone: this.databoucle[i].telephone
                ? this.databoucle[i].telephone
                : null,
            }
          : null
      );
    }
    if (this.distribution != []) {
      this.terres = {
        cultureLigneusePerrenne: this.distribution ? this.distribution : null,
        culturePerenne: this.perrene ? this.perrene : null,
        gestionBiomasse: this.Biomasse ? this.Biomasse : null,
        occupationSol: this.categorie ? this.categorie : null,
        parcelles: this.parcelle ? this.parcelle : null,
        possessionMode: this.possMode ? this.possMode : null,
        residusRecolte: this.occupatio ? this.occupatio : null,
        typeSol: this.typeSol ? this.typeSol : null,
        systemRizicole: this.SR ? this.SR : null,
        terreCultive: this.terreCultives ? this.terreCultives : null,
      };
    }

    if (
      data.materielAgri != null ||
      data.utilisation != null ||
      this.motorisers != null ||
      this.abrie != null ||
      this.Enginss != null ||
      this.stockage != null ||
      this.matmanu != null ||
      this.vache != null
    ) {
      this.materielEquipement = {
        abrisAnimaux: this.abrie ? this.abrie : null,
        enginsPecheEmb: this.Enginss ? this.Enginss : null,
        matMotorise: this.motorisers ? this.motorisers : null,
        materielAgri: data.materielAgri ? data.materielAgri : null,
        moeyenStokProd: this.stockage ? this.stockage : null,
        tractionAnimal: this.matmanu ? this.matmanu : null,
        utilisation: data.utilisation ? data.utilisation : null,
        vacheLaitier: this.vache ? this.vache : null,
      };
    } else {
      this.materielEquipement = null;
    }

    if (data.labelisation != null || data.systemeLabelisation != null) {
      this.Labelisation = [
        {
          labelisation: data.labelisation ? data.labelisation : null,
          systemeLabelisation: data.systemeLabelisation
            ? data.systemeLabelisation
            : null,
        },
      ];
    }
    for (let i = 0; i < this.boucleLabelisation.length; i++) {
      this.Labelisation.push(
        this.boucleLabelisation[i].labelisation ||
          this.boucleLabelisation[i].systemeLabelisation
          ? {
              labelisation: this.boucleLabelisation[i].labelisation
                ? this.boucleLabelisation[i].labelisation
                : null,
              systemeLabelisation: this.boucleLabelisation[i]
                .systemeLabelisation
                ? this.boucleLabelisation[i].systemeLabelisation
                : null,
            }
          : null
      );
    }

    // manombo ny APPUIS

    if (
      data.genreAppuiBeneficie != null ||
      data.montantApportBenefSup10 != null ||
      data.montantObtenuSup10 != null ||
      data.montantApportBenefSup10 != null ||
      data.nombreMaterielSup10 != null ||
      data.qteObtenuParType != null ||
      data.typeServiceAppui != null ||
      data.unite != null
    ) {
      this.RefAppui01 = [
        {
          genreAppuiBenefice: data.genreAppuiBeneficie,
          montantApportBenefSup10: data.montantApportBenefSup10,
          montantObtenuSup10: data.montantObtenuSup10,
          nombreMaterielSup10: data.nombreMaterielSup10,
          qteObtenuParType: data.qteObtenuParType,
          typeServiceAppui: data.typeServiceAppui,
          unite: this.UniteAppuiFormGroup.get("UniteQuantite").value,
        },
      ];
    }
    for (let i = 0; i < this.bouclesRefAppui01.length; i++) {
      this.RefAppui01.push({
        genreAppuiBenefice: this.bouclesRefAppui01[i].genreAppuiBenefice,
        montantApportBenefSup10:
          this.bouclesRefAppui01[i].montantApportBenefSup10,
        montantObtenuSup10: this.bouclesRefAppui01[i].montantObtenuSup10,
        nombreMaterielSup10: this.bouclesRefAppui01[i].nombreMaterielSup10,
        qteObtenuParType: this.bouclesRefAppui01[i].qteObtenuParType,
        typeServiceAppui: this.bouclesRefAppui01[i].typeServiceAppui,
        unite: this.bouclesRefAppui01[i].UniteQuantite,
      });
    }

    if (
      data.dateDebAppui != null ||
      data.dateFinAppui != null ||
      data.filiereAppuier != null
    ) {
      this.refAppuis = [
        {
          dateDebAppui: data.dateDebAppui,
          dateFinAppui: data.dateFinAppui,
          filiereAppuier: data.filiereAppuier,
          refAppui01: this.RefAppui01,
        },
      ];
    }

    for (let i = 0; i < this.bouclesRefAppui.length; i++) {
      this.refAppuis.push({
        dateDebAppui: this.bouclesRefAppui[i].dateDebAppui,
        dateFinAppui: this.bouclesRefAppui[i].dateFinAppui,
        filiereAppuier: this.bouclesRefAppui[i].filiereAppuier,
        refAppui01: this.RefAppui01,
      });
    }

    if (
      data.datePrevuReceptionAppui != null ||
      data.futureAcquisition != null ||
      data.motifAppui != null ||
      data.nomOrganisation != null ||
      data.numAgrement != null ||
      data.operatioAppui != null||
      data.projet != null||
      data.ptfAppui != null||
      data.satisfaitAppui != null||
      data.specificationAppui != null||
      data.titreObtentionAppui != null||
      data.typeCredit != null||
      data.typeFinance != null
    ) {
      this.Appuis = [
        {
          datePrevuReceptionAppui: data.datePrevuReceptionAppui?data.datePrevuReceptionAppui:null,
          futureAcquisition: data.futureAcquisition?data.futureAcquisition:null,
          motifAppui: data.motifAppui?data.motifAppui:null,
          nomOrganisation: data.nomOrganisation?data.nomOrganisation:null,
          numAgrement: data.numAgrement?data.numAgrement:null,
          operatioAppui: data.operatioAppui?data.operatioAppui:null,
          projet: data.projet?data.projet:null,
          ptfAppui: data.ptfAppui?data.ptfAppui:null,
          refAppuis: this.refAppuis,
          satisfaitAppui: data.satisfaitAppui?data.satisfaitAppui:null,
          specificationAppui: data.specificationAppui?data.specificationAppui:null,
          titreObtentionAppui: data.titreObtentionAppui?data.titreObtentionAppui:null,
          typeCredit: data.typeCredit?data.typeCredit:null,
          typeFinance: data.typeFinance?data.typeFinance:null,
        },
      ];
    }

    for (let i = 0; i < this.bouclesAppui.length; i++) {
      this.Appuis.push((this.bouclesAppui[i].datePrevuReceptionAppui||this.bouclesAppui[i].futureAcquisition||this.bouclesAppui[i].motifAppui
        ||this.bouclesAppui[i].nomOrganisation||this.bouclesAppui[i].numAgrement||this.bouclesAppui[i].operatioAppui||this.bouclesAppui[i].projet||this.bouclesAppui[i].ptfAppui
        ||this.bouclesAppui[i].satisfaitAppui||this.bouclesAppui[i].specificationAppui||this.bouclesAppui[i].titreObtentionAppui||this.bouclesAppui[i].typeCredit
        ||this.bouclesAppui[i].typeFinance)?{
        datePrevuReceptionAppui: this.bouclesAppui[i].datePrevuReceptionAppui?this.bouclesAppui[i].datePrevuReceptionAppui:null,
        futureAcquisition: this.bouclesAppui[i].futureAcquisition?this.bouclesAppui[i].futureAcquisition:null,
        motifAppui: this.bouclesAppui[i].motifAppui?this.bouclesAppui[i].motifAppui:null,
        nomOrganisation: this.bouclesAppui[i].nomOrganisation?this.bouclesAppui[i].nomOrganisation:null,
        numAgrement: this.bouclesAppui[i].numAgrement?this.bouclesAppui[i].numAgrement:null,
        operatioAppui: this.bouclesAppui[i].operatioAppui?this.bouclesAppui[i].operatioAppui:null,
        projet: this.bouclesAppui[i].projet?this.bouclesAppui[i].projet:null,
        ptfAppui: this.bouclesAppui[i].ptfAppui?this.bouclesAppui[i].ptfAppui:[],
        refAppuis: this.refAppuis,
        satisfaitAppui: this.bouclesAppui[i].satisfaitAppui?this.bouclesAppui[i].satisfaitAppui:null,
        specificationAppui: this.bouclesAppui[i].specificationAppui?this.bouclesAppui[i].specificationAppui:null,
        titreObtentionAppui: this.bouclesAppui[i].titreObtentionAppui?this.bouclesAppui[i].titreObtentionAppui:null,
        typeCredit: this.bouclesAppui[i].typeCredit?this.bouclesAppui[i].typeCredit:null,
        typeFinance: this.bouclesAppui[i].typeFinance?this.bouclesAppui[i].typeFinance:null,
      }:null);
    }
    if (data.alimentationAnimale!=null||data.appliqueFormationFouni!=null||data.fournisseurIntrant!=null||data.modeUtilisationFourni!=null||
      data.niveauEfficaciteFouni!=null||data.nomEngrais!=null||data.nomProduitPhyto!=null||
      data.prixAchatFourni!=null||data.produitVeto!=null||data.qteParTypeFourni!=null||
      data.typeIntrantsFourni!=null||data.typeSemence!=null) {
      this.reFournisseurForms = [
        {
          "alimentationAnimal": data.alimentationAnimale?data.alimentationAnimale:null,
          "appliqueFormationFouni": data.appliqueFormationFouni?data.appliqueFormationFouni:null,
          "fournisseurIntrant": data.fournisseurIntrant?data.fournisseurIntrant:null,
          "modeUtilisationFourni": data.modeUtilisationFourni?data.modeUtilisationFourni:null,
          "niveauEfficaciteFouni": data.niveauEfficaciteFouni?data.niveauEfficaciteFouni:null,
          "nomEngrais": data.nomEngrais?data.nomEngrais:null,
          "nomProduitPhyto": data.nomProduitPhyto?data.nomProduitPhyto:null,
          "prixAchatFourni": data.prixAchatFourni?data.prixAchatFourni:null,
          "produitVeto": data.produitVeto?data.produitVeto:null,
          "qteParTypeFourni": data.qteParTypeFourni?data.qteParTypeFourni:null,
          "typeIntrantsFourni": data.typeIntrantsFourni?data.typeIntrantsFourni:null,
          "typeSemence": data.typeSemence?data.typeSemence:null,
          "uniteParTypeFourni": this.UniteFourbisseur.get("uniteParTypeFourni").value
        }
      ]
    }
    for (let i = 0; i < this.bouclesreFournisseurForms.length; i++) {
      this.reFournisseurForms.push(
        (this.bouclesreFournisseurForms[i].alimentationAnimale||this.bouclesreFournisseurForms[i].appliqueFormationFouni||
          this.bouclesreFournisseurForms[i].fournisseurIntrant||this.bouclesreFournisseurForms[i].modeUtilisationFourni||
          this.bouclesreFournisseurForms[i].niveauEfficaciteFouni||this.bouclesreFournisseurForms[i].nomEngrais||
          this.bouclesreFournisseurForms[i].nomProduitPhyto||this.bouclesreFournisseurForms[i].prixAchatFourni||
          this.bouclesreFournisseurForms[i].produitVeto||this.bouclesreFournisseurForms[i].qteParTypeFourni||
          this.bouclesreFournisseurForms[i].typeIntrantsFourni||this.bouclesreFournisseurForms[i].typeSemence)?{
          "alimentationAnimal": this.bouclesreFournisseurForms[i].alimentationAnimale?this.bouclesreFournisseurForms[i].alimentationAnimale:null,
          "appliqueFormationFouni": this.bouclesreFournisseurForms[i].appliqueFormationFouni?this.bouclesreFournisseurForms[i].appliqueFormationFouni:null,
          "fournisseurIntrant": this.bouclesreFournisseurForms[i].fournisseurIntrant?this.bouclesreFournisseurForms[i].fournisseurIntrant:null,
          "modeUtilisationFourni": this.bouclesreFournisseurForms[i].modeUtilisationFourni?this.bouclesreFournisseurForms[i].modeUtilisationFourni:null,
          "niveauEfficaciteFouni": this.bouclesreFournisseurForms[i].niveauEfficaciteFouni?this.bouclesreFournisseurForms[i].niveauEfficaciteFouni:null,
          "nomEngrais": this.bouclesreFournisseurForms[i].nomEngrais?this.bouclesreFournisseurForms[i].nomEngrais:null,
          "nomProduitPhyto": this.bouclesreFournisseurForms[i].nomProduitPhyto?this.bouclesreFournisseurForms[i].nomProduitPhyto:null,
          "prixAchatFourni": this.bouclesreFournisseurForms[i].prixAchatFourni?this.bouclesreFournisseurForms[i].prixAchatFourni:null,
          "produitVeto": this.bouclesreFournisseurForms[i].produitVeto?this.bouclesreFournisseurForms[i].produitVeto:null,
          "qteParTypeFourni": this.bouclesreFournisseurForms[i].qteParTypeFourni?this.bouclesreFournisseurForms[i].qteParTypeFourni:null,
          "typeIntrantsFourni": this.bouclesreFournisseurForms[i].typeIntrantsFourni?this.bouclesreFournisseurForms[i].typeIntrantsFourni:null,
          "typeSemence": this.bouclesreFournisseurForms[i].typeSemence?this.bouclesreFournisseurForms[i].typeSemence:null,
          "uniteParTypeFourni": this.bouclesreFournisseurForms[i].uniteParTypeFourni
        }:null
      )
      
    }
    
    if (data.produiseMemeIntrant!=null||data.qteAvantDernierRecolte!=null||data.qteStockExploitationHors!=null||data.typeSpeculationFouni!=null
      || data.uniteAvantDernierRecolte!=null) {
      this.fournisseur = [
        {
          "produiseMemeIntrant": data.produiseMemeIntrant?data.produiseMemeIntrant:null,
          "qteAvantDernierRecolte": data.qteAvantDernierRecolte?data.qteAvantDernierRecolte:null,
          "qteExploitationStockFourni":data.qteStockExploitationHors?data.qteStockExploitationHors:null,
          "reFournisseurForms": this.reFournisseurForms?this.reFournisseurForms:null,
          "typeSpeculationFouni": data.typeSpeculationFouni?data.typeSpeculationFouni:null,
          "uniteAvantDernierRecolte": this.UniteFourbisseur.get("uniteAvantDernierRecolte").value,
          "uniteExploitationStockFourni": this.UniteFourbisseur.get("uniteExploitationStockFourni").value
        }
      ]
    }
    for (let i = 0; i < this.boucleFournisseurs.length; i++) {
      this.fournisseur.push(
        (this.boucleFournisseurs[i].produiseMemeIntrant||this.boucleFournisseurs[i].qteAvantDernierRecolte||
          this.boucleFournisseurs[i].qteStockExploitationHors||this.boucleFournisseurs[i].typeSpeculationFouni)?{
          "produiseMemeIntrant": this.boucleFournisseurs[i].produiseMemeIntrant?this.boucleFournisseurs[i].produiseMemeIntrant:null,
          "qteAvantDernierRecolte": this.boucleFournisseurs[i].qteAvantDernierRecolte?this.boucleFournisseurs[i].qteAvantDernierRecolte:null,
          "qteExploitationStockFourni":this.boucleFournisseurs[i].qteStockExploitationHors?this.boucleFournisseurs[i].qteStockExploitationHors:null,
          "reFournisseurForms": this.reFournisseurForms,
          "typeSpeculationFouni": this.boucleFournisseurs[i].typeSpeculationFouni?this.boucleFournisseurs[i].typeSpeculationFouni:null,
          "uniteAvantDernierRecolte": this.boucleFournisseurs[i].uniteAvantDernierRecolte,
          "uniteExploitationStockFourni": this.boucleFournisseurs[i].uniteExploitationStockFourni
        }:null
      )
    }
    if (data.commercialProduitAgri!=null||data.commercialProduitElev!=null||data.commercialProduitForest!=null||data.commercialProduitSylv!=null
      ||data.investissementAgri!=null||data.investissementElev!=null||data.investissementForest!=null||data.investissementSylv!=null
      ||data.secteurActiviteFemme!=null||data.speculationEspeceAgri!=null||data.speculationEspeceElev!=null||data.speculationEspeceForest!=null||data.speculationEspeceSylv!=null) {
      this.placeDesFemme = [
        {
          "commercialProduitAgri": data.commercialProduitAgri?data.commercialProduitAgri:null,
          "commercialProduitElev": data.commercialProduitElev?data.commercialProduitElev:null,
          "commercialProduitForest": data.commercialProduitForest?data.commercialProduitForest:null,
          "commercialProduitSylv": data.commercialProduitSylv?data.commercialProduitSylv:null,
          "investissementAgri": data.investissementAgri?data.investissementAgri:null,
          "investissementElev": data.investissementElev?data.investissementElev:null,
          "investissementForest": data.investissementForest?data.investissementForest:null,
          "investissementSylv": data.investissementSylv?data.investissementSylv:null,
          "secteurActiviteFemme": data.secteurActiviteFemme?data.secteurActiviteFemme:null,
          "speculationEspeceAgri": data.speculationEspeceAgri?data.speculationEspeceAgri:null,
          "speculationEspeceElev":data.speculationEspeceElev?data.speculationEspeceElev:null,
          "speculationEspeceForest": data.speculationEspeceForest?data.speculationEspeceForest:null,
          "speculationEspeceSylv": data.speculationEspeceSylv?data.speculationEspeceSylv:null
        }
      ]
    }
    
    const datasave = {
      "placeFemme": this.placeDesFemme,
      "fournisseurs": this.fournisseur,
      "agriculture": {
        "agriGenerale": this.AgricultureGeneral ? this.AgricultureGeneral : null,
        "amendementOrganique":this.organique ? this.organique : null,
        "chaulage": this.Chaulage ? this.Chaulage : null, 
        "cultureRente":  this.rente ? this.rente : null,
        "engraisMinerauxAzote": this.ammendement ? this.ammendement : null,
        "perimetreIrrigue": this.Perimetres? this.Perimetres: null,
        
      },
      "elevage": {
        "apicultures": this.Apiculture ? this.Apiculture : null,
        "pratique": this.Pratique ? this.Pratique : null,
      },
      appui: this.Appuis,
      civilite:
        data.dateDelivranceCin ||
        data.cin ||
        data.dateNaiss ||
        data.lieuDelivranceCin ||
        data.lieuNaiss ||
        data.codeNationalite ||
        data.nbrEnfant ||
        data.idNiveauEtude ||
        data.nom ||
        data.prenom ||
        data.sexe ||
        data.nomPrenomMere ||
        data.nomPrenomPere ||
        data.codeStatusMatrimonial
          ? {
              cin: data.cin ? data.cin : null,
              dateDelivranceCin: data.dateDelivranceCin
                ? data.dateDelivranceCin
                : null,
              dateNaiss: data.dateNaiss
                ? data.dateNaiss.length == 10
                  ? data.dateNaiss
                  : "vers " + data.dateNaiss
                : null,
              lieuDelivranceCin: data.lieuDelivranceCin
                ? data.lieuDelivranceCin
                : null,
              lieuNaiss: data.lieuNaiss ? data.lieuNaiss : null,
              localiteIndiv: this.codeLocalite,
              matricule: this.matr ? this.matr : null,
              codeNationalite: data.codeNationalite
                ? data.codeNationalite
                : null,
              idNiveauEtude: data.idNiveauEtude ? data.idNiveauEtude : null,
              nom: data.nom ? data.nom : null,
              nomPrenomMere: data.nomPrenomMere ? data.nomPrenomMere : null,
              nomPrenomPere: data.nomPrenomPere ? data.nomPrenomPere : null,
              photos: this.base64textString,
              prenom: data.prenom ? data.prenom : null,
              sexe: data.sexe ? data.sexe : null,
              status: 2,
              codeStatusMatrimonial: data.codeStatusMatrimonial
                ? data.codeStatusMatrimonial
                : null,
              userAjouter: this.matrEnqueteur,
              userModif: this.matrEnqueteur,
            }
          : null,

      adressContact: this.Adressntct ? this.Adressntct : null,
      sylviCasPepiniere: this.SylviCasPepiniere ? this.SylviCasPepiniere : null,
      sylviGeneral: this.SylviGeneral ? this.SylviGeneral : null,
      sylviProSylvicole: this.SylviProdSylvicole
        ? this.SylviProdSylvicole
        : null,
      famille: this.Famille ? this.Famille : null,
      terres: this.terres ? this.terres : null,
      economie: this.economie ? this.economie : null,
      //serviceFinancier: this.serviceFinancier ? this.serviceFinancier : null,
      serviceFinancier: this.ServiceFinancier ? this.ServiceFinancier : null,
      refAppuis: this.refAppuis ? this.refAppuis : null,
      refAppui01: this.RefAppui01 ? this.RefAppui01 : null,

      contraintes: this.contrainte ? this.contrainte : null,
      contratAgricole: this.contratse ? this.contratse : null,
      logistique: this.logistique ? this.logistique : null,
      materielEquipement: this.materielEquipement,
      production: this.production ? this.production : null,
      nutrition: this.nutrition ? this.nutrition : null,

      peche: this.peche ? this.peche : null,
      aquaculture: this.aquaculture ? this.aquaculture : null,
      assistanceSociale: this.AssistanceSociale ? this.AssistanceSociale : null,

      transformation: this.transformation ? this.transformation : null,
      labelisation: this.Labelisation ? this.Labelisation : null,
    };

    this.accountService.inscription(datasave).subscribe((res) => {
      if (res.status === 200) {
        this.toastr.success("Inscription avec success");
        this.router.navigate(["/listeprod"])
      } else {
        this.toastr.error("Erreur lors le l'inscription");
      }
    });
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(["/login"]);
  }
}

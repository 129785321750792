import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { DragdropService } from '../services/dropdrag/dragdrop.service';
import { donneeTest, donneeTest2 } from './testDonne.js';
import * as _ from "lodash";

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.css']
})
export class StepperComponent implements OnInit {
  isLinear = false;
  formGroup: FormGroup;
  form: FormArray;
  @ViewChild('stepper') stepper;
  dataStep: any[] = [];
  step: any;
  constructor(private _formBuilder: FormBuilder,
    private service: DragdropService) {
  }

  ngOnInit() {
    this.service.listeEnquete().subscribe(resp => {
      if (resp.status == 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.dataStep.push({
            titre: resp.body[i].libelleSection,
            Code: resp.body[i].codeEnquete,
            Designation: resp.body[i].libelleQuestionnaire
          })
        }
      }
      var grouped = this.dataStep.reduce(function (r, a) {
        r[a.titre] = r[a.titre] || [];
        r[a.titre].push(a);
        return r;
      }, {})
      var groups = Object.keys(grouped).map(function (key) {
        return {titre: key, data: grouped[key]};
      });
      this.step = groups;
  
    })
  }


}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, FormControl } from "@angular/forms";
import { ReportingService } from "app/services/reporting/reporting.service";
@Component({
  selector: "app-graph",
  templateUrl: "./graph.component.html",
  styleUrls: ["./graph.component.css"],
})
export class GraphComponent implements OnInit {
  /* selectLocalite!: any;*/
  form = new FormGroup({
    libelleIndicateur: new FormControl(),
  });
  data: {
    nomTable: any;
    nomTableNiv2: any;
    nomMoySum: any;
    nomColonne: any;
    typeCalcule: any;
    //  nomColonneJoin: any;
    nomColonneLocalite: any;
    localite_val: any;
  };

  infoTable: {
    nomtable: any;
    nomColonne: any;
  };
  typeSectionFormGroup: FormGroup;
  section: any[] = [];
  titreRepporting: any;
  typeAffichage: any;
  dataChart: any[] = [];
  listeSection: any;
  listeSectionById: any;
  show: boolean = false;
  show2: boolean = false;
  secteur: any;
  dataChartAfficher: any[] = [];
  itemChoisiList: any;
  codeIndicateur: any;
  change: any;
  dataReg: any[] = [];
  dataChart1222: any[] = [];
  constructor(
    private _formBuilder: FormBuilder,
    private service: ReportingService
  ) {}

  ngOnInit(): void {
    /*  this.selectLocalite = '';*/
    this.typeSectionFormGroup = this._formBuilder.group({
      indicateur: [""],
    });
    this.form = this._formBuilder.group({
      nomColonneJoin: [""],
    });
    this.initChart();
    this.service.getAllSection().subscribe((resp) => {
      if (resp.status == 200) {
        this.section = resp.body;
      }
    });
  }

  changeSection(index) {
    this.form.controls["nomColonneJoin"].reset();
    this.show = false;
    this.change = false;
    this.service.getSectionById(index).subscribe((res) => {
      if (res.status == 200) {
        this.show = true;
        this.listeSectionById = res.body;
      }
    });
  }

  modifiierAfficage(value) {
    this.typeAffichage = value;
  }

  onChange(value) {
    this.actionChart(value[0].data.id);
  }

  initChart() {
    this.actionChart(3);
    this.typeAffichage = 1;
  }

  actionChart(value) {
    this.service.getIndicateurById(value).subscribe((res) => {
      if (res.status == 200) {
        let data = res.body;
        const dataCalcule = {
          nomTable: data.nomTable,
          nomTableNiv2: data.nomTableNiv2 != "" || data.nomTableNiv2 != null ? data.nomTableNiv2 : "",
          nomMoySum: data.colonneJoinNiv1 != "" || data.colonneJoinNiv1 != null? data.colonneJoinNiv1 : "",
          nomColonne: data.colonne,
          typeCalcule: data.typeCalcul
        };
        this.service.calculeTaux(dataCalcule).subscribe((res2) => {
          if (res2.status == 200) {
            this.dataChart = res2.body;
          }
        });
      }
    });
  }

  verifierClass(value) {
    return this.dataChartAfficher.some((item) => item.libelle === value);
  }

  choisirItem(value) {
    this.itemChoisiList = value;
    this.codeIndicateur = value;
    this.titreRepporting = value.libelleIndicateur;
    this.form.reset();
    this.service.getIndicateurById(value.idIndicateur).subscribe((res) => {
      if (res.status == 200) {
        let data2 = res.body;
        this.data = {
          nomTable: data2.nomTable,
          nomTableNiv2: data2.nomTableNiv2 != "" ? data2.nomTableNiv2 : "",
          nomMoySum: data2.colonneJoinNiv1 != "" ? data2.colonneJoinNiv1 : "",
          nomColonne: data2.colonne,
          typeCalcule: data2.typeCalcul,
          // nomColonneJoin: data2.nomColonneJoin,
          nomColonneLocalite: "",
          localite_val: ""
          /* nomTable: "individu_minae",
                          nomColonne: "localite", */
        };
      }
      this.show2 = true;
    });
  }

  changeLocaliter(value) {
    this.service
      .getIndicateurById(this.codeIndicateur.idIndicateur)
      .subscribe((res) => {
        if (res.status == 200) {
          let data2 = res.body;
          this.data = {
            nomTable: data2.nomTable,
            nomTableNiv2: data2.nomTableNiv2 != "" ? data2.nomTableNiv2 : "",
            nomMoySum: data2.colonneJoinNiv1 != "" ? data2.colonneJoinNiv1 : "",
            nomColonne: data2.colonne,
            typeCalcule: data2.typeCalcul,
            //  nomColonneJoin: data2.nomColonneJoin,
            nomColonneLocalite: "localite",
            localite_val: value
            
          };
          //   this.service.calculeTaux(this.data);

          this.service.calculeTaux(this.data).subscribe((respo) => {
            if (respo.status == 200) {
              this.dataChart1222 = respo.body;
            }
          });
        }
        this.show2 = true;
      });
  }
}

import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DragdropService } from 'app/services/dropdrag/dragdrop.service';
import { ListService } from 'app/services/list/list.service';
import * as xlsx  from 'xlsx';

@Component({
  selector: 'app-export-excel',
  templateUrl: './export-excel.component.html',
  styleUrls: ['./export-excel.component.css']
})
export class ExportExcelComponent implements OnInit {

  [x: string]: any;
  @ViewChild('epltable', { static: false }) epltable: ElementRef;
  itemSection: any[]=[];
  Section: any;
  sectionName: any;
  itemSouthemes: any;
  fleche: boolean;
  foko: any[] = [];
  sectionFormGroup: FormGroup;
  localiteFormGroup: FormGroup;
  itemthemes: any;
  itemtheme: any[];
  ThemeNonVide: any;
  ListeSousSectionThemeNonVide: any[];
  dataEconomie: any[]=[];					 
  dataAgriG: any[]=[];
  themess: any;
  dataCulteRente: any []=[];
  dataApicult: any []=[];
  dataAppui: any;
  dataAppuiss: any[]=[];
    dataEngraisMinerauxAzote: any[]=[];
  dataPerimetresirrigues: any[]=[];
  dataRente: any[]=[];
  dataAgriGeneral: any[]=[];
  dataElevage: any[]=[];
  dataSylvyGeneral: any[]=[];
  dataSylvicole: any[]=[];
  dataCasPepiniere: any[]=[];
  dataPeche: any[]=[];
  dataAquaculture: any[]=[];
  dataOrganique: any[]=[];
  dataChaulage: any[]=[];
  dataAdreseContact: any[]=[];
  dataCivilite: any[]=[];						 
  dataMembreFamille: any[]=[];
  dataProduction: any[]=[];
  dataServiceFinancier: any[]=[];
  //dataOrganisationPaysanne: any[]=[];
  dataContratAgricole: any[]=[];
  dataPrincipalContrainte: any[]=[];
  dataFournisseur: any[]=[];
  dataTransformation: any[]=[];
  dataLogistique: any[]=[];
  dataNutrition: any[]=[];
  dataPlaceFemme: any[]=[];
  dataAssistanceSociale: any[]=[];
  dataLabelisation: any[]=[];
  dataPossessionMode: any[]=[];
  dataParcielle: any[]=[];
  dataCategorieOcupationSol: any[]=[];
  dataTypeSol: any[]=[];
  dataTerreCultive: any[]=[];
  dataPerrene: any[]=[];
  dataDistribution: any[]=[];
  dataOcupationRizicol: any[]=[];
  dataOcupationBiomase: any[]=[];
  dataOcupationResidusRecolte: any[]=[];
  dataMaterielAnnuel: any[]=[];
  dataEnginPeche: any[]=[];
  datavache: any[]=[];
  dataAbriAnimaux: any[]=[];
  dataMoyensStockageProduitsx: any[]=[];
  dataMaterielMotorise: any[]=[];
  IndivByDistrict: any[]=null;
  indivByRegion:any[]=null;
  codeCommune: any;
  commune2: any;
  listeIndiv: any[]=null;
  itemsPerPage = 100;
  currentPage = 1;
  isLoading = false;

  constructor(
    private _formBuilder: FormBuilder,
    private dd: DragdropService,
    private listService: ListService

  ) { }

  ngOnInit(): void {
    this.sectionFormGroup = this._formBuilder.group({
      section:[''], 
      sous_section: [''],
      theme: [''],
      sous_theme: ['']
    })
    this.localiteFormGroup = this._formBuilder.group({
      region: [''],
      district: [''],
      commune:['']
    })
    this.getAllSection();

  }
  fkt(index) {
    this.foko = []
    if (index.length > 3) {
      this.listService.prendListeCommune(index).subscribe(resp => {
        for (let i = 0; i < resp.length; i++) {
          this.foko.push({
            id: resp[i].codeCommune,
            text: resp[i].commune + " (District:"+ resp[i].district + ")" + " (Région:"+ resp[i].region + ")"
          })
        }
      })
    }
  }  
  onChange() {
    this.isLoading = true;
    this.listService.prendIndividuByCommune(this.localiteFormGroup.get('commune').value).subscribe(reponse=>{
      this.listeIndiv = reponse;
      this.isLoading = false;
    })
  }
  district(){
    this.isLoading = true;
    this.dd.getIndivParDitrict(this.localiteFormGroup.get('district').value).subscribe(resp=>{
      this.IndivByDistrict = resp.body
      this.isLoading = false;
    })
  }
  region(){
    this.isLoading = true;
    this.dd.getIndivParRegion(this.localiteFormGroup.get('region').value).subscribe(resp=>{
      this.indivByRegion = resp.body
      this.isLoading = false;
    })
  }
  exportexcel_Civilite_Commune():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Civilite.xlsx');
  }
  exportexcel_Civilite_Region():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Civilite.xlsx');
  }
  exportexcel_Civilite_District():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Civilite.xlsx');
  }
  exportexcel_appui():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'epltable.xlsx');
   }
   exportexcel_Civilite():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Civilite.xlsx');
   }
    exportexcel_AdresseContact():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AdresseContact.xlsx');
   }

 exportexcel_MembreFamille():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'MembreFamille.xlsx');
   }
   exportexcel_Production():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Production.xlsx');
   }
   exportexcel_Economie():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Economie.xlsx');
   } 
   exportexcel_ServiceFinancier():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'ServiceFinancier.xlsx');
   }
  /*  exportexcel_OrganisationPaysanne():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'OrganisationPaysanne.xlsx');
   } */
   exportexcel_EngraisMinerauxAzote():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'EngraisMinerauxAzote.xlsx');
   }
   exportexcel_Perimetresirrigues():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Perimetresirrigues.xlsx');
   }
   exportexcel_Rente():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'CultureRente.xlsx');
   }
   exportexcel_AgriGeneral():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AgricultureGrenerale.xlsx');
   }
   exportexcel_AmendementOrganique():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AmendementOrganique.xlsx');
   }
   exportexcel_Chaulage():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Chaulage.xlsx');
   }
   exportexcel_Elevage():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'PratiqueElevage.xlsx');
   }
   exportexcel_Apicult():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Apiculture.xlsx');
   }
   exportexcel_SylviGeneral():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'SylvicultureGeneral.xlsx');
   }
   exportexcel_Sylvicole():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Sylvicole.xlsx');
   }  
   exportexcel_CasPepiniere():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'CasPepiniere.xlsx');
   }
   exportexcel_Peche():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Peche.xlsx');
   }
   exportexcel_Aquaculture():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Aquaculture.xlsx');
   } 
    
   exportexcel_ContratAgricole():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'ContratAgricole.xlsx');
   }
   exportexcel_PrincipalContrainte():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'PrincipalContrainte.xlsx');
   }
   exportexcel_Fournisseur():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Fournisseur.xlsx');
   }
   exportexcel_Transformation():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Transformation.xlsx');
   }
   exportexcel_Logistique():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Logistique.xlsx');
   }
  exportexcel_Nutrition():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Nutrition.xlsx');
   }
    exportexcel_PlaceFemme():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'PlaceFemme.xlsx');
   }
   exportexcel_AssistanceSociale():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AssistanceSociale.xlsx');
   }
   exportexcel_Labelisation():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Labelisation.xlsx');
   }
   exportexcel_PossessionMode():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Possession et Mode.xlsx');
   }
   exportexcel_Parcielle():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Parcielle.xlsx');
   }
   
   exportexcel_CategorieOcupationSol():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'CategorieOcupationSol.xlsx');
   }
   exportexcel_TypeSol():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'TypeSol.xlsx');
   }
   exportexcel_TerreCultive():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'TerreCultive.xlsx');
   }
   exportexcel_Perenne():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Perenne.xlsx');
   }
   exportexcel_Distribution():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'Distribution.xlsx');
   }

   exportexcel_OcupationRizicol():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'OcupationRizicol.xlsx');
   }
   exportexcel_OcupationBiomase():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'OcupationBiomase.xlsx');
   }
   exportexcel_OcupationResidusRecolte():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'OcupationResidusRecolte.xlsx');
   }
   exportexcel_MaterielAnnuel():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'MaterielAnnuel.xlsx');
   }
   exportexcel_EnginPeche():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'EnginPeche.xlsx');
   }
   exportexcel_Vache():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'VacheLaitier.xlsx');
   }
   exportexcel_AbriAnimaux():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'AbriAnimaux.xlsx');
   }

   exportexcel_MoyensStockageProduitsxux():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'MoyensStockageProduitsx.xlsx');
   }
   exportexcel_MaterielMotorise():void{
    const ws: xlsx.WorkSheet =   
    xlsx.utils.table_to_sheet(this.epltable.nativeElement);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    xlsx.writeFile(wb, 'MaterielMotorise.xlsx');
   }
  


  ListeSousSectionThmeNonVides(Section){
    this.dd.ListesousSectionThemeNonvide(Section).subscribe(resp =>  {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.ThemeNonVide.push({
            id: resp.body[i].idSousSection,
            text: resp.body[i].libelleSousSection
          })
        }
      }
    })
  }
  prendListethemeSouthemeVide(idSousSection){
    this.itemtheme = [];
    this.dd.prendListethemeSouthemeVide(idSousSection).subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtheme.push({
            id: res.body[i].idTheme,
            text: res.body[i].LibelleTheme
          })
        }
      }
    })
  }
  getAllSection() {
    this.dd.findAllSection().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemSection.push({
            id: res.body[i].IdSection,
            text: res.body[i].libelleSection
          })
        }
      }
    })
    
  }


  theme(id) {
    this.themess = id.value
    /* this.getDataCultRente()
    this.getDataApiculture() */
	  this.getAllEngraisMinerauxAzote()
    this.getAllPerimetresirrigues()
    this.getAllRente()
    this.getAllAgriGeneral()
    this.getAllAmendementOrganique()
    this.getAllChaulage()
    this.getAllElevage()								 
    this.getAllApiculture()
	  this.getAllSylvyGeneral()
    this.getAllSylvicole()
    this.getAllCasPepiniere()
    this.getAllPeche()
    this.getAllAquaculture()				
  }
  listProd(){
    
  }
  SousSection(id) {
    this.prendListethemeSouthemeVide(id.value)
    this.checkSouTeme(id.value)
    this.itemSouthemes = []
    this.SousSections = id.value
    this.getAllPossessionMode()
    this.getAllParcielle()
    this.getAllCategorieOcupationSol()
    this.getAllTypeSol()
    this.getAllTerreCultive()
    this.getAllPerrene()
    this.getAllDistribution()
    this.getAllOcupationRizicol()
    this.getAllOcupationBiomase()
    this.getAllOcupationResidusRecolte()
    this.getAllMaterielAnnuel()
    this.getAllEnginPeche()
    this.getAllvache()
    this.getAllAbriAnimaux()
    this.getAllMoyensStockageProduitsx()
    this.getAllMaterielMotorise()
  }
  section(id) {
    this.ListeSousSectionThmeNonVide(id.value)
    this.checkTeme(id.value)
    this.Section = id.value
    for (let i = 0; i < this.itemSection.length; i++) {
      if (this.itemSection[i].id === id.value) {
       this.sectionName = this.itemSection[i].text;
      }
    }
    this.itemSouthemes = []
    this.fleche = true
    if (id.value == "B") {
    this.getAllCivilite()
    }
    if (id.value == "L") {
      this.getAllAppuis()
    }
    if (id.value == "C") {
      this.getAllAdresseContact()
    }
    if (id.value == "D") {
      this.getAllMembreFamille()
    }
    if (id.value == "H") {
      
    this.getAllEconomie()
    }
    if (id.value == "J") {
      this.getAllServiceFinancier()
    }
    if (id.value == "M") {
      this.getAllContratAgricole()
    }
    if (id.value == "N") {
      this.getAllPrincipalContrainte()
    }
    if (id.value == "O") {
      this.getAllFournisseur()
    }
    if (id.value == "P") {
      this.getAllTransformation()
    }
    if (id.value == "Q") {
      this.getAllLogistique()
    }
    if (id.value == "R") {
      this.getAllNutrition()
    }
    if (id.value == "S") {
      this.getAllPlaceFemme()
    }
    if (id.value == "T") {
      this.getAllAssistanceSocialee()
    }
    if (id.value == "U") {
      this.getAllLabelisation()
    }
    if (id.value == "G") {
      this.getAllProduction()
      
    }
}

checkTeme(Section) {
  this.dd.Checktheme(Section).subscribe(resp => {
    this.itemthemes = resp.body
   })
 }
 checkSouTeme(sousSection) {
  this.dd.CheckSousThemes(sousSection).subscribe(resp => {
    this.itemSouthemes = resp.body
   })
 }

 getAllCivilite(){				  
  this.dd.getAllCivilite().subscribe(resp=>{
    this.dataCivilite = resp.body
  })
}
 getAllAdresseContact(){
  this.dd.getAllAdresseContact().subscribe(resp=>{
    this.dataAdreseContact = resp.body
  })
}
 getAllMembreFamille(){
  this.dd.getAllMembreFamille().subscribe(resp=>{
    this.dataMembreFamille = resp.body
  })
}
 getAllProduction(){
  this.dd.getAllProduction().subscribe(resp=>{
    this.dataProduction = resp.body
  })
}
 getAllEconomie(){
  this.dd.getAllEconomie().subscribe(resp=>{
    this.dataEconomie = resp.body
  })
}
getAllServiceFinancier(){
  this.dd.getAllServiceFinancier().subscribe(resp=>{
    this.dataServiceFinancier = resp.body
  })
}
getAllEngraisMinerauxAzote(){
  this.dd.getAllEngraisMinerauxAzote().subscribe(resp=>{
    this.dataEngraisMinerauxAzote = resp.body
  })
}
getAllPerimetresirrigues(){
  this.dd.getAllPerimetresirrigues().subscribe(resp=>{
    this.dataPerimetresirrigues = resp.body
  })
}

getAllRente(){
  this.dd.getAllRente().subscribe(resp=>{
    this.dataRente = resp.body
  })
}
getAllAgriGeneral(){
  this.dd.getAllAgriGeneral().subscribe(resp=>{
    this.dataAgriGeneral = resp.body
  })
}
getAllAmendementOrganique(){
  this.dd.getAllAmendementOrganique().subscribe(resp=>{
    this.dataOrganique = resp.body
  })
}
getAllChaulage(){
  this.dd.getAllChaulage().subscribe(resp=>{
    this.dataChaulage = resp.body
  })
}
getAllElevage(){
  this.dd.getAllElevage().subscribe(resp=>{
    this.dataElevage = resp.body
  })
}
getAllApiculture(){
  this.dd.getAllApculture().subscribe(resp=>{
    this.dataApicult = resp.body
  })
}
getAllSylvyGeneral(){
  this.dd.getAllSylvyGeneral().subscribe(resp=>{
    this.dataSylvyGeneral = resp.body
  })
}
getAllSylvicole(){
  this.dd.getAllSylvicole().subscribe(resp=>{
    this.dataSylvicole = resp.body
  })
}
getAllCasPepiniere(){
  this.dd.getAllCasPepiniere().subscribe(resp=>{
    this.dataCasPepiniere = resp.body
  })
}
getAllPeche(){
  this.dd.getAllPeche().subscribe(resp=>{
    this.dataPeche = resp.body
  })
}
getAllAquaculture(){
  this.dd.getAllAquaculture().subscribe(resp=>{
    this.dataAquaculture = resp.body
  })
}
getAllAppuis(){
  this.dd.getAllAppuis().subscribe(resp=>{
    this.dataAppui = resp.body
    this.dataAppuiss=this.dataAppui.appuis
  })
}
getAllContratAgricole(){
  this.dd.getAllContratAgricole().subscribe(resp=>{
    this.dataContratAgricole = resp.body
  })
}
getAllPrincipalContrainte(){
  this.dd.getAllPrincipalContrainte().subscribe(resp=>{
    this.dataPrincipalContrainte = resp.body
  })
}
getAllFournisseur(){
  this.dd.getAllFournisseur().subscribe(resp=>{
    this.dataFournisseur = resp.body
  })
}
 getAllTransformation(){
  this.dd.getAllTransformation().subscribe(resp=>{
    this.dataTransformation = resp.body
  })
}
 getAllLogistique(){
  this.dd.getAllLogistique().subscribe(resp=>{
    this.dataLogistique = resp.body
  })
}
getAllNutrition(){
  this.dd.getAllNutrition().subscribe(resp=>{
    this.dataNutrition = resp.body
  })
}
getAllPlaceFemme(){
  this.dd.getAllPlaceFemme().subscribe(resp=>{
    this.dataPlaceFemme = resp.body
  })
}
getAllAssistanceSocialee(){
  this.dd.getAllAssistanceSocialee().subscribe(resp=>{
    this.dataAssistanceSociale = resp.body
  })
}
 getAllLabelisation(){
  this.dd.getAllLabelisation().subscribe(resp=>{
    this.dataLabelisation = resp.body
  })
}
getAllPossessionMode(){
  this.dd.getAllPossessionMode().subscribe(resp=>{
    this.dataPossessionMode = resp.body
  })
} 
getAllParcielle(){
  this.dd.getAllParcielle().subscribe(resp=>{
    this.dataParcielle = resp.body
  })
}
getAllCategorieOcupationSol(){
  this.dd.getAllCategorieOcupationSol().subscribe(resp=>{
    this.dataCategorieOcupationSol = resp.body
  })
}

getAllTypeSol(){
  this.dd.getAllTypeSol().subscribe(resp=>{
    this.dataTypeSol = resp.body
  })
}
getAllTerreCultive(){
  this.dd.getAllTerreCultive().subscribe(resp=>{
    this.dataTerreCultive = resp.body
  })
}
getAllPerrene(){
  this.dd.getAllPerrene().subscribe(resp=>{
    this.dataPerrene = resp.body
  })
}
 getAllDistribution(){
  this.dd.getAllDistribution().subscribe(resp=>{
    this.dataDistribution = resp.body
  })
}

getAllOcupationRizicol(){
  this.dd.getAllOcupationRizicol().subscribe(resp=>{
    this.dataOcupationRizicol = resp.body
  })
}
getAllOcupationBiomase(){
  this.dd.getAllOcupationBiomase().subscribe(resp=>{
    this.dataOcupationBiomase = resp.body
  })
}
getAllOcupationResidusRecolte(){
  this.dd.getAllOcupationResidusRecolte().subscribe(resp=>{
    this.dataOcupationResidusRecolte = resp.body
  })
}
getAllMaterielAnnuel(){
  this.dd.getAllMaterielAnnuel().subscribe(resp=>{
    this.dataMaterielAnnuel = resp.body
  })
}
getAllEnginPeche(){
  this.dd.getAllEnginPeche().subscribe(resp=>{
    this.dataEnginPeche = resp.body
  })
}
getAllvache(){
  this.dd.getAllvache().subscribe(resp=>{
    this.datavache = resp.body
  })
}
getAllAbriAnimaux(){
  this.dd.getAllAbriAnimaux().subscribe(resp=>{
    this.dataAbriAnimaux = resp.body
  })
}
getAllMoyensStockageProduitsx(){
  this.dd.getAllMoyensStockageProduitsx().subscribe(resp=>{
    this.dataMoyensStockageProduitsx = resp.body
  })
}
getAllMaterielMotorise(){
  this.dd.getAllMaterielMotorise().subscribe(resp=>{
    this.dataMaterielMotorise = resp.body
  })
}



 ListeSousSectionThmeNonVide(Section){
  this.ListeSousSectionThemeNonVide = []
  this.dd.ListesousSectionThemeNonvide(Section).subscribe(resp =>  {
    if (resp.status === 200) {
      for (let i = 0; i < resp.body.length; i++) {
        this.ListeSousSectionThemeNonVide.push({
          id: resp.body[i].idSousSection,
          text: resp.body[i].libelleSousSection
        })
      }
    }
  })
}

}

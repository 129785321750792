import { TestService } from './../../services/realisation/test.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-liste-par-daterealisation',
  templateUrl: './liste-par-daterealisation.component.html',
  styleUrls: ['./liste-par-daterealisation.component.css']
})
export class ListeParDaterealisationComponent implements OnInit {
  dateset:any
  listes: any
  declanche:any
  constructor(private validationService: TestService) { }

  ngOnInit(): void {
    this.declanche = false
  }
  getliste(){
    this.validationService.getRealisationTestByDateRealisationTest(this.dateset).subscribe(data =>{
      this.listes = data.body
      this.declanche = true
      
    })
  }
}

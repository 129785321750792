import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ListService } from 'app/services/list/list.service';

@Component({
  selector: 'app-cin',
  templateUrl: './cin.component.html',
  styleUrls: ['./cin.component.css']
})
export class CinComponent implements OnInit {
  matricule: any;
  datailsProd: any;
  photocinRecto: any;
  photocinVerso: any;

  constructor(
    private list: ListService,
    private route: ActivatedRoute,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    this.matricule = this.route.snapshot.paramMap.get("id");
    this.getProdByMatricule()
  }
  getProdByMatricule() {
    this.list.getProdByMatricule(this.matricule).subscribe((response) => {
      this.datailsProd = response.body;
      this.photocinRecto = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + this.datailsProd.recto
      );
      this.photocinVerso = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + this.datailsProd.verso
      );
      
    });
    
  }

}

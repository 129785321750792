import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DomSanitizer } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { ListService } from "app/services/list/list.service";
import { AccountService } from "../services/account/account.service";

export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: "app-detailproducteur",
  templateUrl: "./detailproducteur.component.html",
  styleUrls: ["./detailproducteur.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class DetailproducteurComponent implements OnInit {
  datailsProd: any;
  matricule: any;
  photo: any;
  listeAdrss: any[] = [];
  animal: string;
  name: string;
  secondFormGroup: FormGroup;
  constructor(
    private accountService: AccountService,
    private list: ListService,
    private route: ActivatedRoute,
    private router: Router,
    private _sanitizer: DomSanitizer,
    public dialog: MatDialog,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.matricule = this.route.snapshot.paramMap.get("id");
    this.getProdByMatricule();
    this.getAdrsCtnt();
  }

  logout() {
    this.accountService.logout();
    this.router.navigate(["/login"]);
  }

  getProdByMatricule() {
    this.list.getProdByMatricule(this.matricule).subscribe((response) => {
      this.datailsProd = response.body;
      this.photo = this._sanitizer.bypassSecurityTrustResourceUrl(
        "data:image/jpg;base64," + this.datailsProd.photos
      );
    });
  }
  getdetails(matricule: string) {
    this.router.navigate(["ModifProd/" + matricule]);
  }
  modifLocaliter(matricule: string) {
    this.router.navigate(["modiflocalite/" + matricule]);
  }
  getdetailsInfo(matricule: string) {
    this.router.navigate(["modifInfo/" + matricule]);
  }
  getAdrsCtnt() {
    this.list.getAdrsCtnct(this.matricule).subscribe((resp) => {
      this.listeAdrss = resp.body;
    });
  }
}

import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Detailrealisation } from 'app/detailrealisation';

@Injectable({
  providedIn: 'root'
})
export class TestService {

  constructor(
    private http: HttpClient
  ) { }

  findAllSection() {
    // let headers = new HttpHeaders({"Authorization" : "Bearer " + token});
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/getAllSection`,  { headers: headers, observe: 'response' });
  }
  sousSection(Section) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/listeSousSectionThemeVide/`+ Section,  { headers: headers, observe: 'response' });
  }

  getOservation(Section) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/listeObservationBySectionRealisationTest/`+ Section,  { headers: headers, observe: 'response' });
  }
  getObservatio2(sousSection) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/prendListeObservationSiSousThemeVide/`+ sousSection,  { headers: headers, observe: 'response' });
  }
  getObservatio3(theme) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/prendObservationSousThemeNonVide1/`+ theme,  { headers: headers, observe: 'response' });
  }


  sousSection2(Section) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/listeSousSectionThemeNonVide/`+ Section,  { headers: headers, observe: 'response' });
  }
  Checktheme(Section) {
    // let headers = new HttpHeaders({"Authorization" : "Bearer " + token});
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/ckeckThemeBySection/` + Section,  { headers: headers, observe: 'response' });
  }
  CheckSousThemes(soussection){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/checkSousTheme/` + soussection,  { headers: headers, observe: 'response' });  
  }
  theme(sousSection) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/prendListeThemeBySousSection/`+ sousSection,  { headers: headers, observe: 'response' });
  }
  Southeme(theme) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/prendSousThemeNonVide1/`+ theme,  { headers: headers, observe: 'response' });
  }
  theme2(sousSection) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/prendListeSiSousThemeVide/`+ sousSection,  { headers: headers, observe: 'response' });
  }
  global(){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getAllAvancementRealisationTest`,  { headers: headers, observe: 'response' });
  }
  getRealisationTestByIdQuest(idQuest){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getRealisationTestByIdQuest/`+ idQuest,  { headers: headers, observe: 'response' });
  }
  listeRealisationTest(){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/listeRealisationTest`,  { headers: headers, observe: 'response' });
  }
  getRealisationTestByDateRealisationTest(dataset){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getRealisationTestByDateRealisationTest/`+ dataset,  { headers: headers, observe: 'response' });
  }
  getAllSection(){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getAllSection`,  { headers: headers, observe: 'response' });
  }
  ckeckThemeBySection(section){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/ckeckThemeBySection/`+ section,  { headers: headers, observe: 'response' });
  }
  listeSousSectionThemeNonVide(section){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/listeSousSectionThemeNonVide/`+ section,  { headers: headers, observe: 'response' });
  }
  listeSousSectionThemeVide(section){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/listeSousSectionThemeVide/`+ section,  { headers: headers, observe: 'response' });
  }
  getAvancementGlobaleBySection(idsection){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getAvancementGlobaleBySection/`+ idsection,  { headers: headers, observe: 'response' });
  }
  getAvancementGlobal(){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getTotalAvancementRealisationTest`,  { headers: headers, observe: 'response' });
  }
  getListeObservationGlobal(){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getObservationGlobaleRealisationTest`,  { headers: headers, observe: 'response' });
  }
  
  register(data) {
    const headers = new HttpHeaders()
    /* .set('content-type', 'application/json') */
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`${environment.apiUrl2}api/realisationTest/saveReferenceTest`, data, { observe: 'response' });
  }
  saveObservation(data) {
    const headers = new HttpHeaders()
    /* .set('content-type', 'application/json') */
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post(`${environment.apiUrl2}/api/realisationTest/saveObservationReferenceTest`, data, { observe: 'response' });
  }
  getrealisationtestebycoderealisation(nom){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getRealisationTestByNom/`+ nom,  { headers: headers, observe: 'response' });
  }
/*   getlinerealisationbyId(idRealisation){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiValidation}api/realisationTest/getRealisationTestByIdRealisation/`+ idRealisation,  { headers: headers, observe: 'response' });
  } */
  getlinerealisationbyId(idRealisation): Observable<Detailrealisation>{
    return this.http.get<Detailrealisation>(`${environment.apiValidation}api/realisationTest/getRealisationTestByIdRealisation/`+ idRealisation)
  }
  getrealisationmax(section){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/listeSousSectionThemeVideDateMax/`+ section,  { headers: headers, observe: 'response' });
  }
  getObservation(section){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrl2}api/realisationTest/getObservationGlobaleBySection/`+ section,  { headers: headers, observe: 'response' });
  }
}
  
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-transformation',
  templateUrl: './transformation.component.html',
  styleUrls: ['./transformation.component.css']
})
export class TransformationComponent implements OnInit {
  id:string
  transformation: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getTransformation()
  }

  getTransformation(){
    this.list.getTransformation(this.id).subscribe(resp => {
      this.transformation = resp.body
    })
  }
}

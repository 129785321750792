import { Component, Input, OnInit } from '@angular/core';
import { TestService } from '../services/realisation/test.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AccountService } from '../services/account/account.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-realisationtest',
  templateUrl: './realisationtest.component.html',
  styleUrls: ['./realisationtest.component.css']
})

export class RealisationtestComponent implements OnInit {
  prenom: any
  nom: any
  status: any



  itemSection: any[] = [];
  itemSousSection: any[] = [];
  itemSousSection2: any[] = [];
  itemtheme2: any[] = [];
  itemtheme: any[] = [];
  itemCheckSouthemes: any;
  itemCheckthemes: any;
  sectionFormGroup: FormGroup;
  tableFormGroup: FormGroup;
  secFormGroup: FormGroup;
  infoFormGroup: FormGroup;
  dataEnvoye: any[] = [];
  Section: string;
  itemsoutheme: any[] = [];
  datejour: Date;
  listeteste: any;
  itemdatamax: any;
  item: any;
  itemdata: any[] = [];
  dev: any;
  integre: any;
  Validation: any;
  dataObservation: any[] = [];

  constructor(
    private accountService: AccountService,
    private _formBuilder: FormBuilder,
    private test: TestService,
    private router: Router,
  ) { }




  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
  ngOnInit(): void {
    this.getLocalStorage()
    let currentDate = new Date();
    this.datejour = currentDate


    const user = localStorage.getItem('user')
    const valUser = JSON.parse(user)
    this.getAllSection();
    this.infoFormGroup = this._formBuilder.group({
      nom: [''],
      prenom: [''],
      service: [''],
      datejour: [''],
      Obsrvation_global: ['']
    })
    this.secFormGroup = this._formBuilder.group({
      section: [''],
    })
    this.sectionFormGroup = this._formBuilder.group({
      sous_section: [''],
      theme: ['']
    });
    this.tableFormGroup = this._formBuilder.group({
      Developpement: [''],
      Test_Integration_Donnees: [''],
      Test_Validation: [''],
      Scenario_test: [''],
      Observation: [''],
      Support_web: [''],
      Support_Mobile: [''],
      Taux_Realisation: [''],
      Taux_Validation: [''],
    })
  }
  getLocalStorage() {
    const values = JSON.parse(localStorage.getItem('user'));
    this.prenom = values.individu.prenom
    this.nom = values.individu.nom
    this.status = values.individu.status
  }
  getAllSection() {
    this.test.findAllSection().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemSection.push({
            id: res.body[i].IdSection,
            text: res.body[i].libelleSection
          })
        }
      }
    })
  }
  checkTheme(Section) {
    this.test.Checktheme(Section).subscribe(resp => {
      this.itemCheckthemes = resp.body
    })
  }
  checkSouTheme(sousSection) {
    this.test.CheckSousThemes(sousSection).subscribe(resp => {
      this.itemCheckSouthemes = resp.body
    })
  }
  section(id) {
    this.checkTheme(id.value)
    this.getSousSectionCheckthemeFalse(id.value)
    this.getSousSectionchecktemeTrue(id.value)
    this.itemCheckSouthemes = []
  }
  SousSection(id) {
    this.checkSouTheme(id.value)
    this.getthemeCheckSouThemeFalse(id.value)
    this.getthemeChecksouThemeTrue(id.value)
    this.itemCheckSouthemes = []
  }
  theme(id) {
    this.getsouthemebyTheme(id.value)
  }


  getSousSectionCheckthemeFalse(Section) {
    this.itemSousSection = []
    this.test.sousSection(Section).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemSousSection.push({
            idQuest: resp.body[i].idQuest,
            idSection: resp.body[i].idSection,
            idSousSection: resp.body[i].idSousSection,
            idTheme: resp.body[i].idTheme,
            idSousTheme: resp.body[i].idSousTheme,
            idQuestionnaire: resp.body[i].idQuestionnaire,
            ordre: resp.body[i].ordre,

            libelleSection: resp.body[i].libelleSection,
            libelleSousection: resp.body[i].libelleSousSection,
            libelleTheme: resp.body[i].libelleTheme,
            libelleSousTheme: resp.body[i].libelleSousTheme,
            libelleQuestionnaire: resp.body[i].libelleQuestionnaire,
            exigences: resp.body[i].exigences,

            developpement: resp.body[i].developpement,
            testIntegrationDonnee: resp.body[i].testIntegrationDonnee,
            testValidation: resp.body[i].testValidation,
            scenario_test: resp.body[i].scenario_test,
            observation: resp.body[i].observation,
            supportWeb: resp.body[i].supportWeb,
            supportMobile: resp.body[i].supportMobile,
            dateDebutTest: resp.body[i].dateDebutTest,
            tauxRealisation: resp.body[i].tauxRealisation,
            tauxValidation: resp.body[i].tauxValidation,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            service: resp.body[i].service,
            dateRealisationTest: resp.body[i].dateRealisationTest,
            dateValidation: resp.body[i].dateValidation
          })
          this.dev = resp.body[i].developpement
          this.integre = resp.body[i].testIntegrationDonnee
          this.Validation = resp.body[i].testValidation
          let total = this.dev + this.integre + this.Validation
          let tauxrealisation = total / 3
          let tauxvalidation = this.Validation
        }
      }
    })
  }
  getSousSectionchecktemeTrue(Section) {
    this.itemSousSection2 = []
    this.test.sousSection2(Section).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemSousSection2.push({
            id: resp.body[i].idSousSection,
            text: resp.body[i].libelleSousSection
          })
        }
      }
    })
  }
  getthemeCheckSouThemeFalse(idSousSection) {
    this.itemtheme2 = []
    this.test.theme2(idSousSection).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemtheme2.push({
            idQuest: resp.body[i].idQuest,
            idSection: resp.body[i].idSection,
            idSousSection: resp.body[i].idSousSection,
            idTheme: resp.body[i].idTheme,
            idSousTheme: resp.body[i].idSousTheme,
            idQuestionnaire: resp.body[i].idQuestionnaire,
            ordre: resp.body[i].ordre,

            libelleSection: resp.body[i].libelleSection,
            libelleSousection: resp.body[i].libelleSousSection,
            libelleTheme: resp.body[i].libelleTheme,
            libelleSousTheme: resp.body[i].libelleSousTheme,
            libelleQuestionnaire: resp.body[i].libelleQuestionnaire,
            exigences: resp.body[i].exigences,

            developpement: resp.body[i].developpement,
            testIntegrationDonnee: resp.body[i].testIntegrationDonnee,
            testValidation: resp.body[i].testValidation,
            scenario_test: resp.body[i].scenario_test,
            observation: resp.body[i].observation,
            supportWeb: resp.body[i].supportWeb,
            supportMobile: resp.body[i].supportMobile,
            dateDebutTest: resp.body[i].dateDebutTest,
            tauxRealisation: resp.body[i].tauxRealisation,
            tauxValidation: resp.body[i].tauxValidation,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            service: resp.body[i].service,
            dateRealisationTest: resp.body[i].dateRealisationTest,
            dateValidation: resp.body[i].dateValidation
          })
        }
      }
    })
  }
  getthemeChecksouThemeTrue(idSousSection) {
    this.itemtheme = [];
    this.test.theme(idSousSection).subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemtheme.push({
            id: res.body[i].idTheme,
            text: res.body[i].LibelleTheme,
          })
        }
      }
    })
  }
  getsouthemebyTheme(theme) {
    this.itemsoutheme = []
    this.test.Southeme(theme).subscribe(resp => {
      if (resp.status === 200) {
        for (let i = 0; i < resp.body.length; i++) {
          this.itemsoutheme.push({
            idQuest: resp.body[i].idQuest,
            idSection: resp.body[i].idSection,
            idSousSection: resp.body[i].idSousSection,
            idTheme: resp.body[i].idTheme,
            idSousTheme: resp.body[i].idSousTheme,
            idQuestionnaire: resp.body[i].idQuestionnaire,
            ordre: resp.body[i].ordre,

            libelleSection: resp.body[i].libelleSection,
            libelleSousection: resp.body[i].libelleSousSection,
            libelleTheme: resp.body[i].libelleTheme,
            libelleSousTheme: resp.body[i].libelleSousTheme,
            libelleQuestionnaire: resp.body[i].libelleQuestionnaire,
            exigences: resp.body[i].exigences,

            developpement: resp.body[i].developpement,
            testIntegrationDonnee: resp.body[i].testIntegrationDonnee,
            testValidation: resp.body[i].testValidation,
            scenario_test: resp.body[i].scenario_test,
            observation: resp.body[i].observation,
            supportWeb: resp.body[i].supportWeb,
            supportMobile: resp.body[i].supportMobile,
            dateDebutTest: resp.body[i].dateDebutTest,
            tauxRealisation: resp.body[i].tauxRealisation,
            tauxValidation: resp.body[i].tauxValidation,
            nom: resp.body[i].nom,
            prenom: resp.body[i].prenom,
            service: resp.body[i].service,
            dateRealisationTest: resp.body[i].dateRealisationTest,
            dateValidation: resp.body[i].dateValidation
          })
        }
      }
    })
  }

  precedent() {

  }


  Suivant() {
    const observationGlobal = {
      "section": this.secFormGroup.get('section').value,
      "nom": this.nom,
      "prenom": this.prenom,
      "service": this.status.libelleStatus,
      "observation": this.infoFormGroup.get('Obsrvation_global').value,
    }
    this.dataObservation.push(observationGlobal)

    var data = []
    for (let i = 0; i < this.itemSousSection.length; i++) {
      data.push(
        {
          "idQuest": this.itemSousSection[i].idQuest,
          "idSection": this.itemSousSection[i].idSection,
          "libelleSection": this.itemSousSection[i].libelleSection,
          "idSousSection": this.itemSousSection[i].idSousSection,
          "idQuestionnaire": this.itemSousSection[i].idQuestionnaire,
          "libelleSousSection": this.itemSousSection[i].libelleSousection,
          "idTheme": this.itemSousSection[i].idTheme,
          "libelleTheme": this.itemSousSection[i].libelleTheme,
          "idSousTheme": this.itemSousSection[i].idSousTheme,
          "libelleQuestionnaire": this.itemSousSection[i].libelleQuestionnaire,
          "ordre": this.itemSousSection[i].ordre,

          "exigences": this.itemSousSection[i].exigences,
          "developpement": this.itemSousSection[i].developpement,
          "testIntegrationDonnee": this.itemSousSection[i].testIntegrationDonnee,
          "testValidation": this.itemSousSection[i].testValidation,
          "scenario_test": this.itemSousSection[i].scenario_test,
          "observation": this.itemSousSection[i].observation,
          "supportWeb": this.itemSousSection[i].supportWeb,
          "supportMobile": this.itemSousSection[i].supportMobile,
          "tauxRealisation": this.itemSousSection[i].tauxRealisation,
          "tauxValidation": this.itemSousSection[i].tauxValidation,
          "libelleSousTheme": this.itemSousSection[i].libelleSousTheme,
        })
    }


    for (let i = 0; i < this.itemtheme2.length; i++) {
      data.push(
        {
          "idQuest": this.itemtheme2[i].idQuest,
          "idSection": this.itemtheme2[i].idSection,
          "libelleSection": this.itemtheme2[i].libelleSection,
          "idSousSection": this.itemtheme2[i].idSousSection,
          "idQuestionnaire": this.itemtheme2[i].idQuestionnaire,
          "libelleSousSection": this.itemtheme2[i].libelleSousection,
          "idTheme": this.itemtheme2[i].idTheme,
          "libelleTheme": this.itemtheme2[i].libelleTheme,
          "idSousTheme": this.itemtheme2[i].idSousTheme,
          "libelleQuestionnaire": this.itemtheme2[i].libelleQuestionnaire,
          "ordre": this.itemtheme2[i].ordre,

          "exigences": this.itemtheme2[i].exigences,
          "developpement": this.itemtheme2[i].developpement,
          "testIntegrationDonnee": this.itemtheme2[i].testIntegrationDonnee,
          "testValidation": this.itemtheme2[i].testValidation,
          "scenario_test": this.itemtheme2[i].scenario_test,
          "observation": this.itemtheme2[i].observation,
          "supportWeb": this.itemtheme2[i].supportWeb,
          "supportMobile": this.itemtheme2[i].supportMobile,
          "tauxRealisation": this.itemtheme2[i].tauxRealisation,
          "tauxValidation": this.itemtheme2[i].tauxValidation,
          "libelleSousTheme": this.itemtheme2[i].libelleSousTheme,
        })
    }

    for (let i = 0; i < this.itemsoutheme.length; i++) {
      data.push(
        {
          "idQuest": this.itemsoutheme[i].idQuest,
          "idSection": this.itemsoutheme[i].idSection,
          "libelleSection": this.itemsoutheme[i].libelleSection,
          "idSousSection": this.itemsoutheme[i].idSousSection,
          "idQuestionnaire": this.itemsoutheme[i].idQuestionnaire,
          "libelleSousSection": this.itemsoutheme[i].libelleSousection,
          "idTheme": this.itemsoutheme[i].idTheme,
          "libelleTheme": this.itemsoutheme[i].libelleTheme,
          "idSousTheme": this.itemsoutheme[i].idSousTheme,
          "libelleQuestionnaire": this.itemsoutheme[i].libelleQuestionnaire,
          "ordre": this.itemsoutheme[i].ordre,

          "exigences": this.itemsoutheme[i].exigences,
          "developpement": this.itemsoutheme[i].developpement,
          "testIntegrationDonnee": this.itemsoutheme[i].testIntegrationDonnee,
          "testValidation": this.itemsoutheme[i].testValidation,
          "scenario_test": this.itemsoutheme[i].scenario_test,
          "observation": this.itemsoutheme[i].observation,
          "supportWeb": this.itemsoutheme[i].supportWeb,
          "supportMobile": this.itemsoutheme[i].supportMobile,
          "tauxRealisation": this.itemsoutheme[i].tauxRealisation,
          "tauxValidation": this.itemsoutheme[i].tauxValidation,
          "libelleSousTheme": this.itemsoutheme[i].libelleSousTheme,
        })
    }
    this.itemCheckthemes = []
    this.itemCheckSouthemes = []
    this.dataEnvoye.push(data)
    this.infoFormGroup.reset()
  }


  Enregistrez() {
    const observationGlobal =
      [{
        "section": this.secFormGroup.get('section').value,
        "nom": this.nom,
        "prenom": this.prenom,
        "service": this.status.libelleStatus,
        "observation": this.infoFormGroup.get('Obsrvation_global').value,
      }]
    this.test.saveObservation(observationGlobal).subscribe(data =>
      console.log('data=>', data)
    )


    const Observation2 = []
    for (let i = 0; i < this.dataObservation.length; i++) {
      Observation2.push(
        {
          "section": this.dataObservation[i].section,
          "nom": this.dataObservation[i].nom,
          "prenom": this.dataObservation[i].prenom,
          "service": this.dataObservation[i].service,
          "observation": this.dataObservation[i].observation,
        }
      )
    }
    this.test.saveObservation(Observation2).subscribe(data =>
      console.log('data=>', data)
    )


    const data = []
    for (let i = 0; i < this.itemsoutheme.length; i++) {
      data.push(
        {
          "idQuest": this.itemsoutheme[i].idQuest,
          "idSection": this.itemsoutheme[i].idSection,
          "libelleSection": this.itemsoutheme[i].libelleSection,
          "idSousSection": this.itemsoutheme[i].idSousSection,
          "idQuestionnaire": this.itemsoutheme[i].idQuestionnaire,
          "libelleSousSection": this.itemsoutheme[i].libelleSousection,
          "idTheme": this.itemsoutheme[i].idTheme,
          "libelleTheme": this.itemsoutheme[i].libelleTheme,
          "idSousTheme": this.itemsoutheme[i].idSousTheme,
          "libelleQuestionnaire": this.itemsoutheme[i].libelleQuestionnaire,
          "ordre": this.itemsoutheme[i].ordre,

          "exigences": this.itemsoutheme[i].exigences,
          "developpement": this.itemsoutheme[i].developpement,
          "testIntegrationDonnee": this.itemsoutheme[i].testIntegrationDonnee,
          "testValidation": this.itemsoutheme[i].testValidation,
          "scenario_test": this.itemsoutheme[i].scenario_test,
          "observation": this.itemsoutheme[i].observation,
          "supportWeb": this.itemsoutheme[i].supportWeb,
          "supportMobile": this.itemsoutheme[i].supportMobile,
          "tauxRealisation": this.itemsoutheme[i].tauxRealisation,
          "tauxValidation": this.itemsoutheme[i].tauxValidation,
          "libelleSousTheme": this.itemsoutheme[i].libelleSousTheme,
          "nom": this.nom,
          "prenom": this.prenom,
          "service": this.status.libelleStatus
        })
    }
    for (let i = 0; i < this.itemtheme2.length; i++) {
      data.push(
        {
          "idQuest": this.itemtheme2[i].idQuest,
          "idSection": this.itemtheme2[i].idSection,
          "libelleSection": this.itemtheme2[i].libelleSection,
          "idSousSection": this.itemtheme2[i].idSousSection,
          "idQuestionnaire": this.itemtheme2[i].idQuestionnaire,
          "libelleSousSection": this.itemtheme2[i].libelleSousection,
          "idTheme": this.itemtheme2[i].idTheme,
          "libelleTheme": this.itemtheme2[i].libelleTheme,
          "idSousTheme": this.itemtheme2[i].idSousTheme,
          "libelleQuestionnaire": this.itemtheme2[i].libelleQuestionnaire,
          "ordre": this.itemtheme2[i].ordre,

          "exigences": this.itemtheme2[i].exigences,
          "developpement": this.itemtheme2[i].developpement,
          "testIntegrationDonnee": this.itemtheme2[i].testIntegrationDonnee,
          "testValidation": this.itemtheme2[i].testValidation,
          "scenario_test": this.itemtheme2[i].scenario_test,
          "observation": this.itemtheme2[i].observation,
          "supportWeb": this.itemtheme2[i].supportWeb,
          "supportMobile": this.itemtheme2[i].supportMobile,
          "tauxRealisation": this.itemtheme2[i].tauxRealisation,
          "tauxValidation": this.itemtheme2[i].tauxValidation,
          "libelleSousTheme": this.itemtheme2[i].libelleSousTheme,
          "nom": this.nom,
          "prenom": this.prenom,
          "service": this.status.libelleStatus
        })
    }
    for (let i = 0; i < this.itemSousSection.length; i++) {
      data.push(
        {
          "idQuest": this.itemSousSection[i].idQuest,
          "idSection": this.itemSousSection[i].idSection,
          "libelleSection": this.itemSousSection[i].libelleSection,
          "idSousSection": this.itemSousSection[i].idSousSection,
          "idQuestionnaire": this.itemSousSection[i].idQuestionnaire,
          "libelleSousSection": this.itemSousSection[i].libelleSousection,
          "idTheme": this.itemSousSection[i].idTheme,
          "libelleTheme": this.itemSousSection[i].libelleTheme,
          "idSousTheme": this.itemSousSection[i].idSousTheme,
          "libelleQuestionnaire": this.itemSousSection[i].libelleQuestionnaire,
          "ordre": this.itemSousSection[i].ordre,

          "exigences": this.itemSousSection[i].exigences,
          "developpement": this.itemSousSection[i].developpement,
          "testIntegrationDonnee": this.itemSousSection[i].testIntegrationDonnee,
          "testValidation": this.itemSousSection[i].testValidation,
          "scenario_test": this.itemSousSection[i].scenario_test,
          "observation": this.itemSousSection[i].observation,
          "supportWeb": this.itemSousSection[i].supportWeb,
          "supportMobile": this.itemSousSection[i].supportMobile,
          "tauxRealisation": this.itemSousSection[i].tauxRealisation,
          "tauxValidation": this.itemSousSection[i].tauxValidation,
          "libelleSousTheme": this.itemSousSection[i].libelleSousTheme,
          "nom": this.nom,
          "prenom": this.prenom,
          "service": this.status.libelleStatus
        })
    }

    for (let i = 0; i < this.dataEnvoye.length; i++) {
      for (let k = 0; k < this.dataEnvoye[i].length; k++) {
        data.push(
          {
            "idQuest": this.dataEnvoye[i][k].idQuest,
            "idSection": this.dataEnvoye[i][k].idSection,
            "libelleSection": this.dataEnvoye[i][k].libelleSection,
            "idSousSection": this.dataEnvoye[i][k].idSousSection,
            "idQuestionnaire": this.dataEnvoye[i][k].idQuestionnaire,
            "libelleSousSection": this.dataEnvoye[i][k].libelleSousSection,
            "idTheme": this.dataEnvoye[i][k].idTheme,
            "libelleTheme": this.dataEnvoye[i][k].libelleTheme,
            "idSousTheme": this.dataEnvoye[i][k].idSousTheme,
            "libelleSousTheme": this.dataEnvoye[i][k].libelleSousTheme,
            "libelleQuestionnaire": this.dataEnvoye[i][k].libelleQuestionnaire,
            "ordre": this.dataEnvoye[i][k].ordre,
            "exigences": this.dataEnvoye[i][k].exigences,
            "developpement": this.dataEnvoye[i][k].developpement,
            "testIntegrationDonnee": this.dataEnvoye[i][k].testIntegrationDonnee,
            "testValidation": this.dataEnvoye[i][k].testValidation,
            "scenario_test": this.dataEnvoye[i][k].scenario_test,
            "supportWeb": this.dataEnvoye[i][k].supportWeb,
            "supportMobile": this.dataEnvoye[i][k].supportMobile,
            "tauxRealisation": this.dataEnvoye[i][k].tauxRealisation,
            "tauxValidation": this.dataEnvoye[i][k].tauxValidation,
            "observation": this.dataEnvoye[i][k].observation,
            "nom": this.nom,
            "prenom": this.prenom,
            "service": this.status.libelleStatus
          }
        )
      }
    }
    const alefa = {
      "observationGlobale": this.infoFormGroup.get('Obsrvation_global').value,
      "listParam": data
    }
    this.test.register(alefa).subscribe(data => {
      if (data.status === 200) {
        this.router.navigate(['validation']);
      }

    })


  }

}

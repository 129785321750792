import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AccountService } from '../../services/account/account.service';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.css']
})
export class CardComponent implements OnInit {

  @ViewChild('screen') screen: ElementRef;
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('downloadLink') downloadLink: ElementRef;

  page = 1;
  pageSize = 8;
  collectionSize: any;
  returnUrl: string
  showModal: boolean
  showModal2: boolean
  details: any
  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  listProducteur: any
  detail: any
  data: any
  closeModal: string;



  constructor() { }

  ngOnInit(): void {
  }
  generatePDF() {
    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      const imgWidth = 210;
      const imgHeight = canvas.height * imgWidth / canvas.width;
      const contentDataURL = canvas.toDataURL('image/png')
      const img = new jsPDF('p', 'mm', 'a4');
      const position = 0;
      img.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight)
      img.save('newPDF.pdf');
    });

  }
  downloadImage() {
    const data = document.getElementById('contentToConvert');
    html2canvas(data).then(canvas => {
      const imgWidth = 1004;
      const imgHeight = 650;
      this.canvas.nativeElement.src = canvas.toDataURL();
      this.downloadLink.nativeElement.href = canvas.toDataURL('image/png');
      this.downloadLink.nativeElement.download = 'card.png';
      this.downloadLink.nativeElement.click();
    });
  }
  hide() {
    this.showModal = false;
  }

}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountService } from "app/services/account/account.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-ajout-paysane",
  templateUrl: "./ajout-paysane.component.html",
  styleUrls: ["./ajout-paysane.component.css"],
})
export class AjoutPaysaneComponent implements OnInit {
  organisation: FormGroup;

  constructor(
    private _formBuilder: FormBuilder,
    private ac: AccountService,
    private toastr: ToastrService,
    private route: Router
  ) {}

  ngOnInit(): void {
    this.organisation = this._formBuilder.group({
      nomOrganis: [""],
      fonctionnaliteOrganis: [""],
      nomOprAffiliation: [""],
      affiliationOP: [""],
      adresseOrgani: [""],
      nomPresident: [""],
      adrsPresident: [""],
      dateFinMandat: [""],
      dateCreation: [""],
      numRecepise: [""],
      couvertureGeo: [""],
      nbreHommeOrgan: [""],
      nbreFemmeOrgani: [""],
      affiliation_OP_IMF_IF: [""],
      numBanque: [""],
      beneficiaireAppuiPaysane: [""],
      oganiseintervention: [""],
      statusOrganise: [""],
      domaineInteventionOrganisation: [""],
      raisonNonFonct: [""],
      dateOpIMF: [""],
      affiliation: [""],
    });
  }
  submit() {
    const data = {
      nomOrgan: this.organisation.get("nomOrganis").value,
      fonctionnaliteOrganis: this.organisation.get("fonctionnaliteOrganis")
        .value,
      nomOprAffiliation: this.organisation.get("nomOprAffiliation").value,
      affiliationOP: this.organisation.get("affiliationOP").value,
      adresseOrgani: this.organisation.get("adresseOrgani").value,
      nomPresident: this.organisation.get("nomPresident").value,
      adrsPresident: this.organisation.get("adrsPresident").value,
      dateFinMandat: this.organisation.get("dateFinMandat").value,
      dateCreation: this.organisation.get("dateCreation").value,
      numRecepise: this.organisation.get("numRecepise").value,
      couvertureGeo: this.organisation.get("couvertureGeo").value,
      nbreHommeOrgan: this.organisation.get("nbreHommeOrgan").value,
      nbreFemmeOrgani: this.organisation.get("nbreFemmeOrgani").value,
      affiliation_OP_IMF_IF: this.organisation.get("affiliation_OP_IMF_IF")
        .value,
      numBanque: this.organisation.get("numBanque").value,
      beneficiaireAppuiPaysane: this.organisation.get(
        "beneficiaireAppuiPaysane"
      ).value,
      oganiseintervention: this.organisation.get("oganiseintervention").value,
      statusOrganise: this.organisation.get("statusOrganise").value,
      domaineInteventionOrganisation: this.organisation.get(
        "domaineInteventionOrganisation"
      ).value,
      raisonNonFonct: this.organisation.get("raisonNonFonct").value,
      dateOpIMF: this.organisation.get("dateOpIMF").value,
      affiliation: this.organisation.get("affiliation").value,
    };
    this.ac.saveOrganisationPaysane(data).subscribe((dataBomm) => {
      if (dataBomm.status === 200) {
        this.toastr.success("Ajout avec success");
        this.route.navigate(["orgPays"]);
      }
    });
  }
}

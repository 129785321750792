import { Component, OnInit } from '@angular/core';
import { AccountService } from "../services/account/account.service";
import { any } from 'codelyzer/util/function';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-profil-individus',
  templateUrl: './profil-individus.component.html',
  styleUrls: ['./profil-individus.component.css']
})
export class ProfilIndividusComponent implements OnInit {

  prenom: any
  nom: any
  contact: any
  mail: any
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  constructor(
    private accountService: AccountService, 
    private router: Router,
    private route: ActivatedRoute,) { }

  ngOnInit(): void {
    this.getLocalStorage()
  }
  getLocalStorage() {
    const values = JSON.parse(localStorage.getItem('user'));
    this.prenom = values.individu.prenom
    this.nom = values.individu.nom
    this.contact = values.individu.contact
    this.mail = values.individu.mail
    this.lieuNaiss = values.individu.lieuNaiss
    this.dateNaiss = values.individu.dateNaiss
    this.cin = values.individu.cin
    this.sexe = values.individu.sexe
    this.libelleStatus = values.status.libelleStatus
    this.national = values.individu.national
    this.nomFokontany = values.individu.fokontany.nomFokontany
    this.nomCommune = values.individu.commune.nomCommune
    this.nomDistrict = values.individu.district.nomDistrict
    this.nomRegion = values.individu.region.nomRegion
    this.nomProvince = values.individu.province.nomProvince
    this.imgIndividu = values.individu.imgIndividu
    this.status = values.status[0]
    this.imgIndividu = values.individu.photos
  }

  home() {
    this.router.navigate(['acceuil']);
  }
  mandeha() {
    this.router.navigate(['ajout_prod']);
  }
}

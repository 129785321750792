import { Component, OnInit } from '@angular/core';
import { AccountService } from 'app/services/account/account.service';
import { DragdropService } from 'app/services/dropdrag/dragdrop.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-listeequete',
  templateUrl: './listeequete.component.html',
  styleUrls: ['./listeequete.component.css']
})
export class ListeequeteComponent implements OnInit {
  liste: any[]=[];

  constructor(
    private service: DragdropService,
    private accountService: AccountService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getAlllisteEnquete()
  }
  getAlllisteEnquete() {
    this.service.listeDesEnquetes().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.liste.push({
            id: res.body[i].idEnquete,
            text: res.body[i].nomEnquete,
            date: res.body[i].dateInsert,
            observation: res.body[i].observation
          })
        }
      }
    })
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
}

import { Component, OnInit } from '@angular/core';
import { CardService } from '../services/card/card.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-histappui',
  templateUrl: './histappui.component.html',
  styleUrls: ['./histappui.component.css']
})
export class HistappuiComponent implements OnInit {
  id: string;
  dataAppui: any;
  dataIndiv: any;
  sexe: any;
  imgIndividu: any

  constructor(
    private cardServ: CardService,
    private route: ActivatedRoute,
    private router: Router,
    private _location: Location,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getListAppui(this.id)
    this.getListIndividu(this.id)
  }
  getListAppui(id){
    this.cardServ.getAppui(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataAppui = res.body;
      }
    })
  }
  retour() { 
    this._location.back();
  }
  getListIndividu(id){
    this.cardServ.getAllIndividu(id).subscribe(res =>{
      if (res.status == 200) {
        this.dataIndiv = res.body;
      }
    })
  }
  activ() {
    this.router.navigate(['sectact/' + this.id]);
  }
  home() {
    this.router.navigate(['acceuil']);
  }
  menfamille() {
    this.router.navigate(['menfamille/' + this.id]);
  }
  parcelle() {
    this.router.navigate(['parcelle/' + this.id]);
  }
}

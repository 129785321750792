import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-servicefin',
  templateUrl: './servicefin.component.html',
  styleUrls: ['./servicefin.component.css']
})
export class ServicefinComponent implements OnInit {
  id: string
  service: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getServiceFinancier()
  }
  getServiceFinancier(){
    this.list.getServiceFinance(this.id).subscribe(resp=>{
      this.service = resp.body
    })
  }

}

import { TestService } from './../../services/realisation/test.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-liste-realisationglobal',
  templateUrl: './liste-realisationglobal.component.html',
  styleUrls: ['./liste-realisationglobal.component.css']
})
export class ListeRealisationglobalComponent implements OnInit {
  listes:any[]

  constructor(private validationService: TestService,
    private router: Router) { }

  ngOnInit(): void {
    this.validationService.listeRealisationTest().subscribe(data => {
      this.listes = data.body
      if(data.status === 200){
        const name = data.body.map(o => o.nom)
        const filtered = data.body.filter(({nom}, index) => !name.includes(nom, index + 1))
        this.listes = filtered
      }
    })
  }
  detail(nom: string){
    this.router.navigate(['detaillisterealisation/'+nom])
  }

}

import { InputBase } from './../base-input/input-base';
import { Injectable } from '@angular/core';
import { InputTextBox } from '../base-input/input-textbox';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class InputService {

  constructor() { }

  setValidFormDataForDynamicForms2(listForm, exception: any[]) {
    const inputs: InputBase<any>[] = [];
    for (let i = 0; i < listForm.length; i++) {
      let there_is_exception = -1;
      for (let j = 0; j < exception.length; j++) {
        if (listForm[i].id_type_info == exception[j].id_type_info) {
          there_is_exception = j;
        }
      }
      if (there_is_exception < 0) {
        const inputTextBox = new InputTextBox({
          key: listForm[i].id_type_info,
          label: listForm[i].libelle,
          type: listForm[i].type_champ,
          value: '',
          required: true
        });
        inputs.push(inputTextBox);
      } else {
        const inputTextBox = new InputTextBox({
          key: listForm[i].id_type_info,
          label: listForm[i].libelle,
          type: listForm[i].type_champ,
          value: exception[there_is_exception].val != null ? exception[there_is_exception].val : '',
          required: exception[there_is_exception].isrequired,
          readonly: exception[there_is_exception].readonly
        });
        inputs.push(inputTextBox);
      }
    }
    return inputs;
  }

  setValidFormDataForDynamicForms(listForm) {
    const inputs: InputBase<any>[] = [];
    for (let i = 0; i < listForm.length; i++) {
      const inputTextBox = new InputTextBox({
        key: listForm[i].id_type_info,
        label: listForm[i].libelle,
        type: listForm[i].type_champ,
        value: '',
        required: true
      });
      inputs.push(inputTextBox);
    }
    return inputs;
  }

  toFormGroup(input: InputBase<string>[] ) {
    const group: any = {};

    input.forEach(question => {
      group[question.key] = question.required ? new FormControl(question.value || '', Validators.required)
                                              : new FormControl(question.value || '');
    });
    return new FormGroup(group);
  }

  
}

import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ListService } from "app/services/list/list.service";

@Component({
  selector: "app-organisatin-paysane",
  templateUrl: "./organisatin-paysane.component.html",
  styleUrls: ["./organisatin-paysane.component.css"],
})
export class OrganisatinPaysaneComponent implements OnInit {
  id: string;
  listeOrganisation: any[] = [];
  constructor(private route: ActivatedRoute, private list: ListService) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get("id");
    this.getOrganisation();
  }
  getOrganisation() {
    this.list.getOrganisationByMatricule().subscribe((resp) => {
      this.listeOrganisation = resp.body;
    });
  }
}

import { Component, OnInit } from '@angular/core';
import { AccountService } from '../services/account/account.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CardService } from '../services/card/card.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-tableadmin',
  templateUrl: './tableadmin.component.html',
  styleUrls: ['./tableadmin.component.css']
})
export class TableadminComponent implements OnInit {
  searchText: any;
  prenom: any
  nom: any
  totalItems: any;
  totalPages: any;
  contact: any
  pagination: any;
  mail: any
  currentPage: number = 1;
  lieuNaiss: any
  dateNaiss: any
  cin: any
  sexe: any
  libelleStatus: any
  codePostal: any
  nomFokontany: any
  imgIndividu: any
  nomCommune: any
  nomDistrict: any
  nomRegion: any
  nomProvince: any
  lot: any
  national: any
  status: any
  dataNonValider: any;
  dataValider: any;
  admin: any = {};
  token: any;
  activeTech: any = {};
  show: false;
  userToDelete: any;
  constructor(
    private accountService: AccountService,
    private router: Router,
    private cardServ: CardService,
    private toast: ToastrService) {
  
  }

  ngOnInit(): void {
    this.admin = JSON.parse(localStorage.getItem('user'));
    this.getListTechValider()
  }
  openModal(matricule) {
    this.userToDelete = this.dataValider.find(x => x.matricule === matricule);
    $('#exampleModalLongConfsupp').modal('show');
}
  getListTechValider() {
    this.accountService.getAllTechValider(this.currentPage).subscribe((res) => {
      if (res.status == 200) {
        this.dataValider = res.body.content;
        this.pagination = res.body;
        this.totalPages = this.pagination.totalPages;      
        this.currentPage = parseInt(this.pagination.currentPage) + 1;
        this.totalItems = this.pagination.totalPages * this.pagination.totalPages; 
      }
    })
  }

  suppr() {
    this.accountService.deleteUser(this.userToDelete.matricule).subscribe(
        (response) => {
            this.toast.success('Suppression réussie')
            window.location.reload();
          
        },(error) => {
          this.toast.error("Une erreur c'est produit");
        }
    );
}

  changeValue(page: number) {
    this.show = false;
    if (page) {
      this.currentPage = page;
      this.getListTechValider()
    }
  }
  home() {
    this.router.navigate(['acceuil']);
  }
  TechNV() {
    this.router.navigate(['technonvalider']);
  }
  TechV() {
    this.router.navigate(['tableadmin']);
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
  valider(){
    for (let i = 0; i < this.dataNonValider.length; i++) {
      const matr = this.dataNonValider[i].matricule
    this.accountService.validerTechnicien(matr).subscribe(resp =>{
      if (resp.status == 200) {
        this.toast.success('Validation technicien avec success')
        this.ngOnInit()
      }else{
        this.toast.error('Une erreur s\'est produite')
      }
    })
    }
  }
}
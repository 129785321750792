import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detailprod',
  templateUrl: './detailprod.component.html',
  styleUrls: ['./detailprod.component.css']
})
export class DetailprodComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

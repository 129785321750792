export enum Role {
    enquêteur= 'Enquêteur',
    admin= 'Agent Administratif',
    directeur = 'Directeur',
    admine = 'Admin',
    superviseur = 'Superviseur',
    fda = 'FDA',
    ministre = 'Ministre',
    coordonnateur ='Coordonnateur',
    sg = 'SG',
    GestionnaireDeCarte = 'Gestionnaire de carte'
}

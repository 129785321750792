import { Component, OnInit } from '@angular/core';
import { DragdropService } from '../services/dropdrag/dragdrop.service';
import { ToastrService } from 'ngx-toastr';

import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AccountService } from 'app/services/account/account.service';

@Component({
  selector: 'app-dragdrop',
  templateUrl: './dragdrop.component.html',
  styleUrls: ['./dragdrop.component.css']
})
export class DragdropComponent implements OnInit {
  panelOpenState = false;


  itemSection: any[] = [];
  dataSousSection: any[] = [];
  sousSection: any[] = [];
  dataSelection: any[] = [];
  ENQUETE: any[]=[];
  items : any[] = [];
  submitted: false;

  dinameFormGroup: FormGroup;
  Section: string;
  secteur: string;
  ListeSousSectionThemeNonVide: any[];
  listeSouSectionThemevide: any[];
  Soussection: string;
  prendListeSiSouThemeVide: any[];
  itemtheme: any[];
  themes: string;
  itemSoustheme: any[];
  sectionFormGroup: FormGroup;
  QuestionnaireElevage: any[];
  QuestionnaireElevageBySTheme: any[];
  enqueteFormGroup: FormGroup;
  
  itemthemes: any;
  itemSouthemes: any;
  sousSectionFormGroup: FormGroup;
  fleche: boolean = false
  sectionName: any;
  itemsEnquetes: any[]=[];
  selectAll: any[]=[];
  matrEnqueteur: any;

  constructor(
    private _formBuilder: FormBuilder,
    private dd: DragdropService,
    private toastr: ToastrService,
    private route: Router,
    private router: Router, 
    private accountService: AccountService
  ) { }

ngOnInit(): void {
  const data = JSON.parse(localStorage.getItem('user'))
    this.matrEnqueteur = data.matricule
    this.getAllSection();
    this.sectionFormGroup = this._formBuilder.group({
      section:[''], 
      sous_section: [''],
      theme: [''],
      sous_theme: ['']
    })

    this.enqueteFormGroup = this._formBuilder.group({
      nom_enquête: ['', Validators.required],
      Observation: ['', Validators.required],
    })
  }
getAllSection() {
    this.dd.findAllSection().subscribe(res => {
      if (res.status === 200) {
        for (let i = 0; i < res.body.length; i++) {
          this.itemSection.push({
            id: res.body[i].IdSection,
            text: res.body[i].libelleSection
          })
        }
      }
    })
  }


checkTeme(Section) {
 this.dd.Checktheme(Section).subscribe(resp => {
   this.itemthemes = resp.body
  })
}
checkSouTeme(sousSection) {
  this.dd.CheckSousThemes(sousSection).subscribe(resp => {
    this.itemSouthemes = resp.body
   })
 }

section(id) {
    this.listeSouSectionThemeVide(id.value)
    this.ListeSousSectionThmeNonVide(id.value)
    this.checkTeme(id.value)
    this.Section = id.value
    for (let i = 0; i < this.itemSection.length; i++) {
      if (this.itemSection[i].id === id.value) {
       this.sectionName = this.itemSection[i].text;
      }
    }
    this.itemSouthemes = []
    this.fleche = true
}
SousSection(id) {
  this.prendListethemeSouthemeVide(id.value)
  this.getQuestionnaireActivite(id.value)
  this.checkSouTeme(id.value)
  this.Soussection = id.value
  this.itemSouthemes = []
}
theme(id) {
  this.getSoustheme(id.value)
  this.getQuestionnaireByTheme(id.value)
  this.themes = id.value
}
soustheme(id) {
  this.getQuestionnaireBySousTheme(id.value)
  this.themes = id.value
}
ListeSousSectionThmeNonVide(Section){
  this.ListeSousSectionThemeNonVide = []
  this.dd.ListesousSectionThemeNonvide(Section).subscribe(resp =>  {
    if (resp.status === 200) {
      for (let i = 0; i < resp.body.length; i++) {
        this.ListeSousSectionThemeNonVide.push({
          id: resp.body[i].idSousSection,
          text: resp.body[i].libelleSousSection
        })
      }
    }
  })
}
prendListethemeSouthemeVide(idSousSection){
  this.itemtheme = [];
  this.dd.prendListethemeSouthemeVide(idSousSection).subscribe(res => {
    if (res.status === 200) {
      for (let i = 0; i < res.body.length; i++) {
        this.itemtheme.push({
          id: res.body[i].idTheme,
          text: res.body[i].LibelleTheme
        })
      }
    }
  })
}
getSoustheme(idtheme){
  this.itemSoustheme = [];
  this.dd.Soustheme(idtheme).subscribe(res => {
    if (res.status === 200) {
      for (let i = 0; i < res.body.length; i++) {
        this.itemSoustheme.push({
          id: res.body[i].IdSousTheme,
          text: res.body[i].libelleSousTheme
        })
      }
    }
  })
}
getQuestionnaireByTheme(idTheme){
  this.dataSelection = [];
  this.dd.getQuestionnaireBytheme(idTheme).subscribe((resp) =>{
    if (resp.status == 200) {
      let data: any[] = [];
      if (resp.body.length > 0) {
          for (let i = 0; i < resp.body.length; i++) {
            data.push({
              idsection:resp.body[i].idSection,
              section:resp.body[i].libelleSection,
              idQ: resp.body[i].idQuestionnaire,
              text: resp.body[i].libelleQuestionnaire,
              idSousection: resp.body[i].idSousSection,
              soussection:resp.body[i].libelleSousSection,
              idtheme: resp.body[i].idTheme,
              theme:resp.body[i].libelleTheme,
              idSoustheme: resp.body[i].idSousTheme,
              soustheme:resp.body[i].libelleSousTheme,
              ordre:resp.body[i].ordre,
              controltype:resp.body[i].controleType,
              lybelletype:resp.body[i].libelleType
            })
          }
          this.QuestionnaireElevage = data;
          this.selectAll = this.QuestionnaireElevage
      }
    }
  })
}
getQuestionnaireBySousTheme(idSousTheme){
  this.dataSelection = [];
  this.dd.getQuestionnaireBySoustheme(idSousTheme).subscribe((resp) =>{
    if (resp.status == 200) {
      let data: any[] = [];
      if (resp.body.length > 0) {
          for (let i = 0; i < resp.body.length; i++) {
            data.push({
              idsection:resp.body[i].idSection,
              section:resp.body[i].libelleSection,
              idQ: resp.body[i].idQuestionnaire,
              text: resp.body[i].libelleQuestionnaire,
              idSousection: resp.body[i].idSousSection,
              soussection:resp.body[i].libelleSousSection,
              idtheme: resp.body[i].idTheme,
              theme:resp.body[i].libelleTheme,
              idSoustheme: resp.body[i].idSousTheme,
              soustheme:resp.body[i].libelleSousTheme,
              ordre:resp.body[i].ordre,
              controltype:resp.body[i].controleType,
              lybelletype:resp.body[i].libelleType
            })
          }
          this.QuestionnaireElevageBySTheme = data;
          this.selectAll = this.QuestionnaireElevageBySTheme
      }
    }
  })
}
listeSouSectionThemeVide(Section){
  this.dataSelection = [];
  this.dd.ListeSouSectionThemeVide(Section).subscribe((resp) =>{
    if (resp.status == 200) {
      let data: any[] = [];
      if (resp.body.length > 0) {
          for (let i = 0; i < resp.body.length; i++) {
            data.push({
              idsection:resp.body[i].idSection,
              section:resp.body[i].libelleSection,
              idQ: resp.body[i].idQuestionnaire,
              text: resp.body[i].libelleQuestionnaire,
              idSousection: resp.body[i].idSousSection,
              soussection:resp.body[i].libelleSousSection,
              idtheme: resp.body[i].idTheme,
              theme:resp.body[i].libelleTheme,
              idSoustheme: resp.body[i].idSousTheme,
              soustheme:resp.body[i].libelleSousTheme,
              ordre:resp.body[i].ordre,
              controltype:resp.body[i].controleType,
              lybelletype:resp.body[i].libelleType
            })
          }
          this.listeSouSectionThemevide = data;
          this.selectAll = this.listeSouSectionThemevide
      }
    }
  })
}
getQuestionnaireActivite(idSousSection){
  this.dataSelection = [];
  this.dd.prendListeSiSouThemeVide(idSousSection).subscribe((resp) =>{
    if (resp.status == 200) {
      let data: any[] = [];
      if (resp.body.length > 0) {
          for (let i = 0; i < resp.body.length; i++) {
            data.push({
              idsection:resp.body[i].idSection,
              section:resp.body[i].libelleSection,
              idQ: resp.body[i].idQuestionnaire,
              text: resp.body[i].libelleQuestionnaire,
              idSousection: resp.body[i].idSousSection,
              soussection:resp.body[i].libelleSousSection,
              idtheme: resp.body[i].idTheme,
              theme:resp.body[i].libelleTheme,
              idSoustheme: resp.body[i].idSousTheme,
              soustheme:resp.body[i].libelleSousTheme,
              ordre:resp.body[i].ordre,
              controltype:resp.body[i].controleType,
              lybelletype:resp.body[i].libelleType
            })
          }
          this.prendListeSiSouThemeVide = data;
          this.selectAll = this.prendListeSiSouThemeVide
      }
    }
  })
}
drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }
  toutSousection(){
    this.dataSelection = this.selectAll
    this.listeSouSectionThemevide = []
    this.QuestionnaireElevage = []
    this.prendListeSiSouThemeVide = []
  }
  Suivant(){
  for (let i = 0; i < this.dataSelection.length; i++) {

  }
  this.ENQUETE.push(this.dataSelection)
  this.sectionFormGroup.reset()
  /* this.sousSectionFormGroup.reset() */
  this.dataSelection = []
  this.listeSouSectionThemevide = []
  this.prendListeSiSouThemeVide = []
  this.QuestionnaireElevage = []
  this.QuestionnaireElevageBySTheme = []
  this.itemthemes = []
  this.itemSouthemes = []
  this.fleche = false
  }

  Valider(){
  var data = []
  for (let i = 0; i < this.dataSelection.length; i++) {
      data.push(
        {
         "control_type":         this.dataSelection [i].controltype,
         "idQuestionnaire":      this.dataSelection [i].idQ,
         "idSection":            this.dataSelection [i].idsection,
         "idSousSection":        this.dataSelection [i].idSousection,
         "idSousTheme":          this.dataSelection [i].idSoustheme,
         "idTheme":              this.dataSelection [i].idtheme,
         "libelleQuestionnaire": this.dataSelection [i].text,
         "libelleSection":       this.dataSelection [i].section,
         "libelleSousSection":   this.dataSelection [i].soussection,
         "libelleSousTheme":     this.dataSelection [i].soustheme,
         "libelleTheme":         this.dataSelection [i].theme,
         "libelle_type":         this.dataSelection [i].lybelletype,
         "matriculeAjout":       this.matrEnqueteur,
         "ordre":                this.dataSelection [i].ordre,
         "nomEnquete":           this.enqueteFormGroup.get('nom_enquête').value,
         "observation":          this.enqueteFormGroup.get('Observation').value,
        }
      )
       }
  for (let i = 0; i < this.ENQUETE.length; i++) {
    for (let k = 0; k < this.ENQUETE[i].length; k++) {
        data.push(
          {
            "control_type":         this.ENQUETE [i][k].controltype,
            "idQuestionnaire":      this.ENQUETE [i][k].idQ,
            "idSection":            this.ENQUETE [i][k].idsection,
            "idSousSection":        this.ENQUETE [i][k].idSousection,
            "idSousTheme":          this.ENQUETE [i][k].idSoustheme,
            "idTheme":              this.ENQUETE [i][k].idtheme,
            "libelleQuestionnaire": this.ENQUETE [i][k].text,
            "libelleSection":       this.ENQUETE [i][k].section,
            "libelleSousSection":   this.ENQUETE [i][k].soussection,
            "libelleSousTheme":     this.ENQUETE [i][k].soustheme,
            "libelleTheme":         this.ENQUETE [i][k].theme,
            "libelle_type":         this.ENQUETE [i][k].lybelletype,
            "matriculeAjout":       this.matrEnqueteur,
            "ordre":                this.ENQUETE [i][k].ordre,
            "nomEnquete":           this.enqueteFormGroup.get('nom_enquête').value,
            "observation":          this.enqueteFormGroup.get('Observation').value,
            }
        )
    }
  }
  const dataSave = {
    "enquetes": data
  }
  this.dd.mandefa(data).subscribe( data => {
    if (data.status == 200) {
    this.ENQUETE = []; 
    this.enqueteFormGroup.reset(); 
    this.sectionFormGroup.reset();
    this.router.navigate(['liste-enquete']);
    }
  },
  error => {
  });

}
  miseho(id) {
    this.secteur = id.value
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
}
}
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-fournisseur',
  templateUrl: './fournisseur.component.html',
  styleUrls: ['./fournisseur.component.css']
})
export class FournisseurComponent implements OnInit {
  id:string
  fournisseur: any[]=[];
  reffournisseur: any[]=[];
  constructor(
    
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getFournisseur();
  }

  getFournisseur(){
    this.list.getFournisseur(this.id).subscribe(resp=>{
      this.fournisseur = resp.body.fournisseurs
      this.reffournisseur = resp.body.refFournisseurs
      
    })
  }

}
 
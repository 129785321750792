import { TestService } from './../../services/realisation/test.service';
import { Component, OnInit } from '@angular/core';
import { ThrowStmt } from '@angular/compiler';
import { style } from '@angular/animations';
import { color } from 'html2canvas/dist/types/css/types/color';

@Component({
  selector: 'app-global',
  templateUrl: './global.component.html',
  styleUrls: ['./global.component.css']
})
export class GlobalComponent implements OnInit {
  idsection:any
  globalAll: any
  sommExigenxes: any
  tauxRealisationGlobal: any
  tauxValidationGlobal: any
  option1: any
  option2: any
  resteRealisation: any
  resteValidation: any
  listeSection: any;
  show: boolean = false;
  loader: boolean = false;
  global: any[];
  sommEx: any;
  graph1: { title: { text: string; subtext: string; left: string; }; tooltip: { trigger: string; }; legend: { orient: string; left: string; }; series: { name: string; type: string; radius: string; data: { value: any; name: string; }[]; emphasis: { itemStyle: { shadowBlur: number; shadowOffsetX: number; shadowColor: string; }; }; }[]; };

  constructor(private validationService: TestService) { }

  ngOnInit(): void {
    this.getGlobal()
    this.validationService.getAllSection().subscribe(data => {
      this.listeSection = data.body
    })
  }
  getGlobal(){
    this.validationService.getAvancementGlobal().subscribe(resp => {
      if(resp.status === 200){
        const array = [];
        for (let i = 0; i < 1; i++) {
          array.push(resp.body)
        }
        this.global = array
        const exigences = []
        const resalisation = []
        const validation = []
        let sommeRealisation
        let sommeValidation
        for (let index = 0; index < this.global.length; index++) {
          exigences.push(parseInt(this.global[index]['Nombre_exigences']))
          resalisation.push(parseInt(this.global[index]['taux_realisation']))
          validation.push(parseInt(this.global[index]['taux_validation']))
        }
        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        //somme
        this.sommEx = exigences.reduce(reducer)
        sommeRealisation = resalisation.reduce(reducer)
        sommeValidation = resalisation.reduce(reducer)
        //taux et reste
        this.tauxRealisationGlobal = sommeRealisation/resalisation.length
        
        this.resteRealisation = 100 - this.tauxRealisationGlobal
        this.tauxValidationGlobal = sommeValidation / validation.length
        this.resteValidation = 100 - this.tauxValidationGlobal

        this.graph1 = {
          title: {
            text: '',
            subtext: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '80%',
              data: [
                { value: this.tauxRealisationGlobal, name: 'Terminé' },
                { value: this.resteRealisation, name: 'Reste à faire' },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
      }
    })
  }

  changeSection(id){
    this.show = false;
    this.loader = true;
    this.validationService.getAvancementGlobaleBySection(id).subscribe(res =>{
      if(res.status == 200){
        const arr = [];
        for (let i = 0; i < 1; i++) {
         arr.push(res.body)
        }
        
        if (res.body.idSection) {
          this.show = true;
        this.loader = false;
        }
        this.globalAll = arr
        const exigences = []
        const resalisation = []
        const validation = []
        let sommeRealisation
        let sommeValidation
  
        for (let index = 0; index < this.globalAll.length; index++) {
          exigences.push(parseInt(this.globalAll[index]['Nombre_exigences']))
          resalisation.push(parseInt(this.globalAll[index]['taux_realisation']))
          validation.push(parseInt(this.globalAll[index]['taux_validation']))
        }
        const reducer = (previousValue, currentValue) => previousValue + currentValue;
        //somme
        this.sommExigenxes = exigences.reduce(reducer)
        sommeRealisation = resalisation.reduce(reducer)
        sommeValidation = resalisation.reduce(reducer)
        //taux et reste
        this.tauxRealisationGlobal = sommeRealisation/resalisation.length
        
        this.resteRealisation = 100 - this.tauxRealisationGlobal
        this.tauxValidationGlobal = sommeValidation / validation.length
        this.resteValidation = 100 - this.tauxValidationGlobal
        //graph
        this.option1 = {
          title: {
            text: '',
            subtext: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            {
              name: 'Access From',
              type: 'pie',
              radius: '80%',
              data: [
                { value: this.tauxRealisationGlobal, name: 'Terminé' },
                { value: this.resteRealisation, name: 'Reste à faire' },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        this.option2 = {
          color:['#F08231 ','#6d9401'],
          title: {
            text: '',
            subtext: '',
            left: 'center'
          },
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'vertical',
            left: 'left'
          },
          series: [
            { 
              name: 'Access From',
              type: 'pie',
              radius: '80%',
              data: [
                { value: this.tauxValidationGlobal, name: 'Terminé' },
                { value: this.resteValidation, name: 'Reste à faire', },
              ],
            
              
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
      }
    })
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-civilite',
  templateUrl: './civilite.component.html',
  styleUrls: ['./civilite.component.css']
})
export class CiviliteComponent implements OnInit {
  id: string
  show:boolean=false
  civil: any;
  constructor(
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.civilite()

  }
  civilite(){
    this.list.getCivilite(this.id).subscribe(resp=>{
      this.civil = resp.body
    })
  }
  modifier(){
    /* this.show=true */
    
  }
  preparEdit() {


  }

}

import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { AccountService } from "app/services/account/account.service";
import { ToastrService } from "ngx-toastr";
import * as XLSX from "xlsx";

type AOA = any[][];
@Component({
  selector: "app-import-excel",
  templateUrl: "./import-excel.component.html",
  styleUrls: ["./import-excel.component.css"],
})
export class ImportExcelComponent implements OnInit {
  @ViewChild("epltable", { static: false }) epltable: ElementRef;
  datasave: any;
  datasaveImport: any;
  foko: any[] = [];
  fileName = "doublon.xlsx";
  secondFormGroup: FormGroup;
  codeLocalite: any;
  matr: any;
  matrFamille: any;
  matrProd: any[] = [];
  myFormGroup: FormGroup;
  boucleCivilite: any[] = [];
  databoucle: any[] = [];
  donneEnregistre: any[] = [];
  doublon: any[] = [];
  status: number;
  LocaliteIntrovable: any[] = [];
  constructor(
    private ac: AccountService,
    private router: Router,
    private toastr: ToastrService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.secondFormGroup = this._formBuilder.group({
      fokontany: [""],
      commune: [""],
      district: [""],
      region: [""],
      province: [""],
      village: [""],
      codeLocalite: [""],
      milieu: [""],
    });
  }
  /* name = 'Angular ' + VERSION.major; */
  public tableData: any;
  public tableTitle: any;
  public customPagination = 1;
  public recordsPerPage = 10;
  public tableRecords = [];
  public pageStartCount = 0;
  public pageEndCount = 10;
  public totalPageCount = 0;
  public currentPage = 0;

  public uploadData(e) {
    /* wire up file reader */
    const target: DataTransfer = <DataTransfer>(<unknown>event.target);
    if (target.files.length !== 1) {
      throw new Error("Cannot use multiple files");
    }
    const reader: FileReader = new FileReader();
    reader.readAsBinaryString(target.files[0]);
    reader.onload = (e: any) => {
      /* create workbook */
      const binarystr: string = e.target.result;

      const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: "binary" });

      /* selected the first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws); // to get 2d array pass 2nd parameter as object {header: 1}
      this.tableData = data;
      this.tableTitle = Object.keys(this.tableData[0]);
      this.tableRecords = this.tableData;
    };
  }
  fkt(index) {
    if (index.length > 3) {
      this.ac.prendListeFokotany(index).subscribe((resp) => {
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.foko.push({
              id: resp.body[i].codeFokontany,
              text: resp.body[i].fokontany + "(" + resp.body[i].commune + ")",
              codeCommune: resp.body[i].codeCommune,
              commune: resp.body[i].commune,
              codeDistrict: resp.body[i].codeDistrict,
              district: resp.body[i].district,
              codeRegion: resp.body[i].codeRegion,
              region: resp.body[i].region,
              codeProvince: resp.body[i].codeProvince,
              province: resp.body[i].province,
              codeLocalite: resp.body[i].codeLocalite,
              milieu: resp.body[i].libelleMilieu,
            });
          }
        }
      });
    }
  }
  export() {
    /* pass here the table id */
    let element = document.getElementById("doublons");
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);

    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }
  onChange(event) {
    this.secondFormGroup
      .get("codeLocalite")
      .setValue(event[0].data.codeLocalite);

    this.codeLocalite = event[0].data.codeLocalite;
    //ato no mambotra ny matricule
    this.ac.getmatricule(this.codeLocalite, 2).subscribe((resp: any) => {
      if (resp) {
        this.matr = resp;
        this.matrProd.push(this.matr);
      }
    });
    this.ac.getmatriculeFamille(this.codeLocalite).subscribe((resp: any) => {
      if (resp) {
        this.matrFamille = resp;
      }
    });
  }
  functionBoucleCivilite(i) {
    this.databoucle.push(this.myFormGroup.value);
    this.myFormGroup.controls["age"].reset();
    this.myFormGroup.controls["cin"].reset();
    this.myFormGroup.controls["commune"].reset();
    this.myFormGroup.controls["dateDelivranceCin"].reset();
    this.myFormGroup.controls["dateInsert"].reset();
    this.myFormGroup.controls["dateNaiss"].reset();
    this.myFormGroup.controls["district"].reset();
    this.myFormGroup.controls["fokontany"].reset();
    this.myFormGroup.controls["lieuDelivranceCin"].reset();
    this.myFormGroup.controls["lieuNaiss"].reset();
    this.myFormGroup.controls["localiteIndiv"].reset();
    this.myFormGroup.controls["nationalite"].reset();
    this.myFormGroup.controls["niveauEtude"].reset();
    this.myFormGroup.controls["nom"].reset();
    this.myFormGroup.controls["nomPrenomMere"].reset();
    this.myFormGroup.controls["nomPrenomPere"].reset();
    this.myFormGroup.controls["prenom"].reset();
    this.myFormGroup.controls["province"].reset();
    this.myFormGroup.controls["region"].reset();
    this.myFormGroup.controls["sexe"].reset();
    this.myFormGroup.controls["status"].reset();
    this.myFormGroup.controls["statusMatrimonial"].reset();
  }

  Ok() {
    this.router.navigate(["listeprod"]);
  }

  onSubmit() {
    for (let i = 0; i < this.tableData.length; i++) {
      this.datasave = {
        adressContact: {
          adrsActuelle:
            this.tableData[i].Adresse_actuelle != "" ||
            this.tableData[i].Adresse_actuelle != undefined
              ? this.tableData[i].Adresse_actuelle
              : "",
          email:
            this.tableData[i].E_mail != "" ||
            this.tableData[i].E_mail != undefined
              ? this.tableData[i].E_mail
              : "",
          hameauVillage:
            this.tableData[i].Village_Hameau != "" ||
            this.tableData[i].Village_Hameau != undefined
              ? this.tableData[i].Village_Hameau
              : "",
          mobileBanking:
            this.tableData[i].Mobile_Banking != "" ||
            this.tableData[i].Mobile_Banking != undefined
              ? this.tableData[i].Mobile_Banking
              : "",
          pseudo:
            this.tableData[i].Pseudo != "" ||
            this.tableData[i].Pseudo != undefined
              ? this.tableData[i].Pseudo
              : "",
          telephone:
            this.tableData[i].Num_Tel != "" ||
            this.tableData[i].Num_Tel != undefined
              ? this.tableData[i].Num_Tel
              : "",
        },
        civilite: {
          province: this.tableData[i].Province,
          region: this.tableData[i].Région,
          district: this.tableData[i].District,
          commune: this.tableData[i].Commune,
          fokontany: this.tableData[i].Fokotany,
          cin: this.tableData[i].Cin,
          dateDelivranceCin: this.tableData[i].Date_délivrance_Cin,
          matricule: this.tableData[i].Matricule,
          dateNaiss: this.tableData[i].Date_de_naissance,
          lieuDelivranceCin: this.tableData[i].Lieu_de_délivrance_Cin,
          lieuNaiss: this.tableData[i].Lieu_de_naissance,
          nationalite: this.tableData[i].Nationalité,
          niveauEtude: this.tableData[i].Niveau_instruction,
          nom: this.tableData[i].Nom,
          nomPrenomMere: this.tableData[i].Nom_de_la_mère,
          nomPrenomPere: this.tableData[i].Nom_du_père,
          prenom: this.tableData[i].Prénom,
          sexe: this.tableData[i].Sexe,
          status: 2,
          statusMatrimonial: this.tableData[i].Statut_matrimonial,
        },

        famille: {
          cinFamille: this.tableData[i].Cin_Famille,
          dateCinFamille: this.tableData[i].Date_Cin_Famille,
          dateNaissFamille: this.tableData[i].Date_Naiss_Famille,
          lieuCinFamille: this.tableData[i].Lieu_Cin_Famille,
          lieuNaissFamille: this.tableData[i].Lieu_Naiss_Famille,
          matriculeFamille: this.tableData[i].MatriculeFamille,
          nationaliteFamille: this.tableData[i].Nationalite_Famille,
          niveauInstructionFamille:
            this.tableData[i].Niveau_Instruction_Famille,
          nomFamille: this.tableData[i].Nom_Famille,
          parentLien: this.tableData[i].Lien_Parente,
          prenomFamille: this.tableData[i].Prenom_Famille,
          sexeFamille: this.tableData[i].Sexe_Famille,
        },
        agriculture: {
          agriGenerale: [
            {
              agePlante: this.tableData[i].age_plante,
              agePlanteRepique: this.tableData[i].age_PlanteRepique,
              anneeAcquisition: this.tableData[i].annee_acquisition,
              autre_culture: this.tableData[i].autre_culture,
              culture_succession: this.tableData[i].culture_succession,
              debutPeriodeRecolteAgri: this.tableData[i].debut_periode_recolte,
              debutPlantation: this.tableData[i].debut_plantation,
              dureeDernierRecolte: this.tableData[i].duree_Dernier_Recolte,
              finPeriodeRecolteAgri: this.tableData[i].fin_periode_recolte,
              finPlantation: this.tableData[i].fin_plantation,
              modeFertlisation: this.tableData[i].mode_fertilisation,
              modeLbour: this.tableData[i].mode_labour,
              modePlantation: this.tableData[i].mode_Plantation,
              modeRepiquage: this.tableData[i].mode_repiquage,
              modeSarclageDesherbage:
                this.tableData[i].mode_sarclage_desherbage,
              nbrePieds: this.tableData[i].nbre_pieds,
              nomCulture: this.tableData[i].nom_culture,
              nomCultureAssociation: this.tableData[i].nomCulture_Association,
              nomCultureDerobee: this.tableData[i].nom_Culture_Derobee,
              nom_variete: this.tableData[i].nom_variete,
              pratiqueCultureAssociation:
                this.tableData[i].pratique_culture_association,
              productionSaison: this.tableData[i].production_saison,
              productionSemence: this.tableData[i].prod_semence,
              qtEngraisAzote: this.tableData[i].qt_Engrais_Azote,
              qteEngraisUtilise: this.tableData[i].qte_Engrais_Utilise,
              sourceEauAgri: this.tableData[i].source_Eau_Agriculture,
              sourceSemences: this.tableData[i].source_Semences,
              systemeDrainage: this.tableData[i].systeme_Drainage,
              systemeIrrigation: this.tableData[i].systeme_Irrigation,
              tempsTravail: this.tableData[i].temps_Travail,
              traitementsContreInsectes:
                this.tableData[i].traitements_Contre_Insectes,
              traitementsContreRavageurs:
                this.tableData[i].traitements_Contre_Ravageurs,
              traitementsMaladies: this.tableData[i].traitements_Maladies,
              typeSemences: this.tableData[i].type_Semences,
              uniteEngraisAzote: this.tableData[i].unite_Engrais_Azote,
              uniteEngraisUtilise: this.tableData[i].unite_Engrais_Utilise,
            },
          ],
          amendementOrganique: [
            {
              qteAmendement: this.tableData[i].qte_Amendement,
              typeAmendement: this.tableData[i].type_Amendement,
              uniteAmendement: this.tableData[i].unite_Amendement,
            },
          ],
          chaulage: [
            {
              totalChaux: this.tableData[i].total_Chaux,
              typeChaux: this.tableData[i].type_Chaux,
            },
          ],
          cultureRente: [
            {
              agePiedsProductif: this.tableData[i].age_Pieds_Productif,
              agreeOuNon: this.tableData[i].agree_Ou_Non,
              anneeProductif: this.tableData[i].annee_Productif,
              codePoinconVanille: this.tableData[i].code_poincon_vanille,
              collecteurOuNon: this.tableData[i].collecteur_ou_non,
              cultureRenteProduisez: this.tableData[i].culture_rente_produise,
              dateAchat: this.tableData[i].dateAchat,
              debutPeriodeCultureRente:
                this.tableData[i].debut_periode_culture_rente,
              debutPeriodePlantation:
                this.tableData[i].debut_periode_plantation,
              debutPeriodeRecolte: this.tableData[i].debut_periode_recolte,
              detaillantOuNon: this.tableData[i].detaillant_Ou_Non,
              ecartementPiedCulture: this.tableData[i].ecarte_pied,
              exportateurFinale: this.tableData[i].exportateur_Finale,
              finPeriodeCultureRente:
                this.tableData[i].fin_periode_culture_rente,
              finPeriodePlantation: this.tableData[i].fin_periode_plantation,
              finPeriodeRecolte: this.tableData[i].fin_periode_recolte,
              gpsRente: this.tableData[i].gps_rente,
              lieuEchange: this.tableData[i].lieu_echange,
              lieuExploitation: this.tableData[i].lieu_exploitation,
              marquePoincon: this.tableData[i].marque_poincon,
              nbreExploitation: this.tableData[i].nbre_exploitation,
              nbrePiedsProductif: this.tableData[i].nbre_pieds_productif,
              nbrePiedsTotal: this.tableData[i].nbre_pieds_total,
              nomCollecteurAcheteur: this.tableData[i].nom_Collecteur_Acheteur,
              nomDetaillant: this.tableData[i].nom_Detaillant,
              observation: this.tableData[i].observation,
              periodeCulture: this.tableData[i].periode_culture,
              possedeExploitation: this.tableData[i].possede_exploitation,
              pourcentageQteVendue: this.tableData[i].pourcentage_Qte_Vendue,
              prixAchat: this.tableData[i].prixAchat,
              prixDeVenteNature: this.tableData[i].prix_vente_nature,
              prixDeVentePrepare: this.tableData[i].prix_De_Vente_Prepare,
              prixVenteEuros: this.tableData[i].prix_vente_euros,
              prixVenteNature: this.tableData[i].prix_de_vente_nature,
              prixVentePrepare: this.tableData[i].prix_de_vente_prepare,
              prodCultureRente: this.tableData[i].production_Culture_Rente,
              qteVendueNature: this.tableData[i].qte_vendue_nature,
              qteVenduePrepare: this.tableData[i].qte_vendue_prepare,
              refAgreement: this.tableData[i].referance_Agreement,
              referenceContrat: this.tableData[i].reference_Contrat,
              surfaceApproximative: this.tableData[i].surface_Approximative,
              surfaceApproximativeTotal:
                this.tableData[i].surface_Approximative_Total,
              totalProduction: this.tableData[i].total_Production,
              uniteAgePiedProductif: this.tableData[i].unite_age_pied_productif,
              uniteVendueNature: this.tableData[i].unite_Vendue_Nature,
              uniteVenduePrepare: this.tableData[i].unite_Vendue_Prepare,
              variete: this.tableData[i].variete,
            },
          ],
          engraisMinerauxAzote: [
            {
              pourcentageAzote: this.tableData[i].pourcentage_Azote,
              pourcentageUree: this.tableData[i].pourcentage_Uree,
              qteUtilise: this.tableData[i].qte_Utilise,
              typeEngrais: this.tableData[i].type_Engrais,
              uniteEngraiUtilise: this.tableData[i].unite_Engrai_Utilise,
            },
          ],
          perimetreIrrigue: [
            {
              cotisationAue: this.tableData[i].cotisation_Aue,
              districtAppartient: this.tableData[i].district_Appartient,
              etatPerimetreIrrigue: this.tableData[i].etat_Perimetre_Irrigue,
              frequencePaiement: this.tableData[i].frequence_Paiement,
              membreAue: this.tableData[i].membre_Aue,
              montantCotisation: this.tableData[i].montant_Cotisation,
              nomAue: this.tableData[i].nom_Aue,
              nomPerimetreIrrigue: this.tableData[i].nom_Perimetre_Irrigue,
              prodPerimetreIrrigue: this.tableData[i].prod_Perimetre_Irrigue,
            },
          ],
        },
        appui: {
          beneficiaireAppui: this.tableData[i].beneficiere_appui,
          datePrevuReceptionAppui: this.tableData[i].date_reception_appui,
          futureAcquisition: this.tableData[i].future_acquisition,
          motifAppui: this.tableData[i].motif_appui,
          nomOrganisation: this.tableData[i].nom_organisation,
          numAgrement: this.tableData[i].num_agrement,
          operatioAppui: this.tableData[i].operation_apppui,
          projet: this.tableData[i].projet,
          ptfAppui: [this.tableData[i].ptfAppui],
          refAppuis: [
            {
              dateDebAppui: this.tableData[i].dateDebAppui,
              dateFinAppui: this.tableData[i].dateFinAppui,
              filiereAppuier: this.tableData[i].filiereAppuier,
              refAppui01: [
                {
                  genreAppuiBenefice: this.tableData[i].genreAppuiBenefice,
                  montantApportBenefSup10:
                    this.tableData[i].montantApportBenefSup10,
                  montantObtenuSup10: this.tableData[i].montantObtenuSup10,
                  nombreMaterielSup10: this.tableData[i].nombreMaterielSup10,
                  qteObtenuParType: this.tableData[i].qteObtenuParType,
                  typeServiceAppui: this.tableData[i].typeServiceAppui,
                  unite: this.tableData[i].unite,
                },
              ],
            },
          ],
          satisfaitAppui: this.tableData[i].satisfait_appui,
          specificationAppui: this.tableData[i].specification_appui,
          titreObtentionAppui: this.tableData[i].titre_obtention_appui,
          typeCredit: this.tableData[i].type_credit,
          typeFinance: this.tableData[i].type_finance,
        },
      };
      this.databoucle.push(this.datasave);
    }
    this.ac.inscriptionParExcel(this.databoucle).subscribe(
      (res) => {
        this.status = res.status;
        if (res.status === 200) {
          this.donneEnregistre = res.body.individuSave;
          this.doublon = res.body.listeDoublons;
          this.toastr.success(
            "Nombre total des lignes : " +
              res.body.nbreAdd +
              "\n" +
              "Nombre de lignes insérées :" +
              res.body.nbreSave +
              "\n" +
              "Nombre de données en doublons :" +
              res.body.nbreDoublons +
              "\n",
            "Inscription avec success"
          );
          this.databoucle.splice;
        }
        if (res.status === 201) {
          this.doublon = res.body;
        }
        if (res.status === 203) {
          this.LocaliteIntrovable = res.body;
        }
      },
      (error) => {
        this.toastr.error(
          "ERREUR INSERTION DES DONNEES!!! LA TOTALITE DES DONNEES INSEREES EXISTENT DEJA EN DOUBLON"
        );
      }
    );
  }
}

import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class DragdropService {
  constructor(
    private router: Router, 
    private http: HttpClient) { }
    
  deleteUser(matricule: number): Observable<any> {
    const headers = new HttpHeaders().set("Access-Control-Allow-Origin", "*");
    return this.http.delete(`${environment.apiReferentiel}api/savenquete/deleteEnquete/` + matricule, { headers: headers });
  }
  mandefa(data) {
    const headers = new HttpHeaders()
    /* .set('content-type', 'application/json') */
    .set('Access-Control-Allow-Origin', '*');
    return this.http.post<any>(`${environment.apiReferentiel}api/savenquete/saveEnquete`, data,  { headers: headers, observe: 'response' });
  }
  findAllSection() {
    // let headers = new HttpHeaders({"Authorization" : "Bearer " + token});
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/getAllSection`,  { headers: headers, observe: 'response' });
  }
  Checktheme(Section) {
    // let headers = new HttpHeaders({"Authorization" : "Bearer " + token});
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/ckeckThemeBySection/` + Section,  { headers: headers, observe: 'response' });
  }
  CheckSousThemes(soussection){
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/checkSousTheme/` + soussection,  { headers: headers, observe: 'response' });  
  }
  ListesousSectionThemeNonvide(Section) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/listeSousSectionThemeNonVide/`+ Section,  { headers: headers, observe: 'response' });
  }

  ListeProdEconomie() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiEconomie}api/economie/getAllEconomie`,  { headers: headers, observe: 'response' });
  }
  ListeCultRente() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllCultureRente`,  { headers: headers, observe: 'response' });
  }

  ListeApicult() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllApiculture`,  { headers: headers, observe: 'response' });
  }

  Soustheme(idtheme) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/getSousThemeByidTheme/`+ idtheme,  { headers: headers, observe: 'response' });
  }
  getQuestionnaireBySoustheme(idSousTheme) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/getAllQuestionnairesByIdSousTheme/`+ idSousTheme,  { headers: headers, observe: 'response' });
  }
  getQuestionnaireBytheme(theme) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/prendSousThemeNonVide/`+ theme,  { headers: headers, observe: 'response' });
  }
 ListeSouSectionThemeVide(Section) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/listeSousSectionThemeVide/`+ Section,  { headers: headers, observe: 'response' });
  }
  prendListeSiSouThemeVide(sousSection) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/prendListeSiSousThemeVide/`+ sousSection,  { headers: headers, observe: 'response' });
  }
  prendListethemeSouthemeVide(sousSection) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/referentiel/prendListeThemeBySousSection/`+ sousSection,  { headers: headers, observe: 'response' });
  }

  /**
   * ENQUETE DYNAMIQUE SERVICE
   */

   listeEnquete() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/savenquete/enqueteListe`,  { headers: headers, observe: 'response' });
  }

  listeDesEnquetes() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/savenquete/enqueteListe`,  { headers: headers, observe: 'response' });
  }

  listeDesEnquetesParam(data) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.post<any>(`${environment.apiReferentiel}api/savenquete/enquetePagination`, data, { headers: headers, observe: 'response' });
  }

  detailEnquete(id: any) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiReferentiel}api/savenquete/reponseEnqueteById/`+id,  { headers: headers, observe: 'response' });
  }

  saveIndividu(id: any) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.post<any>(`${environment.apiUrlIndiv}api/individu/saveIndividuMinae`, id,  { headers: headers, observe: 'response' });
  }

  getAllCivilite() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendIndividuByAllMatricule`,  { headers: headers, observe: 'response' });
  }

  getAllAdresseContact() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv2}api/contactAdrs/listeAllAdrsContact`,  { headers: headers, observe: 'response' });
  }
  
getAllMembreFamille() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiMembrefamille}api/famille/prendAllMembreFamille`,  { headers: headers, observe: 'response' });
  }
   getAllProduction() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllProductionIndivProd`,  { headers: headers, observe: 'response' });
  }
  getAllEconomie() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiEconomie}api/economie/getAllEconomie`,  { headers: headers, observe: 'response' });
  }
  getAllServiceFinancier() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiServiceFinancier}api/serviceFinacier/listeAllServiceFinancier`,  { headers: headers, observe: 'response' });
  }
  /* getAllOrganisationPaysanne() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiOrganisationPaysane}api/secteur/prendAllApiculture`,  { headers: headers, observe: 'response' });
  } */
  getAllEngraisMinerauxAzote() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllEngraisMinerauxAzotes`,  { headers: headers, observe: 'response' });
	  																																					   
  }
   getAllPerimetresirrigues() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllGestionPerimetreIrrigues`,  { headers: headers, observe: 'response' });
  } 
   getAllRente() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllCultureRente`,  { headers: headers, observe: 'response' });
  } 
    getAllAgriGeneral() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllAgriGenerale`,  { headers: headers, observe: 'response' });
  }
    getAllAmendementOrganique() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllAmendementOrganique`,  { headers: headers, observe: 'response' });
  }
    getAllChaulage() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllChaulage`,  { headers: headers, observe: 'response' });
  }
  
  getAllElevage() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/getAllPratiqueElevage`,  { headers: headers, observe: 'response' });
  }
    getAllApculture() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllApiculture`,  { headers: headers, observe: 'response' });																																 
  }
  
    getAllSylvyGeneral() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllSylviGeneral`,  { headers: headers, observe: 'response' });
  }
    getAllSylvicole() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllSylviSylvicole`,  { headers: headers, observe: 'response' });
  }
    getAllCasPepiniere() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllSylviCasPepiniere`,  { headers: headers, observe: 'response' });
  }
    getAllPeche() {
				   
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllPeche`,  { headers: headers, observe: 'response' });
																													 
  }
   getAllAquaculture() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiActivite}api/secteur/prendAllAquaculture`,  { headers: headers, observe: 'response' });
  }
  
  getIndivParDitrict(district) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendIndividuByAllMatriculeAndDistrict/`+ district,  { headers: headers, observe: 'response' });
  }
  getIndivParRegion(region) {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendIndividuByAllMatriculeAndRegion/`+ region,  { headers: headers, observe: 'response' });
  }
    getAllAppuis() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiAppuis}api/appui/prendAllAppuis`,  { headers: headers, observe: 'response' });
  }
  getAllContratAgricole() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiContratAgrie}api/contratAgricole/listeAllContratAgri`,  { headers: headers, observe: 'response' });
  }
  
  getAllPrincipalContrainte() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllContrainte`,  { headers: headers, observe: 'response' });
  }
  getAllFournisseur() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllFournisseur`,  { headers: headers, observe: 'response' });
  }
  getAllTransformation() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllTransformation`,  { headers: headers, observe: 'response' });
  }
  getAllLogistique() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllLogistique`,  { headers: headers, observe: 'response' });
  }
  getAllNutrition() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiNutrition}api/nutrition/getAllNutrition`,  { headers: headers, observe: 'response' });
  }
  getAllPlaceFemme() {	   
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/femme/getAllPlaceFemme`,  { headers: headers, observe: 'response' });
  }
  getAllAssistanceSocialee() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllAssistanceSociale`,  { headers: headers, observe: 'response' });
  } 
  getAllLabelisation() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllLabelisation`,  { headers: headers, observe: 'response' });
  }
  getAllPossessionMode() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllPossessionModeIndiv`,  { headers: headers, observe: 'response' });
  }
  getAllOcupationRizicol() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllSystemeRizicoleIndiv`,  { headers: headers, observe: 'response' });
  }
  getAllParcielle() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllParcelleTerreIndiv`,  { headers: headers, observe: 'response' });
  }
  
  getAllTypeSol() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllTypeSol`,  { headers: headers, observe: 'response' });
  }
  getAllTerreCultive() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllTerreCultiveIndiv`,  { headers: headers, observe: 'response' });
  }
  getAllPerrene() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllCulturePerenne`,  { headers: headers, observe: 'response' });
  }
  getAllDistribution() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllCultureLigneusePerenne`,  { headers: headers, observe: 'response' });
  }
 
  getAllOcupationBiomase() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllBiomasse`,  { headers: headers, observe: 'response' });
  }
  
  getAllCategorieOcupationSol() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllOccupationSol`,  { headers: headers, observe: 'response' });
  }
  getAllOcupationResidusRecolte() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllResidusRecolte`,  { headers: headers, observe: 'response' });
  }

  /* getAllMaterielAgricole() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllResidusRecolte`,  { headers: headers, observe: 'response' });
  } */
getAllMaterielAnnuel() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllMaterielAnnuelTractionAnimal`,  { headers: headers, observe: 'response' });
  }
  getAllEnginPeche() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllEnginsPecheEmbarquations`,  { headers: headers, observe: 'response' });
  }

  getAllvache() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllMaterielVacheLaitieres`,  { headers: headers, observe: 'response' });
  }

  getAllAbriAnimaux() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllAbrisAnimauxInstallationFixe`,  { headers: headers, observe: 'response' });
  }
  getAllMoyensStockageProduitsx() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllMoyensStockageProduits`,  { headers: headers, observe: 'response' });
  }
  getAllMaterielMotorise() {
    const headers = new HttpHeaders()
    .set('content-type', 'application/json')
    return this.http.get<any>(`${environment.apiUrlIndiv}api/individu/prendAllMaterielMotorise`,  { headers: headers, observe: 'response' });
  }



}

import { Component, OnInit } from '@angular/core';
import { TestService } from '../../services/realisation/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Detailrealisation } from 'app/detailrealisation';

@Component({
  selector: 'app-updaterealisation',
  templateUrl: './updaterealisation.component.html',
  styleUrls: ['./updaterealisation.component.css']
})
export class UpdaterealisationComponent implements OnInit {
  id: string
  dataId: Detailrealisation = new Detailrealisation();
  constructor(
    private teste: TestService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');
    this.teste.getlinerealisationbyId(this.id).subscribe(data=>{
        this.dataId = data
    })
  }
}

import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { all, any } from 'codelyzer/util/function';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { Label } from 'ng2-charts';





@Component({
  selector: 'app-bar',
  templateUrl: './bar.component.html',
  styleUrls: ['./bar.component.css']
})
export class BarComponent implements OnInit, OnChanges {
  multi: any[];
  view: any[] = [700, 400];
  public barChartOptions: ChartOptions = {
    responsive: true,};
    
    public barChartType: ChartType = 'bar';
    public barChartLegend = true;
    public barChartPlugins = [];
  


  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Country';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Population';
  legendTitle: string = 'Years';

  colorScheme = {
    domain: ['#5AA454', '#C7B42C', '#AAAAAA']
  };

  @Input() dataChart: any [] =[];
  @Input() dataChart1: any [] = [];
  /*optionss: {
    tooltip: { trigger: string; axisPointer: { type: string; }; }; legend: { data: string[]; }; toolbox: { show: boolean; orient: string; left: string; top: string; feature: { mark: { show: boolean; }; dataView: { show: boolean; readOnly: boolean; }; magicType: { show: boolean; type: string[]; }; restore: { show: boolean; }; saveAsImage: { show: boolean; }; }; }; xAxis: { type: string; axisTick: { show: boolean; }; data: string[]; }[]; yAxis: { type: string; }[]; series: ({
      name: string; type: string; barGap: number;
       //label: labelOption, 
      emphasis: { focus: string; }; data: number[];
    } | {
      name: string; type: string;
       //label: labelOption, 
      emphasis: { focus: string; }; data: number[]; barGap?: undefined;
    })[];
  };*/
  dataxv: string;
  dataxy: any[] = [];
  bardata: string[] = [];
  bardata2: Label[] = [];
  idataxv: any;
  idataxy: any;
  idataY: any;

 // type dataChar = Array<datataux: [], labelcol: string>;
  constructor() { 
    Object.assign({},this.dataChart)
  }
  option: any;
  dataX: any[] = []
  dataY: any[] = []
  dataX2: any[] = [];
  dataY2: any []= [];
  dataY3: any[] = [];
  dataX3: any[] = [];
  dataY4: any[] = [];
  dataX4: any[] = [];
  dataSerie: any[] = [];
  dataCondition:boolean;
  public barChartLabels: Label[] = [];
  public barChartData: ChartDataSets[] = [];
  ngOnInit(): void {
  }


  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngOnChanges() {
    this.modifierDataChart();
    
  }


  

  modifierDataChart() {
    this.dataX = [];
    this.dataY = [];
    this.dataX2 = [];
    this.dataY2 =  [];
    this.dataY3 = [];
    this.dataX3 = [];
    this.dataY4 = [];
    this.dataX4 = [];
    this.dataSerie = [];
    this.barChartLabels = [];
    this.barChartData = [];
    this.dataChart.map(element => {
                  if( element.listeLocalite == undefined && element.listeLocalite ==null){
                    this.dataY.push(element.tauxPourcentage !== undefined ? element.tauxPourcentage : element.nbreTotal)
                    this.dataX.push( element.valeurColonne);
                    this.dataCondition=true;
                  }else{
                    this.dataCondition=false;
                    this.bardata.push( element.listeLocalite);
                        this.bardata = Object.values(element.listeLocalite)
                        this.dataY.push(element.listeTauxNbreColonnes);
                    for (let i = 0; i <  element.listeTauxNbreColonnes.length; i++) {
                      this.dataxv = element.listeTauxNbreColonnes[i].valueColonne
                      this.dataxy = element.listeTauxNbreColonnes[i].tauxNbreListe
                    };

                  }    
                });
                this.initChart();
 }

                  

  initChart() {


// GESTION OPTION bar chart AGGREGE
            this.dataChart.map(item => {
                if(item.listeLocalite == undefined && item.listeLocalite ==null){
                  this.dataY2.push(item.tauxPourcentage !== undefined ? item.tauxPourcentage : item.nbreTotal)
                  this.dataX2.push(item.valeurColonne);
                }else{
           this.barChartLabels = Object.values(item.listeLocalite)
                for (let i = 0; i <  item.listeTauxNbreColonnes.length; i++) {
                  this.idataxv = item.listeTauxNbreColonnes[i].valueColonne
                  this.idataxy = item.listeTauxNbreColonnes[i].tauxNbreListe
            this.barChartData[i] = { data:item.listeTauxNbreColonnes[i].tauxNbreListe , label:  item.listeTauxNbreColonnes[i].valueColonne } ; 
                }
                }
            });
//GESTION OPTION bar chart DEUX DIMENSION
    this.option = {
      color: ['#F08231'],
      legend: {
        data: []
      },
      dataZoom: [
        {
          show: true,
          realtime: true,
          dataBackground: {
            areaStyle: {
              color: "rgba(0,0,0,0.15)"
            },
            lineStyle: {
              color: "rgba(0,0,0,0.15)"
            },
          }
        },
        {
          type: 'inside',
          realtime: true,
          start: 90,
          end: 100,
        }
      ],
      toolbox: {
        show: true,
        feature: {
          magicType: { show: true, type: ['bar','line'],title: 'Transformer'},
          restore: { show: true, title: 'Actualiser'},
          saveAsImage: { show: true ,
            title: 'Enregistrer sous l\'image'
          }
        }
      },
      calculable: true,
      xAxis: [
        {
          type: 'category',
          data: this.dataX
        }
      ],
      yAxis: [
        {
          type: 'value'
        }
      ],
      series: [
        
        {
          name: '2021',
          type: 'bar',
          data: this.dataY,
          markPoint: {
            data: [
              { name: 'Max', value: 182.2, xAxis: 7, yAxis: 183 },
              { name: 'Min', value: 2.3, xAxis: 11, yAxis: 3 }
            ]
          },
          markLine: {
            data: [{ type: 'average', name: 'Avg' }]
          },
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: '#F08231'
            }
          }
        },

      ]
    }
  }


}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-appuis',
  templateUrl: './appuis.component.html',
  styleUrls: ['./appuis.component.css']
})
export class AppuisComponent implements OnInit {
  id: string
  Appuis: any[] = [];
  refAppuis: any[] = [];
  refAppui01: any[] = [];
  constructor(
    private route: ActivatedRoute,
    private list: ListService,) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getAppuis()
  }
  getAppuis() {

    this.list.getAppuis(this.id).subscribe(resp => {
      this.Appuis = resp.body
    })
  }
  getrefAppuis() {

    this.list.getrefAppuis(this.id).subscribe(resp => {
      this.refAppuis = resp.body
    })
  }
  getrefAppuis01() {

    this.list.getrefAppuis01(this.id).subscribe(resp => {
      this.refAppui01 = resp.body
    })
  }

}


import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileValidator } from 'ngx-material-file-input';
import { FormBuilder, FormGroup } from '@angular/forms';
import {Location} from '@angular/common';
import { ListService } from '../../services/list/list.service';
import { AccountService } from '../../services/account/account.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dialog-overview-example-dialog',
  templateUrl: './dialog-overview-example-dialog.component.html',
  styleUrls: ['./dialog-overview-example-dialog.component.css']
})
export class DialogOverviewExampleDialogComponent implements OnInit {
  matricule: string;
  base64textString: string;
  thierdFormGroup: FormGroup;
  readonly maxSize = 104857600;
  datailsProd: any;
  base64textStrings: string;
  selectedFile: File;
  selectedFileCodeQr: File;
  selectedFileRecto: File;
  selectedFileVerso: File;
  public onFileChanged(event) {
    this.selectedFile = event.target.files[0];
  }
  public onFileChangedCodeQr(event) {
    this.selectedFileCodeQr = event.target.files[0];
  }
  public onFileChangedRecto(event) {
    this.selectedFileRecto = event.target.files[0];
  }
  public onFileChangedVerso(event) {
    this.selectedFileVerso = event.target.files[0];
  }
  constructor(
    private route: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _location: Location,
    private list: ListService,
    private ac: AccountService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.matricule = this.route.snapshot.paramMap.get('matr');
    this.thierdFormGroup = this._formBuilder.group({
      photos: ["", FileValidator.maxContentSize(this.maxSize)],
      qrCode:["",FileValidator.maxContentSize(this.maxSize)],
      recto: ["", FileValidator.maxContentSize(this.maxSize)],
      verso:["",FileValidator.maxContentSize(this.maxSize)]
    });
    this.getProdByMatricule()
  }
  retour(){
    this._location.back();
  }
  sauvegarder(){
    const formData = new FormData()
    formData.append('photos', this.selectedFile?this.selectedFile:null);
    formData.append('qrCode',this.selectedFileCodeQr?this.selectedFileCodeQr:null);
    formData.append('recto',this.selectedFileRecto?this.selectedFileRecto:null);
    formData.append('verso',this.selectedFileVerso?this.selectedFileVerso:null);
    this.ac.updateIndiviu(this.matricule, formData).subscribe(response=>{
      if (response.status === 200) {
        this.toastr.success("Modification avec succès");
        this._location.back();
      }
      else{
        this.toastr.error("Une erreur c'est produit");
      }
    })
  }
  getProdByMatricule(){
    this.list.getProdByMatricule(this.matricule).subscribe((response) => {
      this.datailsProd = response.body;
    })
  }

}

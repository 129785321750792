import { Component, OnInit, ViewChild, ElementRef, OnChanges } from '@angular/core';
import { AlertService } from "../../services/alert.service";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { Router } from "@angular/router";
import { AccountService } from "../../services/account/account.service";
import { ListService } from "app/services/list/list.service";
import { FileValidator } from "ngx-material-file-input";
import { ToastrService } from "ngx-toastr";
import { Status } from "../../models/status";
import { any } from "codelyzer/util/function";
import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";
import * as moment from "moment";

@Component({
  selector: "app-inscription",
  templateUrl: "./inscription.component.html",
  styleUrls: ["./inscription.component.css"],
})
export class InscriptionComponent implements OnInit {
  isLinear = false; // true si prod
  statusFormGroup: FormGroup;
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thierdFormGroup: FormGroup;

  commune: any;
  itemFokotany: any[];
  itemStatus: any;
  district: any;
  region: any;
  province: any;

  showProd: boolean = false;
  isShown: boolean = false;

  foko: any[] = [];

  /**
   * In this example, it's 100 MB (=100 * 2 ** 20).
   */
  readonly maxSize = 104857600;
  selectedFile: File;
  itemSituationMatrimonial: any;
  code: any;
  idstate: any;
  matr: any;
  codecontact: Object;
  showNationalite: boolean = false;
  base64textString: string;
  databoucle: any[] = [];

  suivant = document.querySelectorAll('.suivant');

  constructor(
    private _formBuilder: FormBuilder,
    private ac: AccountService,
    private listService: ListService,
    private route: Router,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.ac.codecontact().subscribe((resp) => {
      if (resp.status === 200) {
        this.codecontact = resp.body;
      }
    });
    this.statusFormGroup = this._formBuilder.group({
      status: ["", Validators.required],
    });
    this.firstFormGroup = this._formBuilder.group({
      matricule: [""],
      nom: ["", Validators.required],
      prenom: ["", Validators.required],
      Nom_prenom_pere: [""],
      Nom_prenom_mere: ["", Validators.required],
      Nationalite: ["", Validators.required],
      metier: ["", Validators.required],
      lieuNaiss: ["", Validators.required],
      dateNaiss: ["", Validators.required],
      cin: ["", Validators.required],
      datecin: ["", Validators.required],
      lieucin: ["", Validators.required],
      sexe: ["", Validators.required],
      statut_matrimonial: [""],
      password: ["", Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      adresse_actuel: [""],
      mail: [""],
      hameau_village: [""],
      mobile_banking: [""],
      pseudo: [""],
      telephone: ["", Validators.required],
      localite: ["", Validators.required],
    });
    this.thierdFormGroup = this._formBuilder.group({
      photos: ["", FileValidator.maxContentSize(this.maxSize)],
    });

    this.getStatus();
    this.getSituationMatrimonial();
  }

  getStatus() {
    this.ac.findAllStatus().subscribe((res) => {
      this.itemStatus = res;
    });
  }
  getSituationMatrimonial() {
    this.ac.matrimonial().subscribe((res) => {
      this.itemSituationMatrimonial = res;
    });
  }
  fkt(index) {
    this.foko = [];
    if (index.length > 3) {
      this.ac.prendListeFokotany(index).subscribe((resp) => {
        if (resp.status == 200) {
          for (let i = 0; i < resp.body.length; i++) {
            this.foko.push({
              id: resp.body[i].codeFokontany,
              text: resp.body[i].fokontany + "(" + resp.body[i].commune + ")",
              codelocalite: resp.body[i].codeLocalite,
            });
          }
        }
      });
    }
  }
  boucleAdres(i) {
    this.databoucle.push(this.secondFormGroup.value);
    this.secondFormGroup.reset();
  }

  public onFileChanged(event) {
    var files = event.target.files;
    var file = files[0];

    if (files && file) {
      var reader = new FileReader();

      reader.onload = this._handleReaderLoaded.bind(this);

      reader.readAsBinaryString(file);
    }
  }
  _handleReaderLoaded(readerEvt) {
    var binaryString = readerEvt.target.result;
    this.base64textString = btoa(binaryString);
  }

  onChange(event) {
    this.secondFormGroup.get("localite").setValue(event[0].data.id);
    this.code = event[0].data.codelocalite;
    this.ac
      .getmatricule(this.code, parseInt(this.idstate))
      .subscribe((resp: any) => {
        if (resp) {
          this.matr = resp;
        }
      });

    /*     this.secondFormGroup.get('fokontany').setValue(event[0].data.id);
        this.listService.detailFokotany(event[0].data.id).subscribe(res => {
          if (res.status === 200) {
            const data = res.body;se
            this.secondFormGroup.get('commune').setValue(data.idCommune);
            this.commune = data.nomCommune;
            this.secondFormGroup.get('district').setValue(data.district.idDistrict);
            this.district = data.district.nomDistrict;
            this.secondFormGroup.get('region').setValue(data.district.region.idRegion);
            this.region = data.district.region.nomRegion;
            this.secondFormGroup.get('province').setValue(data.district.region.province.idProvince);
            this.province = data.district.region.province.nomProvince;
          }
        }) */
  }
  getmatricule(code, status) {
    this.ac.getmatricule(code, status).subscribe((resp) => {
    });
  }
  register() {
    const adressContact = [
      {
        adrsActuelle: this.secondFormGroup.get("adresse_actuel").value,
        email: this.secondFormGroup.get("mail").value,
        telephone: this.secondFormGroup.get("telephone").value,
        hameauVillage: this.secondFormGroup.get("hameau_village").value,
        mobileBanking: this.secondFormGroup.get("mobile_banking").value,
      },
    ];
    for (let i = 0; i < this.databoucle.length; i++) {
      adressContact.push({
        adrsActuelle: this.databoucle[i].adresse_actuel,
        email: this.databoucle[i].mail,
        telephone: this.databoucle[i].telephone,
        hameauVillage: this.databoucle[i].hameau_village,
        mobileBanking: this.databoucle[i].mobile_banking,
      });
    }
    const data = {
      adressContact: adressContact,
      civilite: {
        cin: this.firstFormGroup.get("cin").value,
        dateDelivranceCin: this.firstFormGroup.get("datecin").value,
        dateNaiss: this.firstFormGroup.get("dateNaiss").value,
        lieuDelivranceCin: this.firstFormGroup.get("lieucin").value,
        lieuNaiss: this.firstFormGroup.get("lieuNaiss").value,
        localiteIndiv: this.code,
        matricule: this.matr,
        codeNationalite: this.firstFormGroup.get("Nationalite").value,
        nom: this.firstFormGroup.get("nom").value,
        prenom: this.firstFormGroup.get("prenom").value,
        sexe: this.firstFormGroup.get("sexe").value,
        nomPrenomMere: this.firstFormGroup.get("Nom_prenom_mere").value,
        nomPrenomPere: this.firstFormGroup.get("Nom_prenom_pere").value,
        status: this.statusFormGroup.get("status").value,
        codeStatusMatrimonial:
          this.firstFormGroup.get("statut_matrimonial").value,
        password: this.firstFormGroup.get("password").value,
        photos: this.base64textString,
      },
    };

    this.ac.inscription(data).subscribe((res) => {
      if (res.status === 200) {
        this.toastr.success("Inscription avec success! et un mail vous a été envoyer");
        this.toastr.success(this.matr);
        this.route.navigate(["login"]);
      } else {
        this.toastr.error("Erreur lord le l'inscription");
      }
    });
  }

  state(id) {
    if (id.value === "2") {
      this.showProd = true;
    } else {
      this.showProd = false;
    }
    this.statusFormGroup.get("status").setValue(id.value);
    this.idstate = id.value;
  }
  misMiova(id) {
    if (id.value === "2") {
      this.showNationalite = true;
    } else {
      this.showNationalite = false;
    }
  }
  miverina() {
    this.isShown = !this.isShown;
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-materielequip',
  templateUrl: './materielequip.component.html',
  styleUrls: ['./materielequip.component.css']
})
export class MaterielequipComponent implements OnInit { 
  panelOpenState = false;
  id: string
  materiels: any[]=[];
  abrisAnimauxInstallationFixes: any[]=[];
  enginsPecheEmbarquations: any[]=[];
  materielAnnuelTractionAnimals: any[]=[];
  materielVacheLaitieres: any[]=[];
  moyensStockageProduits: any[]=[];
  materielMotorises: any[]=[];
  materielAgricole: any;
  constructor(
    private route: ActivatedRoute,
    private list: ListService,
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getMateriels()
  }
  getMateriels(){
    this.list.getMateriels(this.id).subscribe(resp=>{
      this.materiels = resp.body
      
      this.abrisAnimauxInstallationFixes = resp.body.abrisAnimauxInstallationFixes
      this.enginsPecheEmbarquations = resp.body.enginsPecheEmbarquations
      this.materielAnnuelTractionAnimals = resp.body.materielAnnuelTractionAnimals
      this.materielVacheLaitieres = resp.body.materielVacheLaitieres
      this.moyensStockageProduits = resp.body.moyensStockageProduits
      this.materielMotorises = resp.body.materielMotorises
    })
  }

}

import { InputService } from './dynamic-input/providers/input.service';
import { LoginComponent } from './account/login/login.component';
import { AccountService } from './services/account/account.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, enableProdMode } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AgChartsAngularModule } from 'ag-charts-angular';
import { ChartsModule } from 'ng2-charts';


import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';

import { AppComponent } from './app.component';

import {
  AgmCoreModule
} from '@agm/core';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ErrorInterceptor } from './helper/error.interceptor';
import { JwtInterceptor } from './helper/jwt.interceptor';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ProfilComponent } from './profil/profil.component';
import { RegisterComponent } from './account/register/register.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ProfilIndividusComponent } from './profil-individus/profil-individus.component';
import { CardTechComponent } from './componentsutul/card-tech/card-tech.component';
import { CardComponent } from './componentsutul/card/card.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatStepperModule } from '@angular/material/stepper';
import { NgxSelectModule } from 'ngx-select-ex';
import { InscriptionComponent } from './account/inscription/inscription.component';
import { MaterialFileInputModule } from 'ngx-material-file-input';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { ToastrModule } from 'ngx-toastr';
import { AjoutProdComponent } from './ajout-prod/ajout-prod.component';
import { MatTabsModule } from '@angular/material/tabs';
import { AcceuilsComponent } from './acceuils/acceuils.component';
import { TableprodComponent } from './tableprod/tableprod.component';
import { InfoProducteurComponent } from './info-producteur/info-producteur.component';
import { SectactComponent } from './sectact/sectact.component';
import { MenfamilleComponent } from './menfamille/menfamille.component';
import { HistappuiComponent } from './histappui/histappui.component';
import { ParcelleComponent } from './parcelle/parcelle.component';
import { MatCardModule } from '@angular/material/card';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { TableadminComponent } from './tableadmin/tableadmin.component';
import { TechnonvaliderComponent } from './technonvalider/technonvalider.component';
import { MatDialogModule } from '@angular/material/dialog';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DynamicInputComponent } from './dynamic-input/dynamic-input/dynamic-input.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { DragdropComponent } from './dragdrop/dragdrop.component';
import { NgxEchartsModule } from 'ngx-echarts';
import * as echarts from 'echarts';
import { GraphComponent } from './reporting/graph/graph.component';
import { StepperComponent } from './stepper/stepper.component';
import { PieComponent } from './reporting/typeChart/pie/pie.component';
import { LineComponent } from './reporting/typeChart/line/line.component';
import { BarComponent } from './reporting/typeChart/bar/bar.component';
import { TableComponent } from './reporting/typeChart/table/table.component';
export function chartModule(): any {
  return import('echarts');
}
import { MatExpansionModule } from '@angular/material/expansion';
import { RealisationtestComponent } from './realisationtest/realisationtest.component';
import { ReportingService } from './services/reporting/reporting.service';
import { ViewComponent } from './reporting/view/view.component';
import { ListeequeteComponent } from './listeequete/listeequete.component';
import { ListeEnqueteComponent } from './enquete/liste-enquete/liste-enquete.component';
import { DetailEnqueteComponent } from './enquete/detail-enquete/detail-enquete.component';
import { DynamicFormQuestionComponent } from './utils/dynamic-form-question/dynamic-form-question.component';
import { DynamicFormComponent } from './utils/dynamic-form/dynamic-form.component';
import { InfoIndivComponent } from './utils/info-indiv/info-indiv.component';
import { ValidationComponent } from './validation/validation/validation.component';
import { GlobalComponent } from './validation/global/global.component';
import { ListeRealisationComponent } from './validation/liste-realisation/liste-realisation.component';
import { ListeRealisationglobalComponent } from './validation/liste-realisationglobal/liste-realisationglobal.component';
import { SaveReferenceTestComponent } from './validation/save-reference-test/save-reference-test.component';
import { ListeParDaterealisationComponent } from './validation/liste-par-daterealisation/liste-par-daterealisation.component';
import { ListeSectionComponent } from './validation/liste-section/liste-section.component';
import { DetailsSectionComponent } from './validation/details-section/details-section.component';

import { DetailslisterealisationComponent } from './validation/detailslisterealisation/detailslisterealisation.component';
import { UpdaterealisationComponent } from './validation/updaterealisation/updaterealisation.component';
import { InitialComponent } from './realisationtest/initial/initial.component';

import { CreateAdminComponent } from './individu/create-admin/create-admin.component';
import { CreateTechComponent } from './individu/create-tech/create-tech.component';
import { MenuIndivComponent } from './individu/menu-indiv/menu-indiv.component';
import { ListobsevationComponent } from './validation/listobsevation/listobsevation.component';
import { NgxCopyPasteModule } from 'ngx-copypaste'
import { timeout } from 'rxjs/operators';

import { AffichageComponent } from './reporting/affichage/affichage.component';

import { ListeprodComponent } from './listeprod/listeprod.component';
import { DetailprodComponent } from './detailprod/detailprod.component';
import { DetailproducteurComponent } from './detailproducteur/detailproducteur.component';
import { SecteuractiviteComponent } from './secteuractivite/secteuractivite.component';
import { TerreComponent } from './terre/terre.component';
import { CiviliteComponent } from './civilite/civilite.component';
import { MemfamilleComponent } from './memfamille/memfamille.component';
import { AdrescontactComponent } from './adrescontact/adrescontact.component';
import { ProductionComponent } from './production/production.component';
import { EconomieComponent } from './economie/economie.component';
import { MaterielequipComponent } from './materielequip/materielequip.component';
import { ServicefinComponent } from './servicefin/servicefin.component';
import { AppuisComponent } from './appuis/appuis.component';
import { ContratagriComponent } from './contratagri/contratagri.component';
import { ContrainteComponent } from './contrainte/contrainte.component';
import { FournisseurComponent } from './fournisseur/fournisseur.component';
import { TransformationComponent } from './transformation/transformation.component';
import { LogistiqueComponent } from './logistique/logistique.component';
import { NutritionComponent } from './nutrition/nutrition.component';
import { FemmeComponent } from './femme/femme.component';
import { AssitancesocialComponent } from './assitancesocial/assitancesocial.component';
import { LabelisationComponent } from './labelisation/labelisation.component';
import { SuivicartComponent } from './suivicart/suivicart.component';
import { DetailsecteurComponent } from './secteuractivite/detailsecteur/detailsecteur.component';
import { ReportComponent } from './report/report.component';
import { EditprodComponent } from './editprod/editprod.component';
import { MatRadioModule } from '@angular/material/radio';
import { ExportExcelComponent } from './export-excel/export-excel.component';
import { ImportExcelComponent } from './import-excel/import-excel.component';
import { OrganisatinPaysaneComponent } from './organisatin-paysane/organisatin-paysane.component';
import { AjoutPaysaneComponent } from './ajout-paysane/ajout-paysane.component';
import { DialogOverviewExampleDialogComponent } from './detailprod/dialog-overview-example-dialog/dialog-overview-example-dialog.component';
import { FileInfoComponent } from './file-info/file-info.component';
import { GestionCarteComponent } from './gestion-carte/gestion-carte.component';
import { StatistiqueComponent } from './statistique/statistique.component';
import { GestionlocaliteComponent } from './gestionlocalite/gestionlocalite.component';
import { ModifInfoIndivComponent } from './modif-info-indiv/modif-info-indiv.component';
import { ModifMotDePassComponent } from './modif-mot-de-pass/modif-mot-de-pass.component';
import { CinComponent } from './cin/cin.component';
import { MotDePasseOublierComponent } from './mot-de-passe-oublier/mot-de-passe-oublier.component';
import { NouveauMotdePasseComponent } from './nouveau-motde-passe/nouveau-motde-passe.component';
import { ModifLocaliteComponent } from './modif-localite/modif-localite.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';






enableProdMode();

@NgModule({
  imports: [Ng2SearchPipeModule ,
    AgChartsAngularModule,
    MatRadioModule,
    NgxCopyPasteModule,
    CommonModule,
    BrowserModule,
    ChartsModule,
    MatExpansionModule,
    DragDropModule,
    MatCheckboxModule,
    BrowserAnimationsModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ComponentsModule,
    RouterModule,
    AppRoutingModule,
    DropDownsModule,
    NgbModule,
    MatPaginatorModule,
    MatTableModule,
    MatStepperModule,
    NgxSelectModule,
    MaterialFileInputModule,
    MatIconModule,
    MatCardModule,
    MatDatepickerModule,
    MatNativeDateModule,
    AgmCoreModule.forRoot({
      apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
    }),
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatAutocompleteModule,
    ToastrModule.forRoot({ timeOut: 30000000 }),
    MatSidenavModule,
    MatToolbarModule,
    MatDialogModule,
    MatSlideToggleModule,
    // NgxEchartsModule.forRoot({echarts: () => import('echarts')})
    NgxEchartsModule.forRoot({
      echarts: chartModule
    })
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ProfilIndividusComponent,
    CardTechComponent,
    CardComponent,
    InscriptionComponent,
    AjoutProdComponent,
    AcceuilsComponent,
    TableprodComponent,
    InfoProducteurComponent,
    SectactComponent,
    MenfamilleComponent,
    HistappuiComponent,
    ParcelleComponent,
    TableadminComponent,
    TechnonvaliderComponent,
    DynamicInputComponent,
    DragdropComponent,
    GraphComponent,
    StepperComponent,
    PieComponent,
    LineComponent,
    BarComponent,
    TableComponent,
    RealisationtestComponent,
    ListeequeteComponent,
    ListeEnqueteComponent,
    DetailEnqueteComponent,
    DynamicFormQuestionComponent,
    DynamicFormComponent,
    InfoIndivComponent,
    ValidationComponent,
    GlobalComponent,
    ListeRealisationComponent,
    ListeRealisationglobalComponent,
    SaveReferenceTestComponent,
    ListeParDaterealisationComponent,
    ListeSectionComponent,
    DetailsSectionComponent,
    CreateAdminComponent,
    CreateTechComponent,
    MenuIndivComponent,
    ViewComponent,
    ListeequeteComponent,
    DetailslisterealisationComponent,
    UpdaterealisationComponent,
    InitialComponent,

    ListeequeteComponent,

    ListobsevationComponent,

    ListeprodComponent,

    DetailprodComponent,

    DetailproducteurComponent,

    SecteuractiviteComponent,

    TerreComponent,

    CiviliteComponent,

    MemfamilleComponent,

    AdrescontactComponent,

    ProductionComponent,

    EconomieComponent,

    MaterielequipComponent,

    ServicefinComponent,

    AppuisComponent,

    ContratagriComponent,

    ContrainteComponent,

    FournisseurComponent,

    TransformationComponent,

    LogistiqueComponent,

    NutritionComponent,

    FemmeComponent,

    AssitancesocialComponent,

    LabelisationComponent,

    SuivicartComponent,

    DetailsecteurComponent,

    ReportComponent,

    AffichageComponent,

    EditprodComponent,

    ExportExcelComponent,

    ImportExcelComponent,

    OrganisatinPaysaneComponent,

    AjoutPaysaneComponent,

    DialogOverviewExampleDialogComponent,

    FileInfoComponent,

    GestionCarteComponent,

    StatistiqueComponent,

    GestionlocaliteComponent,

    ModifInfoIndivComponent,

    ModifMotDePassComponent,

    CinComponent,

    MotDePasseOublierComponent,

    NouveauMotdePasseComponent,

    ModifLocaliteComponent,
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    AccountService,
    InputService,
    ReportingService,
    MatDatepickerModule,
    MatNativeDateModule],
  bootstrap: [AppComponent],
  entryComponents: [GraphComponent]
})
export class AppModule { }

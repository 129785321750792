import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-economie',
  templateUrl: './economie.component.html',
  styleUrls: ['./economie.component.css']
})
export class EconomieComponent implements OnInit {
  id:string
  economie: any[]=[];
  constructor(
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getEconnomie()
  }

  getEconnomie(){
    this.list.getEconomieByMatricule(this.id).subscribe(resp=>{
      this.economie = resp.body
      
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { TestService } from '../../services/realisation/test.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { AccountService } from '../../services/account/account.service';

@Component({
  selector: 'app-detailslisterealisation',
  templateUrl: './detailslisterealisation.component.html',
  styleUrls: ['./detailslisterealisation.component.css']
})
export class DetailslisterealisationComponent implements OnInit {
  nom: string;
  dataRealisation: any;
  constructor(
    private test: TestService,
    private route: ActivatedRoute,
    private _location: Location,
    private router: Router,
    private accountService: AccountService
  ) { }

  ngOnInit(): void {
    this.nom = this.route.snapshot.paramMap.get('nom');
    this.test.getrealisationtestebycoderealisation(this.nom).subscribe(res=>{
      if (res.status === 200) {
        this.dataRealisation = res.body
      }
    }
     
      

      )
  }
  retour() { 
    this._location.back();
  }
  modifier(id: any){
    this.router.navigate(['updaterealisation/'+id])
  }
  logout() {
    this.accountService.logout();
    this.router.navigate(['/login']);
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';
//import * as XLSX from 'xlsx';

@Component({
  selector: 'app-terre',
  templateUrl: './terre.component.html',
  styleUrls: ['./terre.component.css']
})
export class TerreComponent implements OnInit {
  panelOpenState = false;
  id: string;
  terre: any[]=[];
  possessionModes: any[]=[];
  residusRecoltes: any[]=[];
  parcelles: any[]=[];
  culturePerennes: any[]=[];
  gestionBiomasses: any[]=[];
  systemeRizicoles: any[]=[];
  occupationSols: any[]=[];
  culturePerenne: any[]=[];
  terreCultive: any[]=[];
  typeSol: any[]=[];

  constructor(
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getTerre()
  }
  getTerre(){
    this.list.getTerre(this.id).subscribe(resp=>{
      this.terre = resp.body
      this.possessionModes = resp.body.possessionModes
      this.residusRecoltes = resp.body.residusRecoltes
      this.parcelles = resp.body.parcelles
      this.culturePerennes = resp.body.cultureLigneusePerennes
      this.gestionBiomasses = resp.body.gestionBiomasses
      this.systemeRizicoles = resp.body.systemeRizicoles
      this.occupationSols = resp.body.occupationSols
      this.culturePerenne = resp.body.culturePerennes
      this.terreCultive = resp.body.terreCultives
      this.typeSol = resp.body.typeSols
    })
  }
}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListService } from '../services/list/list.service';

@Component({
  selector: 'app-contrainte',
  templateUrl: './contrainte.component.html',
  styleUrls: ['./contrainte.component.css']
})
export class ContrainteComponent implements OnInit {

  id:string
  contrainte: any[]=[];
  agricole: any[]=[];
  elevage: any[]=[];
  aqua: any[]=[];
  forestiere: any[]=[];
  constructor(
    
    private route: ActivatedRoute,
    private list: ListService
  ) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id')
    this.getContrainte();
  }
  getContrainte(){
    this.list.getContrainte(this.id).subscribe(resp=>{
      this.contrainte = resp.body
      /* this.agricole = resp.body.agricole
      this.elevage = resp.body.elevage
      this.aqua = resp.body.aquaculture
      this.forestiere = resp.body.forestiere */
      
    })
  }

}
